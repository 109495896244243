<!--Template-->

<template>
    <div class="list-wrapper col-md-6">
        <div :class="[ 'list', { 'checked': checked } ]"
             @click="toggleSelection"
        >
            <div class="details">
                <div class="title">{{ title }}</div>
                <div class="description">{{ creationDate }}</div>
            </div>

            <a class="preview"
               :href="'http://www.inlinkz.com/new/previewProject.php?id=' + id"
               target="_blank"
               @click.stop
            >
                <span>
                    <div class="text">preview</div>
                    <div class="fa fa-eye"></div>
                </span>
            </a>
        </div>
    </div>
</template>

<!--Style-->
<style lang='less' scoped>
    @import "../../../less/mixins";
    @import "../../../less/libs/flat-ui/variables";

    .list-wrapper {
        padding: 0 4px;

        > .list {
            background: #eee;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
            width: 100%;
            margin-bottom: 8px;
            display: flex;
            overflow: hidden;

            &.checked {
                background: mix(#eee, @brand-secondary);

                > .preview {
                    background: mix(#eee, @brand-secondary, 65%);
                }
            }

            > .details {
                padding: 10px;
                width: ~'calc(100% - 70px)';

                > .title {
                    font-size: 20px;
                    font-weight: bolder;
                    line-height: 1;
                }

                > .description {
                    font-size: 13px;
                    margin-top: 10px;
                }

                > * {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            > .preview {
                width: 70px;
                position: relative;
                font-size: 20px;
                background: #eee;
                color: #555;
                transition: 0.3s color ease;

                > span {
                    .centered-block;

                    text-align: center;

                    > .text {
                        font-size: 12px;
                        margin-bottom: -3px;
                        font-weight: bolder;
                    }
                }

                &:hover {
                    color: @brand-secondary;
                }
            }
        }
    }
</style>

<!--Script-->
<script  >
    export default{
        props: {
            store: {
                type: Object,
                default: {}
            },
            data: {
                type: Object,
                default: {}
            },
            checked: {
                type: Boolean,
                default: true
            }
        },

        created() {
            _.merge(this, this.data);
        },

        computed: {
            migrationLists: {
                get() {
                    return this.store.getters.getMigrationLists;
                },
                set(val) {
                    this.store.dispatch('setMigrationLists', val);
                }
            },

            creationDate()
            {
                return moment(this.creationTime).format('dddd Do of MMMM YYYY (HH:mm)');
            }
        },

        methods: {
            select(bool = true) {
                _.each(this.migrationLists, (list, index) => {
                    if (list.id === this.id) {
                        this.$set(this.migrationLists[index], 'checked', bool);
                    }
                });
            },
            toggleSelection() {
                this.select(!this.checked);
            },
            destroySelf() {
                this.$events.emit('destroyMigrationLink', this.id);
            }
        }
    }
</script>
