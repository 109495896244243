<template>
    <modal :show="show"
           :full="full"
           :title="title"
           :okText="okText"
           :cancelText="cancelText"
           :removeClose="removeClose"
           opacity="half"
           @cancel="cancel"
           @ok="getStarted"
    >
        <div class="content-wrapper" style="text-align: center;">
            <div class="media">
                <img :src="badgeImg" alt="15 day trial image">
            </div>
            <div class="content">
                <span>Start a Risk-Free 15-Day Trial</span>
                <br>
                (No Credit Card Required)

                <br>
                <div class="small-letters">
                    Trial plan offers all <strong>PLATINUM</strong> features
                </div>
            </div>
        </div>
    </modal>
</template>

<script  >
    import Modal from '../lib/components/Modal';

    export default{
        components: {
            'modal': Modal,
        },

        props: {
            badgeImg: {
                type: String,
                required: true
            },
            show: {
                type: Boolean,
                default: false
            },
        },

        data(){
            return {
                removeClose: true,
                full: false,
                title: 'Try the Platinum Tier 100% Free',
                okText: "GET STARTED",
                cancelText: "Close",
            }
        },


        methods: {
            getStarted() {
                window.location.href = this.env.APP_URL + '/register';
            },


            cancel() {
                setTimeout(() => {
                    this.show = false
                }, 100);
            },
        },
    }
</script>

<style lang="less">
    .content-wrapper {
        text-align: center;
    }

    .media {

        img {
            width: 150px;
        }
    }

    .content {
        > span {
            font-size: 22px;
        }

        margin-top: 20px;

        .small-letters {
            margin-top: 30px;
            font-size: 16px;
        }
    }
</style>
