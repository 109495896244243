var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { title: _vm.title, show: _vm.show, full: true },
      on: { cancel: _vm.cancel }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentMode === "user",
              expression: "currentMode === 'user'"
            }
          ]
        },
        [
          _c("div", { staticClass: "form-group search-tags" }, [
            _c(
              "div",
              { staticClass: "input-group input-group input-group-rounded" },
              [
                _c("span", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    { staticClass: "btn", attrs: { type: "submit" } },
                    [_c("span", { staticClass: "fui-search" })]
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Search tags" },
                  domProps: { value: _vm.search },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.search = $event.target.value
                    }
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: ["tags-wrapper", { empty: !_vm.tags.length }] },
            [
              _c("scrollbar", { attrs: { type: "macosx" } }, [
                _c(
                  "div",
                  { class: ["user-tags", { empty: !_vm.tags.length }] },
                  [
                    !_vm.tags.length
                      ? _c("span", [
                          _c("div", [_vm._v("No tags have been created yet.")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "smaller" }, [
                            _vm._v("Your product tags will show up here.")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.filteredTags, function(tag) {
                      return _c("div", { staticClass: "tag" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: tag.name,
                              expression: "tag.name"
                            }
                          ],
                          ref: "tagname",
                          refInFor: true,
                          class: [
                            "form-control",
                            "input-sm",
                            { disabled: !tag.editing }
                          ],
                          domProps: { value: tag.name },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.updateTag(tag)
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(tag, "name", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "buttons" }, [
                          tag.editing
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary hint--top hint--rounded",
                                  attrs: { "aria-label": "save" },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateTag(tag)
                                    }
                                  }
                                },
                                [_c("span", { staticClass: "fa fa-save" })]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-warning hint--top hint--rounded",
                                  attrs: { "aria-label": "edit" },
                                  on: {
                                    click: function($event) {
                                      return _vm.editTag(tag.id)
                                    }
                                  }
                                },
                                [_c("span", { staticClass: "fa fa-pencil" })]
                              ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger hint--top hint--rounded",
                              attrs: {
                                "tag-id": tag.id,
                                "aria-label": "delete"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deleteTag(tag.id)
                                }
                              }
                            },
                            [_c("span", { staticClass: "fa fa-trash-o" })]
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentMode === "product",
              expression: "currentMode === 'product'"
            }
          ]
        },
        [
          _c("tag-select-box", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.linkTags.length,
                expression: "linkTags.length"
              }
            ],
            attrs: {
              owner: "link",
              linkid: _vm.productId,
              show: true,
              "link-tags": _vm.linkTags
            },
            on: { selectionchanged: _vm.onLinkTagsChange }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.linkTags.length,
                  expression: "!linkTags.length"
                }
              ],
              staticStyle: {
                "text-align": "center",
                color: "#888",
                height: "73px",
                padding: "25px"
              }
            },
            [_vm._v("\n            This product has no tags\n        ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "add-tag" }, [
            !_vm.addingTag
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm",
                    on: { click: _vm.addTagClicked }
                  },
                  [
                    _c("span", { staticClass: "fa fa-plus" }),
                    _vm._v("Add new\n            ")
                  ]
                )
              : _c("div", { staticClass: "new-tag" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.tagName,
                        expression: "tagName"
                      }
                    ],
                    ref: "newTag",
                    staticClass: "form-control input-sm",
                    attrs: { type: "text", placeholder: "Your new tag" },
                    domProps: { value: _vm.tagName },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.addTag($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.tagName = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group" }, [
                    _c(
                      "button",
                      {
                        class: [
                          "btn",
                          "btn-primary",
                          "hint--top",
                          "hint--rounded",
                          { unabled: !_vm.tagName.length }
                        ],
                        attrs: { "aria-label": "save" },
                        on: { click: _vm.addTag }
                      },
                      [_c("span", { staticClass: "fa fa-check" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-darkgray hint--top hint--rounded",
                        attrs: { "aria-label": "close" },
                        on: { click: _vm.cancelAddTag }
                      },
                      [_c("span", { staticClass: "fa fa-times" })]
                    )
                  ])
                ])
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "form-group search-tags" }, [
            _c(
              "div",
              { staticClass: "input-group input-group input-group-rounded" },
              [
                _c("span", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    { staticClass: "btn", attrs: { type: "submit" } },
                    [_c("span", { staticClass: "fui-search" })]
                  )
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Search tags" },
                  domProps: { value: _vm.search },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.search = $event.target.value
                    }
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: ["tags-wrapper for-link", { empty: !_vm.tags.length }] },
            [
              _c("scrollbar", { attrs: { type: "macosx" } }, [
                _c(
                  "div",
                  { class: ["user-tags", { empty: !_vm.tags.length }] },
                  [
                    !_vm.tags.length
                      ? _c("span", [
                          _c("div", [_vm._v("No tags have been created yet.")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "smaller" }, [
                            _vm._v("Your product tags will show up here.")
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.filteredTags, function(tag) {
                      return _c(
                        "div",
                        {
                          class: [
                            "tag",
                            {
                              selectable: !tag.editing,
                              selected: _vm.belongsToLink(tag)
                            }
                          ],
                          on: {
                            click: function($event) {
                              return _vm.toggleSelectTag(tag)
                            }
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: tag.name,
                                expression: "tag.name"
                              }
                            ],
                            ref: "linkTagname",
                            refInFor: true,
                            class: [
                              "form-control",
                              "input-sm",
                              { disabled: !tag.editing }
                            ],
                            domProps: { value: tag.name },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.updateTag(tag)
                              },
                              click: function($event) {
                                $event.stopPropagation()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(tag, "name", $event.target.value)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "buttons" }, [
                            tag.editing
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary hint--top hint--rounded",
                                    attrs: { "aria-label": "save" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.updateTag(tag)
                                      }
                                    }
                                  },
                                  [_c("span", { staticClass: "fa fa-save" })]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-warning hint--top hint--rounded",
                                    attrs: { "aria-label": "edit" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.editTag(tag.id)
                                      }
                                    }
                                  },
                                  [_c("span", { staticClass: "fa fa-pencil" })]
                                ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-danger hint--top hint--rounded",
                                attrs: {
                                  "tag-id": tag.id,
                                  "aria-label": "delete"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteTag(tag.id)
                                  }
                                }
                              },
                              [_c("span", { staticClass: "fa fa-trash-o" })]
                            )
                          ])
                        ]
                      )
                    })
                  ],
                  2
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.currentMode === "user"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-embossed btn-darkgray",
                  on: { click: _vm.cancel }
                },
                [_vm._v("\n            Close\n        ")]
              )
            : [
                _c(
                  "button",
                  {
                    class: [
                      "btn",
                      "btn-embossed",
                      "btn-primary",
                      { unabled: !_vm.linkTagsCanSave }
                    ],
                    on: { click: _vm.save }
                  },
                  [_vm._v("\n                Save\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-embossed btn-darkgray",
                    on: { click: _vm.cancel }
                  },
                  [_vm._v("\n                Close\n            ")]
                )
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }