var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6 col-md-offset-3" }, [
        _c(
          "div",
          { staticClass: "wrapper" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("drop-in", {
              attrs: {
                submitRoute: "/payment-method",
                clientToken: _vm.details.clientToken,
                buttonText: "Update payment method (no charge)",
                type: "change"
              }
            }),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "payment-info" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "braintree" }, [
                _c("a", { attrs: { href: _vm.badgeLink, target: "_blank" } }, [
                  _c("img", {
                    attrs: {
                      src:
                        "https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png",
                      width: "250px",
                      height: "44px",
                      border: "0"
                    }
                  })
                ])
              ])
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v(
          "\n                        Current payment method\n                    "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "credit-cards" }, [
      _c("img", { attrs: { src: "/img/credit_cards.png" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }