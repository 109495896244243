var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { ready: _vm.ready }, attrs: { id: "product-list" } },
    [
      _vm.groupedListLinks.length
        ? _c(
            "draggable",
            {
              staticClass: "products",
              attrs: { options: _vm.draggableOptions },
              on: { end: _vm.onDragEnd },
              model: {
                value: _vm.groupedListLinks,
                callback: function($$v) {
                  _vm.groupedListLinks = $$v
                },
                expression: "groupedListLinks"
              }
            },
            _vm._l(_vm.groupedListLinks, function(link, index) {
              return _c("product", {
                key: link.id,
                ref: "product",
                refInFor: true,
                attrs: {
                  index: index,
                  data: link,
                  inlist: true,
                  draggable: true,
                  user: _vm.user
                },
                on: {
                  savelist: _vm.saveList,
                  removefromlist: _vm.removeFromList
                }
              })
            }),
            1
          )
        : _c("div", { staticClass: "empty-msg" }, [
            _c("h5", [_vm._v("Your list is empty")]),
            _vm._v("\n        Add your products now!\n    ")
          ]),
      _vm._v(" "),
      typeof _vm.affiliateLinks === "string"
        ? [
            _c(
              "modal",
              {
                attrs: {
                  title: "Preview link",
                  "cancel-text": "Close",
                  show: _vm.previewModalShow,
                  "ok-class": "hidden"
                },
                on: {
                  cancel: function($event) {
                    _vm.previewModalShow = false
                  }
                }
              },
              [
                _c("div", { staticClass: "affiliate-title" }, [
                  _c("b", [_vm._v("Affiliate URL")]),
                  _vm._v(" "),
                  _c("span", [_vm._v("(click to visit)")])
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "affiliate-link",
                    attrs: { href: _vm.affiliateLinks, target: "_blank" }
                  },
                  [_c("pre", [_vm._v(_vm._s(_vm.affiliateLinks))])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#c0392b",
                      "font-size": "12px",
                      "text-align": "center",
                      "margin-top": "35px",
                      "line-height": "1.3"
                    }
                  },
                  [
                    _c("div", [
                      _vm._v("The link above is for preview purposes only.")
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("Please do not copy/paste it in your blog.")
                    ])
                  ]
                )
              ]
            )
          ]
        : [
            _c(
              "modal",
              {
                attrs: {
                  title: "Preview links",
                  "cancel-text": "Close",
                  show: _vm.previewModalShow,
                  "ok-class": "hidden"
                },
                on: {
                  cancel: function($event) {
                    _vm.previewModalShow = false
                  }
                }
              },
              [
                _c("div", { staticClass: "affiliate-title" }, [
                  _c("b", [_vm._v("Affiliate URLs")]),
                  _vm._v(" "),
                  _c("span", [_vm._v("(click to visit)")])
                ]),
                _vm._v(" "),
                _vm._l(_vm.affiliateLinks, function(affiliateLink) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "affiliate-link",
                        attrs: { href: affiliateLink, target: "_blank" }
                      },
                      [_c("pre", [_vm._v(_vm._s(affiliateLink))])]
                    )
                  ]
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#c0392b",
                      "font-size": "12px",
                      "text-align": "center",
                      "margin-top": "35px",
                      "line-height": "1.3"
                    }
                  },
                  [
                    _c("div", [
                      _vm._v("The links above are for preview purposes only.")
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("Please do not copy/paste them in your blog.")
                    ])
                  ]
                )
              ],
              2
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }