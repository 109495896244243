var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      class: {
        compulsory: _vm.invoiceDetailsMissing || !_vm.invoiceDetailsComplete
      },
      attrs: {
        id: "invoice-modal",
        title: "Invoice details",
        show: _vm.detailsModalShow
      },
      on: {
        cancel: function($event) {
          _vm.invoiceDetailsMissing || !_vm.invoiceDetailsComplete
            ? function() {}
            : (_vm.detailsModalShow = false)
        }
      }
    },
    [
      _vm.invoiceDetailsMissing
        ? _c("div", { staticClass: "error-msg" }, [
            _c("div", [
              _vm._v("Please provide your address for your invoices.")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("label", { staticClass: "control-label" }, [_vm._v("First Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.invoiceDetails.firstName,
                expression: "invoiceDetails.firstName"
              }
            ],
            ref: "firstname",
            staticClass: "form-control input-sm",
            attrs: {
              autocomplete: "on",
              type: "text",
              placeholder: "first name"
            },
            domProps: { value: _vm.invoiceDetails.firstName },
            on: {
              click: function($event) {
                return _vm.$refs.firstname.select()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.invoiceDetails, "firstName", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("label", { staticClass: "control-label" }, [_vm._v("Last Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.invoiceDetails.lastName,
                expression: "invoiceDetails.lastName"
              }
            ],
            ref: "lastname",
            staticClass: "form-control input-sm",
            attrs: {
              autocomplete: "on",
              type: "text",
              placeholder: "last name"
            },
            domProps: { value: _vm.invoiceDetails.lastName },
            on: {
              click: function($event) {
                return _vm.$refs.lastname.select()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.invoiceDetails, "lastName", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("label", { staticClass: "control-label" }, [_vm._v("Address")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.invoiceDetails.address,
                expression: "invoiceDetails.address"
              }
            ],
            ref: "address",
            staticClass: "form-control input-sm",
            attrs: { autocomplete: "on", type: "text", placeholder: "address" },
            domProps: { value: _vm.invoiceDetails.address },
            on: {
              click: function($event) {
                return _vm.$refs.address.select()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.invoiceDetails, "address", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12" }, [
          _c("label", { staticClass: "control-label" }, [
            _vm._v("City/Region")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.invoiceDetails.region,
                expression: "invoiceDetails.region"
              }
            ],
            ref: "region",
            staticClass: "form-control input-sm",
            attrs: { autocomplete: "on", type: "text", placeholder: "region" },
            domProps: { value: _vm.invoiceDetails.region },
            on: {
              click: function($event) {
                return _vm.$refs.region.select()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.invoiceDetails, "region", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("label", { staticClass: "control-label" }, [
            _vm._v("Postal Code")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.invoiceDetails.postalCode,
                expression: "invoiceDetails.postalCode"
              }
            ],
            ref: "postalCode",
            staticClass: "form-control input-sm",
            attrs: {
              autocomplete: "on",
              type: "text",
              placeholder: "postal code"
            },
            domProps: { value: _vm.invoiceDetails.postalCode },
            on: {
              click: function($event) {
                return _vm.$refs.postalCode.select()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.invoiceDetails, "postalCode", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("label", { staticClass: "control-label" }, [_vm._v("Country")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.invoiceDetails.countryCode,
                expression: "invoiceDetails.countryCode"
              }
            ],
            ref: "countryCode",
            staticClass: "form-control input-sm",
            attrs: {
              autocomplete: "on",
              type: "text",
              placeholder: "country code"
            },
            domProps: { value: _vm.invoiceDetails.countryCode },
            on: {
              click: function($event) {
                return _vm.$refs.countryCode.select()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.invoiceDetails, "countryCode", $event.target.value)
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            class: [
              "btn",
              "btn-embossed",
              "btn-primary",
              { unabled: _vm.invoiceDetailsMissing }
            ],
            staticStyle: { width: "69px" },
            on: { click: _vm.saveUserDetails }
          },
          [
            !_vm.saving
              ? [_vm._v("Save")]
              : _c("span", {
                  staticClass: "fa fa-circle-o-notch fa-spin fa-fw"
                })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: [
              "btn",
              "btn-embossed",
              "btn-darkgray",
              _vm.invoiceDetailsMissing || !_vm.invoiceDetailsComplete
                ? "hidden"
                : "btn-darkgray"
            ],
            on: {
              click: function($event) {
                _vm.invoiceDetailsMissing || !_vm.invoiceDetailsComplete
                  ? function() {}
                  : (_vm.detailsModalShow = false)
              }
            }
          },
          [_vm._v("\n            Close\n        ")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }