import { render, staticRenderFns } from "./PlanReminder.vue?vue&type=template&id=c23f3930&"
import script from "./PlanReminder.vue?vue&type=script&lang=js&"
export * from "./PlanReminder.vue?vue&type=script&lang=js&"
import style0 from "./PlanReminder.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/linkdeli/node_modules/vue-loader/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c23f3930')) {
      api.createRecord('c23f3930', component.options)
    } else {
      api.reload('c23f3930', component.options)
    }
    module.hot.accept("./PlanReminder.vue?vue&type=template&id=c23f3930&", function () {
      api.rerender('c23f3930', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/lib/components/PlanReminder.vue"
export default component.exports