var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid-item" }, [
    _c(
      "div",
      {
        staticClass: "thumbnail ld-thumb",
        style: { "background-color": _vm.backgroundColor, height: _vm.height }
      },
      [
        _c(
          "div",
          { staticClass: "image-wrapper" },
          [
            _c(
              "image-square",
              {
                attrs: {
                  src: _vm.linkdata.thumb_path,
                  alt: _vm.linkdata.title,
                  opacity: 0.7,
                  roundness: _vm.imageRoundness,
                  "spinner-color": "#aaa"
                },
                on: { "image-errored": _vm.requestImageRePull }
              },
              [
                _c("a", {
                  staticClass: "link-layer",
                  attrs: {
                    href: _vm.linkUrl(_vm.firstShop),
                    target: "_blank",
                    rel: "nofollow"
                  }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.options.enabled.title
          ? _c(
              "div",
              {
                staticClass: "title-wrapper",
                style: { "min-height": _vm.titleMinHeight }
              },
              [
                _c(
                  "div",
                  {
                    ref: "title",
                    staticClass: "product-title",
                    style: { "font-size": _vm.fontSize },
                    on: { click: _vm.resetTrunk }
                  },
                  [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.options.enabled.shopLinks
          ? [
              _vm.options.shopLinks.text !== null &&
              _vm.options.shopLinks.text.length
                ? _c("div", { staticClass: "caption ld-caption" }, [
                    _c("div", { staticClass: "shopfrom-title" }, [
                      _vm._v(_vm._s(_vm.options.shopLinks.text))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "shop_thumb_container" }, [
                _c(
                  "div",
                  { staticClass: "shops-wrapper" },
                  _vm._l(_vm.shops, function(shop) {
                    return _c(
                      "span",
                      {
                        staticClass:
                          "shop_thumb_wrapper hint--top hint--rounded",
                        attrs: { "aria-label": shop.name }
                      },
                      [
                        _c(
                          "span",
                          [
                            _c(
                              "logo-image",
                              {
                                attrs: {
                                  src: shop.thumb,
                                  side: "26px",
                                  "extra-classes": "shop_thumb",
                                  "is-round": false
                                }
                              },
                              [
                                _c("a", {
                                  attrs: {
                                    href: _vm.linkUrl(shop),
                                    target: "_blank",
                                    rel: "nofollow"
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }