<!--Receives products and displays very small thumbs preview with remove icons-->
<!--Template-->
<template>
    <div class=parent>
        <div class="container scrollme tableContainer">

            <table class="table-responsive table-hover">
                <tbody>
                <tr>
                    <td v-for="l in list">
                        <product :data=l :inlist=true tsize="t"></product>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
        <div v-if='listId>0' class="menu">
            <a class="btn-primary bt btn btn-xs" target=_blank :href="'widget/' + listUuid"><i
                    class="fa fa-eye"></i></a>
            <a @click="edit()" class="btn-default bt btn btn-xs" :href="'lists/' + listId"><i
                    class="fa fa-pencil"></i></a>
            <button @click="remove()" class="bt btn btn-xs btn-danger"><i class="fa fa-remove"></i></button>
        </div>
    </div>
</template>

<!--Style-->
<style scoped lang='less'>
    .list {
        list-style-type: none;
    }

    .parent {
        position: relative;
    }

    .bt {
        width: 28px !important;
    }

    .menu {
        display: inline-block;
        width: 30px;
        height: 75px;
        border-left: 2px dashed gray;
        vertical-align: top;
        padding-left: 3px;

    }

    .scrollme {
        /*width: 100%;*/
        width: calc(~"100% - 40px");
        display: inline-block;
        overflow-x: auto;
        overflow-y: hidden;
    }

    table {
        padding: 4px;
    }

</style>

<!--Script-->
<script  >
    import Product from './Product.vue'
    export default{
        props: {
            'list': {
                type: Array
            },
            'listId': Number,
            'listUuid': ""
        },
        watch: {
//            'list': function () {
//                console.log("List watch");
//
//            }
        },
        mounted() {
        },
        data(){
            return {
                links: []
            }
        },
        methods: {
            renderLinks() {
                let self = this;
            },
            view() {

            },
            edit() {

            },
            remove() {

            }

        },
        components: {
            product: Product
        },
        events: {}
    }
</script>
