var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show"
          }
        ],
        staticClass: "modal-wrapper"
      },
      [
        _c(
          "div",
          {
            staticClass: "modal",
            on: {
              mousedown: function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.clickMask($event)
              }
            }
          },
          [
            _c(
              "div",
              { ref: "dialog", class: ["modal-dialog", _vm.modalClass] },
              [
                _c(
                  "div",
                  { staticClass: "modal-content", attrs: { id: _vm.id } },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-header" },
                      [
                        _vm.removeClose
                          ? [
                              _c(
                                "a",
                                {
                                  staticClass: "close",
                                  attrs: { type: "button" },
                                  on: { click: _vm.cancel }
                                },
                                [
                                  _vm._v(
                                    "\n                                x\n                            "
                                  )
                                ]
                              )
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "h4",
                          { staticClass: "modal-title" },
                          [
                            _vm._t("title", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.title) +
                                  "\n                            "
                              )
                            ])
                          ],
                          2
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-body" },
                      [_vm._t("default")],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-footer" },
                      [
                        _vm._t("footer", [
                          _c(
                            "button",
                            {
                              ref: "okButton",
                              class: ["btn", "btn-embossed", _vm.okClass],
                              on: { click: _vm.ok }
                            },
                            [
                              _vm.loading
                                ? _c("span", {
                                    staticClass:
                                      "fa fa-circle-o-notch fa-spin fa-fw"
                                  })
                                : [_vm._v(_vm._s(_vm.okText))]
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              class: ["btn", "btn-embossed", _vm.cancelClass],
                              on: { click: _vm.cancel }
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.cancelText) +
                                  "\n                            "
                              )
                            ]
                          )
                        ])
                      ],
                      2
                    )
                  ]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { class: ["modal-backdrop", _vm.opacity] })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }