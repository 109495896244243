// Template

<template>
    <div class="container">
        <div id="shops" class="row">

            <div v-if="!isTeamMember" class="search col-md-12">
                <div id="search-shop" class="input-group input-group-hg input-group-rounded flat">
                    <span class="input-group-btn">
                        <button type="submit" class="btn">
                            <span class="fa fa-search"></span>
                        </button>
                    </span>
                    <input type="text"
                           v-model="searchString"
                           class="form-control flat"
                           placeholder="Search for shop..."
                    >
                </div>
                <div class="request-shop-oneliner">
                    Didn't find the shop you were looking for?
                    <button style='margin-left:15px;' class="btn btn-primary btn-embossed"
                            @click="addShop"
                    >
                        Add it!
                    </button>
                </div>
            </div>

            <span class="shops">
                <div class="user-shops col-md-6">
                    <div class="title">Your Shops</div>
                    <div class="content"
                         ref="userShopsContent"
                    >
                        <template v-if="userShopsCache.length">
                            <shop v-for="shop in userShopsCache"
                                  :data="shop"
                                  type="user"
                                  :key="shop.id"
                            ></shop>
                        </template>

                        <div v-else
                             class="no-shops"
                        >
                            You have no shops yet
                        </div>
                    </div>
                </div>

                <div class="all-shops col-md-6">
                    <div class="title">Available Shops</div>
                    <div class="content-wrapper"
                         ref="allShopsContent"
                    >
                        <scrollbar>
                            <div class="content">
                                <div id="all-shops-wrapper">
                                    <template v-if="filteredAllShops.length">
                                         <shop v-for="shop in filteredAllShops"
                                               :data="shop"
                                               type="all"
                                               :key="shop.id"
                                         ></shop>
                                    </template>

                                    <div v-else
                                         class="no-shops"
                                    >

                                        <template v-if="searchString.length">
                                        <div>No shops found for "{{ searchString }}"</div>
                                            </template>
                                        <template v-else>
                                        <div>No shops available</div>
                                            </template>

                                    </div>

                                    <br>

                                    <div v-if="!isTeamMember" class="request-shop">
                                        <button class="btn btn-primary btn-embossed"
                                                @click="addShop"
                                        >
                                            Request shop
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </scrollbar>
                    </div>
                </div>
            </span>
        </div>

        <br>
        <br>
        <br>

        <modal :show="addShopModal.show"
               :small="true"
               :title="!shopNotFound ? 'Add shop' : 'Request shop'"
               @cancel="closeAddShop"
        >
            <div v-show="!newShop && !shopNotFound"
                 :class="[ 'form-group', 'affiliate-url', { 'has-error': affiliateErrorMsg.length } ]"
            >
                <div class="info-box">For shops in <b>ShareASale, CJ affiliate or Rakuten/Linkshare</b>, please provide a deep affiliate link of the shop you want to add</div>

                <label>Deep affiliate link</label>

                <div class="url-input">
                    <input v-model="affiliateLink"
                           type="text"
                           :class="[ 'form-control', { 'fetching': loadingShop } ]"
                           placeholder="please enter a deep affiliate link"
                           @click="$event.target.select()"
                    >
                    <loader v-if="loadingShop"
                            side="20px"
                    ></loader>
                </div>

                <div class="row">
                    <div class="col-md-12" style="margin-top: 8px; font-size: 12px;">
                        <a class="pull-right" href="/page/how-do-i-find-a-deep-link-in-the-affiliate-networks-dashboard" target="_blank">How do I get a deep affiliate link?</a>
                    </div>
                    <hr>
                    <div class="col-md-12" style="margin-top: 8px; font-size: 13px;">
                       If the shop is not in <b>ShareASale, CJ or Rakuten/LinkShare</b>, please click <b><a href="/shops/request" target="_blank">here to request it.</a></b>
                    </div>
                </div>

                <div v-if="affiliateErrorMsg.length"
                     class="error-msg"
                >
                    <span v-html="affiliateErrorMsg"></span>
                </div>
            </div>

            <div v-if="newShop"
                 class="shop-found"
            >
                <!--<div class="text">Shop found</div>-->

                <div class="new-shop">
                    <logo-image :src="newShop.icon"
                                side="30px"
                    ></logo-image>
                    <div class="text">{{ newShop.name }}</div>
                </div>
            </div>

            <div v-if="shopNotFound"
                 class="shop-not-found"
            >
                <div class="info-box">
                    <div>Your shop could not be found.</div>
                    <div>Please provide some details.</div>
                </div>

                <div class="form-group">
                    <label>Shop URL</label>

                    <input v-model="shopToRequest.url"
                           type="text"
                           class="form-control"
                           placeholder="please enter shop URL"
                           @click="$event.target.select()"
                    >
                </div>

                <div class="form-group">
                    <label>Sample affiliate links</label>

                    <textarea v-model="shopToRequest.links"
                              rows="4"
                              type="text"
                              class="form-control"
                              placeholder="please include a couple of your own affiliate links to this shop"
                              @click="$event.target.select()"
                    ></textarea>
                </div>
            </div>

            <div slot="footer">
                <button v-if="!shopNotFound"
                        :class="[ 'btn', 'btn-embossed', 'btn-primary', { 'unabled': !newShop || savingShop } ]"
                        @click="saveNewShop"
                >
                    <template v-if="!savingShop">Add</template>
                    <span v-else
                          class="fa fa-circle-o-notch fa-spin fa-fw"
                    ></span>
                </button>

                <button v-else
                        :class="[ 'btn', 'btn-embossed', 'btn-primary', { 'unabled': !canRequestShop || savingShop } ]"
                        @click="requestShop"
                >
                    <template v-if="!savingShop">Request</template>
                    <span v-else
                          class="fa fa-circle-o-notch fa-spin fa-fw"
                    ></span>
                </button>

                <button class="btn btn-embossed btn-darkgray"
                        @click="closeAddShop"
                >
                    Close
                </button>
            </div>
        </modal>
    </div>
</template>

// Style

<style lang="less" scoped>
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    #shops {
        margin-top: 30px;

        > .search {

            > .input-group {

                > input,
                > span > button {
                    background: #eee !important;
                    border: none;
                }

                > input {
                    padding-left: 5px !important;
                    font-size: 15px;
                }
            }

            padding: 0;
            margin-bottom: 40px;
        }

        > .shops {
            min-height: 600px;
            display: block;

            > div {

                > .title {
                    text-align: center;
                    background: @brand-secondary;
                    padding: 20px;
                    border-radius: 5px;
                    color: #fff;
                    font-size: 23px;
                    font-weight: bold;
                }

                .content {
                    padding: 20px;
                    padding-bottom: 0;

                    .no-shops {
                        text-align: center;
                        font-weight: lighter;
                        font-size: 20px;
                        color: #aaa;
                        margin-top: 10px;
                    }
                }

                &.all-shops {

                    > .title {
                        background: #454545;
                    }
                }
            }
        }
    }

    .request-shop {
        text-align: center;
        color: #555;
        padding: 20px;
        background: #f5f5f5;

        > div {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 15px;
        }
    }

    .request-shop-oneliner {
        text-align: right;
        color: #555;
        padding: 20px;
        /*background: #f5f5f5;*/

        > div {
            font-size: 18px;
            font-weight: bold;
            /*margin-bottom: 15px;*/
        }
    }


    .affiliate-url {

        > .url-input {
            position: relative;

            > input.fetching {
                padding-right: 40px;
            }

            > .loader {
                position: absolute;
                top: 11px;
                right: 11px;
            }
        }

        > .error-msg {
            color: @brand-danger;
            font-size: 13px;
            text-align: center;
            margin-top: 10px;
        }
    }

    .shop-found {

        > .text {
            margin-bottom: 10px;
            text-align: center;
            font-size: 20px;
            font-weight: lighter;
            color: #888;
        }

        > .new-shop {
            display: flex;

            > .logo {
                height: 30px;
                flex: 30px 0 0 ;
            }

            > .text {
                font-size: 14px;
                position: relative;
                top: 3px;
                overflow: hidden;
                text-align: center;
                width: 100%;
                text-overflow: ellipsis;
                font-weight: bolder;
                padding-left: 10px;
            }
        }
    }

    .affiliate-url,
    .shop-not-found {

        > .info-box {
            padding: 10px;
            border-radius: 5px;
            color: #fff;
            background: @brand-info;
            text-align: center;
            margin-bottom: 15px;
            font-size: 15px;
            line-height: 1.4;
        }
    }

    .modal-footer {
        button {
            &.unabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
</style>

<style lang="less">
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    .content-wrapper {

        .scroll-wrapper {

            .scroll-x {
                display: none;
            }

            .scroll-y {
                height: 98%;
                min-height: 98%;
                right: 6px;
                top: 1%;
            }
        }
    }
</style>

// Javascript

<script>
    import Shop from './Shop.vue'
    import EnjoyHint from '../enjoyhint'
    import Fuse from 'fuse.js'
    import validator from 'validator';

    import Scrollbar from '../lib/components/Scrollbar';
    import Modal from '../lib/components/Modal';

    const TOUR_PLAYED = 'shops-tour-played';

    export default
    {
        components: {
            shop: Shop,
            scrollbar: Scrollbar,
            modal: Modal,
        },

        props: {
            userShops: {
                type: Array,
                default: []
            },
            allShops: {
                type: Array,
                default: []
            }
        },

        data()
        {
            return {
                userShopsCache: [],
                searchString: '',
                fuse: null,

                addShopModal: {
                    show: false
                },
                affiliateLink: null,
                affiliateErrorMsg: '',
                loadingShop: false,
                newShop: null,
                newShopAffiliateData: null,
                shopNotFound: false,
                shopToRequest: {},
                savingShop: false
            }
        },

        created() {
            this.fuse = new Fuse(this.allShops, {
                shouldSort: true,
                threshold: 0.35,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: [
                    "name",
                    "url",
                    "short_name"
                ]
            });

            this.userShopsCache = _.cloneDeep(this.userShops);
        },

        mounted() {
            this.$nextTick(() => {
                if (!this.$localStorage.get(TOUR_PLAYED) && !window.isMobile(930, 700)) {
                    this.$http.get('/api/user/shops').then(response => {
                        let userShops = response.data;

                        if (!userShops.length)
                        {
                            let enjoyhint = new EnjoyHint({
                                    onSkip: () => {
                                        this.$localStorage.set(TOUR_PLAYED, true);
                                    }
                                });

                            var enjoyhint_script_steps = [
                                {
                                    'next #search-shop': 'Search for the shop you want to add'
                                },
                                {
                                    'next #all-shops-wrapper' : 'Click the "Add" button to connect your<br>affiliate id with a shop on this list'
                                }
                            ];

                            enjoyhint.set(enjoyhint_script_steps);
                            enjoyhint.run();
                        }
                        else
                            this.$localStorage.set(TOUR_PLAYED, true);
                    }).catch(error => {
                        console.log(error);
                    });
                }

                this.setAllShopsHeight();
            });
        },

        computed: {
            filteredAllShops() {
                let search = _.lowerCase(this.searchString);

                if (search.length)
                    return this.fuse.search(search);
                else
                    return this.allShops;
            },
            canRequestShop() {
                return this.shopToRequest.url && this.shopToRequest.links;
            }
        },

        methods: {
            setAllShopsHeight() {
                // set "available shops" height
                let userShopsHeight = parseInt(window.getComputedStyle(this.$refs.userShopsContent).getPropertyValue('height'));

                this.$refs.allShopsContent.style.height = `${ userShopsHeight < 585 ? 585 : userShopsHeight }px`;
            },

            addShop() {
                this.affiliateLink = null;
                this.affiliateErrorMsg = '';
                this.newShop = null;
                this.newShopAffiliateData = null;
                this.shopNotFound = false;
                this.shopToRequest = {};

                this.addShopModal.show = true;
            },
            closeAddShop() {
                this.addShopModal.show = false;
            },
            saveNewShop() {
                this.closeAddShop();

                this.$http.post('/api/shop/add', {
                    shop: this.newShop,
                    affiliate: this.newShopAffiliateData
                }).then((response) => {
                    let data = response.data;

                    switch (data.status) {
                        case 'ok':
                            this.userShopsCache.push(data.shop);

                            this.$events.emit('notify', {
                                type: 'success',
                                title: 'Shop added',
                                message: `Shop <b>${ this.newShop.name }</b> added successfully.`,
                                timeout: 2000
                            });
                            break;
                        case 'error':
                            this.$events.emit('notify', {
                                type: 'danger',
                                title: 'Error',
                                message: `Error adding shop <b>${ this.newShop.name }</b>.`,
                                timeout: 2000
                            });
                            break;
                    }
                }).catch(() => {
                    //
                });
            },

            requestShop() {
                this.closeAddShop();

                this.$http.post('/api/shop/request', {
                    url: this.shopToRequest.url,
                    info: this.shopToRequest.links
                }).then((response) => {
                    let data = response.data;

                    if (data.status === 'ok') {
                        this.$events.emit('notify', {
                            type: 'success',
                            title: 'Shop requested',
                            message: 'We will review your application and add your shop manually (usually within 1-2 working days).',
                            timeout: 5000
                        });
                    }
                }).catch(() => {
                    //
                });
            }
        },

        watch: {
            affiliateLink(val) {
                if (!val) {
                    this.affiliateErrorMsg = '';
                    return;
                } else if (!validator.isURL(val)) {
                    this.affiliateErrorMsg = '<b>Please enter a valid URL</b>';
                    return;
                } else {
                    this.affiliateErrorMsg = '';
                    this.loadingShop = true;

                    this.$http.post('/api/shop/determine', {
                        url: this.affiliateLink
                    }).then((response) => {
                        let data = response.data;

                        switch (data.status) {
                            case 'ok':
                                this.newShop = data.shop;
                                this.newShopAffiliateData = data.affiliate;
                                break;
                            case 'error':
                                let msg = data.message;
                                this.affiliateErrorMsg = `<div style="margin-bottom: 10px;"><b><div>Please enter a <u>deep</u> affiliate link.</div><div>Example for ${ msg.network }:</div></b></div><div style="word-break: break-all; text-align: left;">${ msg.link }</div>`;
                                break;
                            case 'shop-exists':
                                this.closeAddShop();
                                this.$events.emit('notify', {
                                    type: 'info',
                                    title: 'Shop exists',
                                    message: `<div>Shop <b>${ data.shopName }</b> already exists.</div><div>Please select it on the "Available Shops" list.</div>`,
                                    timeout: 5000
                                });
                                break;
                            case 'not-found':
                                this.shopNotFound = true;
                                break;
                        }

                        this.loadingShop = false;
                    }).catch(() => {
                        this.loadingShop = false;
                    });
                }
            },

            userShopsCache() {
                this.setAllShopsHeight();
            }
        }
    }
</script>