var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tabbable" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content" }, [
      _c("div", { staticClass: "tab-pane active", attrs: { id: "tab4" } }, [
        !_vm.products.showResults && _vm.migrationProducts.length
          ? _c("div", { staticClass: "row", attrs: { id: "links" } }, [
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "search col-md-6" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "input-group input-group-hg input-group-rounded flat"
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control flat",
                      attrs: {
                        type: "text",
                        placeholder: "Enter link/url substring to filter by"
                      },
                      domProps: { value: _vm.searchString },
                      on: { input: _vm.updateSearch }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "buttons col-md-6" }, [
                _c("div", { staticStyle: { "margin-top": "10px" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.selectVisible(true)
                        }
                      }
                    },
                    [_vm._v("Select visible")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-darkgray",
                      staticStyle: { "margin-left": "3px" },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.selectVisible(false)
                        }
                      }
                    },
                    [_vm._v("Deselect visible")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "links" }, [
                _c("div", { staticClass: "title" }, [
                  _c("b", [_vm._v("Your Products")]),
                  _vm._v(
                    " (selected " +
                      _vm._s(_vm.selectedUserLinks.length) +
                      "/" +
                      _vm._s(_vm.migrationProducts.length) +
                      " )\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _vm.filteredUserLinks.length
                      ? _c(
                          "virtual-list",
                          {
                            staticStyle: { "padding-right": "10px" },
                            attrs: { size: 100, remain: 8 }
                          },
                          _vm._l(_vm.filteredUserLinks, function(link) {
                            return _c("migration-link", {
                              key: link.id,
                              attrs: {
                                data: link,
                                checked: link.checked,
                                store: _vm.$store
                              }
                            })
                          }),
                          1
                        )
                      : _c("h4", [_vm._v("No results found")])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-hg",
                  attrs: { type: "submit" },
                  on: { click: _vm.submitLinks }
                },
                [_vm._v("\n                    Submit\n                ")]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.migrationProducts.length
          ? _c(
              "div",
              { staticStyle: { "margin-top": "0" }, attrs: { id: "links" } },
              [
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br")
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.products.showResults
          ? _c("div", { staticClass: "row", attrs: { id: "result" } }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("h1", [_vm._v("Done!")]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v("Total: " + _vm._s(_vm.products.stats.total))
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v("Existing: " + _vm._s(_vm.products.stats.existing))
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v("Imported: " + _vm._s(_vm.products.stats.imported))
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v("Failed: " + _vm._s(_vm.products.stats.failed))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Existing links are not overwritten or added again to your LinkDeli links."
                  )
                ])
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tab-pane", attrs: { id: "tab5" } }, [
        !_vm.mylists.showResults && _vm.migrationLists.length
          ? _c("div", { staticClass: "lists" }, [
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [
                _c("b", [_vm._v("Your Lists")]),
                _vm._v(
                  " (selected " +
                    _vm._s(_vm.selectedUserLists.length) +
                    "/" +
                    _vm._s(_vm.migrationLists.length) +
                    " )\n                "
                )
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content row" },
                _vm._l(_vm.lists, function(list) {
                  return _c("migration-list", {
                    key: list.id,
                    attrs: {
                      data: list,
                      checked: list.checked,
                      store: _vm.$store
                    }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-hg",
                  style: {
                    opacity: !_vm.checkedListsList().length ? 0.35 : 1,
                    "pointer-events": !_vm.checkedListsList().length
                      ? "none"
                      : "all"
                  },
                  attrs: { type: "submit" },
                  on: { click: _vm.submitLists }
                },
                [_vm._v("\n                    Submit\n                ")]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.migrationLists.length
          ? _c("div", { staticClass: "lists" }, [
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "message" }, [
                _vm._v(
                  "\n                    No lists to migrate!\n                "
                )
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.mylists.showResults
          ? _c("div", { staticClass: "row", attrs: { id: "result" } }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("h1", [_vm._v("Done!")]),
                _vm._v(" "),
                _c("h3", [_vm._v("Total: " + _vm._s(_vm.mylists.stats.total))]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v("Existing: " + _vm._s(_vm.mylists.stats.existing))
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v("Imported: " + _vm._s(_vm.mylists.stats.imported))
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v("Failed: " + _vm._s(_vm.mylists.stats.failed))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Existing lists are not overwritten or added again to your LinkDeli lists."
                  )
                ])
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "nav nav-tabs nav-append-content" }, [
      _c("li", { staticClass: "active" }, [
        _c("a", { attrs: { href: "#tab4" } }, [_vm._v("Products")])
      ]),
      _vm._v(" "),
      _c("li", {}, [_c("a", { attrs: { href: "#tab5" } }, [_vm._v("Lists")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-btn" }, [
      _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
        _c("span", { staticClass: "fa fa-search" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message" }, [
      _vm._v(
        "\n                    The migration button shows only the products that are not available for search in the new service."
      ),
      _c("br"),
      _vm._v(
        "\n                    It is much easier to search them in LinkDeli!\n                "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }