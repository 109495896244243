// Template

<template>
    <div id="add-products"
         :class="[ 'container', { 'show': show } ]"
    >
        <collection :collection="collection"
                    :templates="templates"
                    :adding-products="true"
                    :loader="loader.url"
        ></collection>

        <modal :show="styleModal.show"
               :large="true"
               title="List styling"
               ok-class="hidden"
               cancel-text="Close"
               @cancel="styleModal.show=false"
        >
            <list-styler :is-global="false"
                         :data="listStyle.data"
                         :defaults="listStyle.defaults"
                         @save="saveStyle"
            ></list-styler>
        </modal>

        <product-list :user="user"
                      :collection="collection"
                      :links="links"
        ></product-list>

        <search-products :collection-id="collection.id"
                         :userid="user.id"
                         :shops="shops"
        ></search-products>

        <tags-modal :show="tagsModal.show"
                    :product-id="tagsModal.productId"
                    @cancel="tagsModal.show=false"
        ></tags-modal>

        <product-modal :show="addProductModal.show"
                       :data="addProductModal.data"
                       @cancel="onProductModalClose"
        ></product-modal>
    </div>
</template>


<style lang="less">
    #add-products {
        transition: 0.3s opacity ease;
        opacity: 0;

        &.show {
            opacity: 1;
        }

        .styler-wrapper {
            padding-bottom: 10px;
        }
    }
</style>


<script  >
    import Collection from './Collection.vue';
    import ListStyler from './ListStyler.vue';
    import ProductList from './ProductList.vue';
    import SearchProducts from './SearchProducts.vue';
    import TagsModal from './TagsModal.vue';
    import ProductModal from './ProductModal.vue';
    import Modal from '../lib/components/Modal.vue';

    import EnjoyHint from '../enjoyhint'
    window.enjoyhint = null;

    const TOUR_PLAYED = 'add-products-tour-played';

    export default
    {
        data()
        {
            return {
                show: false,

                styleModal: {
                    show: false
                },

                tagsModal: {
                    show: false,
                    productId: null
                },

                addProductModal: {
                    show: false
                }
            }
        },

        props: [ 'data', 'loader' ],

        components: {
            collection: Collection,
            'list-styler': ListStyler,
            'product-list': ProductList,
            'search-products': SearchProducts,
            'tags-modal': TagsModal,
            'product-modal': ProductModal,
            'modal': Modal,
        },

        created() {
            _.merge(this, this.data);

            this.$store.state.user = this.user;

            this.$events.on('manage-user-tags', () => {
                this.tagsModal = {
                    show: true,
                    productId: null
                };
            });

            this.$events.on('manage-product-tags', (productId) => {
                this.tagsModal = {
                    show: true,
                    productId: productId
                };
            });

            this.$events.on('add-link', () => {
                this.addProductModal = {
                    show: true,
                    data: {
                        mode: 'add',
                        listId: this.collection.id
                    }
                };
            });

            this.$events.on('edit-link', data => {
                this.addProductModal = {
                    show: true,
                    data: _.merge({
                        mode: 'edit'
                    }, data)
                };
            });

            this.$events.on('style-list', () => {
                this.styleModal = {
                    show: true
                };
            });
        },

        methods: {
            onProductModalClose(event) {
                this.addProductModal.show = false;
                this.$events.emit(event.status);
            },

            saveStyle(style) {
                this.$http.post(`/api/collection/${ this.collection.id }/style`, {
                    listStyle : style
                }).then(response => {
                    this.$events.emit('notify', {
                        type: 'success',
                        title: 'Saved',
                        message: 'List styling saved successfully',
                        timeout: 2000
                    });
                }).catch(error => {
                    this.$events.emit('notify', {
                        type: 'danger',
                        title: 'Error',
                        message: 'Error saving list styling',
                        timeout: 2000
                    });
                });
            }
        },

        mounted() {
            this.show = true;

            this.$nextTick(() => {
                this.$http.get(`/api/user/setting/${ TOUR_PLAYED }`).then(response => {
                    let tourHasPlayed = !!response.data.value;

                    if (!tourHasPlayed  && !window.isMobile(930, 700))
                    {
                        let setPlayed = () => {
                                let setting = {};
                                setting[TOUR_PLAYED] = true;

                                this.$http.post('/api/user/setting', setting);
                                this.$localStorage.set(TOUR_PLAYED, true);
                            };

                        enjoyhint = new EnjoyHint({
                            onEnd: () => {
                                setPlayed();
                            },
                            onSkip: () => {
                                setPlayed();
                            }
                        });

                        var enjoyhint_script_steps = [
                            {
                                'next #search-terms-input': 'Enter the name/description of the product<br>you want to search for'
                            },
                            {
                                'next .advanced-settings': 'Explore the advanced options<br>(or use the defaults)'
                            },
                            {
                                'products-loaded #search-button' : 'Click the "search" button',
                                shape: 'circle',
                                radius: 40
                            },
                            {
                                'next .results-hint': 'Choose one or more products'
                            },
                            {
                                'click .widget-code': 'Get the code for your blog',
                                shape: 'circle',
                                radius: 30
                            }
                        ];

                        enjoyhint.set(enjoyhint_script_steps);
                        enjoyhint.run();
                    }
                    else
                        this.$localStorage.set(TOUR_PLAYED, true);
                }).catch(error => {
                    console.log(error);
                });
            });
        }
    }
</script>
