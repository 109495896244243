var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6 col-md-offset-3" }, [
        _c(
          "div",
          { staticClass: "wrapper" },
          [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("\n                        Subscribe to plan "),
                _c("strong", [_vm._v(_vm._s(_vm.details.plan.title))])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "price" }, [
                _c("strong", [
                  _c("span", [_vm._v("$")]),
                  _vm._v(
                    _vm._s(_vm.details.plan.price) +
                      "\n                        "
                  )
                ]),
                _vm._v("/month\n                    ")
              ])
            ]),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("drop-in", {
              attrs: {
                submitRoute: _vm.details.checkRoute,
                clientToken: _vm.details.clientToken,
                planName: _vm.details.plan.title.toLowerCase(),
                buttonText: "Proceed to Checkout",
                type: "subscribe"
              }
            }),
            _vm._v(" "),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "payment-info" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "braintree" }, [
                _c("a", { attrs: { href: _vm.badgeLink, target: "_blank" } }, [
                  _c("img", {
                    attrs: {
                      src:
                        "https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png",
                      width: "250px",
                      height: "44px",
                      border: "0"
                    }
                  })
                ])
              ])
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "credit-cards" }, [
      _c("img", { attrs: { src: "/img/credit_cards.png" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }