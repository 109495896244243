<template>
    <transition name="fade">
        <div v-show="show"
             class="modal-wrapper"

        >
            <div class="modal"
                 @mousedown.self="clickMask"
            >
                <div :class="[ 'modal-dialog', modalClass ]"
                     ref="dialog"
                >
                    <div :id="id"
                         class="modal-content"
                    >
                        <!--Header-->
                        <div class="modal-header">
                            <template v-if="removeClose">
                                <a type="button"
                                   class="close"
                                   @click="cancel"
                                >
                                    x
                                </a>
                            </template>
                            <h4 class="modal-title">
                                <slot name="title">
                                    {{ title }}
                                </slot>
                            </h4>
                        </div>
                        <!--Container-->
                        <div class="modal-body">
                            <slot></slot>
                        </div>
                        <!--Footer-->
                        <div class="modal-footer">
                            <slot name="footer">
                                <button :class="[ 'btn', 'btn-embossed', okClass ]"
                                        @click="ok"
                                        ref="okButton"
                                >
                                    <span v-if="loading"
                                          class="fa fa-circle-o-notch fa-spin fa-fw"
                                    ></span>

                                    <template v-else>{{ okText }}</template>
                                </button>
                                <button :class="[ 'btn', 'btn-embossed', cancelClass ]"
                                        @click="cancel"
                                >
                                    {{ cancelText }}
                                </button>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="['modal-backdrop', opacity]"></div>
        </div>
    </transition>
</template>

<style lang="less" scoped>
    .modal-wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1001;
        cursor: default;

        .modal-backdrop.half {
            filter: alpha(opacity=50);
            opacity: 0.5;
        }

        > .modal {
            display: block;

            .modal-footer {

                .btn {
                    @media (max-width: 767px) {
                        display: inline-block;
                        margin-bottom: 0;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .25s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
    {
        opacity: 0;
    }
</style>

<style lang="less">
    .modal {
        .modal-body {

            input,
            textarea {
                border-color: transparent;
                background: #f5f5f5;
                resize: none;
            }
        }

        .modal-footer {

            .btn + .btn {
                margin-left: 5px;
                padding: 10px 20px;
            }

            .btn {
                @media (max-width: 767px) {
                    display: inline-block;
                    margin-bottom: 0;
                    margin-right: 5px;
                }
            }
        }
    }
</style>

<script  >
    /**
     * Bootstrap Style Modal Component for Vue
     * Depend on Bootstrap.css
     */
    export default {
        props: {
            id: {
                type: String,
                default: ''
            },
            show: {
                type: Boolean,
                twoWay: true,
                default: false
            },
            opacity: {
                type: String,
                default: 'in'
            },
            title: {
                type: String,
                default: 'Modal'
            },
            small: {
                type: Boolean,
                default: false
            },
            large: {
                type: Boolean,
                default: false
            },
            full: {
                type: Boolean,
                default: false
            },
            force: {
                type: Boolean,
                default: false
            },
            transition: {
                type: String,
                default: 'modal'
            },
            okText: {
                type: String,
                default: 'OK'
            },
            cancelText: {
                type: String,
                default: 'Cancel'
            },
            okClass: {
                type: String,
                default: 'btn-primary'
            },
            loading: {
                type: Boolean,
                default: false
            },
            cancelClass: {
                type: String,
                default: 'btn-darkgray'
            },
            removeClose: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                duration: null
            };
        },
        computed: {
            modalClass () {
                return {
                    'modal-lg': this.large,
                    'modal-sm': this.small,
                    'modal-full': this.full
                }
            }
        },
        created () {
            if (this.show) {
                document.body.className += ' modal-open';
            }
        },
        beforeDestroy () {
            document.body.className = document.body.className.replace(/\s?modal-open/, '');
        },
        watch: {
            show (value) {
//                console.log(this);
                if (value) {
                    document.body.className += ' modal-open';
                }
                else {
                    if (!this.duration) {
                        this.duration = window.getComputedStyle(this.$el)['transition-duration'].replace('s', '') * 1000;
                    }
                    window.setTimeout(() => {
                        document.body.className = document.body.className.replace(/\s?modal-open/, '');
                    }, this.duration || 0);
                }
            },

            loading(val) {
                if (val)
                    this.$refs.okButton.style.width = window.getComputedStyle(this.$refs.okButton).width;
            }
        },
        methods: {
            ok () {
                this.$emit('ok');
            },
            cancel () {
                this.$emit('cancel');
            },
            clickMask () {
                if (!this.force)
                    this.cancel();
            }
        }
    };
</script>
