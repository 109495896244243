module.exports = {
    remove_list_danger: {
        type: 'danger',
        title: 'Your links will stop working',
        message: 'If you have used this list in any post or social network, your links will stop working. Please proceed carefully.'
    },
    add_product_warning: {
        type: 'warning',
        title: 'Make sure you have <a href="/page/search-for-a-product-by-name" target="_blank">searched</a> and tried the <a href="/page/adding-a-product-to-your-list-manually-the-bookmarklet-and-the-add-product-button" target="_blank">bookmarklet</a> first',
        message: 'The \'Add product\' button is intended as the last resort and when you are unable to add your products in any other way.'
    },
    link_format_info: {
        message: 'Please make sure you enter the direct link to the product. You do not need to enter any affiliate information.'
    },
    link_search_empty: {

    },
    list_style_info: {
        message: 'You are about to style the current list. If you want to set your lists\' appearance in a global manner, please go to your settings page.'
    }
};