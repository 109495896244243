<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <div class="wrapper">
                    <div class="header">
                        <div class="title">
                            Subscribe to plan <strong>{{ details.plan.title }}</strong>
                        </div>
                        <div class="price">
                            <strong>
                                <span>$</span>{{ details.plan.price }}
                            </strong>/month
                        </div>
                    </div>
                    <br><br>

                    <drop-in :submitRoute="details.checkRoute"
                             :clientToken="details.clientToken"
                             :planName="details.plan.title.toLowerCase()"
                             buttonText="Proceed to Checkout"
                             type="subscribe"
                    ></drop-in>

                    <br><br>

                    <div class="payment-info">
                        <div class="credit-cards">
                            <img src="/img/credit_cards.png">
                        </div>
                        <div class="braintree">
                            <a :href="badgeLink" target="_blank">
                                <img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png" width="250px" height ="44px" border="0"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DropIn from "./Braintree/DropIn";

    export default {
        components: {
            "drop-in": DropIn
        },

        props: {
            details: {
                type: Object,
                required: true,
            }
        },


        mounted() {
            this.showWarningMessage();
        },

        computed: {
            price() {
                return this.plan.price;
            },

            badgeLink() {
                return inlz.badge_link
            }
        },


        methods: {
            showWarningMessage() {
                // no subscription OR fully expired
                if (this.details.subscription === null) {
                    return false;
                }

                if (this.details.subscription.onGracePeriod) {
                    this.$events.emit('notify', {
                        type: 'warning',
                        title: 'Warning',
                        message: 'You are still on grace period from your last subscription. It will be canceled if you proceed.'
                    });
                }
            },
        }
    }
</script>


<style lang="less">
    .coupon {
        color: #bbb;
        .coupon-input {
            padding: 7px;
            border: 2px solid #bdc3c7;
            font-family: 'Helvetica Neue', Arial, sans-serif;
            font-size: 15px;
            line-height: 1.467;
            font-weight: bold;
            text-transform: uppercase;
            height: 42px;
            border-radius: 6px;
            -webkit-appearance: textfield;
            background-color: white;
            -webkit-rtl-ordering: logical;
            cursor: text;
            color: #757575;
        }

    }

    .wrapper {
        text-align: center;
        padding-bottom: 55px;

        > .header {
            background: #f5f5f5;
            border-radius: 5px;
            padding: 20px;

            > .title {
                font-size: 23px;
                color: #bbb;
                margin-bottom: 10px;
                margin-top: -4px;
            }

            > .price {
                font-size: 14px;
                color: #aaa;
                line-height: 1.3;

                > strong {
                    font-size: 22px;
                    color: #888;
                    line-height: 1.3;

                    > span {
                        font-size: 12px;
                        padding-right: 1px;
                    }
                }
            }

            > .includes {
                text-align: center;
                padding: 7px;
                background: #e9e9e9;
                margin-top: 10px;
                border-radius: 5px;
                color: #777;
                font-weight: bolder;
                font-size: 13px;
            }

            > .business {
                margin-top: 13px;
                font-weight: bolder;
                margin-bottom: -6px;
                cursor: pointer;
                font-size: 14px;
                display: block;
            }
        }

        form {

            > .braintree-container {
                min-height: 80px;
                padding: 0 0px;
            }

            > .button-wrapper {
                margin-top: 25px;
                padding: 18px;
            }
        }

        > .payment-info {
            margin: 0 auto 80px auto;

            > .credit-cards {
                > img {
                    width: 250px;
                    margin: 7px 0;
                }
            }

            > .braintree {
            }
        }
    }

</style>

<style lang="less">
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    #vat-modal {

        .description {
            text-align: center;
            padding: 10px;
            background: @brand-info;
            color: #fff;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 20px;
        }

        .fields {
            display: inline-block;
            width: 100%;

            > .vat {
                margin-bottom: 15px;
                display: inline-block;
                width: 100%;

                > .vat-input {
                    position: relative;

                    > input.fetching {
                        padding-right: 40px;
                    }

                    > .loader {
                        position: absolute;
                        top: 11px;
                        right: 11px;
                    }
                }

                > .error-msg {
                    color: @brand-danger;
                    font-size: 13px;
                    text-align: center;
                }

                > button {
                    float: right;
                }
            }

            label {
                margin-bottom: 0;
                padding-left: 5px;
                font-weight: bolder;
            }

            input {
                margin-bottom: 10px;
            }
        }
    }
</style>
