<template>
  <div>
    <div class="list-description product-options">
      <div class="option">
        <label class="enabled">List Description</label>
        <input v-if="this.widgetOptions.description.status"
               id="description-box"
               type="checkbox"
               checked
               data-toggle="switch"
               data-off-color="default"
               data-on-color="success"
        >
        <input v-else
               id="description-box"
               type="checkbox"
               data-toggle="switch"
               data-off-color="default"
               data-on-color="success"
        >

        <div class="list-description-text">
          <textarea v-show="this.widgetOptions.description.status"
                    name="description"
                    id="description"
                    class="form-control"
                    v-model="widgetOptions.description.text"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="list-styler row">
      <div class="col-md-6">
        <div class="product-preview">
          <h4>Item Preview</h4>
          <br>

          <div class="wrapper">
            <widget-product v-if="inViewport"
                            :productdata="dummyProductData"
                            :product-options="productOptions"
            ></widget-product>
          </div>
        </div>

      </div>


      <div class="col-md-6">
        <div class="product-options">
          <div class="option">
            <label class="enabled">
              Product list widget style
            </label>
            <div class="col-md-12">
              <select class="form-control select select-dark select-block"
                      id="list-style"
                      ref="listStyle"
                      :value="widgetOptions.listStyle === undefined ? '0' : widgetOptions.listStyle"
                      data-toggle="select"
              >
                <option :value="0"
                        :selected="!isGridStyle"
                >
                  Grid
                </option>
                <option :value="1"
                        :selected="isGridStyle"
                >
                  Slider
                </option>
              </select>


              <a style='float:right;' title="How do Grid and Slider widgets look like?"
                 href="/page/how-to-add-your-affiliate-product-list-in-your-newsletter-email"
                 target="_blank" class="btn btn-info"><span class="fa fa-question"></span></a>
            </div>
          </div>

          <hr>
          <hr>


          <div class="option">
            <label class="enabled">
              Thumbnail size
            </label>

            <div class="thumb-size-slider-wrapper">
              <div class="thumb-size-slider"></div>
            </div>

            <div class="legends">
              <span class="left small">small</span>
              <span class="centered medium">medium</span>
              <span class="right large">large</span>
            </div>
          </div>

          <div v-if="fullFeatureSupport"
               class="option"
          >
            <label :class="{ 'enabled': productOptions.enabled.background }">
              Background color
            </label>

            <input v-if="productOptions.enabled.background"
                   id="background"
                   type="checkbox"
                   checked
                   data-toggle="switch"
                   data-off-color="default"
                   data-on-color="success"
            >
            <input v-else
                   id="background"
                   type="checkbox"
                   data-toggle="switch"
                   data-off-color="default"
                   data-on-color="success"
            >

            <div class="field"
                 v-if="productOptions.enabled.background"
            >
              <div class="color-field"
                   @click="setChooseBackground"
              >
                <div class="inner"
                     :style="{ 'background': backgroundColor }"
                ></div>
              </div>

              <chrome-picker v-if="chooseBackground"
                             v-model="productOptions.background"
                             v-on-clickaway="unsetChooseBackground"
              ></chrome-picker>
            </div>
          </div>

          <div v-if="fullFeatureSupport"
               class="option"
          >
            <label class="enabled">
              Image roundness
            </label>

            <div class="image-roundness-slider"></div>
          </div>

          <div class="option">
            <label :class="{ 'enabled': productOptions.enabled.title }">
              Product title
            </label>

            <input v-if="productOptions.enabled.title"
                   id="title"
                   type="checkbox"
                   checked
                   data-toggle="switch"
                   data-off-color="default"
                   data-on-color="success"
            >
            <input v-else
                   id="title"
                   type="checkbox"
                   data-toggle="switch"
                   data-off-color="default"
                   data-on-color="success"
            >

            <div class="field"
                 v-show="productOptions.enabled.title"
            >
              <div class="row">
                <label class="col-md-3 control-label">font size</label>
                <div class="col-md-9">
                  <input id="font-size"
                         type="text"
                         :value="productOptions.fontSize"
                         class="form-control spinner"
                         style="background: #fff;"
                         data-toggle="spinner"
                         readonly
                  >
                </div>
              </div>

              <div class="row">
                <label class="col-md-3 control-label">text lines</label>
                <div class="col-md-9">

                  <select class="form-control select select-dark select-block"
                          id="text-lines"
                          ref="textLines"
                          :value="productOptions.textLines"
                          data-toggle="select"

                  >
                    <template v-for="tl in 4">
                      <option v-if="tl === productOptions.textLines"
                              :value="tl"
                              selected
                      >
                        {{ tl }} line (s)
                      </option>

                      <option v-else
                              :value="tl"
                      >
                        {{ tl }} line (s)
                      </option>
                    </template>
                    >
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div v-if="fullFeatureSupport"
               class="option"
          >
            <label :class="{ 'enabled': productOptions.enabled.shopLinks }">
              Shop links
            </label>

            <input v-if="productOptions.enabled.shopLinks"
                   id="shop-links"
                   type="checkbox"
                   checked
                   data-toggle="switch"
                   data-off-color="default"
                   data-on-color="success"
            >
            <input v-else
                   id="shop-links"
                   type="checkbox"
                   data-toggle="switch"
                   data-off-color="default"
                   data-on-color="success"
            >


            <div class="field"
                 v-if="productOptions.enabled.shopLinks"
            >
              <div class="row">
                <label class="col-md-3 control-label">display text</label>
                <div class="col-md-9">
                  <input v-model="productOptions.shopLinks.text"
                         placeholder="(no text)"
                         type="text"
                         class="form-control"
                         style="background: #fff;"
                         @click="$event.target.select()"
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-else
               class="option"
          >
            <label :class="{ 'enabled': productOptions.shopLinks.text.length }">
              "Buy from"
            </label>

            <input v-if="productOptions.shopLinks.text.length"
                   id="buy-from"
                   type="checkbox"
                   checked
                   data-toggle="switch"
                   data-off-color="default"
                   data-on-color="success"
            >
            <input v-else
                   id="buy-from"
                   type="checkbox"
                   data-toggle="switch"
                   data-off-color="default"
                   data-on-color="success"
            >
          </div>
        </div>

        <div class="row"
             style="margin-top: 20px;"
        >
          <div class="col-md-12">
            <button v-if="showReset"
                    class="btn btn-lg btn-darkgray btn-embossed pull-right"
                    @click="resetStyle"
            >
              Reset
            </button>

            <button class="btn btn-lg btn-primary btn-embossed pull-right"
                    style="margin-right: 10px;"
                    @click="saveStyle"
            >
              Save
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../less/mixins";
@import "../../less/libs/flat-ui/variables";

.list-description {
  border-radius: 4px;
  background: #fafafa;
  padding: 20px;
  margin-bottom: 30px;

  .option {

    label {
      font-size: 18px;
      color: #555;
      font-weight: bolder;
      width: 20%;
    }

    .bootstrap-switch {
      top: -1px !important;
    }
  }

  .list-description-text {
    margin-top: 10px;

    textarea {
      opacity: 1;
      background: white;
      color: #2e2e2e;
      padding: 7px;
      font-size: 14px;
      resize: none;

      &:focus {
        border: 2px solid green;
      }
    }
  }
}

.list-styler {
  user-select: none;

  .product-preview {

    > h4 {
      color: @brand-secondary;
      text-align: center;
      padding: 20px;
      border-radius: 4px;
      border: 2px solid @brand-secondary;
      margin-top: 0;
    }

    > .wrapper {
      max-width: 250px;
      margin: 0 auto;
      display: block;
      pointer-events: none;
    }
  }

  #text-lines {
    width: 100%;
  }

  .product-options {
    border-radius: 4px;
    background: #fafafa;
    padding: 20px;

    > .option {
      position: relative;
      padding-bottom: 10px;

      &:last-child {
        padding-bottom: 0;
      }

      > label {
        font-size: 17px;
        color: #555;
        font-weight: bolder;
        width: 100%;
        padding-left: 4px;

        &:not(.enabled) {
          opacity: 0.6;
        }
      }

      > .thumb-size-slider-wrapper {
        /*padding: 0 16px;*/
        /*margin-top: 4px;*/
      }

      > .legends {
        position: relative;
        width: 100%;
        margin-top: -10px;
        margin-bottom: 2px;
        height: 26px;

        span {
          width: 100%;
          font-weight: bold;
          font-size: 13px;
          color: #555;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.6;

          &.selected {
            color: @brand-secondary;
            opacity: 1;
          }

          &.centered {
            text-align: center;
          }

          &.right {
            text-align: right;
          }
        }
      }

      > .field {
        margin-top: 8px;

        > .vue-color__chrome {
          margin: 0 auto;
          margin-top: 20px;
        }

        > .color-field {
          width: 100%;
          height: 50px;
          border-radius: 4px;
          background: url("/img/gray_stripes.png");
          position: relative;
          /*box-shadow: 0 0 20px fadeout(#000, 80%);*/

          > .inner {
            .content-block;
          }
        }

        label {
          font-weight: bolder;
          color: #888;
          line-height: 3.3;
          margin-bottom: 0;
          text-align: center;
          font-size: 12px;
        }

        input {
          border-color: transparent;

          &:hover {
            border-color: lighten(@gray-light, 10%);
          }

          &:focus {
            border-color: @brand-secondary;
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
@import "../../less/libs/flat-ui/variables";

.list-styler {

  .product-options {

    .vue-color__chrome {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    }

    > .option {

      > .bootstrap-switch {
        right: 0;
        position: absolute;
        top: 5px;
      }
    }
  }

  .ui-spinner {
    width: 100%;

    > #font-size {
      font-size: 16px !important;
      width: 100%;
      color: #333;
    }

    &.focus {

      > #font-size {
        color: @brand-secondary;
      }
    }
  }
}
</style>

// Javascript

<script>
import WidgetProduct from '../widget/Product.vue'
import {Chrome} from 'vue-color'
import {mixin as clickaway} from 'vue-clickaway';
import inViewport from 'in-viewport';

import options from '../lib/config/widget'

let defaultOptions = _.cloneDeep(options);

export default {
  data() {
    return {
      dummyProductData: {
        links: [
          {
            thumb_path: '/img/dummy-product.jpg',
            title: "Elmer's Liquid School Glue, Clear, Washable, 5 Ounces, 1 Count, lots of text to be truncated underneath! And some more! Elmer's Liquid School Glue, Clear, Washable, 5 Ounces, 1 Count, lots of text to be truncated underneath! And some more!",
            icon: {
              name: 'MyShop',
              path: 'https://www.linkdeli.com/favicon.ico'
            },
            pivot: {}
          }
        ]
      },
      options: _.cloneDeep(options),
      chooseBackground: false,
      $thumbSizeSlider: null,
      $imageRoundnessSlider: null,
      inViewport: false
    }
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isGlobal: {
      type: Boolean,
      default: true
    },
    defaults: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },

  computed: {
    productOptions: {
      get() {
        return this.options.product;
      },
      set(val) {
        this.options.product = val;
      }
    },
    widgetOptions: {
      get() {
        return this.options.widget;
      },
      set(val) {
        this.options.widget = val;
      }
    },
    isGridStyle() {
      // return true; // to force linear style
      if (this.widgetStyle === undefined) {
        return true
      }

      return this.widgetStyle.listStyle !== "1";
    },
    defaultOptions() {
      return this.defaults || defaultOptions;
    },
    backgroundColor() {
      if (this.productOptions.enabled.background) {
        let color = this.productOptions.background.rgba;

        return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
      } else
        return 'transparent';
    },

    fullFeatureSupport() {
      return this.plan.list_styling === 3;
    },
    showReset() {
//                console.log(JSON.stringify(this.options));
//                console.log(JSON.stringify(defaultOptions));

      return JSON.stringify(this.options) !== JSON.stringify(this.defaultOptions);
    }
  },

  mixins: [clickaway],

  created() {
    _.merge(this.options, this.data || {});
  },

  mounted() {
    this.$thumbSizeSlider = $('.thumb-size-slider');

    let $legends = this.$thumbSizeSlider.parent().siblings('.legends'),
        selectLegend = (value) => {
          $legends.find('span').removeClass('selected');

          switch (value) {
            case 1:
              $legends.find('.small').addClass('selected');
              break;
            case 2:
              $legends.find('.medium').addClass('selected');
              break;
            case 3:
              $legends.find('.large').addClass('selected');
              break;
          }
        };

    this.$thumbSizeSlider.slider({
      min: 1,
      max: 3,
      value: this.widgetOptions.thumbSize || 2,
      orientation: "horizontal",
      range: "min",
      slide: (event, ui) => {
        let value = ui.value;

        selectLegend(value);
        this.widgetOptions.thumbSize = value;
      }
    }).addSliderSegments(this.$thumbSizeSlider.slider("option").max);

    selectLegend(this.widgetOptions.thumbSize || 2);

    if (this.fullFeatureSupport) {
      this.$imageRoundnessSlider = $('.image-roundness-slider');

      this.$imageRoundnessSlider.slider({
        min: 3,
        max: 50,
        value: this.productOptions.imageRoundness || 50,
        orientation: "horizontal",
        range: "min",
        slide: (event, ui) => {
          this.productOptions.imageRoundness = ui.value;
        }
      });
    }

    // make sure all switches have been initialized
    let switchListen = (switchClass, listener) => {
      let $switch = null
          , loop = setInterval(() => {
        $switch = $(switchClass);

        if ($switch.length) {
          $switch.on('switchChange.bootstrapSwitch', listener);
          clearInterval(loop);
        }
      }, 500);
    };

    switchListen('.bootstrap-switch-id-background', (e, state) => {
      this.productOptions.enabled.background = state;
    });

    switchListen('.bootstrap-switch-id-title', (e, state) => {
      this.productOptions.enabled.title = state;
    });

    switchListen('.bootstrap-switch-id-shop-links', (e, state) => {
      this.productOptions.enabled.shopLinks = state;
    });

    switchListen('.bootstrap-switch-id-description-box', (e, state) => {
      this.widgetOptions.description.status = state;
    });

    switchListen('.bootstrap-switch-id-buy-from', (e, state) => {
      this.productOptions.shopLinks.text = state ? 'buy from' : '';
    });

    $(document).ready(() => {
      let self = this;

      // init font-size spinner
      $('#font-size').customspinner({
        min: 11,
        max: 25
      }).on('focus', function () {
        $(this).closest('.ui-spinner').addClass('focus');
      }).on('blur', function () {
        $(this).closest('.ui-spinner').removeClass('focus');
      }).on('spin', function (e) {
        self.productOptions.fontSize = $(this).customspinner('value');
      }).on('spinstop', function (e) {
        self.productOptions.fontSize = $(this).customspinner('value');
      });
    });

    inViewport(this.$el, () => {
      this.inViewport = true;
    });

    $(this.$refs.textLines).on('select2-selecting', e => {
      this.options.product.textLines = e.val;
    });

    $(this.$refs.listStyle).on('select2-selecting', e => {
      this.options.widget.listStyle = e.val;
    });

  },

  components: {
    'widget-product': WidgetProduct,
    'chrome-picker': Chrome
  },

  methods: {
    setChooseBackground() {
      this.chooseBackground = true;
    },

    unsetChooseBackground() {
      this.chooseBackground = false;
    },

    saveStyle() {
      this.options.product.textLines = this.productOptions.textLines;

      this.$emit('save', this.options);
    },
    resetStyle() {
      swal({
            title: "Reset to defaults?",
            text: this.isGlobal ? "<div>This will reset your lists' styling to the LinkDeli defaults.</div><div>Are you sure you want to continue?</div>" : "<div>This will remove any styling applied to this list.</div><div>Are you sure you want to continue?</div>",
            html: true,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Reset",
            closeOnConfirm: true,
            showLoaderOnConfirm: true
          },
          (isConfirm) => {
            if (isConfirm) {
              this.options = _.cloneDeep(this.defaultOptions);

              this.$nextTick(() => {
                $('[data-toggle="switch"]').each(function () {
                  $(this).bootstrapSwitch('state', !!$(this).attr('checked'));
                })
              });

              this.$thumbSizeSlider.slider('value', 2);

              if (this.fullFeatureSupport)
                this.$imageRoundnessSlider.slider('value', 50);

              if (this.isGlobal)
                this.$emit('save', this.options);
              else
                this.$emit('save', null);
            }
          });
    }
  }
}
</script>
