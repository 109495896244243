var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      !_vm.userIsLoggedIn
        ? _c("pricing-popup", {
            attrs: { show: _vm.showTrialPopup, badgeImg: _vm.badgeImgSrc }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { id: "user_pricing" } }, [
        _c("div", { staticClass: "features" }, [
          _c("div", { staticClass: "header" }),
          _vm._v(" "),
          _c(
            "span",
            _vm._l(_vm.plans.features, function(feature) {
              return _c(
                "div",
                {
                  staticClass: "feature hint--top hint--rounded hint--large",
                  attrs: { "aria-label": feature.description }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(feature.name) +
                      "\n                "
                  )
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "plans" },
          _vm._l(_vm.singlePaidPlans, function(plan, key) {
            return _c(
              "div",
              { class: ["plan", { active: _vm.isPlanCurrent(key) }] },
              [
                _c(
                  "div",
                  {
                    staticClass: "header",
                    style: { background: _vm.planColor(key) }
                  },
                  [
                    key === "gold"
                      ? _c("span", { staticClass: "recommended" }, [
                          _c("span", { staticClass: "fa fa-star" })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(plan.title))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "shade" }, [
                      _c("div", { staticClass: "price" }, [
                        _c("span", { staticClass: "dollar" }, [_vm._v("$")]),
                        _c("span", { staticClass: "number" }, [
                          _vm._v(_vm._s(plan.price))
                        ]),
                        _c("span", { staticClass: "basis" }, [
                          _vm._v("/ month")
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "features" },
                  [
                    _vm._l(plan.features, function(feature, key) {
                      return _c(
                        "div",
                        {
                          class: [
                            "feature",
                            {
                              enabled: feature && typeof feature === "boolean",
                              disabled: !feature && typeof feature === "boolean"
                            }
                          ]
                        },
                        [
                          _c("div", { staticClass: "description" }, [
                            _vm._v(_vm._s(_vm.plans.features[key].name))
                          ]),
                          _vm._v(" "),
                          feature
                            ? [
                                typeof feature === "boolean"
                                  ? _c("span", { staticClass: "fa fa-check" })
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.plans.features[key].plan[feature]
                                        )
                                      )
                                    ])
                              ]
                            : _c("span", { staticClass: "fa fa-close" })
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _vm.isPlanCurrent(key)
                      ? _c(
                          "div",
                          {
                            staticClass: "tick",
                            style: { background: _vm.planColor(key) }
                          },
                          [_c("span", { staticClass: "icon icon-tick" })]
                        )
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.isPlanCurrent(key)
                  ? _c(
                      "div",
                      {
                        staticClass: "plan-active",
                        style: { background: _vm.planColor(key) }
                      },
                      [_c("span", { staticClass: "text" }, [_vm._v("Active")])]
                    )
                  : [
                      !_vm.isSubscriptionActive && !_vm.isPartOfTeam
                        ? [
                            _c(
                              "a",
                              {
                                staticClass: "buy-now btn btn-primary",
                                attrs: { href: "/subscribe/" + key }
                              },
                              [
                                _vm._m(0, true),
                                _vm._v(" "),
                                _c("span", { staticClass: "text" }, [
                                  _vm._v("Buy now")
                                ])
                              ]
                            )
                          ]
                        : _vm._e()
                    ]
              ],
              2
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "team_pricing" } }, [
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "plans" },
          _vm._l(_vm.teamPlans, function(plan, key) {
            return _c(
              "div",
              { staticClass: "plan" },
              [
                _c(
                  "div",
                  {
                    staticClass: "header",
                    style: { background: _vm.planColor(key) }
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(plan.title))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description" }, [
                      _vm._v(_vm._s(plan.description))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "shade" }, [
                      _c("div", { staticClass: "price" }, [
                        _c("span", { staticClass: "dollar" }, [_vm._v("$")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "number" }, [
                          _vm._v(_vm._s(plan.price))
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "basis" }, [
                          _vm._v("/ month")
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm.isPlanCurrent(key)
                  ? _c(
                      "div",
                      {
                        staticClass: "plan-active",
                        style: { background: _vm.planColor(key) }
                      },
                      [_c("span", { staticClass: "text" }, [_vm._v("Active")])]
                    )
                  : [
                      !_vm.isSubscriptionActive && !_vm.isPartOfTeam
                        ? [
                            _c(
                              "a",
                              {
                                staticClass: "buy-now btn btn-primary",
                                attrs: { href: "/subscribe/" + key }
                              },
                              [
                                _vm._m(3, true),
                                _vm._v(" "),
                                _c("span", { staticClass: "text" }, [
                                  _vm._v("Buy now")
                                ])
                              ]
                            )
                          ]
                        : _vm._e()
                    ]
              ],
              2
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "symbol-wrapper" }, [
      _c("span", { staticClass: "symbol fa fa-shopping-cart" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "or-plan",
        attrs: { id: "team-plans", name: "team-plans" }
      },
      [
        _c("div", { staticClass: "text" }, [_vm._v("or")]),
        _vm._v(" "),
        _c("div", { staticClass: "line" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description-bs" }, [
      _vm._v("\n            Plans available for "),
      _c("b", [_vm._v("Teams")]),
      _vm._v(" "),
      _c("div", { staticClass: "description-actual" }, [
        _c("div", [
          _vm._v("All team accounts include full feature support (as of the "),
          _c("b", [_vm._v("Platinum")]),
          _vm._v(" plan).")
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("Team owner has full overview of their team's actions.")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "symbol-wrapper" }, [
      _c("span", { staticClass: "symbol fa fa-shopping-cart" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }