<template>
  <div>
    <div class="row">
      <!-- PREVIEW BOX -->
      <div class="col-md-6">
        <h4 class="preview-h">Share on Facebook preview</h4>
        <div class="preview-box">
          <div class="preview-image">
            <image-square :src="imageSrc"
                          :opacity="0.7"
                          spinner-color="#bbb"
                          spinner-side="30px"
                          no-image-msg="<div style='color: #999;'><div><span style='font-size: 40px; margin-bottom: 5px;' class='fa fa-picture-o fa-3'></span></div><div style='font-size: 12px;'>image preview</div></div>"
            ></image-square>
          </div>
          <div class="preview-info">
            <div>LINKDELI.COM</div>
            <div class="title">
              {{ title.text.length > 0 ? title.text : "Collection's name"  }}
            </div>
            <div class="description">
              {{ description.length > 0 ? description : "(empty description)"  }}
            </div>
          </div>
        </div>

        <div v-if="settings !== null" class="revert">
          <a style="color: #c71111 !important;" @click.prevent="revert" href="#">
            Remove custom settings
          </a>
        </div>
      </div><!-- /.col-md-5 -->

      <!-- SETTING -->
      <div class="col-md-6">
        <!-- TITLE -->
        <div class="form-group">
          <label for="titleType">Preview Title</label>
          <select class="form-control select select-dark select-block"
                  ref="titleType"
                  id="titleType"
                  data-toggle="select"
                  v-model="title.type"
          >
            <option value="default">Use Collection's Name</option>
            <option value="custom">Use Custom Text</option>
          </select>

          <!-- custom title input -->
          <template v-if="title.type === 'custom'">
            <div class="custom-title-input">
              <label for="customTitle">Custom Title</label>
              <input class="form-control"
                     placeholder="Enter preview title"
                     type="text"
                     id="customTitle"
                     v-model="title.text"
              >
            </div>
          </template>
        </div><!-- /.form-group -->


        <!-- DESCRIPTION -->
        <div class="form-group">
          <label for="description">Preview Description</label>
          <input class="form-control"
                 placeholder="Enter preview description"
                 type="text"
                 id="description"
                 v-model="description"
          >
        </div><!-- /.form-group -->

        <!-- ENTER / UPLOAD IMAGE -->
        <div class="form-group">
          <label for="image_url">Preview Image</label>
          <input type="text"
                 class="form-control"
                 placeholder="Enter image url (eg: https://example.com/image.png)"
                 name="image_url"
                 id="image_url"
                 :value="imageSrc"
                 @input="updateImageSrc"
          >
          <div class="manual-upload">
            <div>
              <div class="separator">
                - or -
              </div>

              <button v-if="!uploading" @click="openUploadWindow" class="btn btn-sm btn-info btn-embossed">
                <i class="fa fa-upload"></i> Upload image manually
              </button>
              <button v-else disabled class="btn btn-sm btn-info btn-embossed">
                <i class="fa fa-upload"></i> Uploading
              </button>
              <input type="file"
                     class="hidden"
                     accept="image/*"
                     name="image_upload"
                     id="image_upload"
                     ref="selectImage"
                     @change="selectImage"
              >
            </div>
          </div>
        </div><!-- /.form-group -->

        <div class="form-group">
          <button class="btn btn-lg btn-primary btn-embossed pull-right"
                  style="margin-right: 10px;"
                  @click="save"
                  :disabled="!readyToSave"
          >
            Save
          </button>
        </div><!-- /.form-group -->
      </div><!-- /.col-md-6 -->
    </div><!-- /.row -->
  </div>
</template>

<script>
import axios from 'axios';

// Components
import ImageSquare from '../lib/components/ImageSquare.vue';

export default {
  components: {
    'image-square': ImageSquare,
  },

  props: {
    settings: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      title: {
        type: 'default',
        text: ""
      },
      description: "",
      imageSrc: "",
      uploading: false,
    }
  },

  computed: {
    readyToSave() {
      return !this.uploading && this.imageSrc.length > 0;
    }
  },

  mounted() {
    $(this.$refs.titleType).on('select2-selecting', e => {
      this.title.type = e.val;
      if (this.title.type === 'default') {
        this.title.text = ""
      }
    });

    // Set initial values
    if (this.settings !== null) {
      this.description = this.settings.description || ""
      this.imageSrc    = this.settings.image_url || ""

      if (this.settings.title !== null) {
        this.title.type = "custom";
        this.title.text = this.settings.title;
      }
    }
  },


  methods: {
    openUploadWindow() {
      // reset input (file) field to original/null value
      // if this is not done the 'change' event does not fire second time
      this.$refs.selectImage.value = null
      this.$refs.selectImage.click();
    },

    updateImageSrc: _.debounce(function (e) {
      this.imageSrc = e.target.value;
    }, 500),

    validateImage(imageFile) {
      if (!_.startsWith(imageFile.type, 'image')) {
        this.$events.emit('notify', {
          type: 'danger',
          title: 'File type not supported',
          message: 'The file you are trying to upload is not an image.',
          timeout: 5000
        });

        return false;
      }

      if ((imageFile.size / (1024 * 1024)).toFixed(2) > 5) {
        this.$events.emit('notify', {
          type: 'danger',
          title: 'Image size exceeded',
          message: 'Your image is bigger than 5MB. Please upload a smaller image',
          timeout: 5000
        });

        return false;
      }

      return true;
    },

    // IMAGE UPLOAD
    selectImage() {
      let imageUpload = this.$refs.selectImage.files[0];

      if (!this.validateImage(imageUpload)) {
        return false;
      }

      let formData = new FormData();
      formData.append("image", imageUpload);
      formData.append("_token", window.csrfToken);

      this.uploading = true;
      axios.post('api/user/setting/fb-preview/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        this.uploading = false;
        if (response.data.status === "success") {
          this.imageSrc = response.data.img;
        }
      })
      .catch((error) => {
        this.uploading = false;
        let title = 'Error';
        let message = 'An error occurred, please try again.'
        if (error.response.data.errors) {
          message = error.response.data.errors.image[0]
        }
        this.$events.emit('notify', {
          type: 'danger',
          title: title,
          message: message,
          timeout: 5000
        });
      })
    },

    // REVERT SETTINGS
    revert() {
      if(confirm("Do you really want to revert to default preview?")){
          axios.post('api/user/setting/fb-preview', {
          _token: window.csrfToken,
          _method: "delete"
        })
        .then((response) => {
          let status = response.data.status

          if (status === 'success') {
            this.title.type = 'default';
            this.title.text = '';
            this.description = '';
            this.imageSrc = '';
            this.settings = null;

            this.$events.emit('notify', {
              type: 'success',
              title: 'Success',
              message: 'Settings reverted to defaults',
              timeout: 5000
            });
          }
        })
        .catch((error) => {
          this.$events.emit('notify', {
            type: 'danger',
            title: 'Error',
            message: 'An error occurred, please try again.',
            timeout: 5000
          });
        })
      }
    },

    // SAVE SETTINGS
    save() {
      axios.post('api/user/setting/fb-preview', {
        _token: window.csrfToken,
        title: this.title.text,
        description: this.description,
        image: this.imageSrc,
      })
      .then((response) => {
        let status = response.data.status

        switch (status) {
          case 'success':
            this.$events.emit('notify', {
              type: 'success',
              title: 'Saved',
              message: 'Facebook preview settings saved',
              timeout: 2000
            });
            break;
          case 'fail':
            this.$events.emit('notify', {
              type: 'danger',
              title: "Something went wrong!",
              message: 'An error occurred, please try again or contact our support.',
              timeout: 5000
            });
            break;
        }
      })
      .catch((error) => {
        let title = 'Error';
        let message = 'An error occurred, please try again.'
        if (error.response.data.errors) {
          message = error.response.data.errors.image[0]
        }
        this.$events.emit('notify', {
          type: 'danger',
          title: title,
          message: message,
          timeout: 5000
        });
      });
    }
  }
}
</script>

<style lang='less'>
#fb-preview-settings {
  .preview-h {
    width: 85%;
    color: #16a085;
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    border: 2px solid #16a085;
    margin-top: 0;
  }

  .preview-box {
    width: 85%;
    background: #f2f3f5;
    border: 1px solid #e3e3e3;

    .preview-image {
      background: white;
      margin: 0 auto;
      width: 100%;
    }

    .preview-info {
      margin-top: 5px;
      padding: 8px;
      text-transform: uppercase;

      .title {
        text-transform: none;
        font-size: 15px;
        font-weight: bold;
      }

      .description {
        text-transform: none;
        font-size: 15px;
      }
    }
  }

  padding: 35px 20px 60px 20px;

  h4 {
    color: #555;
  }

  input {
    border-color: transparent;
    background: #f5f5f5;
  }

  label {
    padding-left: 2px;
    font-size: 17px;
    color: #555;
    font-weight: bolder;
  }

  > .wrapper {
    margin-top: 50px;
  }

  .manual-upload {
    text-align: center;

    .separator {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #aaa;
      font-size: 14px;
    }
  }

  .option {
    position: relative;
    padding-bottom: 20px;

    > label {
      font-size: 17px;
      color: #555;
      font-weight: bolder;
      width: 100%;
      padding-left: 4px;

      &:not(.enabled) {
        opacity: 0.6;
      }
    }

    > .bootstrap-switch {
      right: 0;
      position: absolute;
      top: 5px;
    }
  }

  .custom-title-input {
    width: 95%;
    margin: 0 auto;
    margin-top: 15px;
  }

  .revert {
    width: 85%;
    text-align: center;
    margin-top: 20px;
    font-size: 17px;
  }
}
</style>
