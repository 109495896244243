var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { id: "braintree-form", action: _vm.submitRoute, method: "POST" }
      },
      [
        _c("input", {
          attrs: { type: "hidden", name: "_token" },
          domProps: { value: _vm.csrfToken }
        }),
        _vm._v(" "),
        _c("div", { attrs: { id: "braintree-container" } }),
        _vm._v(" "),
        _vm.type === "subscribe"
          ? _c(
              "div",
              { staticClass: "coupon", staticStyle: { "padding-top": "25px" } },
              [
                _vm._v("\n            Have a coupon?\n            "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.couponCode,
                      expression: "couponCode"
                    }
                  ],
                  staticClass: "coupon-input",
                  attrs: { type: "text", name: "couponCode" },
                  domProps: { value: _vm.couponCode },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.couponCode = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    attrs: { type: "button", id: "coupon-button" },
                    on: { click: _vm.validateCoupon }
                  },
                  [_vm._v("\n                Apply\n            ")]
                ),
                _vm._v(" "),
                _c("div", { class: ["coupon-message", _vm.couponStatus] }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.couponMessage) +
                      "\n            "
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "button-wrapper" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-lg btn-block btn-embossed",
              attrs: { type: "submit", disabled: _vm.processing }
            },
            [
              _c("span", { staticStyle: { "margin-right": "8px" } }, [
                !_vm.processing
                  ? _c("span", [_vm._v(_vm._s(_vm.buttonText))])
                  : _c("span", [_vm._v("Processing, please wait")])
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "icon" }, [
                !_vm.processing && _vm.type === "subscribe"
                  ? _c("i", { staticClass: "fa fa-shopping-cart" })
                  : _c("span", { staticClass: "icon" }, [
                      _c("img", { attrs: { src: _vm.processingSvg } })
                    ])
              ])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }