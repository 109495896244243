// Template

<template>
    <div class="container">
        <pricing-popup v-if="!userIsLoggedIn"
                       :show="showTrialPopup"
                       :badgeImg="badgeImgSrc"
        ></pricing-popup>

        <div id="user_pricing">
            <div class="features">
                <div class="header"></div>
                <span>
                    <div v-for="feature in plans.features"
                         class="feature hint--top hint--rounded hint--large"
                         :aria-label="feature.description"
                    >
                        {{ feature.name }}
                    </div>
                </span>
            </div>

            <div class="plans">
                <div v-for="(plan, key) in singlePaidPlans"
                     :class="[ 'plan', { 'active': isPlanCurrent(key) } ]"
                >
                    <div class="header"
                         :style="{ 'background': planColor(key) }"
                    >
                        <span v-if="key === 'gold'"
                              class="recommended"
                        >
                            <span class="fa fa-star"></span>
                        </span>

                        <div class="title">{{ plan.title }}</div>
                        <span class="shade">
                            <div class="price">
                                <span class="dollar">$</span><span class="number">{{ plan.price }}</span><span class="basis">/ month</span>
                            </div>
                            <!--<div class="description">{{ plan.description }}</div>-->
                        </span>
                    </div>

                    <span class="features">
                        <div v-for="(feature, key) in plan.features"
                             :class="[ 'feature', { 'enabled': feature && typeof feature === 'boolean', 'disabled': !feature && typeof feature === 'boolean' } ]"
                        >
                            <div class="description">{{ plans.features[key].name }}</div>

                            <template v-if="feature">
                                <span v-if="typeof feature === 'boolean'"
                                      class="fa fa-check"
                                ></span>
                                <span v-else>{{ plans.features[key].plan[feature] }}</span>
                            </template>

                            <span v-else
                                  class="fa fa-close"
                            ></span>
                        </div>

                        <div v-if="isPlanCurrent(key)"
                             class="tick"
                             :style="{ 'background': planColor(key) }"
                        >
                            <span class="icon icon-tick"></span>
                        </div>
                    </span>

                    <div v-if="isPlanCurrent(key)"
                         class="plan-active"
                         :style="{ 'background': planColor(key) }"
                    >
                        <span class="text">Active</span>
                    </div>

                    <template v-else>
                        <template v-if="!isSubscriptionActive && !isPartOfTeam">
                            <a :href="'/subscribe/' + key"
                               class="buy-now btn btn-primary"
                            >
                                <span class="symbol-wrapper">
                                        <span class="symbol fa fa-shopping-cart"></span>
                                    </span>

                                <span class="text">Buy now</span>
                            </a>
                        </template>
                    </template>
                </div>
            </div>
        </div>

        <div id="team_pricing">
            <div class="or-plan" id="team-plans" name="team-plans">
                <div class="text">or</div>
                <div class="line"></div>
            </div>

            <div class="description-bs">
                Plans available for <b>Teams</b>
                <div class="description-actual">
                    <div>All team accounts include full feature support (as of the <b>Platinum</b> plan).</div>
                    <div>Team owner has full overview of their team's actions.</div>
                </div>
            </div>

            <div class="plans">
                <div v-for="(plan, key) in teamPlans"
                     class="plan"
                >
                    <div class="header"
                         :style="{ 'background': planColor(key) }"
                    >
                        <div class="title">{{ plan.title }}</div>
                        <div class="description">{{ plan.description }}</div>

                        <span class="shade">
                            <div class="price">
                                <span class="dollar">$</span>
                                <span class="number">{{ plan.price }}</span>
                                <span class="basis">/ month</span>
                            </div>
                        </span>
                    </div>

                    <div v-if="isPlanCurrent(key)"
                         class="plan-active"
                         :style="{ 'background': planColor(key) }"
                    >
                        <span class="text">Active</span>
                    </div>

                    <template v-else>
                        <template v-if="!isSubscriptionActive && !isPartOfTeam">
                            <a :href="'/subscribe/' + key"
                               class="buy-now btn btn-primary"
                            >
                                <span class="symbol-wrapper">
                                    <span class="symbol fa fa-shopping-cart"></span>
                                </span>

                                <span class="text">Buy now</span>
                            </a>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

// Style

<style lang="less">
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    #user_pricing {
        margin-top: 40px;
        margin-bottom: 70px;
        display: flex;
        flex-direction: row;

        > .features {
            flex: 0 0 200px;

            > span {
                @radius: 4px;

                background: #f6f6f6;
                border-radius: @radius;
                display: block;
                margin-top: 10px;

                > .feature {
                    color: fadeout(#000, 30%);
                    font-size: 15px;
                    transition: all ease 0.25s;
                    line-height: 2;
                    font-weight: 500;
                    border-radius: 0;

                    &:first-of-type {
                        border-top-left-radius: @radius;
                        border-top-right-radius: @radius;
                    }

                    &:last-of-type {
                        border-bottom-left-radius: @radius;
                        border-bottom-right-radius: @radius;
                        padding-bottom: 0;
                    }

                    &:after {
                        font-size: 14px;
                        width: 110%;
                    }

                    &:hover {
                        background: fadeout(#000, 50%);
                        color: #fff;
                    }

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        > .plans {
            flex: 1;
            display: flex;

            > .plan {
                flex: 1;
                margin-left: 10px;

                &.active {

                    > .features > *:not(.tick) {
                        opacity: 0.25;
                        pointer-events: none;
                    }

                    > .features {
                        position: relative;

                        .tick {
                            .centered-block;
                            .block-round;

                            width: 100px;
                            height: 100px;

                            > span {
                                .centered-block;

                                font-size: 30px;
                                color: fadeout(#000, 60%);
                            }
                        }
                    }
                }

                > .header {
                    border-radius: 3px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    > .recommended {
                        .block-round;

                        position: absolute;
                        top: -36px;
                        right: 23px;
                        font-size: 33px;
                        width: 70px;
                        height: 70px;
                        background: inherit;
                        border: 3px solid #fff;

                        > span {
                            .centered-block;

                            color: fadeout(#000, 65%);
                        }
                    }

                    > .title {
                        flex: 1;
                        font-weight: bolder;
                        font-size: 25px;
                        color: fadeout(#000, 60%);
                        padding: 20px;
                        line-height: 2;
                    }

                    > .shade {
                        flex: 1;
                        padding: 15px;
                        background: fadeout(#000, 70%);
                        display: block;
                        color: #fff;
                        border-bottom-left-radius: 3px;
                        border-bottom-right-radius: 3px;

                        > .price {
                            position: relative;
                            display: inline-flex;

                            > .dollar {
                                font-size: 17px;
                                position: absolute;
                                opacity: 0.85;
                                bottom: 15px;
                                left: -15px;
                            }

                            > .number {
                                font-weight: bolder;
                                font-size: 45px;
                            }

                            > .basis {
                                position: absolute;
                                top: 35px;
                                right: -50px;
                                font-size: 13px;
                                opacity: 0.85;
                            }
                        }
                    }
                }

                > .features {
                    display: block;

                    > .feature {
                        background: #f6f6f6;
                        color: #777;

                        &.enabled {
                            color: @brand-success;
                        }

                        &.disabled {
                            color: @brand-danger;
                        }

                        > .description {
                            display: none;
                        }
                    }
                }

                > .buy-now {
                    margin-top: 10px;
                    width: 100%;
                    padding: 25px;
                    font-size: 20px;
                    font-weight: bolder;
                    position: relative;
                    overflow: hidden;
                    border-radius: 4px;

                    > .symbol-wrapper {
                        background: fadeout(#fff, 85%);
                        display: block;
                        position: absolute;
                        height: 100%;
                        width: 70px;
                        top: 0;
                        left: 0;

                        > .symbol {
                            .centered-block;

                            font-size: 25px;
                            color: fadeout(#fff, 20%)
                        }
                    }

                    > .text {
                        padding-left: 45px;
                        width: 100%;
                        display: block;
                    }
                }

                > .plan-active {
                    margin-top: 10px;
                    width: 100%;
                    padding: 25px;
                    font-size: 20px;
                    font-weight: bolder;
                    position: relative;
                    overflow: hidden;
                    border-radius: 4px;
                    line-height: 1.4;

                    > .text {
                        width: 100%;
                        display: block;
                        text-align: center;
                        color: fadeout(#000, 60%);
                    }
                }
            }
        }

        > .features,
        > .plans {

            .header {
                height: 200px;
                display: block;
            }

            .feature {
                padding: 10px;
                text-align: center;
                width: 100%;
                cursor: default;
                margin-top: 10px;
                border-radius: 3px;
                height: 50px;

                > span {
                    font-weight: bolder;
                    font-size: 15px;
                    line-height: 2;
                }
            }
        }

        @media (max-width: 992px) {

            > .features {
                display: none;
            }

            > .plans {
                flex-direction: column;

                > .plan {
                    margin-bottom: 100px;
                    margin-left: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .feature {
                        height: auto;

                        > span {
                            line-height: 1;
                        }

                        > .description {
                            display: block !important;
                            margin-bottom: 3px;
                            font-size: 15px;
                            color: #888;
                        }
                    }
                }
            }
        }
    }

    #team_pricing {

        > .or-plan {
            .or-line(#fff);

            margin-bottom: 40px;
        }

        > .description-bs {
            line-height: 1;
            padding: 40px;
            text-align: center;
            margin-bottom: 20px;
            font-size: 24px;
            color: #aaa;

            > .description-actual {
                font-size: 17px;
                margin-top: 13px;
            }
        }

        > .plans {
            margin-bottom: 150px;
            display: flex;
            flex-direction: row;

            > .plan {
                flex: 1;
                margin-right: 10px;
                border-radius: 4px;
                color: #fff;

                > .header {
                    border-radius: 3px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    > .recommended {
                        .block-round;

                        position: absolute;
                        top: -36px;
                        right: 23px;
                        font-size: 33px;
                        width: 70px;
                        height: 70px;
                        background: inherit;
                        border: 3px solid #fff;

                        > span {
                            .centered-block;

                            color: fadeout(#000, 65%);
                        }
                    }

                    > .title {
                        flex: 1;
                        font-weight: bolder;
                        font-size: 25px;
                        color: fadeout(#fff, 35%);
                        padding: 20px;
                        line-height: 2;
                    }

                    > .description {
                        margin-top: -25px;
                        padding-bottom: 20px;
                        color: fadeout(#fff, 35%);
                        font-size: 15px;
                    }

                    > .shade {
                        flex: 1;
                        padding: 15px;
                        background: fadeout(#000, 70%);
                        display: block;
                        color: #fff;
                        border-bottom-left-radius: 3px;
                        border-bottom-right-radius: 3px;

                        > .price {
                            position: relative;
                            display: inline-flex;

                            > .dollar {
                                font-size: 17px;
                                position: absolute;
                                opacity: 0.85;
                                bottom: 15px;
                                left: -15px;
                            }

                            > .number {
                                font-weight: bolder;
                                font-size: 45px;
                            }

                            > .basis {
                                position: absolute;
                                top: 35px;
                                right: -50px;
                                font-size: 13px;
                                opacity: 0.85;
                            }
                        }
                    }
                }

                > .buy-now {
                    margin-top: 10px;
                    width: 100%;
                    padding: 25px;
                    font-size: 20px;
                    font-weight: bolder;
                    position: relative;
                    overflow: hidden;
                    border-radius: 4px;

                    > .symbol-wrapper {
                        background: fadeout(#fff, 85%);
                        display: block;
                        position: absolute;
                        height: 100%;
                        width: 70px;
                        top: 0;
                        left: 0;

                        > .symbol {
                            .centered-block;

                            font-size: 25px;
                            color: fadeout(#fff, 20%)
                        }
                    }

                    > .text {
                        padding-left: 45px;
                        width: 100%;
                        display: block;
                    }
                }

                > .plan-active {
                    margin-top: 10px;
                    width: 100%;
                    padding: 25px;
                    font-size: 20px;
                    font-weight: bolder;
                    position: relative;
                    overflow: hidden;
                    border-radius: 4px;
                    line-height: 1.4;

                    > .text {
                        width: 100%;
                        display: block;
                        text-align: center;
                        color: fadeout(#fff, 20%);
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        @media (max-width: 992px) {

            > .plans {
                flex-direction: column;

                > .plan {
                    margin-bottom: 100px;
                    margin-right: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    > .feature {
                        height: auto;

                        > span {
                            line-height: 1;
                        }

                        > .description {
                            display: block;
                            margin-bottom: 3px;
                            font-size: 15px;
                            color: #888;
                        }
                    }
                }
            }
        }
    }
</style>

<script>

    import PricingPopup from './PricingPopup.vue';

    export default
    {
        components: {
            'pricing-popup': PricingPopup,
        },

        data() {
            return {
                paidPlans: [],
                singlePaidPlans: [],
                teamPlans: [],
                showTrialPopup: false,
            }
        },

        props: {
          data: {
              type: Object,
              default: {}
          }
        },

        computed: {
            isTrial() {
                return _.indexOf([ 'trial', 'none'], this.userPlans.current.key) > -1;
            },

            userIsLoggedIn() {
                return this.loggedIn
            },

            isSubscriptionActive() {
                return this.fullyActive;
            }
        },

        methods: {
            planColor(key) {
                switch(key) {
                    case 'silver':
                        return '#e4e3e2';
                        return '#c0c0c0';
                    case 'gold':
                        return '#ffd700';
                    case 'platinum':
                        return '#ffd3a9';
                        return '#e4e3e2';
                    case 'team_small':
                    case 'team_medium':
                    case 'team_big':
                        return '#555';
                }
            },

            isPlanCurrent(key) {
                return this.fullyActive && this.userPlans.current.key === key;
            },

            isPlanHigher(key) {
                if (!this.isTrial) {
                    return _.indexOf(this.userPlans.higher, key) > -1;
                } else
                    return false;
            },

            isPlanLower(key) {
                if (!this.isTrial) {
                    return _.indexOf(this.userPlans.lower, key) > -1;
                } else
                    return false;
            }
        },

        created() {
            _.merge(this, this.data)

            this.paidPlans = _.omit(this.plans.available, [ 'trial', 'team_member' ]);
            this.singlePaidPlans = _.omitBy(this.paidPlans, (value, key) => {
                return _.startsWith(key, 'team');
            });
            this.teamPlans = _.omitBy(this.paidPlans, (value, key) => {
                return !_.startsWith(key, 'team');
            });

            this.plans.features.clicks.plan = _.mapValues(this.plans.features.clicks.plan, plan => {
               return `${ Math.round(plan / 1000) }K`;
            });
        },


        mounted() {
            setTimeout(() => {
                this.showTrialPopup = true
            }, 3000)
        }
    }
</script>
