var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["image", { loaded: _vm.imgLoaded }] },
    [
      _c("div", { staticClass: "aspectKeep" }),
      _vm._v(" "),
      _vm.inViewport
        ? [
            !_vm.hasImage
              ? _c("span", { staticClass: "no-image" }, [
                  _c("div", { staticClass: "default" }, [
                    _vm.noImageMsg.length
                      ? _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.noImageMsg) }
                        })
                      : _c("b", [_vm._v("No image found")])
                  ])
                ])
              : _c(
                  "transition",
                  { attrs: { name: "fade" } },
                  [
                    !_vm.imgLoaded
                      ? _c("loader", {
                          attrs: {
                            side: _vm.spinnerSide,
                            color: _vm.spinnerColor,
                            opacity: _vm.opacity,
                            width: "spinnerWidth"
                          }
                        })
                      : _c(
                          "div",
                          {
                            staticClass: "thumb",
                            style: { "border-radius": _vm.roundness }
                          },
                          [
                            _vm.isPreview || _vm.isCover
                              ? [
                                  _c("span", {
                                    class: {
                                      preview: _vm.isPreview,
                                      cover: _vm.isCover
                                    },
                                    style: { "background-image": _vm.imageUrl },
                                    attrs: { "data-src": _vm.imageSrc }
                                  })
                                ]
                              : [
                                  _c("img", {
                                    attrs: { src: _vm.imageSrc, alt: _vm.alt }
                                  })
                                ],
                            _vm._v(" "),
                            _vm._t("default")
                          ],
                          2
                        )
                  ],
                  1
                )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }