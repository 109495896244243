<template>
    <div :class="[ 'product', size_class, { 'draggable': draggable, 'removing': removing, 'in-products': inuserproducts } ]"
         :data-grp="grpVal"
         :data-id="data.id || 0"
    >
        <div v-if="type === 'bootstrap'"
             :class="[ 'thumbnail', 'group' + grpVal, { 'selected': isSelected, 'in-search': inSearch, 'in-list': inlist, 'saving': saving, 'editing-title': editingTitle } ]"
             :style="{ 'background': background }"
             @click="addToCollection"
             @mouseover="updateBackground"
             ref="thumbnail"
        >

            <!--_________________________ TOP ROW ______________________________-->

            <div class="top-caption"
                 @mouseover="function() { overCaption = true; }"
                 @mouseout="function() { overCaption = false; }"
            >
                <div class="wrapper">
                    <template v-if="inlist">
                        <template v-if="!isJoined">
                            <div v-if="index"
                                 class="join top-button hint--top hint--rounded hint--small"
                                 :aria-label="overCaption ? 'join with previous' : ''"
                                 @click="joinWithPrevious"
                            >
                                <span class="icon icon-join"></span>
                            </div>
                        </template>

                        <div v-else
                             @click="breakGroup"
                             class="break top-button hint--top hint--rounded"
                             :aria-label="overCaption ? 'break join' : ''"
                        >
                            <span class="icon icon-break"></span>
                        </div>
                    </template>

                    <template v-else>
                        <a v-if="isSelected"
                           class="tick top-button"
                           @click.prevent.stop="deselectProduct"
                           href="#"
                        >
                            <span class="fa fa-check"></span>
                        </a>

                        <template v-else>
                            <span v-show="isUserLink"
                                  class="user top-button"
                            >
                                <i class="fa fa-user"></i>
                            </span>
                        </template>
                    </template>

                    <span v-if="inlist"
                          :class="[ 'shop_thumbs', 'pull-right', { 'is-group': data.group } ]"
                          :style="{ 'height': groupHeight }"
                    >
                        <div class="pull-right">
                            <logo-image :src="data.shop_icon"
                                        side="35px"
                            ></logo-image>

                            <template v-if="data.groupLinks">
                                 <logo-image v-for="(product, index) in data.groupLinks"
                                             v-if="index <= 2"
                                             :key="product.id"
                                             :src="product.shop_icon"
                                             side="29px"
                                             style="position: relative; right: -3px;"
                                 ></logo-image>
                            </template>
                        </div>

                        <template v-if="data.groupLinks">
                            <div class="pull-right"
                                 style="padding-top: 6px;"
                            >
                                <logo-image v-for="(product, index) in data.groupLinks"
                                            v-if="index > 2"
                                            :key="product.id"
                                            :src="product.shop_icon"
                                            side="29px"
                                ></logo-image>
                            </div>
                        </template>
                    </span>

                    <span v-else
                          class="shop_thumbs pull-right"
                    >
                        <div class="pull-right">
                            <logo-image :src="data.shop_icon"
                                        side="35px"
                            ></logo-image>
                        </div>
                    </span>
                </div>
            </div>

            <!--___________________________ IMAGE ROW _________________________________-->

            <div class="thumb_container"
                 ref="thumbContainer"
            >
                <image-square :src="data.thumb_path"
                              :alt="title"
                              :opacity="0.7"
                              spinner-color="#bbb"
                              spinner-side="30px"
                ></image-square>

                <div v-if="!inlist"
                     class="click-once"
                >
                    <span v-if="inuserproducts">Click to select</span>
                    <span v-else
                          class="click-add"
                    >
                        <div class="prepend">
                            <span>
                                <div>
                                    <span class="icon icon-arrow-top"></span>
                                </div>
                                <div>prepend</div>
                            </span>
                        </div>
                        <div class="append">
                            <span>
                                <div>append</div>
                                <div class="flipped">
                                    <span class="icon icon-arrow-top"></span>
                                </div>
                            </span>
                        </div>
                    </span>
                </div>
            </div>

            <!--____________________________ TITLE ROW ______________________________-->
            <div class="product-title caption">

                <template v-if="inlist || (inuserproducts && !isUserLink && !isTeamMember)">
                    <span :class="[ 'editable-text', { 'editing': editingTitle } ]"
                          :style="{ 'pointer-events': inuserproducts || currentBackground ? 'all' : 'none' }"
                          @click.stop="editTitle(inlist ? 'custom' : 'default')"
                    >
                        <p class="product-title-text"
                           :title="title"
                        >{{ title }}</p>

                        <span class="edit">
                            <span class="fa fa-pencil"></span>
                            <span class="gradient"
                                  :style="{ 'background': linearBackground }"
                            ></span>
                        </span>

                        <template v-if="inlist">
                            <textarea v-if="editingTitle"
                                      v-model="customTitle"
                                      class="product-title-text"
                                      rows="3"
                                      ref="titleText"
                                      @keydown.enter="saveTitle"
                                      v-on-clickaway="saveTitle"
                            >{{ title }}</textarea>
                        </template>

                        <template v-if="inuserproducts">
                            <textarea v-if="editingTitle"
                                      v-model="customTitleDefault"
                                      class="product-title-text"
                                      rows="3"
                                      ref="titleText"
                                      @keydown.enter="saveDefaultTitle"
                                      v-on-clickaway="saveDefaultTitle"
                            >{{ title }}</textarea>
                        </template>
                    </span>
                </template>

                <p v-else
                   class="product-title-text"
                   :title="title"
                >{{ title }}</p>

                <!--if it is in edit list -->
            </div>

            <div class="footer">
                <template v-if="inlist">
                    <!--<transition name="fade">-->
                        <span v-if="!editingTitle">
                            <button class="btn btn-primary hint--top hint--rounded"
                                    aria-label="preview"
                                    @click="previewLink"
                            >
                                <i class="fa fa-eye"></i>
                            </button>
                            <button v-if="!data.groupLinks"
                                    @click="addTag"
                                    class="btn btn-darkgray hint--top hint--rounded"
                                    aria-label="tags"
                            >
                                <i class="fa fa-tags"></i>
                            </button>
                            <button @click="removeFromCollection"
                                    class="btn btn-danger hint--top hint--rounded"
                                    aria-label="remove"
                            >
                                <loader v-if="removing"
                                        side="15px"
                                        color="#fff"
                                        :opacity="1"
                                ></loader>

                                <i v-else
                                   class="fa fa-trash"
                                ></i>
                            </button>
                        </span>

                        <button v-else
                                @click="saveTitle"
                                class="btn btn-primary"
                                style="width: auto; height: 20px; margin-top: 11px; line-height: 0; padding-left: 28px; padding-right: 12px; float: right;"
                        >
                            <i class="fa fa-check" style="left: 15px;"></i>save
                        </button>
                    <!--</transition>-->
                </template>


                <!--if it is on search list-->

                <template v-else>
                    <span>
                        <button v-if="!inuserproducts"
                                @click.stop="searchSimilar"
                                class="btn btn-primary hint--top hint--rounded"
                                aria-label="similar"
                        >
                            <i class="fa fa-cubes"></i>
                        </button>
                            <!--<button @click.stop="searchInShops"-->
                            <!--class="btn btn-primary hint&#45;&#45;top hint&#45;&#45;rounded"-->
                            <!--aria-label="other shops"-->
                            <!--&gt;-->
                            <!--<i class="fa fa-shopping-cart"></i>-->
                            <!--</button>-->

                        <template v-if="isUserLink">
                            <button @click.stop="editLink"
                                    class="btn btn-primary hint--top hint--rounded" aria-label="edit"
                            >
                                <i class="fa fa-edit"></i>
                            </button>
                            <button @click.stop="addTag"
                                    class="btn btn-darkgray hint--top hint--rounded"
                                    aria-label="tags"
                            >
                                <i class="fa fa-tags"></i>
                            </button>
                            <button @click.stop="deleteLink"
                                    class="btn btn-danger hint--top hint--rounded"
                                    aria-label="remove"
                            >
                                <loader v-if="removing"
                                        side="15px"
                                        color="#fff"
                                        :opacity="1"
                                ></loader>

                                <i v-else
                                   class="fa fa-trash"
                                ></i>
                            </button>
                        </template>

                        <template v-else>
                            <button v-if="!editingTitle"
                                    @click.stop="addTag"
                                    class="btn btn-darkgray hint--top hint--rounded"
                                    aria-label="tags"
                            >
                                <i class="fa fa-tags"></i>
                            </button>
                        </template>
                    </span>

                      <button v-if="editingTitle"
                              @click.stop="saveDefaultTitle"
                              class="btn btn-primary"
                              style="width: auto; height: 20px; margin-top: 11px; line-height: 0; padding-left: 28px; padding-right: 12px; float: right;"
                      >
                        <i class="fa fa-check" style="left: 15px;"></i>save
                    </button>
                </template>

                <!--<div>-->
                    <!--<span v-for="tag in data.tags"-->
                          <!--class="label label-default minitag"-->
                    <!--&gt;-->
                        <!--{{ tag.name }}-->
                    <!--</span>-->
                <!--</div>-->
            </div>
        </div>

        <div v-if="type === 'table'"
             class="img-small"
        >
            <div class="image-wrapper">
                <image-square :src="data.thumb_path"
                              :alt="title"
                              :opacity="0.7"
                              spinner-color="#bbb"
                              spinner-side="20px"
                ></image-square>
            </div>

            <div v-if="joined"
                 class="joined"
            >
                <span>{{ joined }}</span>
            </div>
        </div>
    </div>
</template>

<script  >
    import { mixin as clickaway } from 'vue-clickaway';
    import ImageSquare from '../lib/components/ImageSquare';
    import Loader from '../lib/components/Loader.vue';
    import LogoImage from '../lib/components/LogoImage';

    export default{
        components: {
            'image-square': ImageSquare,
            'logo-image': LogoImage,
            'loader': Loader,
        },

        props: {
            data: {
                type: Object,
                default: null
            },
            index: null,
            inlist: {
                type: Boolean,
                default: false
            },
            tsize: {
                type: String,
                default: "m"
            },
            inedit: {
                type: Boolean,
                default: false
            },
            linksincurrentlist: {
                type: Array
            },
            tags: {
                type: Array
            },
            multiselect: {
                type: Boolean,
                default: false
            },
            userid: {
                type: Number
            },
            background: {
                type: String,
                default: '#f5f5f5'
            },
            draggable: {
                type: Boolean,
                default: false
            },
            inuserproducts: {
                type: Boolean,
                default: false
            },
            user: Object,
            joined: {
                type: Number,
                default: null
            }
        },

        data() {
            return {
                type: "bootstrap",
                groupHeight: '0px',
                saving: false,
                tooltipVisible: false,
                tooltipY: 0,
                tooltipX: 0,
                currentBackground: null,
                editingTitle: false,
                customTitle: null,
                customTitleDefault: null,
                removing: false,
                overCaption: false
            }
        },

        computed: {
            title() {
                return _.trim(this.customTitle) || _.trim(this.customTitleDefault) || this.data.title;
            },
            linearBackground() {
                return `${ this.currentBackground || this.background }`;
            },
            grpVal() {
                if (this.inlist)
                    return this.productGroups[this.data.id];
                else
                    return null;
            },

            // properties for search objects
            inSearch() {
                return !this.inlist;
            },
            isAddedToList () {
                if (this.inuserproducts)
                    return _.findIndex(this.$store.getters.getUserList, {
                                id: this.data.id
                            }) !== -1;
                else
                    return _.findIndex(this.$store.getters.getUserList, {
                                title: this.data.title,
                                url: this.data.url
                            }) !== -1;
            },
            isSelected() {
                return this.inSearch && this.isAddedToList;
            },
            isUserLink() {
                return this.data.user_id == this.userid;
            },

            // properties for list objects
            isJoined() {
                return this.grpVal !== 0;
            },

            size_class() {
                switch (this.tsize) {
                    case "m":
                        this.type = "bootstrap";
                        return "col-xs-4 col-sm-3 col-md-3 col-lg-2";
                        break;
                    case "custom":
                        this.type = "bootstrap";
                        return "";
                    case "t":                       // in table
                        this.type = "table";
                        return "";
                }
            },

            productGroups: {
                get() {
                    return this.$store.getters.getProductGroups;
                },
                set(val) {
                    this.$store.dispatch('setProductGroups', val);
                }
            }
        },

        mixins: [
            clickaway
        ],

        created() {
            if (this.inlist)
            {
                if (this.data.pivot) {
                    // init custom title (if present)
                    this.customTitle = this.data.pivot.title;
                }
            }

            this.customTitleDefault = this.data.custom_title;

            // update custom links on change
            this.$events.on('product-edited', (e) => {
                if (this.data.id === e.id) {
                    _.merge(this.data, e.data);
                }
            });
        },

        mounted() {
            if (this.draggable)
            {
                this.$events.on('group-sync', () => {
                    this.$forceUpdate();
                });

                this.setCurrentBackground();

                let setGroupHeight = () => {
                    try {
                        this.groupHeight = `${ parseInt(window.getComputedStyle(this.$refs.thumbContainer).getPropertyValue('width')) + 7 }px`;
                    } catch(e) {}
                };
                setGroupHeight();

                window.onresize = () => {
                    this.$nextTick(() => {
                        setGroupHeight();
                    });
                };
            }

            this.$events.on('link-remove-failed', id => {
                if (this.data.id === id)
                    this.removing = false;
            });

            this.$events.on('removing-link', id => {
                if (this.data.id === id)
                    this.removing = true;
            });

            this.$events.on('editing-title', event => {
                if (event.id !== this.data.id) {
                    switch (event.type) {
                        case 'custom':
                            this.saveTitle();
                            break;
                        case 'default':
                            this.saveDefaultTitle();
                            break;
                    }
                }
            });
        },

        methods: {
            editTitle(type = 'custom') {
                this.$events.emit('editing-title', {
                    id: this.data.id,
                    type: type
                });

                if (!this.featureAllowed('product_title')) {
                    this.$events.emit('feature-not-supported', 'product_title');
                    return;
                }

                if (!this.editingTitle) {
                    if (type === 'custom')
                        this.customTitle = _.clone(this.title);
                    else if (type === 'default')
                        this.customTitleDefault = _.clone(this.title);

                    this.editingTitle = true;
                    this.$store.dispatch('setEditingTitle', true);

                    this.$nextTick(() => {
                        this.$refs.titleText.select();
                    });
                }
            },

            saveTitle() {
                if (this.editingTitle) {
                    this.customTitle = this.truncateOnWord(this.customTitle, 75);

                    this.$http.post(`/api/links/${ this.data.pivot.id }/custom/title`, {
                        title: this.customTitle.length ? this.customTitle : null
                    }).then(response => {
                        // done
                    }).catch(error => {
                        console.log(error);
                    });

                    this.editingTitle = false
                    this.$store.dispatch('setEditingTitle', false);
                }
            },

            saveDefaultTitle() {
                if (this.editingTitle) {
                    this.customTitleDefault = this.truncateOnWord(this.customTitleDefault, 75);

                    this.$http.post(`/api/links/${ this.data.id }/default/title`, {
                        title: this.customTitleDefault.length ? this.customTitleDefault : null
                    }).then(response => {
                        // done
                    }).catch(error => {
                        console.log(error);
                    });

                    this.editingTitle = false;
                    this.$store.dispatch('setEditingTitle', false);

                    this.$nextTick(() => {
                        this.updateBackground();

                        this.$emit('editlink', {
                            id: this.data.id,
                            data: _.merge(_.cloneDeep(this.data), {
                               title: this.title
                            })
                        });
                    });
                }
            },

            setCurrentBackground() {
                this.currentBackground = null;

                this.$nextTick(() => {
                    setTimeout(() => {
                        if (this.$refs.thumbnail)
                            this.currentBackground = getComputedStyle(this.$refs.thumbnail)['background-color'];
                    }, 300);
                });
            },

            updateBackground() {
                if (this.inuserproducts && !this.isUserLink) {
                    let count = 0,
                        loop = setInterval(() => {
                            this.currentBackground = getComputedStyle(this.$refs.thumbnail)['background-color'];

                            if (++count === 15)
                                clearInterval(loop);
                        }, 20);
                }
            },

            saveList() {
                this.$emit('savelist');
            },

            joinWithPrevious() {
                let previousLink = this.$store.getters.getUserGroupList[this.index - 1];

                if (previousLink.group === 0)
                {
                    this.$store.dispatch('nextGroup');
                    let group = this.$store.getters.getLastGroup;

                    this.productGroups[this.data.id] = group;
                    this.productGroups[previousLink.id] = group;
                }
                else {
                    if (previousLink.groupLinks.length < 7)
                        this.productGroups[this.data.id] = previousLink.group;
                    else {
                        this.$events.emit('notify', {
                            title: 'Could not join products',
                            message: 'Max product joins reached. You are not allowed to join more than 8 products together.',
                            type: 'danger',
                            timeout: 5000
                        });

                        return;
                    }
                }

                this.$nextTick(() => {
                    this.$events.emit('group-sync');
                });

                this.saveList();
            },

            breakGroup() {
                let grpVal = _.clone(this.grpVal);
                this.productGroups = _.mapValues(this.productGroups, group => {
                    return group === grpVal ? 0 : group;
                });

                this.$store.dispatch('removeGroup', grpVal);
                this.$nextTick(() => {
                    this.$events.emit('group-sync');
                });

                this.saveList();
            },

            searchSimilar() {
                this.$emit('searchsimilar', {
                    msg: this.data.title
                });
            },

            searchInShops() {
                this.$emit('searchinshops', this.data.id);
            },

            addToCollection(event)
            {
                if (this.inSearch && !this.isAddedToList && !this.saving)
                {
                    if (this.editingTitle)
                        return;

                    if (!this.inuserproducts) {
                        switch(event.target.className) {
                            case 'append':
                                this.saving = true;
                                this.$emit('appendtocollection', _.merge(this.data, {
                                    title: this.title
                                }));
                                break;
                            case 'prepend':
                                this.saving = true;
                                this.$emit('prependtocollection', _.merge(this.data, {
                                    title: this.title
                                }));
                                break;
                        }
                    } else {
                        this.saving = true;
                        this.$emit('addtocollection', _.merge(this.data, {
                            title: this.title
                        }));
                    }
                }
            },

            previewLink() {
                this.$events.emit('preview-product', this.data);
            },

            addTag() {
                this.$events.emit('manage-product-tags', this.data.id);
            },

            removeFromCollection() {
                this.removing = true;
                this.$emit('removefromlist', this.data);
            },

            editLink() {
                this.$events.emit('edit-link', this.data);
            },

            deleteLink() {
                this.$emit('deletelink', this.data.id);
            },

            deselectProduct() {
                this.$store.dispatch('removeProduct', this.data.id);
            },
        },

        beforeDestroy: function () {
//            eventHub.$off('add-todo', this.addTodo)
//            eventHub.$off('delete-todo', this.deleteTodo)
        },

        events: {
            parentSelectedListRefreshed: function () {
            }
        },

        watch: {
            isSelected(val) {
                if (val)
                    this.saving = false;
            },
            grpVal() {
                this.setCurrentBackground();
            }
        }
    }
</script>


<style scoped lang="less">
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    .product {
        padding: 0;
        background: transparent;
        user-select: none;
        transition: 0.25s opacity ease;

        &.draggable {
            cursor: url('/img/cursors/openhand.cur') 8 8, move !important;

            &:active,
            &.sortable-chosen {
                cursor: url('/img/cursors/closedhand.cur') 8 8, move !important;
            }

            .thumb_container {
                pointer-events: none;
            }
        }

        &.removing {
            pointer-events: none;
            opacity: 0.5;
        }

        &:not(.draggable) {

            .top-caption {
                pointer-events: none;
            }
        }

        &.in-products {
            cursor: pointer;
        }

        > .thumbnail {
            margin: 4px;
            border: none;
            padding: 10px;
            transition: 0.3s background ease;
            overflow: visible;

            &.in-search {
                position: relative;

                &:not(.editing-title):not(.selected):hover {
                    background: #DCDCDC !important;

                    > .thumb_container {
                        cursor: pointer;

                        > .click-once {
                            opacity: 1;
                        }
                    }
                }

                &.saving {
                    cursor: progress;
                    background: #DCDCDC !important;
                }

                &.selected {
                    background: lighten(mix(@brand-secondary, #aaa), 35%) !important;
                    cursor: default;

                    > .thumb_container {

                        > .click-once {
                            opacity: 0;
                        }
                    }
                }
            }

            &.in-list {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top: 5px solid;
                border-color: #eee;
                transition: border-color 0.25s ease;

                > .top-caption {
                    top: 5px;
                }
            }

            > .thumb_container {
                .block-round;

                background: #fff;
                position: relative;
                overflow: hidden;
                padding: 20px;
                z-index: 0;
                /*pointer-events: none;*/

                -webkit-transform: translateZ(0);
                -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

                img {
                    pointer-events: none;
                }

                > .click-once {
                    background: fadeout(#fff, 15%);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: opacity 0.3s ease;
                    line-height: 1.2;
                    text-align: center;
                    color: #333;
                    font-weight: lighter;

                    > span {
                        .centered-block;

                        width: 100%;

                        &.click-add {
                            height: 102%;

                            > * {
                                width: 100%;
                                position: absolute;
                                transition: all ease 0.3s;
                                line-height: 1;

                                > span {
                                    .centered-block;

                                    pointer-events: none;

                                    .icon {
                                        font-size: 17px;
                                    }

                                    > div:first-of-type {
                                        margin-bottom: 2px;
                                    }
                                }

                                &:hover {
                                    background: fadeout(@brand-secondary, 20%) !important;
                                    color: #fff;
                                }
                            }

                            > .prepend {
                                top: 0;
                                height: 50%;
                            }

                            > .append {
                                bottom: 0;
                                height: 50%;
                                background: fadeout(#000, 95%);

                                .flipped {
                                    transform: scale(1, -1);
                                    margin-top: 3px;
                                }
                            }
                        }
                    }
                }
            }

            > .top-caption {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 1;
                padding-bottom: 10px;
                max-height: 50px;

                > .wrapper {
                    padding: 15px;

                    > .shop_thumbs {
                        @margin: 7px;

                        &.is-group {
                            background: fadeout(#f5f5f5, 20%);
                            padding-top: @margin;
                            padding-right: @margin;
                            border-radius: 5px;
                            margin-top: -@margin;
                            margin-right: -@margin;

                            .logo {
                                .block-round;
                                background: #fff;
                                margin-bottom: @margin;
                                margin-left: @margin;
                            }
                        }
                    }

                    > .join,
                    > .break {
                        cursor: pointer;
                        font-size: 20px;
                    }

                    > .top-button {
                        .block-round;
                        .trans-default;

                        position: relative;
                        color: #fff;
                        background: fadeout(#000, 50%);
                        width: 35px;
                        height: 35px;
                        display: block;
                        float: left;
                        line-height: 1;

                        &:hover {
                            background: fadeout(#333, 50%);
                        }

                        &:active {
                            background: fadeout(#555, 50%);
                        }

                        > * {
                            .centered-block;
                        }

                        &.tick {
                            pointer-events: all;
                            font-size: 13px;
                            background: fadeout(@brand-secondary, 15%) !important;
                        }

                        &.user {
                            pointer-events: none;
                            background: #fff;
                            color: #888;
                        }
                    }
                }
            }

            > .product-title {
                color: fadeout(#000, 25%);
            }

            > .footer {
                width: 100%;
                height: 30px;

                .btn {
                    .block-round;

                    width: 30px;
                    height: 30px;
                    position: relative;
                    font-size: 11px;
                    margin-bottom: 5px;

                    > * {
                        .centered-block;
                    }
                }

                > span {

                    > .btn {
                        padding: 0;
                    }
                }
            }
        }

        > .img-small {
            position: relative;

            > .image-wrapper {
                .block-round;

                overflow: hidden;
                padding: 8px;
                background: #fff;
                margin-right: 10px;
                width: 75px;
                height: 75px;

                -webkit-transform: translateZ(0);
                -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
            }

            > .joined {
                .block-round;

                overflow: hidden;
                background: #676767;
                line-height: 1;
                font-size: 12px;
                /*font-weight: bold;*/
                display: inline-block;
                color: #fff;
                position: absolute;
                right: 2px;
                bottom: 2px;
                width: 21px;
                height: 21px;
                /*border: 2px solid #fff;*/

                > span {
                    .centered-block;
                }
            }
        }
    }

    .product-title-text {
        font-size: 12px;
        line-height: 1em;
        height: 2em;
        overflow: hidden;
        margin-bottom: 0;
        text-align: center;
        border: none;
        padding: 0;
        width: 100%;
        resize: none;
    }

    .editable-text {
        display: block;
        position: relative;
        cursor: text;

        > .edit {
            display: none;
            position: absolute;
            top: 3px;
            right: -10px;
            font-size: 20px;
            color: fadeout(#000, 60%);
            line-height: 1;

            > .fa-pencil {
                position: relative;
                z-index: 1;
            }

            > .gradient {
                .block-round;

                position: absolute;
                top: -50%;
                height: 200%;
                width: 200%;
                right: -50%;
                z-index: 0;
                opacity: 1;
            }
        }

        &:hover {

            > .edit {
                display: inline-block;
            }
        }

        &.editing {

            > .product-title-text:not(textarea),
            > .edit {
                opacity: 0;
            }
        }

        > textarea {
            position: absolute;
            top: 0;
            left: 0;
            height: auto;
            z-index: 5;
            border-radius: 3px;
            background: fadeout(#fff, 30%);

            outline: 0 !important;
            -webkit-appearance: none;
        }
    }

    .minitag {
        font-size: 10px;
    }
</style>
