// Template

<template>
    <div class="container"
         :style="{ 'pointer-events': waiting ? 'none' : 'auto', 'margin-top': '35px' }"
    >
        <div class="title">
            <span class="overview">Overview
                <select class="form-control select select-dark select-block"
                        ref="selectOptions"
                        data-toggle="select"
                        style="margin-left: 10px;"
                >
                    <option value="leads">Product leads</option>
                    <option value="efficiency">Peak hours</option>
                    <option v-if="performances"
                            value="performance"
                    >
                        Lists performance
                    </option>
                </select>
            </span>
            <div class="time">
                <span class="range-text">{{ customRangeText }}</span>
                <span :class="[ 'calendar', { 'active': range === 'custom' } ]"
                      aria-label="custom range"
                      class="hint--top hint--rounded hint--small"
                      :style="{ 'pointer-events': dateSelectOpened ? 'none' : 'all' }"
                >
                    <span class="fa fa-calendar"></span>
                    <input id="calendar-range">
                </span>

                <ul class="nav nav-pills static">
                    <li :class="{ 'active': range === 'day' }"
                        style="cursor: pointer;"
                        @click="fetchStats('day')"
                    >
                        <a>day</a>
                    </li>
                    <li :class="{ 'active': range === 'week' }"
                        style="cursor: pointer;"
                        @click="fetchStats('week')"
                    >
                        <a>week</a>
                    </li>
                    <li :class="{ 'active': range === 'month' }"
                        style="cursor: pointer;"
                        @click="fetchStats('month')"
                    >
                        <a>month</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="chart chart-overview noselect">
            <template v-if="overviewSelectedOption === 'leads'">
                <div class="title">Product leads</div>
                <line-chart v-if="!updating && !lineChartUpdating"
                            :data="overviewData"
                            :options="lineChartOptions"
                ></line-chart>
            </template>

            <template v-if="overviewSelectedOption === 'efficiency'">
                <div class="title">Peak hours</div>
                <bar-chart v-if="!updating"
                           :data="metricsData"
                           :options="barChartOptions"
                ></bar-chart>
            </template>

            <template v-if="overviewSelectedOption === 'performance'">
                <div class="title">Lists performance</div>
                <line-chart v-if="!updating && !lineChartUpdating"
                            :data="performanceData"
                            :options="lineChartOptions"
                ></line-chart>
            </template>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="chart">
                    <div class="title">Most popular</div>

                    <template v-if="overview.length">
                        <div v-for="product in productClicks"
                             class="product"
                             :key="product.id"
                             @click="previewLink(product.id)"
                        >
                            <div class="thumb-wrapper">
                                <div class="thumb">
                                    <image-square :src="product.thumb_path"
                                                  :alt="product.title"
                                                  :opacity="0.7"
                                                  spinner-color="#16a085"
                                                  spinner-side="20px"
                                    ></image-square>
                                </div>

                                <logo-image :src="product.shop_icon"
                                            side="22px"
                                            extra-classes="shop"
                                ></logo-image>
                            </div>
                            <div class="title">{{ product.title }}</div>
                            <div class="clicks">
                                <div class="text">leads</div>
                                <div class="number">{{ product.clicks }}</div>
                            </div>
                        </div>
                    </template>

                    <div v-else
                         class="no-clicks"
                    >
                        No leads during this period
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="chart noselect">
                    <div class="title">Leads origin</div>

                    <template v-if="overview.length">
                        <doughnut-chart v-if="!updating"
                                        :data="originData"
                                        :options="doughnutChartOptions"
                        ></doughnut-chart>

                        <br>
                        <br>

                        <horizontal-bar-chart v-if="!updating"
                                              :data="shopData"
                                              :options="horizontalBarChartOptions"
                        ></horizontal-bar-chart>
                    </template>

                    <div v-else
                         class="no-clicks"
                    >
                        No leads during this period
                    </div>
                </div>

                <div v-if="isTeam"
                     class="chart noselect"
                >
                    <div class="title">Member leads</div>

                    <template v-if="overview.length">
                        <horizontal-bar-chart v-if="!updating"
                                   :data="efficiencyData"
                                   :options="horizontalBarChartOptions"
                        ></horizontal-bar-chart>
                    </template>

                    <div v-else
                         class="no-clicks"
                    >
                        No leads during this period
                    </div>
                </div>
            </div>
        </div>

        <br>
        <br>

        <modal title="Preview link"
               cancel-text="Close"
               :show="previewModalShow"
               ok-class="hidden"
               @cancel="previewModalShow = false"
        >
            <div class="affiliate-title">
                <b>Affiliate URL</b> <span>(click to visit)</span>
            </div>

            <a :href="'/previewlink/' + productId"
               target="_blank"
               class="affiliate-link"
            >
                <pre>{{ affiliateLink }}</pre>
            </a>

            <div style="color: #c0392b; font-size: 12px; text-align: center; margin-top: 10px; line-height: 1.3;">
                <div>The link above is for preview purposes only.</div>
                <div>Please do not copy/paste it in your blog.</div>
            </div>
        </modal>
    </div>
</template>

// Style

<style lang="less" scoped>
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    .title {
        font-size: 14px;
        padding: 14px;
        margin-bottom: 5px;

        > span {
            font-size: 22px;
            color: #555;
            font-weight: bolder;
        }

        > .overview {
            position: relative;

            > span {
                @buttonSide: 30px;

                .block-round;

                font-size: 16px;
                transition: 0.3s all ease;
                cursor: pointer;
                border: 2px solid #bbb;
                color: #bbb;
                width: @buttonSide;
                height: @buttonSide;
                position: absolute;
                right: -40px;
                top: -2px;

                > span {
                    .centered-block;
                }

                &:after {
                    font-weight: normal;
                    bottom: 125%;
                }

                &:before {
                    bottom: 125%;
                }

                &:hover,
                &.active {
                    border-color: @brand-secondary;
                    color: @brand-secondary;
                }

                &.active {
                    pointer-events: none;
                }
            }
        }

        > .time {
            float: right;
            position: relative;
            /*top: 10px;*/
            font-weight: bolder;
            color: @brand-secondary;

            > .range-text {
                position: relative;
                top: -2px;
                right: -3px;
                font-size: 12px;
                color: #777;
            }

            > .calendar {
                position: relative;
                display: inline-block;
                color: #bbb;
                cursor: pointer;

                &:after {
                    font-weight: normal;
                }

                &:hover,
                &.active {
                    color: @brand-secondary;
                }

                > span {
                    font-size: 23px;
                    padding: 7px 17px;
                    transition: 0.3s all ease;
                    z-index: 1;
                }

                > input {
                    position: absolute;
                    width: 100%;
                    height: ~'calc(100% + 5px)';
                    top: 0;
                    left: 0;
                    z-index: 2;
                    opacity: 0;
                }
            }

            > ul {
                float: right;

                > li {

                    > a {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }
        }

        @media (max-width: @screen-xs-max) {
            display: inline-block;
            width: 100%;

            > .overview {
                display: block;
            }

            > .time {
                display: block;
                width: 100%;
                padding-top: 15px;

                > ul {
                    float: left;
                }

                > .calendar {
                    float: left;
                }
            }
        }
    }

    .chart {
        background: #f3f3f3;
        border-radius: 5px;
        padding: 15px;
        margin-bottom: 30px;

        &.noselect {
            user-select: none;
        }

        &.chart-overview {
            height: 493px;
        }

        > .title {
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: bolder;
            color: #555;
        }

        > .product {
            display: flex;
            flex: 1;
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }

            > .thumb-wrapper {
                position: relative;
                margin-right: 10px;
                height: 75px;

                > .thumb {
                    .block-round;

                    overflow: hidden;
                    padding: 8px;
                    background: #fff;
                    width: 75px;

                    -webkit-transform: translateZ(0);
                    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
                }

                > .shop {
                    .block-round;

                    position: absolute;
                    bottom: 0;
                    right: 0;
                    padding: 2px;
                    z-index: 2;
                }
            }

            > .title {
                flex: 1;
                font-size: 13px;
                color: #555;
                transition: color ease 0.2s;
            }

            > .clicks {
                flex: 0 0 75px;

                > .text {
                    background: #555;
                    border-radius: 4px;
                    padding: 3px;
                    color: #eee;
                    text-align: center;
                    font-size: 12px;
                }

                > .number {
                    color: #555;
                    padding: 7px;
                    text-align: center;
                    font-weight: bolder;
                }
            }

            &:hover {
                cursor: pointer;

                > .title {
                    color: @brand-secondary;
                }
            }
        }

        > .no-clicks {
            text-align: center;
            padding-bottom: 10px;
            font-size: 14px;
            color: #aaa;
        }
    }
</style>

// Javascript

<script>
    import LineChart from './statistics/lineChart'
    import BarChart from './statistics/barChart'
    import HorizontalBarChart from './statistics/horizontalBarChart'
    import DoughnutChart from './statistics/doughnutChart'
    import Modal from '../lib/components/Modal';
    import ImageSquare from '../lib/components/ImageSquare.vue'
    import LogoImage from '../lib/components/LogoImage.vue'

    import flatpickr from 'flatpickr'
    import distinctColors from 'distinct-colors'

    export default
    {
        components: {
            'line-chart': LineChart,
            'bar-chart': BarChart,
            'horizontal-bar-chart': HorizontalBarChart,
            'doughnut-chart': DoughnutChart,
            'modal': Modal,
            'image-square': ImageSquare,
            'logo-image': LogoImage,
        },

        data()
        {
            return {
                overview: null,
                peakHours: null,
                overviewSelectedOption: 'leads',
                productClicks: null,
                range: null,
                daysNum: null,
                teamMemberEfficiencies: null,
                shops: null,
                performances: null,

                updating: false,
                lineChartUpdating: false,
                flatpickr: null,
                dateRange: [],
                dateSelectOpened: false,
                currentOption: null,
                waiting: false,

                // link preview
                productId: null,
                affiliateLink: null,
                previewModalShow: false,

                overviewData: {
                    datasets: [
                        {
                            label: 'Blog',
                            backgroundColor: '#ef8b00',
                            borderColor: '#ef8b00',
                            hidden: true
                        },
                        {
                            label: 'Youtube',
                            backgroundColor: '#cd201f',
                            borderColor: '#cd201f',
                            hidden: true
                        },
                        {
                            label: 'Email',
                            backgroundColor: '#777',
                            borderColor: '#777',
                            hidden: true
                        },
                        {
                            label: 'Facebook',
                            backgroundColor: '#3b5998',
                            borderColor: '#3b5998',
                            hidden: true
                        },
                        {
                            label: 'Twitter',
                            backgroundColor: '#00aced',
                            borderColor: '#00aced',
                            hidden: true
                        },
                        {
                            label: 'Instagram',
                            backgroundColor: '#8a3ab9',
                            borderColor: '#8a3ab9',
                            hidden: true
                        },
                        {
                            label: 'Total',
                            backgroundColor: '#16a085',
                            borderColor: '#16a085'
                        }
                    ]
                },
                metricsData: {
                    datasets: [
                        {
                            label: 'Blog',
                            backgroundColor: '#ef8b00',
                            borderColor: '#ef8b00'
                        },
                        {
                            label: 'Youtube',
                            backgroundColor: '#cd201f',
                            borderColor: '#cd201f'
                        },
                        {
                            label: 'Email',
                            backgroundColor: '#777',
                            borderColor: '#777'
                        },
                        {
                            label: 'Facebook',
                            backgroundColor: '#3b5998',
                            borderColor: '#3b5998'
                        },
                        {
                            label: 'Twitter',
                            backgroundColor: '#00aced',
                            borderColor: '#00aced'
                        },
                        {
                            label: 'Instagram',
                            backgroundColor: '#8a3ab9',
                            borderColor: '#8a3ab9'
                        }
                    ]
                },
                performanceData: {
                    datasets: []
                },
                efficiencyData: {
                    datasets: [
                        {
                            label: 'Blog',
                            backgroundColor: '#ef8b00',
                            borderColor: '#ef8b00'
                        },
                        {
                            label: 'Youtube',
                            backgroundColor: '#cd201f',
                            borderColor: '#cd201f'
                        },
                        {
                            label: 'Email',
                            backgroundColor: '#777',
                            borderColor: '#777'
                        },
                        {
                            label: 'Facebook',
                            backgroundColor: '#3b5998',
                            borderColor: '#3b5998'
                        },
                        {
                            label: 'Twitter',
                            backgroundColor: '#00aced',
                            borderColor: '#00aced'
                        },
                        {
                            label: 'Instagram',
                            backgroundColor: '#8a3ab9',
                            borderColor: '#8a3ab9'
                        }
                    ]
                },
                shopData: {
                    datasets: [
                        {
                            label: 'Blog',
                            backgroundColor: '#ef8b00',
                            borderColor: '#ef8b00'
                        },
                        {
                            label: 'Youtube',
                            backgroundColor: '#cd201f',
                            borderColor: '#cd201f'
                        },
                        {
                            label: 'Email',
                            backgroundColor: '#777',
                            borderColor: '#777'
                        },
                        {
                            label: 'Facebook',
                            backgroundColor: '#3b5998',
                            borderColor: '#3b5998'
                        },
                        {
                            label: 'Twitter',
                            backgroundColor: '#00aced',
                            borderColor: '#00aced'
                        },
                        {
                            label: 'Instagram',
                            backgroundColor: '#8a3ab9',
                            borderColor: '#8a3ab9'
                        }
                    ]
                },

                chartOptions: {
                    animation: {
                        duration: 0
                    },
                    elements: {
                        line: {
//                            tension: 0.05,
                            fill: false
                        },
                        point: {
                            radius: 4,
                            hoverRadius: 6
                        }
                    },
                    maintainAspectRatio: false
                },

                lineChartOptions: null,
                doughnutChartOptions: null,
                barChartOptions: null,
                horizontalBarChartOptions: null,

                originData: {
                    labels: [ 'Blog', 'Youtube', 'Email', 'Facebook', 'Twitter', 'Instagram' ],
                    datasets: [
                        {
                            backgroundColor: [
                                '#ef8b00',
                                '#cd201f',
                                '#777',
                                '#3b5998',
                                '#00aced',
                                '#8a3ab9'
                            ]
                        }
                    ]
                }
            }
        },

        props: {
            data: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            apiPath: {
                type: String,
                default: '/stats'
            },
            isTeam: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            customRangeText() {
                switch(this.dateRange.length) {
                    case 0:
                        return '';
                    case 1:
                        return moment(this.dateRange[0]).format('D MMM');
                    case 2:
                        return `${ moment(this.dateRange[0]).format('D MMM') } - ${ moment(this.dateRange[1]).format('D MMM') }`;
                }
            }
        },

        created() {
            _.merge(this, this.data);

            this.lineChartOptions = _.cloneDeep(this.chartOptions);
            this.doughnutChartOptions = _.cloneDeep(this.chartOptions);
            this.barChartOptions = _.merge(_.cloneDeep(this.chartOptions));
            this.horizontalBarChartOptions = _.merge(_.cloneDeep(this.chartOptions));
            this.chart = null;

            _.merge(this.lineChartOptions, {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            userCallback: label => {
                                // when the floored value is the same as the value we have a whole number
                                if (Math.floor(label) === label) {
                                    return label;
                                }
                            }
                        }
                    }]
                },
                legend: {
                    labels: {
                        generateLabels: chart => {
                            this.chart = chart;

                            let data = chart.data;
                            if (data.datasets.length)
                            {
                                let datasets = _.cloneDeep(data.datasets);

                                return _.map(data.datasets, (dataset, i) => {
                                    return {
                                        // Instead of `text: dataset,`
                                        // We add the value to the string
                                        text: dataset.label + " : " + _.sum(dataset.data),
                                        fillStyle: dataset.backgroundColor,
                                        strokeStyle: dataset.backgroundColor,
                                        lineWidth: 0,
                                        hidden: dataset.hidden,
                                        index: i
                                    }
                                });
                            } else {
                                return [];
                            }
                        }
                    },
                    onClick(e, label) {
                        this.chart.config.data.datasets[label.index].hidden = !this.chart.config.data.datasets[label.index].hidden;
                        this.chart.update();
                    }
                }
            });

            _.merge(this.doughnutChartOptions, {
                legend: {
                    labels: {
                        generateLabels: chart => {
                            let data = chart.data;
                            if (data.labels.length && data.datasets.length) {
                                return _.map(data.labels, (label, i) => {
                                    let meta = chart.getDatasetMeta(0);
                                    let ds = data.datasets[0];
                                    let arc = meta.data[i];
                                    let custom = arc && arc.custom || {};
                                    let getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                                    let arcOpts = chart.options.elements.arc;
                                    let fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                                    let stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                                    let bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                                    // We get the value of the current label
                                    let value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                                    return {
                                        // Instead of `text: label,`
                                        // We add the value to the string
                                        text: label + " : " + value,
                                        fillStyle: fill,
                                        strokeStyle: stroke,
                                        lineWidth: bw,
                                        hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                                        index: i
                                    };
                                });
                            } else {
                                return [];
                            }
                        }
                    }
                }
            });

            _.merge(this.barChartOptions, {
                scales: {
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            userCallback: label => {
                                // when the floored value is the same as the value we have a whole number
                                if (Math.floor(label) === label) {
                                    return label;
                                }
                            }
                        }
                    }],
                    xAxes: [{
                        stacked: true,
                        barPercentage: 1
                    }]
                },
                legend: {
                    labels: {
                        generateLabels: chart => {
                            this.chart = chart;

                            let data = chart.data;
                            if (data.datasets.length)
                            {
                                let datasets = _.cloneDeep(data.datasets);

                                return _.map(data.datasets, (dataset, i) => {
                                    return {
                                        // Instead of `text: dataset,`
                                        // We add the value to the string
                                        text: dataset.label + " : " + _.sum(dataset.data),
                                        fillStyle: dataset.backgroundColor,
                                        strokeStyle: dataset.backgroundColor,
                                        lineWidth: 0,
                                        hidden: dataset.hidden,
                                        index: i
                                    }
                                });
                            } else {
                                return [];
                            }
                        }
                    },
                    onClick(e, label) {
                        this.chart.config.data.datasets[label.index].hidden = !this.chart.config.data.datasets[label.index].hidden;
                        this.chart.update();
                    }
                }
            });

            _.merge(this.horizontalBarChartOptions, {
                scales: {
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            userCallback: label => {
                                // when the floored value is the same as the value we have a whole number
                                if (Math.floor(label) === label) {
                                    return label;
                                }
                            }
                        }
                    }],
                    yAxes: [{
                        maxBarThickness: 25,
                        stacked: true
                    }]
                },
                legend: {
                    labels: {
                        generateLabels: chart => {
                            this.chart = chart;
//                            console.log(chart)

                            let data = chart.data;
                            if (data.datasets.length)
                            {
//                                console.log(data.datasets);
                                let datasets = _.cloneDeep(data.datasets);

                                return _.map(data.datasets, (dataset, i) => {
                                    return {
                                        // Instead of `text: dataset,`
                                        // We add the value to the string
                                        text: dataset.label + " : " + _.sum(dataset.data),
                                        fillStyle: dataset.backgroundColor,
                                        strokeStyle: dataset.backgroundColor,
                                        lineWidth: 0,
                                        hidden: dataset.hidden,
                                        index: i
                                    }
                                });
                            } else {
                                return [];
                            }
                        }
                    },
                    onClick(e, label) {
                        this.chart.config.data.datasets[label.index].hidden = !this.chart.config.data.datasets[label.index].hidden;
                        this.chart.update();
                    }
                }
            });

            this.prepareData();
        },

        mounted() {
            let self = this,
                lastRange = null;

            if ($('[data-toggle="select"]').length) {
                $('[data-toggle="select"]').select2();
            }

            this.flatpickr = flatpickr('#calendar-range', {
                mode: 'range',
                onOpen() {
                    lastRange = self.range;

                    self.range = 'custom';
                    self.dateSelectOpened = true;
                },
                onChange(selectedDates) {
                    self.dateRange = selectedDates;
                },
                onClose() {
                    self.dateSelectOpened = false;

                    if (self.dateRange.length === 2) {

                        self.fetchStats(self.range, {
                            begin: self.dateRange[0],
                            end: self.dateRange[1]
                        })

                        this.currentOption = 'custom';
                    } else
                        self.range = lastRange;
                }
            });

            $(this.$refs.selectOptions).on('select2-selecting', (e) => {
                this.overviewSelectedOption = e.val;
            });
        },

        methods: {
            enumerateTimesBetweenDates(startDate, endDate, times, format = null) {
                let now = startDate.clone(), dates = [];

                while (now.isBefore(endDate) || now.isSame(endDate)) {
                    dates.push(format ? now.format(format) : new Date(now.format()));
                    now.add(1, times);
                }

                return dates;
            },

            prepareData() {
                let startDate = null,
                    endDate = null;

                if (this.dateRange.length) {
                    startDate = moment(this.dateRange[0]);
                    endDate = moment(this.dateRange[1]);
                } else {
                    startDate = moment().subtract(this.daysNum, 'days');
                    endDate = moment();
                }

                let times = this.daysNum === 1 ? 'hours' : 'days',
                    format = times === 'hours' ? 'HH:00' : 'D/M',
                    overviewTimes = this.enumerateTimesBetweenDates(startDate, endDate, times),
                    overviewLabels = this.enumerateTimesBetweenDates(startDate, endDate, times, format),
                    overviewClicks = {
                        total: [],
                        blog: [],
                        youtube: [],
                        email: [],
                        facebook: [],
                        twitter: [],
                        instagram: []
                    },

                    metricsArray = _.range(0, 24, 0),
                    metricsLabels = [],
                    metricsClicks = {
                        total: [],
                        blog: [],
                        youtube: [],
                        email: [],
                        facebook: [],
                        twitter: [],
                        instagram: []
                    },

                    performancesTimes = null,
                    performancesLabels = null,
                    performancesClicks = [],

                    shopClicks = {
                        total: [],
                        blog: [],
                        youtube: [],
                        email: [],
                        facebook: [],
                        twitter: [],
                        instagram: []
                    },

                    teamMemberClicks = {
                        total: [],
                        blog: [],
                        youtube: [],
                        email: [],
                        facebook: [],
                        twitter: [],
                        instagram: []
                    };

                _.each(metricsArray, (data, index) => {
                    metricsLabels[index] = `${ _.padStart(index, 2, '0') }:00`;
                });


                let total, blog, youtube, email, facebook, twitter, instagram;

                //
                // product leads
                //
                _.each(overviewTimes, (date, index) => {
                    total = 0;
                    blog = 0;
                    youtube = 0;
                    email = 0;
                    facebook = 0;
                    twitter = 0;
                    instagram = 0;

                    switch (times) {
                        case 'days':
                            _.each(this.overview, clickStat => {
                                if (new moment(clickStat.created_at).toDate().toDateString() === date.toDateString()) {
                                    total = clickStat.total;
                                    blog = clickStat.html + clickStat.widget;
                                    youtube = clickStat.youtube;
                                    email = clickStat.email;
                                    facebook = clickStat.facebook;
                                    twitter = clickStat.twitter;
                                    instagram = clickStat.instagram;
                                }
                            });

                            if (!this.dateRange.length)
                                overviewLabels[overviewLabels.length - 1] = 'today';
                            break;
                        case 'hours':
                            date = moment.utc(date).local();

                            _.each(this.peakHours, clickStat => {
                                let hourCheck = () => {
                                    let clickDate = moment.utc(clickStat.created_at).local(),
                                        dateOne = `${ clickDate.hours() }-${ clickDate.date() }`,
                                        dateTwo = `${ date.hours() }-${ date.date() }`;

                                    return dateOne === dateTwo;
                                };

                                if (hourCheck()) {
                                    total = clickStat.total;
                                    blog = clickStat.html + clickStat.widget;
                                    youtube = clickStat.youtube;
                                    email = clickStat.email;
                                    facebook = clickStat.facebook;
                                    twitter = clickStat.twitter;
                                    instagram = clickStat.instagram;
                                }
                            });

                            if (!this.dateRange.length)
                                overviewLabels[overviewLabels.length - 1] = 'now';
                            break;
                    }

                    this.metricsData.labels = metricsLabels;
                    this.overviewData.labels = overviewLabels;

                    overviewClicks.total[index] = total;
                    overviewClicks.blog[index] = blog;
                    overviewClicks.youtube[index] = youtube;
                    overviewClicks.email[index] = email;
                    overviewClicks.facebook[index] = facebook;
                    overviewClicks.twitter[index] = twitter;
                    overviewClicks.instagram[index] = instagram;
                });

                //
                // peak hours
                //
                _.each(metricsArray, (elem, index) => {
                    total = 0;
                    blog = 0;
                    youtube = 0;
                    email = 0;
                    facebook = 0;
                    twitter = 0;
                    instagram = 0;

                    _.each(this.peakHours, clickStat => {
                        if (moment.utc(clickStat.created_at).local().hours() === parseInt(index)) {
                            blog += clickStat.html + clickStat.widget;
                            youtube += clickStat.youtube;
                            email += clickStat.email;
                            facebook += clickStat.facebook;
                            twitter += clickStat.twitter;
                            instagram += clickStat.instagram;
                        }
                    });

                    metricsClicks.blog[index] = blog;
                    metricsClicks.youtube[index] = youtube;
                    metricsClicks.email[index] = email;
                    metricsClicks.facebook[index] = facebook;
                    metricsClicks.twitter[index] = twitter;
                    metricsClicks.instagram[index] = instagram;
                });

                //
                // list performances
                //
                if (this.performances) {
                    performancesTimes = overviewTimes;
                    performancesLabels = overviewLabels;

                    let lists = {},
                        prepareList = (date, index) => {
                            _.each(this.performances, (list, listIndex) => {
                                lists[listIndex] = lists[listIndex] || [];
                                lists[listIndex][index] = 0;

                                _.each(list, clickStat =>{
                                    if (new moment(clickStat.created_at).toDate().toDateString() === date.toDateString()) {
                                        lists[listIndex][index] = clickStat.total;
                                    }
                                });
                            });
                        };

                    _.each(performancesTimes, (date, index) => {
                        switch (times) {
                            case 'days':
                                prepareList(date, index);
                                break;
                            case 'hours':
                                date = moment.utc(date).local();

                                _.each(this.peakHours, clickStat => {
                                    let hourCheck = () => {
                                        let clickDate = moment.utc(clickStat.created_at).local(),
                                            dateOne = `${ clickDate.hours() }-${ clickDate.date() }`,
                                            dateTwo = `${ date.hours() }-${ date.date() }`;

                                        return dateOne === dateTwo;
                                    };

                                    if (hourCheck())
                                        prepareList(date, index);
                                });
                                break;
                        }

                        this.performanceData.labels = performancesLabels;

                        performancesClicks[index] = total;
                    });

                    let colors = distinctColors({
                            count: _.size(lists),
                            lightMin: 20
                        }),
                        colorIndex = 0;

                    this.performanceData.datasets = [];

                    _.each(lists, (listData, index) => {
                        let currentColor = colors[colorIndex].saturate(2).hex();

                        this.performanceData.datasets.push({
                            label: index,
                            backgroundColor: currentColor,
                            borderColor: currentColor,
                            data: listData
                        });

                        colorIndex++;
                    });
                }

                //
                // shops
                //
                this.shopData.labels = _.keys(this.shops);

                let shopIndex = 0;
                _.each(this.shops, elem => {
                    shopClicks.blog[shopIndex] = elem.widget + elem.html;
                    shopClicks.youtube[shopIndex] = elem.youtube;
                    shopClicks.email[shopIndex] = elem.email;
                    shopClicks.facebook[shopIndex] = elem.facebook;
                    shopClicks.twitter[shopIndex] = elem.twitter;
                    shopClicks.instagram[shopIndex] = elem.instagram;

                    shopIndex++;
                });

                this.shopData.datasets[0].data = shopClicks.blog;
                this.shopData.datasets[1].data = shopClicks.youtube;
                this.shopData.datasets[2].data = shopClicks.email;
                this.shopData.datasets[3].data = shopClicks.facebook;
                this.shopData.datasets[4].data = shopClicks.twitter;
                this.shopData.datasets[5].data = shopClicks.instagram;

                //
                // team
                //
                if (this.isTeam) {
                    this.efficiencyData.labels = _.keys(this.teamMemberEfficiencies);

                    let efficiencyIndex = 0;
                    _.each(this.teamMemberEfficiencies, elem => {
                        teamMemberClicks.blog[efficiencyIndex] = elem.widget + elem.html;
                        teamMemberClicks.youtube[efficiencyIndex] = elem.youtube;
                        teamMemberClicks.email[efficiencyIndex] = elem.email;
                        teamMemberClicks.facebook[efficiencyIndex] = elem.facebook;
                        teamMemberClicks.twitter[efficiencyIndex] = elem.twitter;
                        teamMemberClicks.instagram[efficiencyIndex] = elem.instagram;

                        efficiencyIndex++;
                    });

                    this.efficiencyData.datasets[0].data = teamMemberClicks.blog;
                    this.efficiencyData.datasets[1].data = teamMemberClicks.youtube;
                    this.efficiencyData.datasets[2].data = teamMemberClicks.email;
                    this.efficiencyData.datasets[3].data = teamMemberClicks.facebook;
                    this.efficiencyData.datasets[4].data = teamMemberClicks.twitter;
                    this.efficiencyData.datasets[5].data = teamMemberClicks.instagram;
                }

                // needs fixing here
                // use $set
                this.metricsData.datasets[0].data = metricsClicks.blog;
                this.metricsData.datasets[1].data = metricsClicks.youtube;
                this.metricsData.datasets[2].data = metricsClicks.email;
                this.metricsData.datasets[3].data = metricsClicks.facebook;
                this.metricsData.datasets[4].data = metricsClicks.twitter;
                this.metricsData.datasets[5].data = metricsClicks.instagram;

                this.overviewData.datasets[0].data = overviewClicks.blog;
                this.overviewData.datasets[1].data = overviewClicks.youtube;
                this.overviewData.datasets[2].data = overviewClicks.email;
                this.overviewData.datasets[3].data = overviewClicks.facebook;
                this.overviewData.datasets[4].data = overviewClicks.twitter;
                this.overviewData.datasets[5].data = overviewClicks.instagram;
                this.overviewData.datasets[6].data = overviewClicks.total;

                this.originData.datasets[0].data = [ _.sum(overviewClicks.blog), _.sum(overviewClicks.youtube), _.sum(overviewClicks.email), _.sum(overviewClicks.facebook), _.sum(overviewClicks.twitter), _.sum(overviewClicks.instagram) ];
            },

            fetchStats(range, dates = {}) {
                this.waiting = true;
                document.body.style.cursor = 'progress';

                this.$http.post(`${ this.apiPath }/${ this.isTeam ? 'team' : 'user' }/${ range }`, dates).then(response => {
                    _.assign(this, response.body);

                    if (range !== 'custom')
                        this.dateRange = [];

                    this.prepareData();
                    this.updating = true;

                    this.$nextTick(() => {
                        this.updating = false;
                        this.currentOption = range;

                        this.waiting = false;

                        setTimeout(() => {
                            document.body.style.cursor = 'default';
                        }, 250);
                    })
                });
            },

            previewLink(id) {
                this.productId = id;

                this.$http.get(`/affiliatelink/${ this.productId }`).then(response => {
                    this.affiliateLink = response.data;
                    this.previewModalShow = true;
                }).catch(error => {
                    console.log(error)
                })
            }
        },

        watch: {
            range(val) {
                if (val !== 'custom')
                    this.flatpickr.clear();
            },
            overviewSelectedOption(val) {
                if (val === 'leads' || val === 'performance') {
                    this.lineChartUpdating = true;

                    this.$nextTick(() => {
                        this.lineChartUpdating = false;
                    });
                }
            }
        }
    }
</script>