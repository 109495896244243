// Template

<template>
    <div id="team-stats"
         :style="{ 'pointer-events': waiting ? 'none' : 'auto' }"
    >
        <div class="container">
            <div class="choose-mode">
                <div class="modes">
                    <div :class="[ 'mode', { 'active': showTeam } ]"
                         @click="setMode('team')"
                    >
                        <span>Team</span>
                    </div>
                    <div :class="[ 'mode', { 'active': !showTeam } ]"
                         @click="setMode('user')"
                    >
                        <span>Members</span>
                    </div>
                </div>

                <div :class="[ 'choose-user', { 'show': !showTeam } ]">
                    <select class="form-control select select-dark select-block"
                            ref="selectOptions"
                            data-toggle="select"
                    >
                        <option v-for="(user, index) in users"
                                :value="user.id"
                        >
                            <template v-if="index === 0">
                                {{ user.name }}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;owner
                            </template>

                            <template v-else>
                                {{ user.name }}
                            </template>
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div v-if="showTeam">
            <statistics :data="teamData"
                        :is-team="true"
            ></statistics>
        </div>

        <template v-else>
            <statistics v-if="!refreshingUser"
                        :data="userData"
            ></statistics>
        </template>
    </div>
</template>

// Style

<style lang="less">
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    #team-stats {

        .choose-mode {
            width: 100%;
            padding: 15px;
            background: #f5f5f5;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            user-select: none;

            > .modes {
                flex: 0 0 300px;
                display: flex;
                overflow: hidden;
                border-radius: 50px;

                > .mode {
                    flex: 1;
                    width: 90px;
                    height: 50px;
                    position: relative;
                    font-weight: bolder;
                    font-size: 18px;
                    cursor: pointer;
                    transition: all ease 0.25s;
                    background: fadeout(#000, 92%);

                    &:hover {
                        color: @brand-secondary;
                    }

                    &.active {
                        background: @brand-secondary;
                        color: #fff;
                        pointer-events: none;
                    }

                    > span {
                        .centered-block;
                    }
                }
            }

            .choose-user {
                flex: 0 0 200px;
                padding-top: 7px;
                opacity: 0;
                pointer-events: none;

                &.show {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }
</style>

// Javascript

<script  >
    import Statistics from './Statistics.vue';

    export default
    {
        data()
        {
            return {
                teamData: null,
                userData: null,
                mode: 'team',
                users: [],
                refreshingUser: false,
                waiting: false
            }
        },

        props: {
            data: Object,
            default() {
                return {};
            }
        },

        computed: {
          showTeam() {
              return this.mode === 'team';
          }
        },

        created() {
            _.merge(this, this.data);
            this.users = [ this.user ].concat(this.data.members);
        },

        mounted() {
            $(this.$refs.selectOptions).on('select2-selecting', e => {
//                console.log(e.val)
                Cookies.set('stat-user', e.val);
                this.loadUser();
            });
        },

        methods: {
            loadUser() {
                this.waiting = true;
                document.body.style.cursor = 'progress';

                this.$http.post('/stats/user/month').then(response => {
                    this.refreshingUser = true;
                    this.userData = response.body;
                    this.mode = 'user';

                    this.$nextTick(() => {
                        this.refreshingUser = false;
                        this.waiting = false;

                        setTimeout(() => {
                            document.body.style.cursor = 'default';
                        }, 250);
                    })
                });
            },

            setMode(mode) {
                switch(mode) {
                    case 'user':
                        this.loadUser();
                        break;
                    case 'team':
                        this.mode = 'team';
                        break;
                }
            }
        },

        components: {
            'statistics': Statistics
        }
    }
</script>
