// Template

<template>
    <div class="container">
        <div class="single-link-info">
            Search for a product and then select it
        </div>

        <search-products :collection-id="collectionId"
                         :userid="userId"
                         :shops="shops"
                         :single-link="true"
        ></search-products>

        <tags-modal :show="tagsModal.show"
                    :product-id="tagsModal.productId"
                    @cancel="tagsModal.show=false"
        ></tags-modal>

        <product-modal :show="addProductModal.show"
                       :data="addProductModal.data"
                       :single-link="true"
                       @cancel="onProductModalClose"
        ></product-modal>

        <modal id="web-code"
               title="Grab the code"
               cancel-text="Close"
               :show="codeShow"
               cancel-class="btn-darkgray"
               ok-class="hidden"
               @cancel="closeCodeModal"
               :full='true'
        >
            <div class="code-category">HTML link</div>

            <pre ref="htmlItem">{{ htmlLink }}</pre>
            <div class="code-preview">
                <div class="description">preview</div>
                <div class="preview" v-html="htmlLink"></div>
            </div>

            <div style="display: inline-block; width: 100%;">
                <button class="btn btn-primary btn-primary pull-right"
                        v-clipboard:copy="htmlLink"
                        @click="textSelect($refs.htmlItem)"
                >
                    <i class="fa fa-clipboard" aria-hidden="true" style="margin-right: 10px;"></i>
                    <span>Copy</span>
                </button>
            </div>
            <br>
            <br>

            <div class="code-category">link URL</div>

            <pre ref="linkPlain">{{ linkPlain }}</pre>

            <div style="display: inline-block; width: 100%;">
                <button class="btn btn-primary btn-primary pull-right"
                        v-clipboard:copy="linkPlain"
                        @click="textSelect($refs.linkPlain)"
                >
                    <i class="fa fa-clipboard" aria-hidden="true" style="margin-right: 10px;"></i>
                    <span>Copy</span>
                </button>
            </div>

            <br>
        </modal>
    </div>
</template>

// Style

<style lang="less">
    @import "../../less/libs/flat-ui/variables";


    .single-link-info {
        color: @brand-info;
        border:1px solid @brand-info;
        background: white;
        border-radius: 5px;
        padding: 15px;
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 300;
        text-align: center;
    }

    .code-category {
        padding: 8px 12px;
        font-size: 12px;
        margin-bottom: 12px;
        border-radius: 4px;
        background: #676767;
        color: #fff;
    }

    pre {
        white-space: pre-wrap; /* css-3 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word;
        font-size: 11px;
        background: #fff;
        border: none;
        margin-bottom: 0;
    }

    .code-preview {
        padding: 8px 12px;
        border-radius: 4px;
        background: #f5f5f5;
        margin-top: 7px;
        margin-bottom: 17px;

        > .description {
            font-size: 11px;
            margin-bottom: 6px;
            color: #888;
        }

        > .preview {
            font-size: 13px;
        }
    }
</style>

// Javascript

<script  >
    import SearchProducts from './SearchProducts.vue';
    import ProductModal from './ProductModal.vue';
    import Modal from '../lib/components/Modal';
    import TagsModal from './TagsModal.vue';

    export default
    {
        data()
        {
            return {
                addProductModal: {
                    show: false
                },
                codeShow: false,
                productCode: {},

                tagsModal: {
                    show: false,
                    productId: null
                },
            }
        },

        props: {
            data: {
                type: Object,
                default: function() {
                    return {};
                }
            }
        },

        computed: {
            singleProduct: {
                get() {
                    return this.$store.getters.getSingleProduct;
                },
                set(val) {
                    this.$store.dispatch('setSingleProduct', val);
                }
            },
            htmlLink() {
                let html;

                try {
                    html = `<a href="${ this.productCode.links[0].url }" rel="nofollow" target="_blank">${ this.productCode.name }</a>`;
                } catch (e) {}

                return html;
            },
            linkPlain() {
                let link;

                try {
                    link = this.productCode.links[0].url;
                } catch (e) {}

                return link;
            }
        },

        created() {
            _.merge(this, this.data)

            this.$events.on('add-link', () => {
                this.addProductModal = {
                    show: true,
                    data: {
                        mode: 'add',
                        listId: this.collectionId
                    }
                };
            });

            this.$events.on('edit-link', data => {
                this.addProductModal = {
                    show: true,
                    data: _.merge({
                        mode: 'edit'
                    }, data)
                };
            });

            this.$events.on('manage-user-tags', () => {
                this.tagsModal = {
                    show: true,
                    productId: null
                };
            });

            this.$events.on('manage-product-tags', (productId) => {
                this.tagsModal = {
                    show: true,
                    productId: productId
                };
            });
        },

        methods: {
            onProductModalClose(event) {
                this.addProductModal.show = false;
                this.$store.dispatch('setSingleProduct', event.data);
            },
            closeCodeModal() {
                this.codeShow = false

                setTimeout(() => {
                    if (window.getSelection)
                        window.getSelection().removeAllRanges();
                    else if (document.selection)
                        document.selection.empty();

                    this.singleProduct = null;
                }, 300);
            }
        },

        components: {
            'search-products': SearchProducts,
            'product-modal': ProductModal,
            'modal': Modal,
            'tags-modal': TagsModal,
        },

        watch: {
            singleProduct(val) {
                if (val) {
                    this.$http.get(`/api/links/code/${ val.id }`).then(response => {
                        this.productCode = response.body;

                        this.$nextTick(() => {
                            this.codeShow = true;
                        });
                    });
                }
            }
        }
    }
</script>
