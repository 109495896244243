// Template

<template>
    <div class="shop">
        <logo-image :src="icon.path"
                    side="30px"
        ></logo-image>

        <div class="details">
            <div class="name">{{ name }}</div>
            <div class="feed">
                <span v-if="feedtype === 'nofeed'">
                    <div>Not searchable</div>
                    <div>use with <a href="http://linkdeli.com/page/what-has-feed-no-feed-means" target="_blank">bookmarklet</a></div>
                </span>

                <span v-if="feedtype === 'realtime'">
                    <div>Real time search</div>
                </span>

                <span v-if="feedtype === 'feed'">
                    <div>Searchable <span
                            :aria-label="feedUpdate"
                            class="hint--top hint--rounded hint--info info"
                        >
                            <span class="fa fa-info"></span>
                        </span>
                    </div>
                    <div><b>{{ data.feedcount }}</b> products</div>
                </span>
            </div>
            <div class="buttons">
                <template v-if="type === 'user'">
                    <a aria-label="remove"
                       class="btn btn-danger hint--top hint--rounded"
                       :href="'/shops/delete?sid=' + id"
                    >
                        <span class="fa fa-trash"></span>
                    </a>

                    <a aria-label="edit"
                       class="btn btn-warning hint--top hint--rounded"
                       :href="'/shops/edit?sid=' + id"
                    >
                        <span class="fa fa-pencil"></span>
                    </a>
                </template>

                <template v-else>
                    <a aria-label="configure"
                       class="btn btn-primary hint--top hint--rounded"
                       :href="'/shops/subscribe?sid=' + id"
                    >
                        <span class="fa fa-plus"></span>
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>

// Style

<style lang="less" scoped>
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    .shop {
        width: 100%;
        background: #eee;
        border-radius: 50px;
        margin-bottom: 7px;
        padding: 10px;
        display: flex;

        > .logo  {
            flex: 30px 0 0;
            overflow: hidden;
            position: relative;
        }

        .details {
            width: ~'calc(100% - 30px)';
            flex: 1;

            > .name {
                font-size: 12px;
                color: #676767;
                padding: 4px 0;
                margin-left: 10px;
                width: 40%;
                padding-right: 10px;
                float: left;

                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            > .feed {
                width: ~'calc(60% - 100px)';
                float: left;
                font-size: 12px;
                line-height: 1.3;
                position: relative;
                height: 30px;

                > span {
                    .centered-block;

                    width: 100%;

                    > div:first-of-type {
                        font-size: 14px;

                        > .info {
                            .block-round;

                            cursor: pointer;
                            position: relative;
                            width: 18px;
                            height: 18px;
                            background: @brand-info;
                            color: #fff;
                            top: 3px;
                            left: 3px;

                            > span {
                                .centered-block;

                                font-size: 10px;
                                opacity: 0.8;
                            }

                            &:hover {

                                > span {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }

            > .buttons {
                width: 90px;
                float: right;

                @btnSide: 30px;

                > .btn {
                    .block-round;

                    width: @btnSide;
                    height: @btnSide;
                    position: relative;
                    float: right;
                    margin-left: 6px;
                    padding: 0;

                    > span {
                        .centered-block;

                        font-size: 11px;
                        opacity: 0.85;
                    }
                }
            }
        }
    }
</style>

// Javascript

<script  >
    import LogoImage from '../lib/components/LogoImage'

    export default
    {
        components: {
            'logo-image': LogoImage
        },

        props: {
            type: {
                type: String,
                default: 'all'
            },
            data: {
                type: Object,
                default: {}
            }
        },

        computed: {
            feedUpdate() {

                if (this.feedtype === 'feed')
                    return `updated ${ moment(this.feedupdate.date).fromNow() }`;
                else
                    return null
            }
        },

        created() {
            _.merge(this, this.data);
        }
    }
</script>
