// Template

<template>
    <div id="invoice">
        <div class="container">
            <div class="content">
                <div class="header">
                    <div class="logo">
                        <img src="/img/logo.svg">
                    </div>

                    <div class="info">
                        <div class="company">LinkDeli - Kormpetis</div>
                        <div>Proussis i710, Kardia</div>
                        <div>57500, GREECE</div>
                        <div>Email: info@linkdeli.com</div>
                        <div>VAT: EL026620252</div>
                        <a href="https://linkdeli.com"
                           target="_blank"
                        >
                            https://linkdeli.com
                        </a>
                    </div>
                </div>

                <div class="body">
                    <div class="invoice">Invoice {{ details.invoice.local_id }}</div>
                    <div class="customer">
                        <div class="info">
                            <div class="name">
                                {{ details.invoiceDetails.firstName }} {{ details.invoiceDetails.lastName }}
                            </div>
                            <div>{{ details.invoiceDetails.address || 'address' }}</div>
                            <div>{{ details.invoiceDetails.region || 'region' }}, {{ details.invoiceDetails.postalCode || 'postal code'
                                }}
                            </div>
                            <div>{{ details.invoiceDetails.countryCode || 'country code' }}</div>
                            <div>{{ details.vatNo }}</div>
                            <div class="edit"
                                 @click="editInvoiceDetails"
                            >
                                <span class="fa fa-pencil"></span>
                            </div>
                        </div>

                        <div class="date">
                            <div class="">{{ details.invoice.timestamp }}</div>
                            <div class="">Due {{ details.invoice.timestamp }} (Due Upon Receipt)</div>
                        </div>
                    </div>

                    <br>
                    <br>

                    <div class="payments">
                        <table class="table">
                            <colgroup>
                                <col span="1" style="width: 10%;">
                                <col span="1" style="width: 60%;">
                                <col span="1" style="width: 10%;">
                                <col span="1" style="width: 10%;">
                            </colgroup>

                            <thead>
                            <tr>
                                <th>Qty</th>
                                <th>Description</th>
                                <th></th>
                                <th>Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template>
                                <tr>
                                    <td>1</td>
                                    <td>Monthly subscription to LinkDeli (plan <b style="text-transform: capitalize;">
                                        {{ details.invoice.planName }}</b>)
                                    </td>
                                    <td></td>
                                    <td>{{ details.invoice.planTotal }}</td>
                                </tr>

                                <tr>
                                    <td style="border: none;"></td>
                                    <td style="border: none;"></td>
                                    <td style="border: none;">
                                        <b>Paid</b>
                                    </td>
                                    <td style="border: none;">
                                        <b>{{ details.invoice.paid }}</b>
                                    </td>
                                </tr>
                            </template>


                            <tr>
                                <td></td>
                                <td></td>
                                <td>
                                   Total Due
                                </td>
                                <td>
                                   $ 0.00
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style lang="less" scoped>
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    #invoice {
        display: block;
        margin-top: 50px;
        max-width: 100%;

        .content {
            background: #fff;
            border-radius: 5px;
            padding: 25px;
            color: #555;

            > .header {
                display: inline-block;
                width: 100%;
                padding-bottom: 25px;
                border-bottom: 2px solid;
                border-color: #eee;

                > .logo {
                    width: 150px;
                    position: relative;
                    display: inline-block;
                    float: left;
                    margin-right: 25px;

                    &:after {
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }

                    > img {
                        .centered-block;

                        max-width: 100%;
                    }
                }

                > .info {
                    float: left;
                    line-height: 1.4;

                    > .company,
                    > a {
                        font-weight: bolder;
                    }

                    > .company {
                        color: @brand-secondary;
                        margin-bottom: 5px;
                    }

                    > div:last-of-type {
                        margin-bottom: 5px;
                    }
                }
            }

            > .body {
                margin-top: -7px;
                padding-top: 25px;

                > .invoice {
                    font-size: 23px;
                    font-weight: bolder;
                    color: @brand-secondary;
                }

                > .customer {
                    padding-top: 15px;
                    display: inline-block;
                    width: 100%;
                    font-size: 16px;

                    > .info {
                        text-transform: uppercase;
                        line-height: 1.4;
                        float: left;
                        padding-right: 50px;
                        position: relative;
                        cursor: pointer;

                        > .name {
                            font-weight: bolder;
                            color: @brand-secondary;
                            margin-bottom: 5px;
                        }

                        > .edit {
                            .block-round;

                            opacity: 0;
                            pointer-events: none;
                            position: absolute;
                            width: 40px;
                            height: 40px;
                            right: -10px;
                            top: -10px;
                            background: #f0f0f0;
                            transition: 0.25s all ease;

                            &:hover {
                                background: #ddd;
                            }

                            > span {
                                .centered-block;
                            }
                        }

                        &:hover {

                            > .edit {
                                opacity: 1;
                                pointer-events: all;
                            }
                        }
                    }

                    > .date {
                        float: right;
                        line-height: 1.4;
                        text-align: right;

                        > div:first-of-type {
                            margin-bottom: 5px;
                            font-weight: bolder;
                        }
                    }
                }

                > .payments {

                    > table {
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        overflow: hidden;

                        &:first-of-type {
                            margin-bottom: 0;
                        }

                        &:last-of-type {

                            > tbody {

                                > tr {

                                    > td {
                                        border: none;
                                        font-size: 17px;
                                        padding-right: 0;
                                    }
                                }
                            }
                        }

                        > thead {
                            background: @brand-secondary;

                            > tr {

                                > th {
                                    padding: 15px 25px;
                                    color: #fff;
                                    font-size: 15px;
                                }
                            }
                        }

                        > tbody {

                            > tr {

                                > td {
                                    padding: 13px 25px;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>



<script>

    export default {
        props: {
            details: {
                type: Object,
                required: true,
            }
        },

        created() {
            this.$events.on('invoice-details-changed', event => {
                location.reload();
            });
        },


        methods: {
            editInvoiceDetails() {
                this.$events.emit('edit-invoice-details');
            }
        }
    }
</script>
