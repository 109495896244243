require("setimmediate");
window.URI = require('urijs');

window.moment = require('moment');
window.Promise = require('promise');
window.Cookies = require('js-cookie');
window.$ = window.jQuery = require('jquery');

require('bootstrap');
require('selectize');
require('./flat-ui');
window.swal = require('sweetalert');

require('bootstrap-toggle');
require('./enjoyhint/jquery.enjoyhint');
require("fx-trunk8");

window.Clipboard = require('clipboard');
window.Chart = require('chart.js');
window.confirmPopup = require('./lib/confirmPopup');
window.isMobile = require('./lib/isMobile');
window.cookieTool = require('./cookies/CookieToolWrapper.js');
require('fuckadblock');


import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import VueEvents from 'vue-events';
import VueResource from 'vue-resource';
import VueClipboard from 'vue-clipboard2';

Vue.use(VueResource);
Vue.use(VueLocalStorage);
Vue.use(VueEvents);
Vue.use(VueClipboard);

Vue.http.headers.common['X-CSRF-TOKEN'] = window.csrfToken;

import Announcements      from './components/Announcements.vue';
import ChangePayment      from './components/ChangePayment.vue';
import Collection         from './components/Collection.vue';
import CollectionList     from './components/CollectionList.vue';
import ExpirationModal    from './components/ExpirationModal.vue';
import GetVat             from './components/GetVat.vue';
import Invoice            from './components/Invoice.vue';
import InvoiceModal       from './components/InvoiceModal.vue';
import ListAddProducts    from './components/ListAddProducts.vue';
import ListStats          from './components/ListStats.vue';
import MigrationUI        from './components/Migration/MigrationUI.vue';
import Notifications      from './lib/components/Notifications.vue';
import PlanReminder       from './lib/components/PlanReminder.vue';
import Pricing            from './components/Pricing.vue';
import Product            from './components/Product.vue';
import ProductList        from './components/ProductList.vue';
import ProductListPreview from './components/ProductListPreview.vue';
import SearchProducts     from './components/SearchProducts.vue';
import Settings           from './components/Settings.vue';
import Shops              from './components/Shops.vue';
import SingleLink         from './components/SingleLink.vue';
import Statistics         from './components/Statistics.vue';
import Subscribe          from './components/Subscribe.vue';
import TeamStatistics     from './components/TeamStatistics.vue';
import UserProducts       from './components/UserProducts.vue';
import Teasers            from './lib/components/Teasers.vue';


import PlanMixin from './lib/mixins/lib/plan';
import EnvMixin from './lib/mixins/lib/env';
import GeneralMixin from './lib/mixins/lib/general';
Vue.mixin(PlanMixin);
Vue.mixin(EnvMixin);
Vue.mixin(GeneralMixin);

import {store} from './store/index';

window.Vue = new Vue({
	el: '#vapp',
	store: store,
	components: {
		'product': Product,
		'collection-list': CollectionList,
		'plan-reminder': PlanReminder,
		'notifications': Notifications,
		'search-products': SearchProducts,
		'collection': Collection,
		'product-list': ProductList,
		'product-list-preview': ProductListPreview,
		'list-add-products': ListAddProducts,
		'list-stats': ListStats,
		'user-products': UserProducts,
		'single-link': SingleLink,
		'shops': Shops,
		'statistics': Statistics,
		'team-statistics': TeamStatistics,
		'migration-ui': MigrationUI,
		'pricing': Pricing,
		'settings': Settings,
		'subscribe': Subscribe,
		'change-payment': ChangePayment,
		'expiration-modal': ExpirationModal,
		'invoice': Invoice,
		'invoice-modal': InvoiceModal,
		'get-vat': GetVat,
		'announcements': Announcements,
		'teasers': Teasers
	}
});