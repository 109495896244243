var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "form-group tags",
      attrs: { id: "tags-select" }
    },
    [
      _c("label", [
        _vm._v("Tags\n        "),
        _vm.owner === "user"
          ? _c("a", { on: { click: _vm.manageTags } }, [
              _c("span", { staticClass: "fa fa-gear" })
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "select",
        {
          ref: "tagsSelect",
          staticClass: "form-control multiselect multiselect-primary",
          attrs: { multiple: "multiple", "data-toggle": "select" }
        },
        [
          _vm._l(_vm.tags, function(tag) {
            return [
              tag.selected
                ? _c(
                    "option",
                    { attrs: { selected: "" }, domProps: { value: tag.id } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(tag.name) +
                          "\n            "
                      )
                    ]
                  )
                : _c("option", { domProps: { value: tag.id } }, [
                    _vm._v(
                      "\n                " + _vm._s(tag.name) + "\n            "
                    )
                  ])
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }