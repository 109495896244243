function confirmPopup(options, callback) {

	let title = options.title || "Are you sure?"
	let text  = options.text || ""
	let type  = options.type || "warning"

	swal({
		title: title,
		text: text,
		type: type,
		showCancelButton: true,
		confirmButtonColor: "#16a085",
		confirmButtonText: "Proceed",
		closeOnConfirm: false
	}, function () {
		callback()
	});
}

module.exports = confirmPopup;