var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "row", attrs: { id: "shops" } }, [
        !_vm.isTeamMember
          ? _c("div", { staticClass: "search col-md-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "input-group input-group-hg input-group-rounded flat",
                  attrs: { id: "search-shop" }
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchString,
                        expression: "searchString"
                      }
                    ],
                    staticClass: "form-control flat",
                    attrs: { type: "text", placeholder: "Search for shop..." },
                    domProps: { value: _vm.searchString },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchString = $event.target.value
                      }
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "request-shop-oneliner" }, [
                _vm._v(
                  "\n                Didn't find the shop you were looking for?\n                "
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-embossed",
                    staticStyle: { "margin-left": "15px" },
                    on: { click: _vm.addShop }
                  },
                  [_vm._v("\n                    Add it!\n                ")]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "shops" }, [
          _c("div", { staticClass: "user-shops col-md-6" }, [
            _c("div", { staticClass: "title" }, [_vm._v("Your Shops")]),
            _vm._v(" "),
            _c(
              "div",
              { ref: "userShopsContent", staticClass: "content" },
              [
                _vm.userShopsCache.length
                  ? _vm._l(_vm.userShopsCache, function(shop) {
                      return _c("shop", {
                        key: shop.id,
                        attrs: { data: shop, type: "user" }
                      })
                    })
                  : _c("div", { staticClass: "no-shops" }, [
                      _vm._v(
                        "\n                        You have no shops yet\n                    "
                      )
                    ])
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "all-shops col-md-6" }, [
            _c("div", { staticClass: "title" }, [_vm._v("Available Shops")]),
            _vm._v(" "),
            _c(
              "div",
              { ref: "allShopsContent", staticClass: "content-wrapper" },
              [
                _c("scrollbar", [
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { attrs: { id: "all-shops-wrapper" } },
                      [
                        _vm.filteredAllShops.length
                          ? _vm._l(_vm.filteredAllShops, function(shop) {
                              return _c("shop", {
                                key: shop.id,
                                attrs: { data: shop, type: "all" }
                              })
                            })
                          : _c(
                              "div",
                              { staticClass: "no-shops" },
                              [
                                _vm.searchString.length
                                  ? [
                                      _c("div", [
                                        _vm._v(
                                          'No shops found for "' +
                                            _vm._s(_vm.searchString) +
                                            '"'
                                        )
                                      ])
                                    ]
                                  : [_c("div", [_vm._v("No shops available")])]
                              ],
                              2
                            ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        !_vm.isTeamMember
                          ? _c("div", { staticClass: "request-shop" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-embossed",
                                  on: { click: _vm.addShop }
                                },
                                [
                                  _vm._v(
                                    "\n                                        Request shop\n                                    "
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ])
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            show: _vm.addShopModal.show,
            small: true,
            title: !_vm.shopNotFound ? "Add shop" : "Request shop"
          },
          on: { cancel: _vm.closeAddShop }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.newShop && !_vm.shopNotFound,
                  expression: "!newShop && !shopNotFound"
                }
              ],
              class: [
                "form-group",
                "affiliate-url",
                { "has-error": _vm.affiliateErrorMsg.length }
              ]
            },
            [
              _c("div", { staticClass: "info-box" }, [
                _vm._v("For shops in "),
                _c("b", [
                  _vm._v("ShareASale, CJ affiliate or Rakuten/Linkshare")
                ]),
                _vm._v(
                  ", please provide a deep affiliate link of the shop you want to add"
                )
              ]),
              _vm._v(" "),
              _c("label", [_vm._v("Deep affiliate link")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "url-input" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.affiliateLink,
                        expression: "affiliateLink"
                      }
                    ],
                    class: ["form-control", { fetching: _vm.loadingShop }],
                    attrs: {
                      type: "text",
                      placeholder: "please enter a deep affiliate link"
                    },
                    domProps: { value: _vm.affiliateLink },
                    on: {
                      click: function($event) {
                        return $event.target.select()
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.affiliateLink = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.loadingShop
                    ? _c("loader", { attrs: { side: "20px" } })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-md-12",
                    staticStyle: { "margin-top": "8px", "font-size": "12px" }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "pull-right",
                        attrs: {
                          href:
                            "/page/how-do-i-find-a-deep-link-in-the-affiliate-networks-dashboard",
                          target: "_blank"
                        }
                      },
                      [_vm._v("How do I get a deep affiliate link?")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-md-12",
                    staticStyle: { "margin-top": "8px", "font-size": "13px" }
                  },
                  [
                    _vm._v("\n                   If the shop is not in "),
                    _c("b", [_vm._v("ShareASale, CJ or Rakuten/LinkShare")]),
                    _vm._v(", please click "),
                    _c("b", [
                      _c(
                        "a",
                        { attrs: { href: "/shops/request", target: "_blank" } },
                        [_vm._v("here to request it.")]
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.affiliateErrorMsg.length
                ? _c("div", { staticClass: "error-msg" }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.affiliateErrorMsg) }
                    })
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.newShop
            ? _c("div", { staticClass: "shop-found" }, [
                _c(
                  "div",
                  { staticClass: "new-shop" },
                  [
                    _c("logo-image", {
                      attrs: { src: _vm.newShop.icon, side: "30px" }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.newShop.name))
                    ])
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.shopNotFound
            ? _c("div", { staticClass: "shop-not-found" }, [
                _c("div", { staticClass: "info-box" }, [
                  _c("div", [_vm._v("Your shop could not be found.")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("Please provide some details.")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Shop URL")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shopToRequest.url,
                        expression: "shopToRequest.url"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "please enter shop URL"
                    },
                    domProps: { value: _vm.shopToRequest.url },
                    on: {
                      click: function($event) {
                        return $event.target.select()
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.shopToRequest, "url", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Sample affiliate links")]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shopToRequest.links,
                        expression: "shopToRequest.links"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      rows: "4",
                      type: "text",
                      placeholder:
                        "please include a couple of your own affiliate links to this shop"
                    },
                    domProps: { value: _vm.shopToRequest.links },
                    on: {
                      click: function($event) {
                        return $event.target.select()
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.shopToRequest,
                          "links",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            !_vm.shopNotFound
              ? _c(
                  "button",
                  {
                    class: [
                      "btn",
                      "btn-embossed",
                      "btn-primary",
                      { unabled: !_vm.newShop || _vm.savingShop }
                    ],
                    on: { click: _vm.saveNewShop }
                  },
                  [
                    !_vm.savingShop
                      ? [_vm._v("Add")]
                      : _c("span", {
                          staticClass: "fa fa-circle-o-notch fa-spin fa-fw"
                        })
                  ],
                  2
                )
              : _c(
                  "button",
                  {
                    class: [
                      "btn",
                      "btn-embossed",
                      "btn-primary",
                      { unabled: !_vm.canRequestShop || _vm.savingShop }
                    ],
                    on: { click: _vm.requestShop }
                  },
                  [
                    !_vm.savingShop
                      ? [_vm._v("Request")]
                      : _c("span", {
                          staticClass: "fa fa-circle-o-notch fa-spin fa-fw"
                        })
                  ],
                  2
                ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-embossed btn-darkgray",
                on: { click: _vm.closeAddShop }
              },
              [_vm._v("\n                Close\n            ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-btn" }, [
      _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
        _c("span", { staticClass: "fa fa-search" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }