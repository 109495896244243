<!--Template-->

<template>
    <div class="container">
        <modal id="expired-modal"
               title="Expired account"
               :show="expiredDialog"
               ok-text="Upgrade"
               ok-class="upgrade-button btn-primary"
               @ok="upgradeAccount"
               @cancel="closeExpired"
        >
            <div>
                <div class="row">
                    <div class="col-md-12" style="font-size: 14px;">
                        To create new lists, modify existing ones and view any statistics on your lists, you will need
                        to upgrade your account to a paid subscription
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 pull-right" style="margin-top: 12px; font-size: 12px;">
                        <a class="pull-right" href="/pricing" target="_blank">View plans</a>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>


<style lang="less" scoped>

    .wrapper {
        text-align: center;
        padding-bottom: 55px;

        > .header {
            background: #f5f5f5;
            border-radius: 5px;
            padding: 20px;

            > .title {
                font-size: 23px;
                color: #bbb;
                margin-bottom: 10px;
                margin-top: -4px;
            }

            > .price {
                font-size: 14px;
                color: #aaa;
                line-height: 1.3;

                > strong {
                    font-size: 22px;
                    color: #888;
                    line-height: 1.3;

                    > span {
                        font-size: 12px;
                        padding-right: 1px;
                    }
                }
            }

            > .includes {
                text-align: center;
                padding: 7px;
                background: #e9e9e9;
                margin-top: 10px;
                border-radius: 5px;
                color: #777;
                font-weight: bolder;
                font-size: 13px;
            }

            > .business {
                margin-top: 13px;
                font-weight: bolder;
                margin-bottom: -6px;
                cursor: pointer;
                font-size: 14px;
                display: block;
            }
        }

        form {

            > .payment-form {
                min-height: 80px;
                padding: 0 15px;
            }

            > .button-wrapper {
                padding: 18px;
            }
        }

        > .payment-info {

            > .credit-cards {
                text-align: right;

                > img {
                    width: 250px;
                    margin: 7px 0;
                }
            }

            > .braintree {
                text-align: right;
            }
        }
    }
</style>

<style lang="less">
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    #vat-modal {

        .description {
            text-align: center;
            padding: 10px;
            background: @brand-info;
            color: #fff;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 20px;
        }

        .fields {
            display: inline-block;
            width: 100%;

            > .vat {
                margin-bottom: 15px;
                display: inline-block;
                width: 100%;

                > .vat-input {
                    position: relative;

                    > input.fetching {
                        padding-right: 40px;
                    }

                    > .loader {
                        position: absolute;
                        top: 11px;
                        right: 11px;
                    }
                }

                > .error-msg {
                    color: @brand-danger;
                    font-size: 13px;
                    text-align: center;
                }

                > button {
                    float: right;
                }
            }

            label {
                margin-bottom: 0;
                padding-left: 5px;
                font-weight: bolder;
            }

            input {
                margin-bottom: 10px;
            }
        }
    }
</style>

<script  >

    export default
    {
        data()
        {
            return {

                loaded: false,
                expiredDialog: true,
            }
        },

        props: {
            data: {
                type: Object,
                default() {
                    return {};
                }
            }
        },

        computed: {

        },

        created() {
            _.merge(this, this.data);


        },

        mounted() {
            this.$nextTick(() => {
//                let csrfElem = $('#csrf').find('input');
//                $(this.$refs.csrf).replaceWith(csrfElem);
//                $(this.$refs.csrfVat).replaceWith(csrfElem);
                this.loaded = true;
                this.expiredDialog = true;
            });

            this.removeClose = true;
        },

        methods: {
            closeExpired() {
                this.expiredDialog = false;
            },

            upgradeAccount() {
                window.location.href = this.env.APP_URL + '/subscription?return_after_sub=lists';
            }
        },

        watch: {
        }
    }
</script>
