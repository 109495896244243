// Template

<template>
    <div class="notifications-wrapper">
        <div class="content">
            <transition-group name="notifications"
                              tag="div"
                              class="notifications"
            >
                <div v-for="(item, index) in items"
                     :key="item.uuid"
                     :class="[ 'notification', 'alert', 'alert-' + item.type ]"
                     ref="notification"
                >
                    <button type="button"
                            class="close fui-cross"
                            @click="remove(item, $event.target.parentElement)"
                    ></button>

                    <h4 v-if="item.title" v-html="item.title"></h4>
                    <span v-html="item.message"></span>
                </div>
            </transition-group>
        </div>
    </div>
</template>

// Style

<style lang="less" scoped>
    @import "../../../less/mixins";
    @import "../../../less/libs/flat-ui/variables";

    .notifications-wrapper {
        .content-block;

        background: transparent;
        position: fixed;
        pointer-events: none;
        z-index: 99999;

        > .content {
            padding: 20px;
            max-width: 100%;
            width: 500px;
            position: absolute;
            bottom: 20px;
            right: -10px;

            > .notifications {
                position: relative;
                height: 100%;
                display: flex;
                flex-direction: column-reverse;

                > .notification {
                    width: 100%;
                    margin: 5px 0;
                    pointer-events: all;
                    border: none;
                    border-radius: 4px;
                    color: #fff;
                    padding: 15px 45px 15px 15px;
                    max-height: 550px;
                    overflow-y: auto;

                    &.alert-danger {
                        background: @brand-danger;

                        > button {
                            color: mix(@brand-danger, #000);
                        }
                    }

                    &.alert-warning {
                        background: @brand-warning;

                        > button {
                            color: mix(@brand-warning, #000);
                        }
                    }

                    &.alert-success {
                        background: @brand-success;

                        > button {
                            color: mix(@brand-success, #000);
                        }
                    }

                    &.alert-info {
                        background: @brand-info;
                        > button {
                            color: mix(@brand-info, #000);
                        }

                    }

                    &.alert-plain {
                        border: 3px solid #555;
                        background: #f3f3f3;
                        color: #555;

                        > button {
                            color: mix(#ddd, #000);
                        }

                        > a {
                            text-decoration: none;

                            &:hover {
                                color: #555;
                            }
                        }
                    }

                    > button {
                        outline: none;
                        text-shadow: none;
                        font-size: 14px;
                        transition: 0.25s all ease;
                        opacity: 0.7;

                        &:hover {
                            opacity: 1;
                        }
                    }

                    > h4 {
                        margin-top: 0;
                        margin-bottom: 20px;
                    }

                    > span {
                        font-size: 15px;

                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .notification {
        transition: all ease 0.3s;
        display: inline-block;
        z-index: 1;
    }
    .notifications-enter, .notifications-leave-to {
        opacity: 0;
        transform: translateX(50%);
        z-index: 0;
    }
    .notifications-leave-active {
        position: absolute;
    }
</style>

// Javascript

<script  >
    import uuid from 'uuid';

    export default
    {
        data()
        {
            return {
                items: []
            }
        },

        methods: {
            add(item) {
                item.type = item.type || 'info';
                item.uuid = uuid();
                item.onClose = item.onClose || function() {};

                this.items.unshift(_.cloneDeep(item));

                this.$nextTick(() => {
                    if (item.timeout) {
                        setTimeout(() => {
                            let index = _.findIndex(this.items, listItem => {
                                return item.uuid === listItem.uuid;
                            });

                            this.remove(item, this.$refs.notification[index]);
                        }, item.timeout);
                    }
                });
            },
            remove(item, elem) {
                try {
                    elem.style.top = `${ elem.offsetTop - 5 }px`;

                    let currentItem = _.cloneDeep(_.find(this.items, {
                        uuid: item.uuid
                    }));

                    this.items = _.reject(this.items, {
                       uuid: item.uuid
                    });

                    currentItem.onClose();
                } catch (e) {}
            },
            removeAll() {
                this.items = [];
            }
        },

        created() {
            this.$events.on('notify', item => {
                // prevent duplicates
                let isDuplicate = false;

                _.each(this.items, oldItem => {
                    if (oldItem.message === item.message)
                        isDuplicate = true;
                });

                if (!isDuplicate)
                    this.add(item);
            });

            this.$events.on('notify-clear', () => {
                this.removeAll();
            });
        },

        mounted() {
//            setTimeout(() => {
//                this.add({
//                    title: 'aaaaa',
//                    message: 'bbbbbbbbbb'
//                });
//            }, 2000);
        }
    }
</script>

<style lang="less">
    .notifications-wrapper {

        > .content {

            > .notifications {

                > .notification:not('.alert-plain') {

                    > h4,
                    > span {

                        > a {
                            color: fadeout(#000, 65%);
                            cursor: pointer;
                            transition: 0.3s all ease;
                            text-decoration: underline;

                            &:hover {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
