// Template

<template>
    <div :class="[ 'logo', extraClasses, { 'loaded': imgLoaded, 'errored': imgErrored, 'round': isRound } ]"
         :style="{ 'width': side }"
    >
        <template v-if="inViewport">
            <span v-if="imgLoaded">
                <img :src="imageSrc">
            </span>
        </template>

        <slot></slot>
    </div>
</template>

// Style

<style lang="less" scoped>
    @import "../../../less/mixins";

    .logo {
        background: #fff;
        overflow: hidden;
        position: relative;
        max-width: 100%;

        &.round {
            .block-round;

            > span {
                .block-round;
            }
        }

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        > span {
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            overflow: hidden;

            > img {
                .centered-block;

                width: 100%;
                height: 100%;
            }
        }

        &.errored {
            /*padding: 4% !important;*/

            > span {
                border-radius: 0 !important;
            }
        }
    }
</style>

// Javascript

<script  >
    import inViewport from 'in-viewport';

    export default
    {
        data: function()
        {
            return {
                imgLoaded: false,
                imgAspect: null,
                imgErrored: false,
                inViewport: false,
                tryingNoProtocol: true
            };
        },
        props:
        {
            src:
            {
                type: String,
                default: null
            },
            side:
            {
                type: String,
                default: '35px'
            },
            extraClasses:
            {
                type: String,
                default: ''
            },
            isRound:
            {
                type: Boolean,
                default: true
            }
        },
        computed:
        {
            hasImage: function()
            {
                return !!this.src;
            },

            imageUrl: function()
            {
                return `url("${ this.imageSrc }")`;
            },

            imageSrc: function()
            {
                return this.imgErrored
                        ? '/favicon.ico'
                        : this.maybeRemoveProtocol(this.src);
            }
        },
        methods:
        {
            loadImage: function(afterError = false)
            {
                if (!this.hasImage || !this.inViewport)
                    return;

                let image = new Image(),
                    onComplete = () => {
                        this.imgLoaded = true;
                    },
                    onError = (e) => {
                        if (this.tryingNoProtocol) {
                            this.tryingNoProtocol = false;
                            this.loadImage();
                        } else {
                            this.imgErrored = true;
                            this.$emit('image-errored');
                            this.loadImage();
                        }
                    };

                image.onload = function()
                {
                    onComplete();
                };

                image.onerror = function(e)
                {
                    onError(e);
                };

                image.src = this.imageSrc;

                if (image.complete)
                    onComplete();
            },
            maybeRemoveProtocol(src) {
                let resultSrc = _.replace(_.replace(src, 'http:', ''), 'https:', '');

                return this.tryingNoProtocol
                        ? resultSrc
                        : src;
            }
        },
        mounted: function()
        {
            inViewport(this.$el, () => {
                this.inViewport = true;
                this.loadImage();
            });
        }
    }
</script>
