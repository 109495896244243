export function cookie_tool()
{
	return {
		setCookie: (function (name, value, expiration, path = "/")
		{
			var currentDate = new Date();
			currentDate.setDate(currentDate.getDate() + expiration);
			var expires = "expires=" + currentDate.toUTCString();
			document.cookie = name + "=" + value + ";" + expires + ";path=" + path + ";";
		}),
		getCookie: (function (name)
		{
			name += "=";
			var decodedCookie = decodeURIComponent(document.cookie);
			var variables = decodedCookie.split(';');
			for (var i = 0; i < variables.length; i++)
			{
				var cookie = variables[i].trim();
				if (cookie.indexOf(name) == 0)
				{
					return cookie.substring(name.length, cookie.length);
				}
			}

			return "";
		}),
		deleteCookie: (function (name, path = "/")
		{
			document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=" + path + ";";
		})
	};
}

export function gdpr_compliance(cookie_settings, cookie_tool)
{
	var cookieTool = cookie_tool;
	return {
		cookieSettings: cookie_settings,
		cookieTool: cookie_tool,
		cookieBarButton: '',
		cookieSettingsButton: '',
		cookieBarClose: '',
		init: (function ()
		{
			this.addElements();
			this.cookieBarButton = document.body.querySelector(".gdpr_cookie_bar_accept");
			this.cookieSettingsButton = document.body.querySelector(".gdpr_cookie_settings_button");
			this.cookieBarClose = document.body.querySelector(".gdpr_cookie_bar_close");

			this.cookieBarButton.onclick = this.setAllowed;
			this.cookieBarClose.onclick = this.hideBar;
			this.cookieSettingsButton.onclick = this.toggleBar;

			if (!this.getShown())
			{
				this.showBar();
				this.cookieTool.setCookie('gdpr_bar_shown', '1', 365);
			}
		}),
		addElements: (function ()
		{
			var scopes = this.cookieSettings.scopes;
			var allowed = this.getAllowed();
			var cookieBar = '<div class="gdpr_cookie_bar hidden">' +
				'<div class="gdpr_cookie_bar_close"></div>' +
				'<p class="gdpr_cookie_bar_title">' + this.cookieSettings.messages.cookiebar_title + '</p>' +
				'<p class="gdpr_cookie_bar_description">' + this.cookieSettings.messages.cookiebar_description + '</p>' +
				'<form class="gdpr_cookie_bar_options">';
			for (var i = 0; i < scopes.length; i++)
			{
				cookieBar += '<div class="gdpr_cookie_bar_option">' +
					'<label for="gdpr_cookie_bar_option_' + scopes[i].scope + '">' +
					'<div class="gdpr_cookie_bar_option_checkbox">' +
					'<input id="gdpr_cookie_bar_option_' + scopes[i].scope + '" type="checkbox" ' +
					(scopes[i].required == 1 ? 'disabled data-help="' + this.cookieSettings.messages.required_help + '" ' : 'name="' + scopes[i].scope + '" ');

					cookieBar +=  'checked ' +
					'>' +
					'</div>' +
					'<span class="gdpr_cookie_bar_option_title">' + scopes[i].title + '</span>' +
					'<span class="gdpr_cookie_bar_option_description">' + scopes[i].description + '</span>' +
					'</label>' +
					'</div>';
			}

			cookieBar += '</form>' +
				'<button type="button" class="gdpr_cookie_bar_accept">' + this.cookieSettings.messages.cookiebar_button + '</button>' +
				'</div>';
			document.body.insertAdjacentHTML(
				'beforeend',
				cookieBar
			);

			// + '<button type="button" class="gdpr_cookie_settings_button">' + this.cookieSettings.messages.settings_button + '</button>'
		}),
		showBar: (function ()
		{
			document.body.querySelector(".gdpr_cookie_bar")
			.classList.remove("hidden");
		}),
		toggleBar: (function ()
		{
			document.body.querySelector(".gdpr_cookie_bar")
			.classList.toggle("hidden");
		}),
		hideBar: (function ()
		{
			document.body.querySelector(".gdpr_cookie_bar")
			.classList.add("hidden");
		}),
		setAllowed: (function ()
		{
			var formElements = document.body.querySelector("form.gdpr_cookie_bar_options")
				.elements;
			var allowed = [];
			for (var i = 0; i < formElements.length; i++)
			{
				var elementName = formElements[i].getAttribute("name");
				if (elementName && formElements[i].checked)
				{
					allowed.push(elementName);
				}
			}

			cookieTool.setCookie('gdpr_cookie', allowed.join(","), 365);
			document.body.querySelector(".gdpr_cookie_bar")
			.classList.add("hidden");
		}),
		getAllowed: (function ()
		{
			var scopeSettings = this.cookieSettings.scopes;
			var allowed = cookieTool.getCookie('gdpr_cookie')
			.split(",");
			for (var i = 0; i < scopeSettings.length; i++)
			{
				if (scopeSettings[i].required == 1)
				{
					allowed.push(scopeSettings[i].scope);
				}
			}

			return allowed;
		}),
		getShown: (function ()
		{
			return (cookieTool.getCookie('gdpr_bar_shown') != "" ? true : false);
		})
	};
}


export function gdpr_cookie(compliance_tool, cookie_tool)
{
	var complianceTool = compliance_tool;
	var cookieTool = cookie_tool;
	return {
		setCookie: (function (scope, name, value, expiration, path = "/")
		{
			if (complianceTool.getAllowed()
				.indexOf(scope) > -1)
			{
				cookieTool.setCookie(name, value, expiration, path);
			}
		}),
		getCookie: (function (scope, name)
		{
			var cookie = cookieTool.getCookie(name);
			if (cookie != "")
			{
				if (complianceTool.getAllowed()
					.indexOf(scope) > -1)
				{
					return varFull.substring(searchName.length, varFull.length);
				}

				cookieTool.deleteCookie(name);
			}
		}),
		deleteCookie: (function (name)
		{
			cookieTool.deleteCookie(name);
		})
	};
}