var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "loader", style: { "animation-duration": _vm.duration } },
    [
      _c("div", {
        staticClass: "spinner",
        style: {
          width: _vm.side,
          height: _vm.side,
          "border-color": _vm.color,
          "border-width": _vm.borderWidth,
          opacity: _vm.opacity
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }