module.exports = confirmPopup;

function isMobile(maxWidth, maxHeight) {
	let width = maxWidth || 767;
	let height = maxHeight || 700;

	let limitStr = `(max-width: ${maxWidth}px), (max-height: ${maxHeight}px)`;
	return window.matchMedia(limitStr).matches;
}

module.exports = isMobile;