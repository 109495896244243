var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notifications-wrapper" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "transition-group",
          {
            staticClass: "notifications",
            attrs: { name: "notifications", tag: "div" }
          },
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "div",
              {
                key: item.uuid,
                ref: "notification",
                refInFor: true,
                class: ["notification", "alert", "alert-" + item.type]
              },
              [
                _c("button", {
                  staticClass: "close fui-cross",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.remove(item, $event.target.parentElement)
                    }
                  }
                }),
                _vm._v(" "),
                item.title
                  ? _c("h4", { domProps: { innerHTML: _vm._s(item.title) } })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { domProps: { innerHTML: _vm._s(item.message) } })
              ]
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }