var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "collection",
        { "adding-products": _vm.addingProducts, "is-template": _vm.isTemplate }
      ],
      on: { click: _vm.collectionClicked }
    },
    [
      _c(
        "div",
        {
          staticClass: "oneProductList well",
          attrs: {
            id: "Collection-" + _vm.collection.id,
            "data-name": _vm.collection.title,
            "data-id": _vm.collection.id
          }
        },
        [
          _c("div", { staticClass: "name" }, [
            !_vm.editName
              ? _c("span", { on: { click: _vm.clickedListName } }, [
                  _c("span", { staticClass: "title" }, [
                    _c("span", { ref: "title" }, [
                      _vm._v(_vm._s(_vm.collection.title))
                    ]),
                    _c("span", { staticClass: "fa fa-pencil" })
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.editName
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "on-clickaway",
                        rawName: "v-on-clickaway",
                        value: _vm.changedListName,
                        expression: "changedListName"
                      }
                    ],
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.changedListName($event)
                      }
                    }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tempTitle,
                          expression: "tempTitle"
                        }
                      ],
                      ref: "titleInput",
                      style: {
                        "max-width": "calc(100% - 120px)",
                        width: "100%"
                      },
                      attrs: { disabled: _vm.savingNewName, type: "text" },
                      domProps: { value: _vm.tempTitle },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.tempTitle = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.savingNewName
                      ? _c(
                          "span",
                          { staticClass: "loading" },
                          [
                            _c("loader", {
                              attrs: {
                                side: "21px",
                                opacity: 1,
                                color: "#16a085"
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "span",
                          {
                            staticClass: "btn btn-primary",
                            on: { click: _vm.changedListName }
                          },
                          [_c("span", { staticClass: "fa fa-check" })]
                        )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "date" }, [
            _c("span", [_vm._v(_vm._s(_vm.creationDate))])
          ]),
          _vm._v(" "),
          !_vm.isTemplate
            ? _c("div", { staticClass: "link" }, [
                _c("div", { staticClass: "hidden-xs hidden-sm" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-lg hint--top hint--rounded gray-button",
                      style: {
                        "pointer-events": _vm.disableLinkShow ? "none" : "all"
                      },
                      attrs: { "aria-label": "get link to product list" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showLink($event)
                        }
                      }
                    },
                    [_c("span", { staticClass: "fa fa-link" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-lg hint--top hint--rounded gray-button",
                      style: {
                        "pointer-events": _vm.disableLinkShow ? "none" : "all"
                      },
                      attrs: {
                        "aria-label": "printable list",
                        target: "_blank",
                        href: "/lists/" + this.collection.id + "/printable"
                      }
                    },
                    [_c("span", { staticClass: "fa fa-print" })]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown visible-xs visible-sm" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass: "dropdown-menu dropdown-menu-right",
                      attrs: { "aria-labelledby": "dropdownMenu1" }
                    },
                    [
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { target: "_blank" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.showLink($event)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-link" }),
                            _vm._v(" List shortlink\n              ")
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/lists/" + this.collection.id + "/printable",
                              target: "_blank"
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-print" }),
                            _vm._v(" Printable list\n              ")
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.addingProducts
            ? _c("product-list-preview", {
                attrs: { links: _vm.collection.links }
              })
            : _c("br")
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c("div", { staticClass: "bottom-wrapper" }, [
          _c("div", { staticClass: "favourite" }, [
            _c(
              "div",
              {
                class: [
                  "favorite",
                  "hint--top",
                  "hint--rounded",
                  { marked: _vm.isFavorite }
                ],
                attrs: {
                  "aria-label": !_vm.isFavorite
                    ? "mark as favorite"
                    : "unmark favorite"
                },
                on: { click: _vm.toggleFavorite }
              },
              [_c("span", { staticClass: "fa fa-star" })]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "collection-actions" }, [
            _c("div", { staticClass: "visible-xs visible-sm" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default btn-sm mobile-button",
                  on: {
                    click: function($event) {
                      _vm.mobileMenu = !_vm.mobileMenu
                    }
                  }
                },
                [_vm._v("\n              ACTIONS\n            ")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "hidden-xs hidden-sm" }, [
              _c("div", { staticClass: "wrapper" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-sm btn-primary hint--top hint--rounded",
                    attrs: {
                      href: "/view/" + _vm.collection.uuid,
                      target: "_blank",
                      "aria-label": "preview"
                    }
                  },
                  [_c("i", { staticClass: "fa fa-eye" })]
                ),
                _vm._v(" "),
                !_vm.addingProducts
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-sm btn-primary hint--top hint--rounded",
                        attrs: {
                          href: "/lists/" + _vm.collection.id,
                          "aria-label": "edit"
                        }
                      },
                      [_c("i", { staticClass: "fa fa-pencil" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isTemplate
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-sm btn-primary hint--top hint--rounded",
                        style: {
                          "pointer-events": _vm.disableCodeShow ? "none" : "all"
                        },
                        attrs: { href: "#", "aria-label": "web code" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showCode($event)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-code" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isTemplate
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-sm btn-primary hint--top hint--rounded",
                        attrs: {
                          href: "/lists/" + _vm.collection.id + "/stats",
                          "aria-label": "statistics"
                        }
                      },
                      [_c("i", { staticClass: "fa fa-line-chart" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.addingProducts
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-sm btn-darkgray hint--top hint--rounded",
                        attrs: { "aria-label": "style", href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.styleList($event)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-paint-brush" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.addingProducts && !_vm.isTemplate
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-sm btn-warning hint--top hint--rounded",
                        attrs: { "aria-label": "clone", href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showCloneModal($event)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-clone" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTemplate
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-sm btn-warning hint--top hint--rounded hint--small",
                        attrs: { "aria-label": "revert template", href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.templateUnsetShow = true
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-ban",
                          staticStyle: { "font-size": "12px" }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.addingProducts
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-sm btn-danger hint--top hint--rounded",
                        attrs: { "aria-label": "remove", href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.popupModalForDelete($event)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-trash" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.addingProducts && !_vm.isTemplate
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-sm btn-warning already-template hint--top hint--rounded hint--small",
                        attrs: { "aria-label": "append template", href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.popupModalForTemplateAppend($event)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-paperclip fa-lg" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.addingProducts && !_vm.isTemplate
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-sm btn-warning already-template hint--top hint--rounded hint--small",
                        attrs: { "aria-label": "set as template", href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.popupModalForTemplate($event)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-file" })]
                    )
                  : _vm._e()
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              "mobile-actions",
              {
                hidden: !_vm.mobileMenu,
                "visible-xs": _vm.mobileMenu,
                "visible-sm": _vm.mobileMenu
              }
            ]
          },
          [
            _c("ul", [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/view/" + _vm.collection.uuid,
                      target: "_blank"
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-eye" }),
                    _vm._v(" Preview\n            ")
                  ]
                )
              ]),
              _vm._v(" "),
              !_vm.addingProducts
                ? _c("li", [
                    _c(
                      "a",
                      { attrs: { href: "/lists/" + _vm.collection.id } },
                      [
                        _c("i", { staticClass: "fa fa-pencil" }),
                        _vm._v(" Edit\n            ")
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isTemplate
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showCode($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-code" }),
                        _vm._v(" Get Code\n            ")
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isTemplate
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/lists/" + _vm.collection.id + "/stats",
                          target: "_blank"
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-line-chart" }),
                        _vm._v(" Statistics\n            ")
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.addingProducts
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.styleList($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-paint-brush" }),
                        _vm._v(" Style\n            ")
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.addingProducts && !_vm.isTemplate
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.showCloneModal($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-clone" }),
                        _vm._v(" Clone\n            ")
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.addingProducts && !_vm.isTemplate
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.popupModalForTemplate($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-file" }),
                        _vm._v(" Set as template\n            ")
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isTemplate
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.templateUnsetShow = true
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-ban" }),
                        _vm._v(" Revert template\n            ")
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.addingProducts && !_vm.isTemplate
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.popupModalForTemplateAppend($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-paperclip fa-lg" }),
                        _vm._v(" Append template\n            ")
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.addingProducts
                ? _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.popupModalForDelete($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-trash" }),
                        _vm._v(" Remove\n            ")
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("li", { staticClass: "closeMenu" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.mobileMenu = !_vm.mobileMenu
                      }
                    }
                  },
                  [_vm._v("\n              close\n            ")]
                )
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            "ok-text": "Delete",
            show: _vm.modalShow,
            small: true,
            "ok-class": "btn-danger"
          },
          on: { ok: _vm.remove, cancel: _vm.cancel }
        },
        [
          _c("div", [_vm._v("Remove " + _vm._s(_vm.collection.title) + "?")]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Remove " + _vm._s(!_vm.isTemplate ? "list" : "template"))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            id: "link-code",
            title: "Get link",
            show: _vm.linkModalShow,
            "ok-class": "hidden",
            "cancel-text": "Close"
          },
          on: { cancel: _vm.closeLinkModal }
        },
        [
          _c("div", { staticClass: "text" }, [
            _c("div", [
              _vm._v("Use the link below to visit the list directly.")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "link-preview copy-url" }, [
            _c("div", { staticClass: "title" }, [_vm._v("link preview")]),
            _vm._v(" "),
            _c("div", { ref: "shortLink", staticClass: "preview" }, [
              _vm._v("\n          " + _vm._s(_vm.shortLink) + "\n        ")
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.shortLink,
                    expression: "shortLink",
                    arg: "copy"
                  }
                ],
                staticClass:
                  "btn btn-primary btn-embossed hint--top hint--rounded",
                attrs: { "aria-label": "copy" },
                on: {
                  click: function($event) {
                    return _vm.textSelect(_vm.$refs.shortLink)
                  }
                }
              },
              [_c("span", { staticClass: "fa fa-clipboard" })]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { "margin-top": "20px" } }, [
            _c(
              "a",
              {
                staticClass: "btn btn-embossed",
                staticStyle: { color: "#3b5998", "margin-right": "20px" },
                attrs: { href: _vm.facebookLink, target: "_blank" }
              },
              [
                _c("i", {
                  staticClass: "fa fa-facebook",
                  staticStyle: { "font-size": "16px", "margin-right": "8px" }
                }),
                _vm._v("\n          Share on Facebook\n        ")
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-embossed",
                staticStyle: { color: "#c8232c" },
                attrs: { href: _vm.pinterestLink, target: "_blank" }
              },
              [
                _c("i", {
                  staticClass: "fa fa-pinterest",
                  staticStyle: { "font-size": "16px", "margin-right": "8px" }
                }),
                _vm._v("\n          Share on Pinterest\n        ")
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            id: "web-code",
            title: "Grab the code",
            "cancel-text": "Close",
            show: _vm.codeShow,
            "cancel-class": "btn-darkgray",
            "ok-class": "hidden",
            full: true
          },
          on: { cancel: _vm.closeCodeModal }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", [
                _c("form", [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "codeType" }
                      },
                      [
                        _vm._v(
                          "\n                  Select Platform\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.codeType,
                            expression: "codeType"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "codeType" },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.codeType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.selectCodeType
                          ]
                        }
                      },
                      [
                        _c(
                          "option",
                          { attrs: { value: "widget", selected: "" } },
                          [_vm._v("Blog")]
                        ),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "youtube" } }, [
                          _vm._v("Youtube")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "html" } }, [
                          _vm._v("HTML")
                        ]),
                        _vm._v(" "),
                        _c(
                          "option",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.allowwpcom,
                                expression: "allowwpcom"
                              }
                            ],
                            attrs: { value: "wordpresscom" }
                          },
                          [_vm._v("wordpress.com ONLY\n                  ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "option",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.allowwpcom,
                                expression: "!allowwpcom"
                              }
                            ],
                            attrs: { value: "shortcode" }
                          },
                          [_vm._v("WordPress plugin\n                  ")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.codeType === "shortcode"
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-block",
                              "font-size": "13px",
                              "margin-top": "6px",
                              "margin-left": "5px"
                            }
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "/page/the-linkdeli-wordpress-plugin-how-to-install-and-use-it",
                                  target: "_blank"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-exclamation-circle",
                                  attrs: { "aria-hidden": "true" }
                                }),
                                _vm._v(
                                  " How do I use the wordpress plugin?\n                  "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.codeType === "widget",
                  expression: "codeType === 'widget'"
                }
              ]
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "option text-center" }, [
                    _c(
                      "label",
                      {
                        class: { enabled: _vm.showEmailCodeOptions },
                        attrs: { for: "email-code" }
                      },
                      [
                        _vm._v(
                          "\n                Email Integration\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.showEmailCodeOptions
                      ? _c("input", {
                          attrs: {
                            id: "email-code",
                            type: "checkbox",
                            checked: "",
                            "data-toggle": "switch",
                            "data-off-color": "default",
                            "data-on-color": "success"
                          }
                        })
                      : _c("input", {
                          attrs: {
                            id: "email-code",
                            type: "checkbox",
                            "data-toggle": "switch",
                            "data-off-color": "default",
                            "data-on-color": "success"
                          }
                        })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showEmailCodeOptions,
                      expression: "showEmailCodeOptions"
                    }
                  ],
                  staticClass: "row"
                },
                [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "text-center",
                        style: {
                          "margin-bottom": "25px",
                          "pointer-events": _vm.showEmailCodeOptions
                            ? "all"
                            : "none"
                        }
                      },
                      [
                        _c(
                          "select",
                          {
                            ref: "emailOptions",
                            staticClass:
                              "form-control select select-dark select-block",
                            attrs: { "data-toggle": "select" }
                          },
                          _vm._l(_vm.emailCodeOptions, function(option) {
                            return _c(
                              "option",
                              { domProps: { value: option.val } },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(option.name) +
                                    "\n                "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: _vm.widgetCode,
                          expression: "widgetCode",
                          arg: "copy"
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:success",
                          value: _vm.onCopy,
                          expression: "onCopy",
                          arg: "success"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm btn-block",
                      on: {
                        click: function($event) {
                          return _vm.textSelect(_vm.$refs.widgetCode)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-clipboard",
                        staticStyle: { "margin-right": "10px" },
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Copy")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("pre", { ref: "widgetCode" }, [
                    _vm._v(_vm._s(_vm.widgetCode))
                  ])
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.codeType === "youtube",
                  expression: "codeType === 'youtube'"
                }
              ]
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-md-12",
                    staticStyle: {
                      "text-align": "center",
                      "padding-bottom": "25px"
                    }
                  },
                  [
                    _c(
                      "select",
                      {
                        ref: "youtubeOptions",
                        staticClass:
                          "form-control select select-dark select-block",
                        attrs: { "data-toggle": "select" }
                      },
                      _vm._l(_vm.youtubeCodeOptions, function(option) {
                        return _c(
                          "option",
                          { domProps: { value: option.val } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(option.name) +
                                "\n              "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _vm.formattedYoutubeCode.length > 5000
                    ? _c("div", { staticClass: "error-msg" }, [
                        _c("div", [
                          _vm._v("You have exceeded "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://youtube.googleblog.com/2010/09/top-tips-for-partners-words-words-words.html",
                                target: "_blank"
                              }
                            },
                            [_vm._v("YouTube's character limit")]
                          ),
                          _vm._v(" (5000 characters).\n              ")
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "Including a very large number of products will cause your list to be truncated."
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "Please remove some of the products you believe to be the least popular and try again.\n              "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: _vm.formattedYoutubeCode,
                          expression: "formattedYoutubeCode",
                          arg: "copy"
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:success",
                          value: _vm.onCopy,
                          expression: "onCopy",
                          arg: "success"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm btn-block",
                      on: {
                        click: function($event) {
                          return _vm.textSelect(_vm.$refs.youtubeCode)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-clipboard",
                        staticStyle: { "margin-right": "10px" },
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Copy")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.plan.youtube_code && !_vm.hasExpired
                    ? _c("pre", { ref: "youtubeCode" }, [
                        _vm._v(_vm._s(_vm.formattedYoutubeCode))
                      ])
                    : _vm._e()
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.codeType === "html",
                  expression: "codeType === 'html'"
                }
              ]
            },
            [
              _vm._l(_vm.htmlCode, function(item, index) {
                return _c(
                  "div",
                  {
                    ref: "htmlItems",
                    refInFor: true,
                    staticClass: "html-code"
                  },
                  [
                    _c("div", { staticClass: "thumb" }, [
                      _c(
                        "div",
                        { staticClass: "thumb_container" },
                        [
                          _c("image-square", {
                            attrs: {
                              src: item.thumb,
                              opacity: 0.7,
                              "spinner-color": "#bbb",
                              "spinner-side": "30px"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "name",
                              {
                                "not-editing hint--top hint--rounded": !item.editing
                              }
                            ],
                            staticStyle: { cursor: "text !important" },
                            attrs: { "aria-label": "click to edit" }
                          },
                          [
                            item.editing
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.name,
                                      expression: "item.name"
                                    }
                                  ],
                                  staticClass: "text",
                                  domProps: { value: item.name },
                                  on: {
                                    click: function($event) {
                                      _vm.$refs.htmlItems[index]
                                        .querySelector("input")
                                        .select()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        item,
                                        "name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _c(
                                  "div",
                                  {
                                    staticClass: "text",
                                    on: {
                                      click: function() {
                                        _vm.$set(item, "editing", true)
                                        _vm.$nextTick(function() {
                                          _vm.$refs.htmlItems[index]
                                            .querySelector("input")
                                            .select()
                                        })
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.name) +
                                        "\n              "
                                    )
                                  ]
                                ),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "fa fa-pencil edit",
                              staticStyle: { "pointer-events": "none" }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _vm.plan.html_links && !_vm.hasExpired
                          ? [
                              _c("div", { staticClass: "code-category" }, [
                                _vm._v("HTML link")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "html" }, [
                                _c(
                                  "pre",
                                  { staticStyle: { background: "#fff" } },
                                  [_vm._v(_vm._s(_vm.linksToHtml(item)))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:copy",
                                        value: _vm.linksToHtml(item),
                                        expression: "linksToHtml(item)",
                                        arg: "copy"
                                      }
                                    ],
                                    staticClass:
                                      "btn btn-primary btn-primary hint--top hint--rounded copy",
                                    attrs: { "aria-label": "copy" },
                                    on: {
                                      click: function($event) {
                                        _vm.textSelect(
                                          _vm.$refs.htmlItems[
                                            index
                                          ].querySelector("pre")
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "fa fa-clipboard",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("div", { staticClass: "code-category" }, [
                                _vm._v("link URL")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "clearText" },
                                _vm._l(item.links, function(link) {
                                  return _c("div", { staticClass: "link" }, [
                                    _c("label", [
                                      _vm._v(_vm._s(link.shop.name))
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "link-url" }, [
                                      _c(
                                        "pre",
                                        { staticStyle: { background: "#fff" } },
                                        [_vm._v(_vm._s(link.url))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value: link.url,
                                              expression: "link.url",
                                              arg: "copy"
                                            }
                                          ],
                                          staticClass:
                                            "btn btn-primary btn-primary hint--top hint--rounded copy",
                                          attrs: { "aria-label": "copy" },
                                          on: {
                                            click: function($event) {
                                              return _vm.textSelect(
                                                $event.target
                                                  .previousElementSibling
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "fa fa-clipboard",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        ]
                                      )
                                    ])
                                  ])
                                }),
                                0
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c("br")
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.codeType === "wordpresscom",
                  expression: "codeType === 'wordpresscom'"
                }
              ]
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: _vm.wordpressCode,
                          expression: "wordpressCode",
                          arg: "copy"
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:success",
                          value: _vm.onCopy,
                          expression: "onCopy",
                          arg: "success"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm btn-block",
                      on: {
                        click: function($event) {
                          return _vm.textSelect(_vm.$refs.wordpressCode)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-clipboard",
                        staticStyle: { "margin-right": "10px" },
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Copy")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("pre", { ref: "wordpressCode" }, [
                    _vm._v(_vm._s(_vm.wordpressCode))
                  ])
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.codeType === "shortcode",
                  expression: "codeType === 'shortcode'"
                }
              ]
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: _vm.shortCode,
                          expression: "shortCode",
                          arg: "copy"
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:success",
                          value: _vm.onCopy,
                          expression: "onCopy",
                          arg: "success"
                        }
                      ],
                      staticClass: "btn btn-primary btn-sm btn-block",
                      on: {
                        click: function($event) {
                          return _vm.textSelect(_vm.$refs.shortCode)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-clipboard",
                        staticStyle: { "margin-right": "10px" },
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Copy")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("pre", { ref: "shortCode" }, [
                    _vm._v(_vm._s(_vm.shortCode))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br")
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            title: "Append template",
            "ok-text": "Append",
            show: _vm.templateAppendShow,
            small: true,
            "ok-class": _vm.appendTemplateClass,
            loading: _vm.templateAppendLoading
          },
          on: { ok: _vm.appendTemplate, cancel: _vm.closeAppendTemplate }
        },
        [
          _c("div", [
            _c("label", [_vm._v("Select template")]),
            _vm._v(" "),
            _c(
              "select",
              {
                ref: "templateSelect",
                staticClass: "form-control select select-primary mbl",
                staticStyle: { width: "100%" },
                attrs: { "data-toggle": "select", disabled: !_vm.hasTemplates }
              },
              [
                _c(
                  "option",
                  { staticClass: "hidden", attrs: { selected: "" } },
                  [_vm._v("no template")]
                ),
                _vm._v(" "),
                _vm._l(_vm.templates, function(template, index) {
                  return [
                    template
                      ? _c("option", { domProps: { value: index } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(template) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col-md-12",
                  staticStyle: { "margin-top": "-12px", "font-size": "12px" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "pull-right",
                      attrs: {
                        href: "/page/how-to-use-a-template-list",
                        target: "_blank"
                      }
                    },
                    [_vm._v("How do I create a\n              template?")]
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            title: "Save as template",
            "ok-text": "Save",
            show: _vm.templateShow,
            small: true,
            "ok-class": "btn-warning"
          },
          on: { ok: _vm.convertToTemplate, cancel: _vm.closeConvertToTemplate }
        },
        [
          _c("div", [
            _vm._v("\n        Convert "),
            _c("b", [_vm._v(_vm._s(_vm.collection.title))]),
            _vm._v(" to a template?\n      ")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            title: "Clone list",
            "ok-text": "Save",
            show: _vm.cloneModalShow,
            small: true,
            "ok-class": "btn-warning"
          },
          on: { ok: _vm.cloneList, cancel: _vm.closeCloneList }
        },
        [
          _c("div", [
            _vm._v("\n        Clone "),
            _c("b", [_vm._v(_vm._s(_vm.collection.title))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group inline-form" }, [
            _c("br"),
            _vm._v(" "),
            _c("label", [_vm._v("New list name")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.cloneName,
                  expression: "cloneName"
                }
              ],
              staticClass: "form-control new-clone-name",
              attrs: {
                type: "text",
                name: "cloner",
                placeholder: "Clone name"
              },
              domProps: { value: _vm.cloneName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.cloneName = $event.target.value
                }
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            title: "Revert template",
            "ok-text": "Revert",
            show: _vm.templateUnsetShow,
            small: true,
            "ok-class": "btn-warning"
          },
          on: { ok: _vm.convertToList, cancel: _vm.closeConvertToList }
        },
        [
          _c("div", [
            _vm._v("\n        Revert "),
            _c("b", [_vm._v(_vm._s(_vm.collection.title))]),
            _vm._v(" back to a list?\n      ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-default dropdown-toggle",
        attrs: {
          type: "button",
          id: "dropdownMenu1",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "true"
        }
      },
      [_c("i", { staticClass: "fa fa-bars", attrs: { "aria-hidden": "true" } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }