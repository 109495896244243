var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      style: {
        "pointer-events": _vm.waiting ? "none" : "auto",
        "margin-top": "35px"
      }
    },
    [
      _c("div", { staticClass: "title" }, [
        _c("span", { staticClass: "overview" }, [
          _vm._v("Overview\n            "),
          _c(
            "select",
            {
              ref: "selectOptions",
              staticClass: "form-control select select-dark select-block",
              staticStyle: { "margin-left": "10px" },
              attrs: { "data-toggle": "select" }
            },
            [
              _c("option", { attrs: { value: "leads" } }, [
                _vm._v("Product leads")
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "efficiency" } }, [
                _vm._v("Peak hours")
              ]),
              _vm._v(" "),
              _vm.performances
                ? _c("option", { attrs: { value: "performance" } }, [
                    _vm._v(
                      "\n                    Lists performance\n                "
                    )
                  ])
                : _vm._e()
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "time" }, [
          _c("span", { staticClass: "range-text" }, [
            _vm._v(_vm._s(_vm.customRangeText))
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "hint--top hint--rounded hint--small",
              class: ["calendar", { active: _vm.range === "custom" }],
              style: {
                "pointer-events": _vm.dateSelectOpened ? "none" : "all"
              },
              attrs: { "aria-label": "custom range" }
            },
            [
              _c("span", { staticClass: "fa fa-calendar" }),
              _vm._v(" "),
              _c("input", { attrs: { id: "calendar-range" } })
            ]
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "nav nav-pills static" }, [
            _c(
              "li",
              {
                class: { active: _vm.range === "day" },
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function($event) {
                    return _vm.fetchStats("day")
                  }
                }
              },
              [_c("a", [_vm._v("day")])]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                class: { active: _vm.range === "week" },
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function($event) {
                    return _vm.fetchStats("week")
                  }
                }
              },
              [_c("a", [_vm._v("week")])]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                class: { active: _vm.range === "month" },
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function($event) {
                    return _vm.fetchStats("month")
                  }
                }
              },
              [_c("a", [_vm._v("month")])]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "chart chart-overview noselect" },
        [
          _vm.overviewSelectedOption === "leads"
            ? [
                _c("div", { staticClass: "title" }, [_vm._v("Product leads")]),
                _vm._v(" "),
                !_vm.updating && !_vm.lineChartUpdating
                  ? _c("line-chart", {
                      attrs: {
                        data: _vm.overviewData,
                        options: _vm.lineChartOptions
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.overviewSelectedOption === "efficiency"
            ? [
                _c("div", { staticClass: "title" }, [_vm._v("Peak hours")]),
                _vm._v(" "),
                !_vm.updating
                  ? _c("bar-chart", {
                      attrs: {
                        data: _vm.metricsData,
                        options: _vm.barChartOptions
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.overviewSelectedOption === "performance"
            ? [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Lists performance")
                ]),
                _vm._v(" "),
                !_vm.updating && !_vm.lineChartUpdating
                  ? _c("line-chart", {
                      attrs: {
                        data: _vm.performanceData,
                        options: _vm.lineChartOptions
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "chart" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("Most popular")]),
              _vm._v(" "),
              _vm.overview.length
                ? _vm._l(_vm.productClicks, function(product) {
                    return _c(
                      "div",
                      {
                        key: product.id,
                        staticClass: "product",
                        on: {
                          click: function($event) {
                            return _vm.previewLink(product.id)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "thumb-wrapper" },
                          [
                            _c(
                              "div",
                              { staticClass: "thumb" },
                              [
                                _c("image-square", {
                                  attrs: {
                                    src: product.thumb_path,
                                    alt: product.title,
                                    opacity: 0.7,
                                    "spinner-color": "#16a085",
                                    "spinner-side": "20px"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("logo-image", {
                              attrs: {
                                src: product.shop_icon,
                                side: "22px",
                                "extra-classes": "shop"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(product.title))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "clicks" }, [
                          _c("div", { staticClass: "text" }, [_vm._v("leads")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "number" }, [
                            _vm._v(_vm._s(product.clicks))
                          ])
                        ])
                      ]
                    )
                  })
                : _c("div", { staticClass: "no-clicks" }, [
                    _vm._v(
                      "\n                    No leads during this period\n                "
                    )
                  ])
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "chart noselect" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("Leads origin")]),
              _vm._v(" "),
              _vm.overview.length
                ? [
                    !_vm.updating
                      ? _c("doughnut-chart", {
                          attrs: {
                            data: _vm.originData,
                            options: _vm.doughnutChartOptions
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    !_vm.updating
                      ? _c("horizontal-bar-chart", {
                          attrs: {
                            data: _vm.shopData,
                            options: _vm.horizontalBarChartOptions
                          }
                        })
                      : _vm._e()
                  ]
                : _c("div", { staticClass: "no-clicks" }, [
                    _vm._v(
                      "\n                    No leads during this period\n                "
                    )
                  ])
            ],
            2
          ),
          _vm._v(" "),
          _vm.isTeam
            ? _c(
                "div",
                { staticClass: "chart noselect" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("Member leads")]),
                  _vm._v(" "),
                  _vm.overview.length
                    ? [
                        !_vm.updating
                          ? _c("horizontal-bar-chart", {
                              attrs: {
                                data: _vm.efficiencyData,
                                options: _vm.horizontalBarChartOptions
                              }
                            })
                          : _vm._e()
                      ]
                    : _c("div", { staticClass: "no-clicks" }, [
                        _vm._v(
                          "\n                    No leads during this period\n                "
                        )
                      ])
                ],
                2
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            title: "Preview link",
            "cancel-text": "Close",
            show: _vm.previewModalShow,
            "ok-class": "hidden"
          },
          on: {
            cancel: function($event) {
              _vm.previewModalShow = false
            }
          }
        },
        [
          _c("div", { staticClass: "affiliate-title" }, [
            _c("b", [_vm._v("Affiliate URL")]),
            _vm._v(" "),
            _c("span", [_vm._v("(click to visit)")])
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "affiliate-link",
              attrs: { href: "/previewlink/" + _vm.productId, target: "_blank" }
            },
            [_c("pre", [_vm._v(_vm._s(_vm.affiliateLink))])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                color: "#c0392b",
                "font-size": "12px",
                "text-align": "center",
                "margin-top": "10px",
                "line-height": "1.3"
              }
            },
            [
              _c("div", [
                _vm._v("The link above is for preview purposes only.")
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("Please do not copy/paste it in your blog.")])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }