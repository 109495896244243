<!--Template-->

<template>
    <modal :title="title"
           :show="show"
           :full="true"
           @cancel="cancel"
    >
        <!--////////////////// EDW EINAI TO ZOUMI -->

        <div v-show="currentMode === 'user'">
            <div class="form-group search-tags">
                <div class="input-group input-group input-group-rounded">
                    <span class="input-group-btn">
                        <button type="submit" class="btn">
                            <span class="fui-search"></span>
                        </button>
                    </span>
                    <input v-model="search"
                           class="form-control"
                           type="text"
                           placeholder="Search tags"
                    >
                </div>
            </div>

            <div :class="[ 'tags-wrapper', { 'empty' : !tags.length } ]">
                <scrollbar type="macosx">
                    <div :class="[ 'user-tags', { 'empty' : !tags.length } ]">
                        <span v-if="!tags.length">
                            <div>No tags have been created yet.</div>
                            <div class="smaller">Your product tags will show up here.</div>
                        </span>

                        <div v-for="tag in filteredTags"
                             class="tag"
                        >
                            <!-- Prepend -->
                            <input v-model="tag.name"
                                   ref="tagname"
                                   :class="[ 'form-control', 'input-sm', { 'disabled': !tag.editing } ]"
                                   @keyup.enter="updateTag(tag)"
                            />

                            <div class="buttons">
                                <button v-if="tag.editing"
                                        class="btn btn-primary hint--top hint--rounded"
                                        aria-label="save"
                                        @click="updateTag(tag)"
                                >
                                    <span class="fa fa-save"></span>
                                </button>

                                <button v-else
                                        class="btn btn-warning hint--top hint--rounded"
                                        aria-label="edit"
                                        @click="editTag(tag.id)"
                                >
                                    <span class="fa fa-pencil"></span>
                                </button>

                                <button :tag-id="tag.id"
                                        class="btn btn-danger hint--top hint--rounded"
                                        aria-label="delete"
                                        @click="deleteTag(tag.id)"
                                >
                                    <span class="fa fa-trash-o"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </scrollbar>
            </div>
        </div>

        <div v-show="currentMode === 'product'">
            <tag-select-box v-show="linkTags.length"
                            owner="link"
                            :linkid="productId"
                            :show="true"
                            :link-tags="linkTags"
                            @selectionchanged="onLinkTagsChange"
            ></tag-select-box>

            <div v-show="!linkTags.length"
                 style="text-align: center; color: #888; height: 73px; padding: 25px;"
            >
                This product has no tags
            </div>

            <div class="add-tag">
                <button v-if="!addingTag"
                        class="btn btn-primary btn-sm"
                        @click="addTagClicked"
                >
                    <span class="fa fa-plus"></span>Add new
                </button>

                <div v-else
                     class="new-tag"
                >
                    <input type="text"
                           class="form-control input-sm"
                           placeholder="Your new tag"
                           v-model="tagName"
                           ref="newTag"
                           @keyup.enter="addTag"
                    />

                    <div class="input-group">
                        <button :class="[ 'btn', 'btn-primary', 'hint--top', 'hint--rounded', { 'unabled': !tagName.length } ]"
                                aria-label="save"
                                @click="addTag"
                        >
                            <span class="fa fa-check"></span>
                        </button>
                        <button class="btn btn-darkgray hint--top hint--rounded"
                                aria-label="close"
                                @click="cancelAddTag"
                        >
                            <span class="fa fa-times"></span>
                        </button>
                    </div>
                </div>
            </div>

            <hr>

            <div class="form-group search-tags">
                <div class="input-group input-group input-group-rounded">
                    <span class="input-group-btn">
                        <button type="submit" class="btn">
                            <span class="fui-search"></span>
                        </button>
                    </span>
                    <input v-model="search"
                           class="form-control"
                           type="text"
                           placeholder="Search tags"
                    >
                </div>
            </div>

            <div :class="[ 'tags-wrapper for-link', { 'empty' : !tags.length } ]">
                <scrollbar type="macosx">
                    <div :class="[ 'user-tags', { 'empty' : !tags.length } ]">
                        <span v-if="!tags.length">
                            <div>No tags have been created yet.</div>
                            <div class="smaller">Your product tags will show up here.</div>
                        </span>

                        <div v-for="tag in filteredTags"
                             :class="[ 'tag', { 'selectable': !tag.editing, 'selected': belongsToLink(tag) } ]"
                             @click="toggleSelectTag(tag)"
                        >
                            <!-- Prepend -->
                            <input v-model="tag.name"
                                   ref="linkTagname"
                                   :class="[ 'form-control', 'input-sm', { 'disabled': !tag.editing } ]"
                                   @keyup.enter="updateTag(tag)"
                                   @click.stop
                            />

                            <div class="buttons">
                                <button v-if="tag.editing"
                                        class="btn btn-primary hint--top hint--rounded"
                                        aria-label="save"
                                        @click.stop="updateTag(tag)"
                                >
                                    <span class="fa fa-save"></span>
                                </button>

                                <button v-else
                                        class="btn btn-warning hint--top hint--rounded"
                                        aria-label="edit"
                                        @click.stop="editTag(tag.id)"
                                >
                                    <span class="fa fa-pencil"></span>
                                </button>

                                <button :tag-id="tag.id"
                                        class="btn btn-danger hint--top hint--rounded"
                                        aria-label="delete"
                                        @click.stop="deleteTag(tag.id)"
                                >
                                    <span class="fa fa-trash-o"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </scrollbar>
            </div>
        </div>

        <div slot="footer">
            <button v-if="currentMode === 'user'"
                    class="btn btn-embossed btn-darkgray"
                    @click="cancel"
            >
                Close
            </button>

            <template v-else>
                <button :class="[ 'btn', 'btn-embossed', 'btn-primary', { 'unabled': !linkTagsCanSave } ]"
                        @click="save"
                >
                    Save
                </button>

                <button class="btn btn-embossed btn-darkgray"
                        @click="cancel"
                >
                    Close
                </button>
            </template>
        </div>
    </modal>
</template>


<!--Script-->
<script   >
    import TagSelectBox from './TagSelectBox.vue';
    import Modal from '../lib/components/Modal';
    import Scrollbar from '../lib/components/Scrollbar';

    export default{
        props: {
            productId: null,
            show: false
        },

        data(){
            return {
                linkTags: [],
                linkTagsInitial: [],
                addingTag: false,
                tagAddStatusOK: true,
                tagName: '',
                search: ''
            }
        },

        computed: {
            currentMode() {
                if (this.productId)
                    return 'product';
                else
                    return 'user';
            },
            title() {
                return this.currentMode === 'product' ? 'Product Tags' : 'Manage all Tags';
            },
            filteredTags() {
                return _.filter(this.tags, (tag) => {
                    return _.includes(tag.name, this.search);
                });
            },
            tags: {
                get: function() {
                    return  this.$store.getters.getUserTags;
                },
                set: function(val) {
                    this.$store.dispatch('setUserTags', val);
                }
            },
            linkTagsCanSave() {
                return !_.isEqual(this.linkTags, this.linkTagsInitial);
            }
        },

        created() {
            this.loadTags();
        },

        methods: {
            tag(id) {
                return _.find(this.tags, (tag) => {
                    return tag.id === id;
                })
            },
            tagIndex(id) {
                return _.findIndex(this.tags, (tag) => {
                    return tag.id === id;
                })
            },
            tagInput(id) {
                switch (this.currentMode)
                {
                    case 'user':
                        return this.$refs.tagname[this.tagIndex(id)];
                    case 'product':
                        return this.$refs.linkTagname[this.tagIndex(id)];
                }
            },

            loadTags(includeLinkTags = true, setLinkTagsInitial = false) {
                return new Promise((resolve, reject) => {
                    if (this.productId) {
//                    console.log('loading product tags with user tags');
                        if (includeLinkTags)
                            this.linkTags = [];

                        this.$http.get(`/api/links/${ this.productId }/tags`).then(
                            (response) => {
                                this.tags = response.body.user;
                                if (includeLinkTags)
                                {
                                    this.linkTags = response.body.link;

                                    if (setLinkTagsInitial)
                                        this.linkTagsInitial = _.cloneDeep(this.linkTags);
                                }
//                            console.log(this.linkTags);

                                this.$events.emit('tags-loaded');
                                resolve(this.tags);
                            });
                    } else {
//                    console.log('loading just user tags');

                        this.$http.get('/api/user/tags').then(
                            (response) => {
                                this.tags = response.body;

                                this.$events.emit('tags-loaded');
                                resolve(this.tags);
                            }
                        )
                    }
                });
            },

            save() {
                this.$http.put(`/api/links/${ this.productId }/tags`, {
                    tags: this.linkTags
                }).then(
                    (response) => {
                        if (response.body === 'ok') {
                            this.$emit('cancel');
                        }
                    }
                );
            },

            cancel()
            {
                this.$emit('cancel');

                setTimeout(() => {
                    this.search = '';
                    this.tags = _.map(this.tags, (tag) => {
                        if (tag.editing)
                        {
                            tag.name = tag.previousName;
                            tag.editing = false;
                        }

                        return tag;
                    })
                }, 500);
            },

            editTag(tagId)
            {
                let previousTag = _.find(this.tags, (tag) => {
                        return tag.editing;
                    }),
                    currentTag = this.tag(tagId);

                this.$set(currentTag, 'editing', true);
                this.$set(currentTag, 'previousName', currentTag.name);
                this.tagInput(tagId).select();

                if (previousTag) {
                    this.$set(previousTag, 'name', previousTag.previousName);
                    this.$set(previousTag, 'editing', false);
                }
            },

            updateTag(tag)
            {
                this.$set(tag, 'editing', false);

                if (tag.name === tag.previousName)
                    return;

                this.$http.put(`/api/user/tags/${ tag.id }`, {
                    tagtext: tag.name
                }).then(
                    (response) => {
                        swal({
                            title: "Saved!",
                            text: `${ tag.name } has been saved.`,
                            type: "success",
                            timer: 1000,
                            showConfirmButton: false
                        });

                        this.$events.emit('tag-edited', tag);
                    }, (error) => {
                        swal({
                            title: "Error!",
                            text: `There was an error saving the tag: ${ error }`,
                            type: "error",
                            showConfirmButton: false
                        });
                    });
            },

            addTagClicked() {
                this.addingTag = true;

                this.$nextTick(() => {
                    this.$refs.newTag.focus();
                })
            },

            addTag()
            {
                this.$http.post('/api/user/tags', {
                    tagtext: this.tagName
                }).then(
                    (response) => {
                        if (response.body == 'ok') {
                            swal({
                                title: "Added!",
                                text: "The tag has been added.",
                                type: "success",
                                timer: 1000,
                                showConfirmButton: false
                            });

                            this.tagName = '';
                            this.addingTag = false;

                            this.loadTags(false).then((tags) => {
                                this.toggleSelectTag(tags[tags.length - 1]);
                            });
                        } else {
                            swal({
                                title: "Error!",
                                text: `There was an error adding the tag: ${ response.body }`,
                                type: "error",
                                showConfirmButton: false
                            });
                        }
                    }
                )
            },

            cancelAddTag() {
                this.addingTag = false;
            },

            deleteTag(tagId)
            {
                swal({
                        title: "Are you sure?",
                        text: "Remove this tag?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Remove",
                        closeOnConfirm: false,
                        showLoaderOnConfirm: true
                    },
                    () => {
                        this.$http.delete('/api/user/tags/' + tagId).then(
                            (response) => {
                                if (response.body == 'deleted') {
                                    swal({
                                        title: "Removed!",
                                        text: "The tag has been removed.",
                                        type: "success",
                                        timer: 1000,
                                        showConfirmButton: false
                                    });

                                    this.loadTags(false);
                                } else
                                    swal({
                                        title: "Error!",
                                        text: "Some error occurred. Please refresh page.",
                                        type: "error",
                                        showConfirmButton: false
                                    });
                            }, (response) => {
                                swal({
                                    title: "Error!",
                                    text: "Some error occurred. Please refresh page.",
                                    type: "error",
                                    showConfirmButton: false
                                });
                            });
                    });
            },

            toggleSelectTag(tag) {
                if (this.belongsToLink(tag))
                    this.linkTags = _.reject(this.linkTags, (linkTag) => {
                        return linkTag === tag.id;
                    });
                else
                    this.linkTags.push(tag.id);
            },

            belongsToLink(tag) {
                let bool = false;

                _.each(this.linkTags, (linkTag) => {
                    if (linkTag === tag.id)
                    {
                        bool = true;
                        return false;
                    }
                });

                return bool;
            },

            onLinkTagsChange(selection) {
                this.linkTags = _.map(selection, (linkId) => {
                    return parseInt(linkId);
                });
            }
        },

        components: {
            'tag-select-box': TagSelectBox,
            'modal': Modal,
            'scrollbar': Scrollbar
        },

        watch: {
            show: function(bool) {
                if (bool && this.productId)
                    this.loadTags(true, true);
            }
        }
    }
</script>

<!--Style-->
<style lang='less' scoped>
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    .add-tag {
        padding-top: 20px;
        margin-bottom: -8px;
        display: inline-block;
        width: 100%;

        > button {
            float: right;

            > span {
                padding-right: 10px;
                padding-top: 3px;
                color: fadeout(#fff, 25%);
            }
        }

        > .new-tag {
            display: inline-block;
            margin-bottom: -7px;
            width: 100%;

            > input {
                width: ~'calc(100% - 86px)';
                float: left;
                font-size: 13px;
            }

            > .input-group {
                width: 86px;
                float: right;
                padding-top: 1px;
                padding-left: 4px;

                > button {
                    .block-round;

                    margin-left: 8px;
                    width: 33px;
                    height: 33px;
                    position: relative;
                    float: left;
                    padding: 0;

                    &.unabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }

                    > span {
                        .centered-block;

                        font-size: 11px;
                        line-height: 1;
                    }
                }
            }
        }
    }

    hr {
        border-color: fadeout(#000, 95%);
        border-width: 2px;
    }

    .search-tags {

        > .input-group {

            > input,
            > span > button {
                background: #eee !important;
                border: none;
            }

            > input {
                padding-left: 5px !important;
                font-size: 15px;
            }
        }

        margin-bottom: 10px;
    }

    .tags-wrapper {
        position: relative;
        height: 310px;

        &.for-link,
        &.empty {
            height: 170px;
        }
    }

    .user-tags {
        padding: 0 15px;
        margin-bottom: 15px;
        padding-top: 35px;
        position: relative;

        &.empty {
            height: 170px;
        }

        > span {
            .centered-block;

            font-size: 17px;
            color: #777;
            text-align: center;
            width: 100%;

            > .smaller {
                font-size: 14px;
            }
        }

        > .tag {
            @item-height: 30px;

            display: inline-block;
            width: 100%;
            padding: 5px;
            background: #f5f5f5;
            border-radius: 5px;

            &.selectable {
                cursor: pointer;
                transition: background 0.3s ease;

                &:hover {
                    background: darken(#f5f5f5, 5%);
                }
            }

            &.selected {
                background: mix(@brand-secondary, #fff, 15%) !important;

                > input {
                    color: @brand-secondary;
                }
            }

            > input {
                float: left;
                width: ~'calc(100% - 90px)';
                font-size: 15px;
                font-weight: bold;
                border-color: transparent;
                background: #fff;
                color: #555;
                height: @item-height;
                transition: none;

                &.disabled {
                    pointer-events: none;
                    background: transparent;
                }
            }

            > .buttons {
                float: right;

                > button {
                    .block-round;

                    position: relative;
                    width: @item-height;
                    height: @item-height;
                    font-size: 10px;
                    margin-left: 2px;
                    padding: 0;

                    > span {
                        .centered-block;
                    }
                }
            }
        }
    }

    .modal-footer {
        button {
            &.unabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
</style>
