<!--Template-->
<template>
  <div :class="[ 'collection', { 'adding-products': addingProducts, 'is-template': isTemplate } ]"
       @click="collectionClicked"
  >
    <div :id="'Collection-' + collection.id"
         class="oneProductList well"
         :data-name="collection.title"
         :data-id="collection.id"
    >
      <div class="name">
                <span v-if="!editName"
                      @click="clickedListName"
                >
                    <span class="title">
                        <span ref="title">{{ collection.title }}</span><span class="fa fa-pencil"></span>
                    </span>
                </span>

        <span v-if="editName"
              @keyup.enter="changedListName"
              v-on-clickaway="changedListName"
        >
                    <input v-model="tempTitle"
                           ref="titleInput"
                           :disabled="savingNewName"
                           type="text"
                           :style="{
                               'max-width': 'calc(100% - 120px)',
                               'width': '100%'
                           }"
                    >

                    <span v-if="savingNewName"
                          class="loading"
                    >
                        <loader side="21px"
                                :opacity="1"
                                color="#16a085"
                        ></loader>
                    </span>

                    <span v-else
                          class="btn btn-primary"
                          @click="changedListName"
                    >
                        <span class="fa fa-check"></span>
                    </span>
                </span>
      </div>

      <div class="date">
        <span>{{ creationDate }}</span>
      </div>

      <div v-if="!isTemplate" class="link">
        <!--SHOWN ON BIG SCREENS-->
        <div class="hidden-xs hidden-sm">
          <a class="btn btn-lg hint--top hint--rounded gray-button"
             :style="{ 'pointer-events': disableLinkShow ? 'none' : 'all' }"
             aria-label="get link to product list"
             @click.prevent="showLink"
          >
            <span class="fa fa-link"></span>
          </a>
          <a class="btn btn-lg hint--top hint--rounded gray-button"
             :style="{ 'pointer-events': disableLinkShow ? 'none' : 'all' }"
             aria-label="printable list"
             target="_blank"
             :href="'/lists/' + this.collection.id + '/printable'"
          >
            <span class="fa fa-print"></span>
          </a>
<!--          <a class="btn btn-lg hint&#45;&#45;top hint&#45;&#45;rounded gray-button"-->
<!--             :style="{ 'pointer-events': disableLinkShow ? 'none' : 'all' }"-->
<!--             aria-label="share on pinterest"-->
<!--             target="_blank"-->
<!--             :href="pinterestLink"-->
<!--          >-->
<!--            <span class="fa fa-pinterest"></span>-->
<!--          </a>-->
        </div>

        <!--DROPDOWN MENU FOR SMALLER SCREENS-->
        <div class="dropdown visible-xs visible-sm">
          <button class="btn btn-default dropdown-toggle"
                  type="button"
                  id="dropdownMenu1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
          >
            <i class="fa fa-bars" aria-hidden="true"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
            <li>
              <a @click.prevent="showLink" target="_blank">
                <i class="fa fa-link"></i> List shortlink
              </a>
            </li>
            <li>
              <a :href="'/lists/' + this.collection.id + '/printable'" target="_blank">
                <i class="fa fa-print"></i> Printable list
              </a>
            </li>
            <!--                        <li>-->
            <!--                            <a :href="pinterestLink" target="_blank">-->
            <!--                                <i class="fa fa-pinterest-p"></i> Share on pinterest-->
            <!--                            </a>-->
            <!--                        </li>-->
          </ul>
        </div><!-- /.dropdown -->
      </div><!-- /.link -->


      <product-list-preview v-if="!addingProducts"
                            :links="collection.links"
      ></product-list-preview>

      <br v-else/>
    </div>

    <div class="buttons">
      <div class="bottom-wrapper">
        <div class="favourite">
          <div :class="[ 'favorite', 'hint--top', 'hint--rounded', { 'marked': isFavorite } ]"
               :aria-label="!isFavorite ? 'mark as favorite' : 'unmark favorite'"
               @click="toggleFavorite"
          >
            <span class="fa fa-star"></span>
          </div>
        </div>

        <div class="collection-actions">
          <div class="visible-xs visible-sm">
            <button @click="mobileMenu = !mobileMenu"
                    class="btn btn-default btn-sm mobile-button">
              ACTIONS
            </button>
          </div>

          <div class="hidden-xs hidden-sm">
            <div class="wrapper">
              <!--PREVIEW-->
              <a :href="'/view/' + collection.uuid"
                 target="_blank"
                 aria-label="preview"
                 class="btn btn-sm btn-primary hint--top hint--rounded"
              >
                <i class="fa fa-eye"></i>
              </a>
              <!--EDIT-->
              <a v-if="!addingProducts"
                 :href="'/lists/' + collection.id"
                 aria-label="edit"
                 class="btn btn-sm btn-primary hint--top hint--rounded"
              >
                <i class="fa fa-pencil"></i>
              </a>
              <!--CODE-->
              <a v-if="!isTemplate"
                 href="#"
                 @click.prevent="showCode"
                 aria-label="web code"
                 class="btn btn-sm btn-primary hint--top hint--rounded"
                 :style="{ 'pointer-events': disableCodeShow ? 'none' : 'all' }"
              >
                <i class="fa fa-code"></i>
              </a>

              <!--STATS-->
              <a v-if="!isTemplate"
                 :href="'/lists/' + collection.id + '/stats'"
                 aria-label="statistics"
                 class="btn btn-sm btn-primary hint--top hint--rounded"
              >
                <i class="fa fa-line-chart"></i>
              </a>

              <!--STYLE-->
              <a v-if="addingProducts"
                 aria-label="style"
                 class="btn btn-sm btn-darkgray hint--top hint--rounded"
                 @click.prevent="styleList"
                 href="#"
              >
                <i class="fa fa-paint-brush"></i>
              </a>

              <!--CLONE-->
              <a v-if="!addingProducts && !isTemplate"
                 @click.prevent="showCloneModal"
                 aria-label="clone"
                 class="btn btn-sm btn-warning hint--top hint--rounded"
                 href="#"
              >
                <i class="fa fa-clone"></i>
              </a>


              <!--TEMPLATE-->
              <a v-if="isTemplate"
                 @click.prevent="templateUnsetShow = true"
                 aria-label="revert template"
                 class="btn btn-sm btn-warning hint--top hint--rounded hint--small"
                 href="#"
              >
                <i class="fa fa-ban"
                   style="font-size: 12px"
                ></i>
              </a>

              <!--DELETE-->
              <a v-if="!addingProducts"
                 @click.prevent="popupModalForDelete"
                 aria-label="remove"
                 class="btn btn-sm btn-danger hint--top hint--rounded"
                 href="#"
              >
                <i class="fa fa-trash"></i>
              </a>


              <!--TEMPLATE-->
              <a v-if="addingProducts && !isTemplate"
                 @click.prevent="popupModalForTemplateAppend"
                 aria-label="append template"
                 class="btn btn-sm btn-warning already-template hint--top hint--rounded hint--small"
                 href="#"
              >
                <i class="fa fa-paperclip fa-lg"></i>
              </a>

              <a v-if="addingProducts && !isTemplate"
                 @click.prevent="popupModalForTemplate"
                 aria-label="set as template"
                 class="btn btn-sm btn-warning already-template hint--top hint--rounded hint--small"
                 href="#"
              >
                <i class="fa fa-file"></i>
              </a>
            </div><!-- /.wrapper -->
          </div>
        </div>
      </div>

      <div :class="['mobile-actions', {'hidden': !mobileMenu, 'visible-xs': mobileMenu, 'visible-sm': mobileMenu}]">
        <ul>
          <!--PREVIEW-->
          <li>
            <a :href="'/view/' + collection.uuid" target="_blank">
              <i class="fa fa-eye"></i> Preview
            </a>
          </li>
          <!--EDIT-->
          <li v-if="!addingProducts">
            <a :href="'/lists/' + collection.id">
              <i class="fa fa-pencil"></i> Edit
            </a>
          </li>
          <!--CODE-->
          <li v-if="!isTemplate">
            <a @click.prevent="showCode" href="#">
              <i class="fa fa-code"></i> Get Code
            </a>
          </li>
          <!--STATS-->
          <li v-if="!isTemplate">
            <a :href="'/lists/' + collection.id + '/stats'" target="_blank">
              <i class="fa fa-line-chart"></i> Statistics
            </a>
          </li>
          <!--STYLE-->
          <li v-if="addingProducts">
            <a @click.prevent="styleList" href="#">
              <i class="fa fa-paint-brush"></i> Style
            </a>
          </li>
          <!--CLONE-->
          <li v-if="!addingProducts && !isTemplate">
            <a @click.prevent="showCloneModal" href="#">
              <i class="fa fa-clone"></i> Clone
            </a>
          </li>
          <!--SET TEMPLATE-->
          <li v-if="addingProducts && !isTemplate">
            <a @click.prevent="popupModalForTemplate" href="#">
              <i class="fa fa-file"></i> Set as template
            </a>
          </li>
          <!--REVERT TEMPLATE-->
          <li v-if="isTemplate">
            <a @click.prevent="templateUnsetShow = true"
               href="#"
            >
              <i class="fa fa-ban"></i> Revert template
            </a>
          </li>
          <!--APPEND TEMPLATE-->
          <li v-if="addingProducts && !isTemplate">
            <a @click.prevent="popupModalForTemplateAppend" href="#">
              <i class="fa fa-paperclip fa-lg"></i> Append template
            </a>
          </li>
          <!--DELETE-->
          <li v-if="!addingProducts">
            <a @click.prevent="popupModalForDelete" href="#">
              <i class="fa fa-trash"></i> Remove
            </a>
          </li>

          <li class="closeMenu">
            <a href="#" @click.prevent="mobileMenu = !mobileMenu">
              <!--<i class="fa fa-caret-up"></i>-->
              close
            </a>
          </li>
        </ul>
      </div>
      <!--/.mobile-actions-->
    </div>


    <modal ok-text="Delete"
           :show="modalShow"
           :small="true"
           ok-class="btn-danger"
           @ok="remove"
           @cancel="cancel"
    >
      <div>Remove {{ collection.title }}?</div>

      <div slot="title">Remove {{ !isTemplate ? 'list' : 'template' }}</div>
    </modal>


    <!-- --------------------  GET LINK MODAL  ---------------------------- -->

    <modal id="link-code"
           title="Get link"
           :show="linkModalShow"
           ok-class="hidden"
           cancel-text="Close"
           @cancel="closeLinkModal"
    >
      <div class="text">
        <div>Use the link below to visit the list directly.</div>
      </div>

      <div class="link-preview copy-url">
        <div class="title">link preview</div>
        <div class="preview"
             ref="shortLink"
        >
          {{ shortLink }}
        </div>
        <button aria-label="copy"
                class="btn btn-primary btn-embossed hint--top hint--rounded"
                v-clipboard:copy="shortLink"
                @click="textSelect($refs.shortLink)"
        >
          <span class="fa fa-clipboard"></span>
        </button>
      </div>

      <div style="margin-top: 20px;">
        <a :href="facebookLink" target="_blank" class="btn btn-embossed" style="color: #3b5998; margin-right: 20px;">
          <i class="fa fa-facebook" style="font-size: 16px; margin-right: 8px;"></i>
          Share on Facebook
        </a>
        <a :href="pinterestLink" target="_blank" class="btn btn-embossed" style="color: #c8232c;">
          <i class="fa fa-pinterest" style="font-size: 16px; margin-right: 8px;"></i>
          Share on Pinterest
        </a>
      </div>
    </modal>


    <!-- --------------------  GRAB THE CODE MODAL ---------------------------- -->
    <modal id="web-code"
           title="Grab the code"
           cancel-text="Close"
           :show="codeShow"
           cancel-class="btn-darkgray"
           ok-class="hidden"
           @cancel="closeCodeModal"
           :full='true'
    >
      <!--MENU-->
      <div class="row">
        <div class="col-md-12">
          <div>
            <form>
              <div class="form-group">
                <label for="codeType" class="control-label"
                >
                  Select Platform
                </label>

                <select v-model="codeType"
                        @change="selectCodeType"
                        class="form-control"
                        id="codeType"
                >
                  <option value="widget" selected>Blog</option>
                  <option value="youtube">Youtube</option>
                  <option value="html">HTML</option>
                  <option v-show="allowwpcom"
                          value="wordpresscom">wordpress.com ONLY
                  </option>
                  <option v-show="!allowwpcom"
                          value="shortcode">WordPress plugin
                  </option>
                </select>

                <div v-if="codeType === 'shortcode'"
                     style="display: inline-block; font-size: 13px; margin-top: 6px; margin-left: 5px;"
                >
                  <a href="/page/the-linkdeli-wordpress-plugin-how-to-install-and-use-it"
                     target="_blank"
                  >
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> How do I use the wordpress plugin?
                  </a>
                </div>
              </div>
            </form>
          </div><!-- /.menu -->

        </div><!-- /.col-md-12 -->
      </div><!-- /.row -->
      <!-- /MENU -->

      <hr>

      <!-- codeType === 'widget' -->
      <div v-show="codeType === 'widget'">

        <div class="row">
          <div class="col-md-12">
            <div class="option text-center">
              <label for="email-code" :class="{ 'enabled': showEmailCodeOptions }">
                Email Integration
              </label>
              <input v-if="showEmailCodeOptions"
                     id="email-code"
                     type="checkbox"
                     checked
                     data-toggle="switch"
                     data-off-color="default"
                     data-on-color="success"
              >
              <input v-else
                     id="email-code"
                     type="checkbox"
                     data-toggle="switch"
                     data-off-color="default"
                     data-on-color="success"
              >
            </div><!-- /.option -->
          </div><!-- /.col-md-12 -->
        </div><!-- /.row -->

        <div class="row" v-show="showEmailCodeOptions">
          <div class="col-md-12">
            <div class="text-center"
                 :style="{'margin-bottom': '25px', 'pointer-events': showEmailCodeOptions ? 'all' : 'none' }"
            >
              <select class="form-control select select-dark select-block"
                      ref="emailOptions"
                      data-toggle="select"
              >
                <option v-for="option in emailCodeOptions"
                        :value="option.val"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div><!-- /.col-md-12 -->
        </div><!-- /.row -->

        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary btn-sm btn-block"
                    v-clipboard:copy="widgetCode"
                    v-clipboard:success="onCopy"
                    @click="textSelect($refs.widgetCode)"
            >
              <i class="fa fa-clipboard" aria-hidden="true" style="margin-right: 10px;"></i>
              <span>Copy</span>
            </button>
            <br>

            <pre ref="widgetCode">{{ widgetCode }}</pre>
          </div><!-- /.col-md-12 -->
        </div><!-- /.row -->
      </div>
      <!-- codeType === 'widget' -->


      <!-- codeType === 'youtube' -->
      <div v-show="codeType === 'youtube'">
        <div class="row">
          <div class="col-md-12" style="text-align: center; padding-bottom: 25px;">
            <select class="form-control select select-dark select-block"
                    ref="youtubeOptions"
                    data-toggle="select"
            >
              <option v-for="option in youtubeCodeOptions"
                      :value="option.val"
              >
                {{ option.name }}
              </option>
            </select>
          </div><!-- /.col-md-12 -->
        </div><!-- /.row -->

        <div class="row">
          <div class="col-md-12">
            <div v-if="formattedYoutubeCode.length > 5000"
                 class="error-msg"
            >
              <div>You have exceeded <a
                  href="https://youtube.googleblog.com/2010/09/top-tips-for-partners-words-words-words.html"
                  target="_blank">YouTube's character limit</a> (5000 characters).
              </div>
              <div>Including a very large number of products will cause your list to be truncated.</div>
              <div>Please remove some of the products you believe to be the least popular and try again.
              </div>
            </div>

            <button class="btn btn-primary btn-sm btn-block"
                    v-clipboard:copy="formattedYoutubeCode"
                    v-clipboard:success="onCopy"
                    @click="textSelect($refs.youtubeCode)"
            >
              <i class="fa fa-clipboard" aria-hidden="true" style="margin-right: 10px;"></i>
              <span>Copy</span>
            </button>
            <br>

            <pre v-if="plan.youtube_code && !hasExpired" ref="youtubeCode">{{ formattedYoutubeCode }}</pre>
          </div><!-- /.col-md-12 -->
        </div><!-- /.row -->
      </div>
      <!-- codeType === 'youtube' -->


      <!-- codeType === 'html' -->
      <div v-show="codeType === 'html'">
        <div v-for="(item, index) in htmlCode"
             class="html-code"
             ref="htmlItems"
        >
          <div class="thumb">
            <div class="thumb_container">
              <image-square :src="item.thumb"
                            :opacity="0.7"
                            spinner-color="#bbb"
                            spinner-side="30px"
              ></image-square>
            </div>
          </div>

          <div class="content">
            <div :class="[ 'name', { 'not-editing hint--top hint--rounded' : !item.editing } ]"
                 style="cursor: text !important;"
                 aria-label="click to edit"
            >
              <input v-if="item.editing"
                     v-model="item.name"
                     class="text"
                     @click="$refs.htmlItems[index].querySelector('input').select()"
              />

              <div v-else
                   class="text"
                   @click="function() { $set(item, 'editing', true); $nextTick(function() { $refs.htmlItems[index].querySelector('input').select() }); }"
              >
                {{ item.name }}
              </div>

              <span class="fa fa-pencil edit"
                    style="pointer-events: none;"
              ></span>
            </div>

            <template v-if="plan.html_links && !hasExpired">
              <div class="code-category">HTML link</div>
              <div class="html">
                <pre style="background: #fff;">{{ linksToHtml(item) }}</pre>
                <button class="btn btn-primary btn-primary hint--top hint--rounded copy"
                        aria-label="copy"
                        v-clipboard:copy="linksToHtml(item)"
                        @click="textSelect($refs.htmlItems[index].querySelector('pre'))"
                >
                  <span class="fa fa-clipboard" aria-hidden="true"></span>
                </button>
              </div>

              <br>

              <div class="code-category">link URL</div>
              <div class="clearText">
                <div v-for="link in item.links"
                     class="link"
                >
                  <label>{{ link.shop.name }}</label>
                  <div class="link-url">
                    <pre style="background: #fff;">{{ link.url }}</pre>
                    <button class="btn btn-primary btn-primary hint--top hint--rounded copy"
                            aria-label="copy"
                            v-clipboard:copy="link.url"
                            @click="textSelect($event.target.previousElementSibling)"
                    >
                      <span class="fa fa-clipboard" aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <br>
      </div>
      <!-- codeType === 'html' -->

      <!-- codeType === 'wordpresscom' -->
      <div v-show="codeType === 'wordpresscom'">
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary btn-sm btn-block"
                    v-clipboard:copy="wordpressCode"
                    v-clipboard:success="onCopy"
                    @click="textSelect($refs.wordpressCode)"
            >
              <i class="fa fa-clipboard"
                 aria-hidden="true" style="margin-right: 10px;"></i>
              <span>Copy</span>
            </button>
            <br>

            <pre ref="wordpressCode">{{ wordpressCode }}</pre>
          </div>
        </div>
      </div>
      <!-- codeType === 'wordpresscom' -->

      <!-- codeType === 'shortcode' -->
      <div v-show="codeType === 'shortcode'">
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary btn-sm btn-block"
                    v-clipboard:copy="shortCode"
                    v-clipboard:success="onCopy"
                    @click="textSelect($refs.shortCode)"
            >
              <i class="fa fa-clipboard" aria-hidden="true" style="margin-right: 10px;"></i>
              <span>Copy</span>
            </button>
            <br>

            <pre ref="shortCode">{{ shortCode }}</pre>
          </div>
        </div>
        <br>

      </div>
      <!-- codeType === 'shortcode' -->
    </modal>

    <!-- --------------------  APPEND TEMPLATE MODAL  ---------------------------- -->

    <modal title="Append template"
           ok-text="Append"
           :show="templateAppendShow"
           :small='true'
           :ok-class="appendTemplateClass"
           :loading="templateAppendLoading"
           @ok="appendTemplate"
           @cancel="closeAppendTemplate"
    >
      <div>
        <label>Select template</label>
        <select class="form-control select select-primary mbl"
                data-toggle="select"
                :disabled="!hasTemplates"
                ref="templateSelect"
                style="width: 100%;"
        >
          <option class="hidden" selected>no template</option>
          <template v-for="(template, index) in templates">
            <option v-if="template"
                    :value="index"
            >
              {{ template }}
            </option>
          </template>
        </select>

        <div class="row">
          <div class="col-md-12" style="margin-top: -12px; font-size: 12px;">
            <a class="pull-right" href="/page/how-to-use-a-template-list" target="_blank">How do I create a
              template?</a>
          </div>
        </div>
      </div>
    </modal>

    <!-- --------------------  SAVE AS TEMPLATE MODAL  ---------------------------- -->

    <modal title="Save as template"
           ok-text="Save"
           :show="templateShow"
           :small='true'
           ok-class="btn-warning"
           @ok="convertToTemplate"
           @cancel="closeConvertToTemplate"
    >
      <div>
        Convert <b>{{ collection.title }}</b> to a template?
      </div>
    </modal>

    <!-- --------------------  CLONE MODAL  ---------------------------- -->

    <modal title="Clone list"
           ok-text="Save"
           :show="cloneModalShow"
           :small='true'
           ok-class="btn-warning"
           @ok="cloneList"
           @cancel="closeCloneList"
    >
      <div>
        Clone <b>{{ collection.title }}</b>
      </div>

      <div class="form-group inline-form">
        <br>
        <label>New list name</label>
        <input type="text"
               class="form-control new-clone-name"
               name="cloner"
               placeholder="Clone name"
               v-model="cloneName"
        >
      </div>
    </modal>


    <!-- --------------------  UNSET TEMPLATE MODAL  ---------------------------- -->

    <modal title="Revert template"
           ok-text="Revert"
           :show="templateUnsetShow"
           :small='true'
           ok-class="btn-warning"
           @ok="convertToList"
           @cancel="closeConvertToList"
    >
      <div>
        Revert <b>{{ collection.title }}</b> back to a list?
      </div>
    </modal>
  </div>
</template>


<!--Script-->


<script>
import {mixin as clickaway} from 'vue-clickaway';

import date from 'date-and-time';
import messages from '../lib/config/messages'

import Modal from '../lib/components/Modal.vue';
import Loader from '../lib/components/Loader.vue';
import ImageSquare from '../lib/components/ImageSquare.vue';
import ProductListPreview from './ProductListPreview.vue'

export default {
  components: {
    'modal': Modal,
    'loader': Loader,
    'image-square': ImageSquare,
    'product-list-preview': ProductListPreview
  },

  mixins: [
    clickaway
  ],

  localStorage:
      {
        listStyleMessageShown: {
          type: Boolean
        }
      },

  data() {
    return {
      tempTitle: null,
      results: [],
      modalShow: false,
      templateShow: false,
      templateUnsetShow: false,
      templateAppendShow: false,
      templateAppendLoading: false,
      selectedTemplate: null,
      cloneModalShow: false,
      codeShow: false,
      disableCodeShow: false,
      codeType: 'widget',
      emailCode: null,
      youtubeCode: null,
      htmlCode: null,
      youtubeCodeOptions: [
        {
          name: 'Show shop name',
          val: 0
        },
        {
          name: 'Show shortened shop name',
          val: 1
        },
        {
          name: 'Don\'t show shop name',
          val: 2
        }
      ],
      youtubeCodeSelectedOption: 0,
      showEmailCodeOptions: false,
      emailCodeOptions: [
        {
          name: 'Link to product list',
          val: 0
        },
        {
          name: 'Text link products',
          val: 1
        },
        {
          name: 'Thumbnail products',
          val: 2
        }
      ],
      emailCodeSelectedOption: 0,
      editName: false,
      savingNewName: false,
      cloneName: "",
      cloning: false,
      titleInputWidth: null,
      isPlain: false,
      isFavorite: false,
      linkModalShow: false,
      disableLinkShow: false,
      shortLink: null,
      madeTemplate: false,
      allowwpcom: false,
      mobileMenu: false
    }
  },

  props: {
    loader: {
      type: String,
      required: true,
    },
    collection: {},
    templates: {},
    addingProducts: {
      type: Boolean,
      default: false
    },
    expired: {
      type: Boolean,
      default: false
    },
  },

  created() {
    this.isFavorite = this.collection.favorite;
  },

  mounted() {
    let switchListen = (switchClass, listener) => {
      let $switch = null,
          loop = setInterval(() => {
            $switch = $(switchClass);

            if ($switch.length) {
              $switch.on('switchChange.bootstrapSwitch', listener);
              clearInterval(loop);
            }
          }, 500);
    };

    switchListen('.bootstrap-switch-id-email-code', (e, state) => {
      this.showEmailCodeOptions = state;


      if (!state) {
        $(this.$refs.emailOptions).val(0);

        this.emailCodeSelectedOption = 0;
        this.saveUserDefault('code-email', false);
      } else {
        this.saveUserDefault('code-email', true);
      }
    });

    $(this.$refs.emailOptions).on('select2-selecting', (e) => {
      let optionVal = parseInt(e.val);

      if (this.plan.email_code > optionVal) {
        this.saveUserDefault('code-email', optionVal);
        this.emailCodeSelectedOption = optionVal;
      } else {
        e.preventDefault();

        this.$events.emit('feature-not-supported', 'email_code', ++optionVal);
      }
    });

    $(this.$refs.youtubeOptions).on('select2-selecting', (e) => {
      let optionVal = parseInt(e.val);

      this.youtubeCodeSelectedOption = optionVal;
      this.saveUserDefault('code-youtube', optionVal);
    });

    this.cloneName = `${this.collection.title} (clone)`;

    this.$events.on('collection-clicked', (uuid) => {
      if (uuid !== this.collection.uuid)
        this.editName = false;
    });

    $(this.$refs.templateSelect).on('change', (e) => {
      this.selectedTemplate = parseInt(e.val);
    });

    this.allowwpcom = this.userDefaults['wpcom'];
  },

  computed: {
    facebookLink() {
      let url = 'https://www.facebook.com/dialog/share?';
      url += 'app_id=' + this.env.FB_APP_ID;
      url += '&display=popup';
      url += '&href=' + this.env.APP_URL + '/view/' + this.collection.uuid;

      return url;
    },


    pinterestLink() {
      let url = 'http://pinterest.com/pin/create/button/?url=';
      url += this.env.APP_URL + '/view/' + this.collection.uuid;

      let placeholdersDir = this.env.S3_PLACEHOLDERS;
      // if (process.env.APP_ENV === 'local') {
      //   placeholdersDir += 'dev/';
      //   url += '&media=https://linkdeli.s3.amazonaws.com/public/placeholders/dev/a6ba3629-bdd4-4027-9176-55a146bdec5b.jpg'
      //   return url;
      // }

      url += '&media=' + placeholdersDir + this.collection.uuid + '.jpg';
      url += '&description=' + encodeURI(this.collection.title);

      return url;
    },

    user() {
      return this.$store.state.user;
    },
    userDefaults: {
      get() {
        return this.$store.state.userDefaults;
      },
      set(val) {
        this.$store.state.userDefaults = val;
      }
    },
    isTemplate() {
      return (!!this.collection.template || this.madeTemplate) && !this.isPlain;
    },
    hasTemplates() {
      return _.size(this.templates) > 0;
    },
    appendTemplateClass() {
      return `btn-warning ${!this.selectedTemplate || this.templateAppendLoading ? 'disabled' : ''}`
    },

    creationDate() {
      return moment(this.collection.created_at).format('dddd, DD-MMM-YYYY (HH:mm)')
      // return moment(this.collection.created_at).format('dddd Do of MMMM YYYY (HH:mm)');
    },

    formattedYoutubeCode() {
      let code = '——— S U P P L I E S ———\n\n',
          shopName = null;

      _.each(this.youtubeCode, product => {
        code += `• ${product.name}\n`;

        _.each(product.links, link => {
          switch (this.youtubeCodeSelectedOption) {
            case 0:
              shopName = link.shop.name ? `[ ${link.shop.name} ]` : '';
              break;
            case 1:
              shopName = link.shop.short_name ? `[ ${link.shop.short_name} ]` : '';
              break;
            case 2:
              shopName = '';
              break;
          }

          code += ` ——— ${link.url} ${shopName} \n`;
        });
        code += "\n";
      });

      code += "\nProduct list made with LinkDeli - https://linkdeli.com/e/m9ey7d\n";

      return code;
    },

    widgetCode() {
      let code = '<!-- LinkDeli Code Start -->';

      code += '<!-- ' + this.collection.title.replace(/[\W_]+/g, " ") + ' -->';

      // let scriptPart = `<script class="linkdeli-widget" data-campaign="${ this.collection.uuid }" src="${ this.env.APP_URL }/widget.js?|BUILD_HASH|" async="true"><\/script>`;
      let scriptPart = `<script class="linkdeli-widget" data-campaign="${this.collection.uuid}" src="${this.loader}" async="true"><\/script>`;
      let endPart = '<!-- LinkDeli Code End -->';

      switch (this.emailCodeSelectedOption) {
        case 0:
          code += `<a class="linkdeli-image" target=_blank href="${this.env.APP_URL}/view/${this.collection.uuid}" rel="nofollow"><img src="${this.env.S3_PLACEHOLDERS + this.collection.uuid}.jpg"></a>\n`;
          code += scriptPart;
          code += endPart;

          break;
        case 1:
          let linksTable = '<table class="linkdeli-image" border="0" cellspacing="0" width="100%">'
              + '<tr>'
              + '<td></td>'
              + '<td width="580">'
              + '<table class="linkdeli-image" cellpadding="5" style="width: 100%;">'
              + '<tbody>'
              + '<tr>';

          _.each(this.emailCode, (product, index) => {
            if (index && index % 2 === 0)
              linksTable += '</tr><tr>';

            linksTable += `<td align="center" width="50%"><div><b>${_.truncate(product.name, {
                  length: 80,
                  separator: ' '
                })}</b></div>`
                + '<div>[ ';

            _.each(product.links, (link, index) => {
              linksTable += `${index > 0 ? ' | ' : ''}<a href="${link.url}" target="_blank"><b>${link.shop.short_name}</b></a>`;
            });

            linksTable += ' ]</div>'
                + '</td>';
          });

          linksTable += '</tr>'
              + '</tbody>'
              + '</table>'
              + '</td>'
              + '<td></td>'
              + '</tr>'
              + '</table>';

          code += linksTable;
          code += scriptPart;
          code += endPart;
          break;
        case 2:
          let thumbLinksTable = '<table class="linkdeli-image" border="0" cellspacing="0" width="100%">'
              + '<tr>'
              + '<td></td>'
              + '<td width="580">'
              + '<table cellpadding="5" width="100%">'
              + '<tbody>'
              + '<tr>';

          _.each(this.emailCode, (product, index) => {
            if (index && index % 3 === 0)
              thumbLinksTable += '</tr><tr>';
            thumbLinksTable += `<td align="center" width="190px;"><div style="overflow:hidden;height:80px;width:80px; margin-bottom: 10px;" height="80">`
                + `<img src="${product.thumb}" style="max-width:80px;max-height: 80px !important; margin-bottom: 10px; border-radius: 5px;">`
                + `</div>`
                + `<div style="margin-bottom: 5px;"><b>${_.truncate(product.name, {
                  length: 40,
                  separator: ' '
                })}</b></div>`
                + '<div>[ ';

            _.each(product.links, (link, index) => {
              thumbLinksTable += `${index > 0 ? ' | ' : ''}<a href="${link.url}" target="_blank"><b>${link.shop.short_name}</b></a>`;
            });

            thumbLinksTable += ' ]</div>';
          });

          thumbLinksTable += '</tr>'
              + '</tbody>'
              + '</table>'
              + '</td>'
              + '<td></td>'
              + '</tr>'
              + '</table>';

          code += thumbLinksTable;
          code += scriptPart;
          code += endPart;

          break;

      }


      return code;
    },
    wordpressCode() {
      let code = '<!-- LinkDeli Code Start -->\n';
      code += `<a class="linkdeli-image" target=_blank  href="${this.env.APP_URL}/view/${this.collection.uuid}" rel="nofollow"><img src="${this.env.S3_PLACEHOLDERS + this.collection.uuid}.jpg"></a>\n`;
      code += '<!-- LinkDeli Code End -->';
      return code;

    },
    shortCode() {
      let code = '[linkdeli uuid="' + this.collection.uuid + '"]';
      return code;

    }
  },

  methods: {
    linksToHtml(item) {
      let html = `${item.name}( `;

      _.each(item.links, (link, index) => {
        html += `${index !== 0 ? ' | ' : ''}<a href="${link.url}" rel="nofollow" target="_blank">${link.shop.short_name}</a>`;
      });

      html += ' )';

      return html;
    },

    onCopy(event) {

      let setText = (text) => {
        event.trigger.querySelector('span').innerHTML = text;
      };

      setText('Copied');
      setTimeout(() => {
        setText('Copy');
      }, 1000);
    },

    htmlEntities(str) {
      return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    },

    popupModalForDelete() {
      if (!this.isTemplate)
        this.$events.emit('notify', messages.remove_list_danger);

      this.modalShow = true;
    },

    cancel() {
      this.$events.emit('notify-clear');

      this.modalShow = false;
    },

    remove() {
      this.$http.delete('/api/collection/' + this.collection.id).then(
          (response) => {
            this.cancel();

            setTimeout(() => {
              this.$events.emit('list-removed', this.collection.id);
//                            location.reload();
            }, 300);
          },
          (response) => {

          }
      )
    },

    showCode() {

      this.disableCodeShow = true;

      // get pre-selected settings from user defaults
      this.youtubeCodeSelectedOption = this.userDefaults['code-youtube'] || this.youtubeCodeSelectedOption;
      $(this.$refs.youtubeOptions).val(this.youtubeCodeSelectedOption).trigger('change');

      let codeEmail = this.userDefaults['code-email'];
      if (codeEmail) {
        this.showEmailCodeOptions = true;

        if (typeof codeEmail === 'number') {
          this.emailCodeSelectedOption = codeEmail;
          $(this.$refs.emailOptions).val(this.emailCodeSelectedOption).trigger('change');
        }
      }

      this.$nextTick(() => {
        $('[data-toggle="switch"]').each(function () {
          $(this).bootstrapSwitch('state', !!$(this).attr('checked'));
        })
      });

      let fetchCode = () => {
        this.$http.get(`/api/collection/code/${this.collection.id}`).then(response => {
          this.emailCode = null;
          this.youtubeCode = null;
          this.htmlCode = null;

          _.merge(this, response.body);

          this.$nextTick(() => {
            this.codeShow = true;
          });

          // create collection placeholder
          this.$http.post(`/api/collection/${this.collection.id}/placeholder/save`);

          if ($('[data-toggle="select"]').length)
            $('[data-toggle="select"]').select2();
        });
      };

      if (!this.$store.state.savingList)
        fetchCode();
      else {
        let loop = setInterval(() => {
          if (!this.$store.state.savingList) {
            fetchCode();
            clearInterval(loop);
          }
        }, 1000);
      }
    },

    closeCodeModal() {
      this.codeShow = this.disableCodeShow = false;
    },

    selectCodeType() {
      switch (this.codeType) {
        case 'youtube':
          if (!this.featureAllowed('youtube_code')) {
            this.$events.emit('feature-not-supported', 'youtube_code');
            this.codeType = 'widget';   // revert it back
            return;
          }
          break;
        case 'html':
          if (!this.featureAllowed('html_links')) {
            this.$events.emit('feature-not-supported', 'html_links');
            this.codeType = 'widget';   // revert it back
            return;
          }
          break;
      }
    },


    saveUserDefault(name, value) {
      let setting = {};
      setting[name] = value;

      this.userDefaults[name] = value;
      this.$http.post('/api/user/setting', setting);
    },

    collectionClicked() {
      this.$events.emit('collection-clicked', this.collection.uuid);
    },

    clickedListName() {
      this.titleInputWidth = `${this.$refs.title.offsetWidth}px`;

      this.tempTitle = this.collection.title;
      this.editName = true;

      this.$nextTick(() => {
        this.$refs.titleInput.select();
      });
    },

    changedListName() {
      let formData = new FormData();
      formData.append("name", this.tempTitle);

      this.savingNewName = true;

      this.$http.post('/api/collection/' + this.collection.id + '/name', formData).then(
          (response) => {
            this.savingNewName = false;
            this.editName = false;

            this.collection.title = this.tempTitle;
//                            console.log('/api/collection/' + this.collection.id + '/name');
          }
      )
    },

    popupModalForTemplateAppend() {
      this.templateAppendShow = true;
    },

    appendTemplate() {
      this.templateAppendLoading = true;

      this.$http.post(`/api/collection/${this.collection.id}/append`, {
        templateId: this.selectedTemplate
      }).then(
          (response) => {
            this.$events.emit('template-appended', response.data)

            this.closeAppendTemplate();
            setTimeout(() => {
              this.templateAppendLoading = false;
            }, 500);
          }
      )
    },

    closeAppendTemplate() {
      this.templateAppendShow = false;
    },

    popupModalForTemplate() {
      this.templateShow = true;
    },

    closeConvertToTemplate() {
      this.templateShow = false;
    },

    convertToTemplate() {
      this.$http.get('/api/collection/' + this.collection.id + '/totemplate').then(
          () => {
            this.madeTemplate = true;
            this.closeConvertToTemplate();
          }
      )
    },

    closeConvertToList() {
      this.templateUnsetShow = false;
    },

    convertToList() {
      this.$http.get('/api/collection/' + this.collection.id + '/tolist').then(
          () => {
            this.madeTemplate = false;
            this.isPlain = true;
            this.closeConvertToList();
          }
      )
    },

    showCloneModal() {
      this.cloneModalShow = true;

      this.$nextTick(function () {
        $(".new-clone-name").select();
      });
    },

    closeCloneList() {
      this.cloneModalShow = false;
    },

    cloneList() {
      this.$http.post(`/api/collection/${this.collection.id}/clone`, {
        name: this.cloneName
      }).then(response => {
        location.reload();
      });

      this.closeCloneList();
    },

    styleList() {
      if (this.featureAllowed('list_styling')) {
        if (!this.$localStorage.get('listStyleMessageShown')) {
          this.$localStorage.set('listStyleMessageShown', true);

          this.$events.emit('notify', messages.list_style_info);
        }

        this.$events.emit('style-list');
      } else
        this.$events.emit('feature-not-supported', 'list_styling');
    },

    toggleFavorite() {
      this.isFavorite = !this.isFavorite;

      this.$http.post(`/api/collection/${this.collection.id}/favorite`, {
        mark: this.isFavorite
      }).then(response => {
        // do nothing
      }, error => {
        this.isFavorite = !this.isFavorite;
      });
    },

    showLink() {
      this.disableLinkShow = true;

      this.$http.get(`/api/collection/${this.collection.uuid}/shortlink`).then(response => {
        this.shortLink = response.data;
        this.linkModalShow = true;
      }, error => {
        console.error(error)
        this.closeLinkModal();
      });
    },

    closeLinkModal() {
      this.linkModalShow = this.disableLinkShow = false;
    }
  }
}
</script>
<!--Style-->
<style lang='less' scoped>
@import "../../less/mixins";
@import "../../less/libs/flat-ui/variables";

.collection {
  display: block;
  background: #ececec;
  margin-bottom: 40px;
  border-radius: 5px;
  /*overflow: hidden;*/

  &.is-template {
    background: mix(#ececec, @brand-secondary);
  }

  &.adding-products {

    > .oneProductList {

      > .name {
        font-size: 25px;
        /*line-height: 1;*/
        margin-bottom: 3px;
      }
    }
  }

  > .oneProductList {
    position: relative;
    width: 100%;

    &.well {
      border: none;
      margin-bottom: 0;
      padding-bottom: 1px;
      box-shadow: none;
      border-radius: 0;
      background: fadeout(#fff, 50%);
    }

    > .name {
      font-size: 18px;
      font-weight: bold;
      color: fadeout(#000, 40%);
      padding-right: 110px;

      > span {
        width: 85%;

        > .title {

          > .fa {
            opacity: 0;
            color: fadeout(#000, 70%);
            padding-left: 10px;
          }

          &:hover {

            > .fa {
              opacity: 1;
            }
          }
        }

        > .title,
        input {
          border: none;
          outline: none;
          border-radius: 4px;
          padding: 0;
        }

        > input {
          padding: 0;
        }

        > .btn {
          .block-round;

          position: relative;
          width: 25px;
          height: 25px;
          padding: 0;
          font-size: 10px;
          margin-left: 3px;
          margin-top: -3px;

          > span {
            .centered-block;

            line-height: 1;
          }
        }

        > .loading {
          position: relative;
          top: 4px;
          left: 5px;
        }
      }
    }

    > .date {
      font-size: 13px;
      color: fadeout(#000, 60%);
    }

    > .link {
      position: absolute;
      top: 10px;
      right: 20px;

      .gray-button {
        pointer-events: all;
        color: rgb(142, 142, 142);
        width: 40px;
        height: 40px;
        background: rgba(0, 0, 0, 0.05);
        padding: 8px;
        position: relative;
        border: 1px solid rgb(223, 223, 223);
        margin-left: 8px;

        > .fa {
          .centered-block;
          font-size: 1.5em;
        }
      }

      > button {
        @side: 40px;

        .block-round;

        width: @side;
        height: @side;
        position: relative;
        padding: 0;
        font-size: 14px;
        background: fadeout(#000, 95%);
        color: #aaa;

        > .fa {
          .centered-block;
          font-size: 1.5em;
        }
      }

      > .dropdown .dropdown-menu {
        background-color: #e7e9eb;

        li a {
          padding: 10px 16px;
          font-size: 15px;

          i {
            margin-right: 4px;
          }
        }
      }
    }
  }

  > .buttons {
    display: inline-block;
    width: 100%;
    position: relative;

    .bottom-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      padding-right: 12px;
    }

    .collection-actions {
      button.mobile-button {
        background: rgba(22, 160, 133, .8) !important;

        &:hover {
          background: #cacfd2 !important;
        }

        &:visited {
          background: rgba(22, 160, 133, .8) !important;
        }
      }
    }

    > .mobile-actions {
      border-top: 1px solid #d2d2d2;
      padding-top: 10px;

      ul {
        padding: 0px;
        list-style-type: none;

        li {
          a {
            color: #545454;
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 35px;
            line-height: 1.429;
            font-size: 14px;
            transition: background-color 0.4s;

            &:hover {
              color: #16a085;
              background-color: rgba(190, 195, 199, 0.5);
            }

            i {
              margin-right: 8px;
            }
          }
        }
      }

      li.closeMenu {
        text-align: right;

        a {
          padding-right: 20px;
          font-size: 14px;
        }

        /*
        position: absolute;
        bottom: 0;
        right: 12px;
        font-size: 16px;
        color: #8e8e8e;
        background: #ececec;

        &:hover, &:visited, &:focus, &:focus-within {
            color: rgb(22, 160, 133) !important;
        }
        */
      }
    }

    .favourite {
      .favorite {
        font-size: 33px;
        line-height: 1;
        color: fadeout(#000, 90%);
        transition: 0.15s all ease;
        cursor: pointer;

        &:hover {
          color: fadeout(#ffd400, 50%);
        }

        &.marked {
          color: #ffd400;
        }
      }
    }

    .wrapper {
      display: inline-block;
      float: right;
      margin-bottom: -6px;
      padding: 10px;

      .btn {
        .block-round;

        width: 40px;
        height: 40px;
        position: relative;
        padding: 0;

        > .fa {
          .centered-block;
        }
      }
    }
  }

  pre {
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word;
    font-size: 11px;
    background: rgba(243, 243, 243, 0.6);
    border: none;
  }

  #web-code {
    hr {
      border-top: 1px solid #e2e2e2;
    }

    .nav-pills {

      > li {
        cursor: pointer;
      }
    }

    .option {
      position: relative;
      padding-bottom: 20px;

      > label {
        font-size: 17px;
        color: #555;
        font-weight: bolder;
        /*width: 100%;*/
        padding-left: 4px;
        padding-right: 10px;

        &:not(.enabled) {

          > span {
            opacity: 0.6;
          }
        }

        > .btn {
          .block-round;

          position: relative;
          width: 25px;
          height: 25px;
          padding: 0;
          font-size: 10px;
          margin-left: 4px;
          margin-top: -4px;

          > span {
            .centered-block;

            line-height: 1;
          }
        }
      }
    }

    .error-msg {
      background: lighten(@brand-danger, 45%);
      color: @brand-danger;
      padding: 13px;
      line-height: 1.3;
      margin-bottom: 15px;
      font-size: 13px;
      border-radius: 5px;

      a {
        color: @brand-danger;
        font-weight: bolder;
        text-decoration: underline;

        &:hover {
          color: lighter(@brand-danger, 10%);
        }
      }
    }

    .html-code {
      background: #ececec;
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 10px;
      position: relative;
      display: flex;
      flex-direction: row;

      > .thumb {
        flex: 0 0 75px;
        padding-right: 15px;

        > .thumb_container {
          .block-round;

          background: #fff;
          position: relative;
          overflow: hidden;
          padding: 10px;
          z-index: 0;

          -webkit-transform: translateZ(0);
          -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

          > .click-once {
            background: fadeout(#fff, 15%);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity 0.3s ease;
            line-height: 1.2;
            text-align: center;
            color: #333;
            font-weight: lighter;

            > span {
              .centered-block;

              width: 100%;
            }
          }
        }
      }

      > .content {
        flex: 1 0 0;
        position: relative;
        min-width: 0;

        > .code-category {
          padding: 8px 12px;
          font-size: 12px;
          margin-bottom: 12px;
          border-radius: 4px;
          background: #676767;
          color: #fff;
        }

        > .name {
          font-size: 13px;
          margin-bottom: 15px;
          width: 100%;

          > .edit {
            position: absolute;
            top: 3px;
            right: 0;
            opacity: 0;
          }

          &.not-editing {

            > .edit {
              opacity: 0.6;
            }
          }

          > input {
            border: none;
            width: 100%;
            border-radius: 5px;
          }

          div.text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 20px;
          }
        }

        > .html {
          position: relative;

          > pre {
            margin-bottom: 0;
            width: ~'calc(100% - 45px)';
          }

          > .copy {
            .block-round;

            position: absolute;
            width: 35px;
            height: 35px;
            bottom: 0;
            right: 0;
            float: right;
            padding: 0;

            > span {
              .centered-block;

              font-size: 11px;
              line-height: 1;
              padding-left: 2px;
            }
          }
        }

        > .clearText {

          > .link {
            position: relative;
            margin-top: 10px;
            background: fadeout(#000, 97%);
            padding: 10px;
            border-radius: 5px;

            > label {
              line-height: 1.4;
              position: relative;
              top: -3px;
              font-size: 11px;
            }

            > .link-url {

              > pre {
                margin-bottom: 0;
                width: ~'calc(100% - 40px)';
              }

              > .copy {
                .block-round;

                position: absolute;
                width: 30px;
                height: 30px;
                bottom: 10px;
                right: 10px;
                float: right;
                padding: 0;

                > span {
                  .centered-block;

                  font-size: 9px;
                  line-height: 1;
                  padding-left: 2px;
                  pointer-events: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.alreadyTemplate {
  cursor: not-allowed !important;
}

.slide-enter-active {
  -moz-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>

<style lang='less'>
#web-code {
  .select2-container {

    > .select2-choice {
      min-height: 0 !important;
    }
  }

  .option {

    /*> .bootstrap-switch {*/
    /*right: 0;*/
    /*position: absolute;*/
    /*top: 5px;*/
    /*}*/
  }

  .menu {
    display: inline-block;

    @media screen and (max-width: 767px) {
      display: block;
      width: 80%;
      margin: 0 auto;
    }
  }
}

#link-code {

  .text {
    color: #777;
    font-size: 18px;
    text-align: center;
    font-weight: lighter;
  }

  .link-preview {
    margin-top: 25px;
    text-align: center;

    > .title {
      padding: 5px;
      border-radius: 4px;
      color: #fff;
      background: #555;
      max-width: 300px;
      display: none;
      margin: 0 auto;
    }

    > .preview {
      color: #c5c5c5;
      font-size: 24px;
      display: block;
      margin-top: 10px;
    }

    &.copy-url {
      display: flex;
      height: 55px;
      margin-top: 17px;
      margin-bottom: 11px;

      > .preview {
        margin-top: 0;
        padding: 11px;
        border-radius: 200px;
        background: #f4f4f4;
        flex: 1;
        font-size: 20px;
        color: #aaa;
      }

      > button {
        flex: 0 0 55px;
        margin-left: 10px;
      }
    }
  }
}
</style>
