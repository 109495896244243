<!--Template-->

<template>
    <div class="container">
        <modal :show="showVatExemptionModal"
               id="vat-modal"
               title="Business VAT exemption"
               ok-text="I am in the EU but don't have a business VAT ID"
               @ok="noBusinessVAT()"
               cancel-text="I am not in the EU"
               @cancel="notInTheEU()"
               :force="true"
        >
            <div class="description">
                EU Businesses are exempted of EU VAT. If you are an EU-based business please fill in below.
            </div>
            <ul class="">
                <li class="">EU Business: Please fill country and VAT id</li>
                <li class="">EU Citizen: Please click on the green button</li>
                <li class="">Non-EU Citizen: Please click on the gray button</li>
            </ul>

            <hr style="color:darkgray">
            <div class="fields">
                <div>
                    <label>Select country</label>
                    <select class="form-control select select-primary mbl"
                            data-toggle="select"
                            ref="languageSelect"
                            style="width: 100%;"
                    >
                        <template v-for="(countryCode, index) in countryCodes">
                            <option v-if="userCountry === countryNames[index]"
                                    :value="countryCode"
                                    selected
                            >
                                {{ countryNames[index] }}
                            </option>

                            <option v-else
                                    :value="countryCode"
                            >
                                {{ countryNames[index] }}
                            </option>
                        </template>
                    </select>
                </div>

                <div :class="[ 'form-group', 'vat', { 'has-error': errorMsg.length } ]">
                    <label class="control-label">VAT number</label>
                    <div class="vat-input">
                        <input autocomplete="on"
                               :value="vatInput"
                               ref="vatInput"
                               type="text"
                               :class="[ 'form-control', { 'fetching': fetching } ]"
                               placeholder="enter your VAT number here"
                               @input="updateVatInput"
                               @click="$refs.vatInput.select()"
                        >
                        <loader v-if="fetching"
                                side="20px"
                        ></loader>
                    </div>

                    <div v-if="errorMsg.length"
                         class="error-msg"
                    >
                        {{ errorMsg }}
                    </div>

                    <div v-else>
                        <small class="pull-right">Your VAT ID will automatically be checked for validity</small>
                    </div>

                </div>
                <hr style="color:darkgray">
            </div>
        </modal>

    </div>
</template>


<style lang="less" scoped>

    .wrapper {
        text-align: center;
        padding-bottom: 55px;

        > .header {
            background: #f5f5f5;
            border-radius: 5px;
            padding: 20px;

            > .title {
                font-size: 23px;
                color: #bbb;
                margin-bottom: 10px;
                margin-top: -4px;
            }

            > .price {
                font-size: 14px;
                color: #aaa;
                line-height: 1.3;

                > strong {
                    font-size: 22px;
                    color: #888;
                    line-height: 1.3;

                    > span {
                        font-size: 12px;
                        padding-right: 1px;
                    }
                }
            }

            > .includes {
                text-align: center;
                padding: 7px;
                background: #e9e9e9;
                margin-top: 10px;
                border-radius: 5px;
                color: #777;
                font-weight: bolder;
                font-size: 13px;
            }

            > .business {
                margin-top: 13px;
                font-weight: bolder;
                margin-bottom: -6px;
                cursor: pointer;
                font-size: 14px;
                display: block;
            }
        }

        form {

            > .payment-form {
                min-height: 80px;
                padding: 0 15px;
            }

            > .button-wrapper {
                padding: 18px;
            }
        }

        > .payment-info {

            > .credit-cards {
                text-align: right;

                > img {
                    width: 250px;
                    margin: 7px 0;
                }
            }

            > .braintree {
                text-align: right;
            }
        }
    }
</style>

<style lang="less">
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    #vat-modal {

        .description {
            text-align: center;
            padding: 10px;
            background: @brand-info;
            color: #fff;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 20px;
        }

        .fields {
            display: inline-block;
            width: 100%;

            > .vat {
                margin-bottom: 15px;
                display: inline-block;
                width: 100%;

                > .vat-input {
                    position: relative;

                    > input.fetching {
                        padding-right: 40px;
                    }

                    > .loader {
                        position: absolute;
                        top: 11px;
                        right: 11px;
                    }
                }

                > .error-msg {
                    color: @brand-danger;
                    font-size: 13px;
                    text-align: center;
                }

                > button {
                    float: right;
                }
            }

            label {
                margin-bottom: 0;
                padding-left: 5px;
                font-weight: bolder;
            }

            input {
                margin-bottom: 10px;
            }
        }
    }
</style>

<script  >

    export default
    {
        data()
        {
            return {
                vat: false,
                userCountry: null,
                loaded: false,
                showVatExemptionModal: false,

                errorMsg: '',
                fetching: false,
                vatInput: null,
                vatCountryCode: null,

                countryNames: [
                    'Belgium',
                    'Bulgaria',
                    'Czech Republic',
                    'Denmark',
                    'Germany',
                    'Estonia',
                    'Ireland',
                    'Greece',
                    'Spain',
                    'France',
                    'Croatia',
                    'Italy',
                    'Cyprus',
                    'Latvia',
                    'Lithuania',
                    'Luxembourg',
                    'Hungary',
                    'Malta',
                    'Netherlands',
                    'Austria',
                    'Poland',
                    'Portugal',
                    'Romania',
                    'Slovenia',
                    'Slovakia',
                    'Finland',
                    'Sweden',
                    'United Kingdom',
                    'Not in the EU'
                ],
                countryCodes: [

                    'BE',
                    'BG',
                    'CZ',
                    'DK',
                    'DE',
                    'EE',
                    'IE',
                    'EL',
                    'ES',
                    'FR',
                    'HR',
                    'IT',
                    'CY',
                    'LV',
                    'LT',
                    'LU',
                    'HU',
                    'MT',
                    'NL',
                    'AT',
                    'PL',
                    'PT',
                    'RO',
                    'SI',
                    'SK',
                    'FI',
                    'SE',
                    'UK',
                    ''
                ]
            }
        },

        props: {
            data: {
                type: Object,
                default() {
                    return {};
                }
            }
        },

        computed: {
            price() {
                let price = this.plan.price;

                if (this.vat)
                    return price * 0.24 + price;
                else
                    return price;
            }
        },

        created() {
            _.merge(this, this.data);

            if (this.userCountry) {
                _.each(this.countryNames, (countryName, index) => {
                    if (this.userCountry === countryName) {
                        this.vatCountryCode = this.countryCodes[index];
                        return false;
                    }
                });
            }
        },

        mounted() {
            this.$nextTick(() => {
                let csrfElem = $('#csrf').find('input');
                $(this.$refs.csrf).replaceWith(csrfElem);
                $(this.$refs.csrfVat).replaceWith(csrfElem);
                this.loaded = true;
            });

            this.openVatExemptionModal();
            $(this.$refs.languageSelect).on('change', e => {
                this.vatCountryCode = e.val;

                if (this.vatInput.length)
                    this.checkVatValidity();
            });

            this.removeClose = true;
        },

        methods: {
            updateVatInput: _.debounce(function (e) {
//                this.vatInput = e.target.value.replace(/\D+/g, '');
                this.vatInput = e.target.value;
            }, 1000),

            notInTheEU() {
                this.$http.get(`/subscribe/assess/noeu`).then(response => {
                    this.vat = false;
                    this.showVatExemptionModal = false;
                    location.reload();
                });
            },

            noBusinessVAT() {
                this.$http.get(`/subscribe/assess/novat`).then(response => {
                    this.vat = false;
                    this.showVatExemptionModal = false;
                    location.reload();

                });
            },
            checkVatValidity() {
                this.fetching = true;

                if (this.vatInput.length > 7) {
                    this.$http.get(`/subscribe/assess/vat/${ this.vatCountryCode }/${ this.vatInput }`).then(response => {
                        let validity = response.data;

                        this.vat = validity !== 'valid';
                        this.fetching = false;

                        if (validity === 'valid') {
                            this.vat = false;
                            this.errorMsg = 'VAT number verified. Forwarding to subscription page';
                            window.setTimeout(function () {
                                location.reload();
                            }, 5000);
                        } else {
                            this.errorMsg = 'VAT number is not of a registered business in the EU';
                        }
                    }, response => {
                        this.errorMsg = 'VAT country/number is not valid';
                        this.fetching = false;
                    });
                }
            },
            openVatExemptionModal() {
                this.errorMsg = '';
                this.vatInput = '';

                setTimeout(() => {
                    this.showVatExemptionModal = true;
                }, 50);
            }
        },

        watch: {
            vat() {
                if (this.loaded) {
//                    $('#braintree-dropin-frame').remove();
//
//                    this.$nextTick(() => {
//                        this.setupBraintree();
//                    });
                }
            },
            vatInput() {
                if (this.showVatExemptionModal)
                    this.checkVatValidity();
            }
        }
    }
</script>
