export default {
    data() {
        return {
            env: {
                WIDGET_URL: process.env.WIDGET_URL,
                APP_URL: process.env.APP_URL,
                APP_NO_PROTOCOL: process.env.APP_URL_NO_PROTOCOL,
                SHORTENER_URL: process.env.SHORTENER_URL,
                S3_PLACEHOLDERS: process.env.S3_PLACEHOLDERS,
                S3_IMAGES: process.env.S3_IMAGES,
                WORDPRESS_URL: process.env.WORDPRESS_URL,
                FB_APP_ID: process.env.FB_APP_ID,
            }
        }
    }
};