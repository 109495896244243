<template>
    <div id="teasers"
         :class="{ 'side': side, 'open': isOpen, 'closing': closing }"
    >
        <div v-if="title.length">
            <h1>{{ title }}</h1>
            <br>
        </div>

        <div class="search">
            <div class="form-group">
                <div class="input-group input-group input-group-rounded">
                    <span class="input-group-btn">
                        <button type="submit" class="btn">
                            <span class="fui-search"></span>
                        </button>
                    </span>
                    <input @input="debounceInput"
                           class="form-control"
                           type="text"
                           placeholder="Search"
                    >
                </div>
            </div>
        </div>

        <div class="teasers"
             ref="teasers"
        >
            <scrollbar type="macosx"
                       scroll-top=".current"
                       :scroll-offset="50"
            >
                <section v-for="(doc, index) in filteredDocs"
                         :class="{ current: isCurrent(doc) }"
                         :key="doc.id"
                         @click="side ? openDoc(doc.post_name) : function() {}"
                >
                    <template v-if="editing">
                        <div v-if="doc.post_status === 'draft'"
                             class="editing"
                        >
                            <span>draft</span>
                        </div>
                    </template>

                    <image-square :src="doc.thumb"
                                  spinner-color="#bbb"
                                  spinner-side="30px"
                    ></image-square>

                    <div class="wrapper">
                        <h5 class="title">
                            <a @click="openDoc(doc.post_name)">
                                {{ doc.post_title }}
                            </a>
                        </h5>
                        <p>{{ teaserText(doc.post_content) }}</p>

                        <aside v-if="!side">
                        <span v-if="parentPath === 'blog'"
                              class="date"
                        >
                            {{ dateFormat(doc.post_date) }}
                        </span>
                            <template v-if="doc.post_status !== 'draft'">
                                <a @click="openDoc(doc.post_name)"
                                   class="btn btn-primary"
                                >
                                    Read more
                                </a>
                            </template>
                            <template v-if="editing">

                                <a @click="openEdit(doc.ID)"
                                   class="btn btn-danger"
                                >
                                    Edit
                                </a>
                            </template>
                        </aside>
                    </div>
                </section>
            </scrollbar>

            <div class="gradient"></div>
        </div>

        <template v-if="side">
            <div v-if="!isOpen"
                 class="open-button btn btn-primary"
                 @click="open"
            >
                <span class="fa fa-search"></span>
            </div>

            <div v-else
                 class="close-button btn btn-primary"
                 @click="close"
            >
                <span class="fa fa-close"></span>
            </div>
        </template>
    </div>
</template>

// Style

<style lang="less" scoped>
    @import "../../../less/mixins";
    @import "../../../less/libs/flat-ui/variables";

    #teasers {

        &.side {
            width: 450px;
            position: fixed;
            padding-top: 100px;
            right: 0;
            top: 0;
            height: ~'calc(100% - 135px)';
            background: #fff;
            padding-right: 50px;

            > .open-button,
            .close-button {
                display: none;
            }

            @media (max-width: 1228px) {
                margin-top: 140px;
                padding: 30px;
                height: ~'calc(100% - 235px)';
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                right: -450px;
                transition: right ease 0.35s;

                > .open-button {
                    .block-round;
                    .slick(0.8);

                    position: absolute;
                    top: 0px;
                    left: -60px;
                    display: block;
                    width: 50px;
                    height: 50px;
                    font-size: 16px;
                    transition: transform 0.25s ease;

                    > span {
                        .centered-block;
                    }
                }

                > .close-button {
                    .block-round;

                    position: absolute;
                    top: -17px;
                    left: -17px;
                }

                &.open {
                    right: 0;
                }

                &.closing {
                    right: -450px !important;
                }

                &.open,
                &.closing {
                    box-shadow: 0 0 40px 5px fadeout(#000, 80%);

                    > .close-button {
                        display: block;
                    }
                }
            }

            > .teasers {
                height: ~'calc(100% - 70px)';
                overflow-y: auto;
                position: relative;

                section {
                    padding: 15px;
                    border-left: 5px solid transparent;
                    flex-direction: row;

                    &:last-of-type {
                        margin-bottom: 50px;
                    }

                    &:before {
                        top: -30px;
                    }

                    &.current {
                        border-color: @brand-secondary;

                        > h5,
                        > p {
                            color: @brand-secondary;
                        }
                    }

                    &:hover {
                        cursor: pointer;

                        > h5,
                        > p {
                            color: @brand-secondary;
                        }
                    }

                    > .image {
                        flex: 0 0 80px;
                        max-height: 80px;
                    }

                    > .wrapper {
                        padding-left: 20px;

                        > .title {
                            font-size: 20px;
                        }

                        > h5 {
                            margin: 0;
                            padding-top: 0;
                            transition: 0.2s color ease;
                        }

                        > p {
                            text-align: justify;
                            padding-bottom: 0;
                            margin-bottom: 0;
                            color: #555;
                            font-size: 13px;
                            line-height: 1.4;
                            transition: 0.2s color ease;
                        }
                    }
                }

                > .gradient {
                    background-image: svg-gradient(to bottom, fadeout(#fff, 100%), #fff 100%);
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 50px;
                    left: 0;
                    bottom: 0;
                }
            }
        }

        > .search {
            height: 70px;

            > div {

                > .input-group {

                    > input,
                    > span > button {
                        background: #eee !important;
                        border: none;
                    }

                    > input {
                        padding-left: 5px !important;
                        font-size: 15px;
                    }
                }

                margin-bottom: 10px;
            }
        }

        > .teasers {

            section {
                display: flex;
                flex-direction: row;

                > .image {
                    flex: 0 0 220px;
                    max-height: 220px;
                }

                > .wrapper {
                    flex: 1;
                    padding-left: 40px;

                    > .title {
                        font-weight: normal;
                        padding-bottom: 20px;
                        font-size: 35px;

                        > a {
                            color: #676767;

                            &:hover,
                            &:active {
                                cursor: pointer;
                                color: @brand-secondary;
                            }
                        }
                    }

                    > p {
                        text-align: justify;
                        padding-bottom: 20px;
                        color: #555;
                        font-size: 18px;
                        font-weight: lighter;
                    }
                }

                @media (max-width: 650px) {
                    flex-direction: column;

                    > .image {
                        flex: 0 0 150px;
                        max-height: 150px;
                        max-width: 100%;
                        margin-bottom: 20px;
                    }
                }

                > .editing,
                > .published {
                    .block-round;

                    @side: 50px;

                    position: absolute;
                    width: @side;
                    height: @side;
                    top: 0;
                    right: 0;
                    color: fadeout(#fff, 10%);
                    font-size: 20px;

                    > span {
                        .centered-block;
                    }
                }

                > .editing {
                    background: #888;
                }

                > .published {
                    background: @brand-secondary;
                }
            }
        }
    }
</style>

<style lang="less">
    #teasers {

        &.side {

            > .teasers {

                .image {

                    > .thumb {

                        > img {
                            padding: 0 !important;
                        }
                    }
                }
            }
        }
    }
</style>

// Javascript

<script  >
    import striptags from 'striptags'
    import Fuse from 'fuse.js'

    import Scrollbar from './Scrollbar.vue'
    import ImageSquare from './ImageSquare.vue'

    export default
    {
        components: {
            'scrollbar': Scrollbar,
            'image-square': ImageSquare,
        },

        data() {
            return {
                search: '',
                isOpen: false,
                closing: false,
                fuse: null
            }
        },

        props: {
            title: {
                type: String,
                'default': ''
            },
            parentPath: {
                type: String,
                'default': ''
            },
            docs: {
                type: [Object, Array],
                'default': function () {
                    return [];
                }
            },
            currentDoc: {
                type: Object,
                'default': function () {
                    return null;
                }
            },
            editing: {
                type: Boolean,
                'default': false
            }
        },

        computed: {
            side() {
                return !!this.currentDoc;
            },
            filteredDocs() {
                let search = _.lowerCase(this.search);

                if (search.length)
                    return this.fuse.search(search);
                else
                    return _.values(this.docs);
            }
        },

        methods: {
            debounceInput: _.debounce(function (e) {
                this.search = e.target.value;
            }, 500),

            isCurrent(doc) {
                try {
                    return doc.ID === this.currentDoc.ID;
                } catch (e) {
                    return false;
                }
            },

            teaserText(text) {
                text = striptags(text)

                let textTruncated = this.truncateOnWord(text, this.side ? 120 : 300);

                return `${ textTruncated }${ textTruncated.length < text.length ? '...' : '' }`;
            },

            dateFormat(published) {
                return moment(published).format('MMMM Do, YYYY');
            },

            openDoc(url) {
                if (this.parentPath.length)
                    url = `${ this.parentPath }/${ url }`;
                window.location.href = url;
            },

            openEdit(id) {
                url = process.env.WORDPRESS_URL + "/post.php?post=" + id + "&action=edit";
                window.location.href = url;
            },

            open() {
                setTimeout(() => {
                    this.isOpen = true;
                }, 100);
            },

            close() {
                this.closing = true;

                setTimeout(() => {
                    this.isOpen = false;
                    this.closing = false;
                }, 350);
            }
        },

        created() {
            this.fuse = new Fuse(_.values(this.docs), {
                shouldSort: true,
                threshold: 0.6,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: [
                    'post_title',
                    'post_content'
                ]
            });
        }
    }
</script>
