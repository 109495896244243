var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["link", { checked: _vm.checked }],
      on: { click: _vm.toggleSelection }
    },
    [
      _c("div", { staticClass: "details col-md-1" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "image-square",
              {
                attrs: {
                  src: _vm.data.thumbPath,
                  alt: _vm.data.name,
                  "spinner-color": "#aaa"
                },
                on: { "image-errored": _vm.destroySelf }
              },
              [
                _c("a", {
                  attrs: {
                    href: _vm.url,
                    target: "_blank",
                    title: "visit link"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                })
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "name col-md-4" }, [_vm._v(_vm._s(_vm.name))]),
      _vm._v(" "),
      _c("div", { staticClass: "feed col-md-7" }, [_vm._v(_vm._s(_vm.url))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }