// vendor
require('select2');
require('bootstrap-switch');
require('bootstrap-tagsinput');
require('timepicker');
require('datatables');
require('drmonty-datatables-plugins');
require('typeahead.js');

require('jquery-ui/ui/core');
require('jquery-ui/ui/widget');
require('jquery-ui/ui/effect');
require('jquery-ui/ui/position');
require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/ui/widgets/button');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/widgets/slider');
require('jquery-ui/ui/widgets/spinner');

// flat-ui
require('./lib/fileinput');
require('./lib/radiocheck');
require('./lib/application');