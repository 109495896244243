<template>
    <div>
        <form id="braintree-form" :action="submitRoute" method="POST">
            <input type="hidden" name="_token" :value="csrfToken">
            <div id="braintree-container"></div>

            <!-- COUPON -->
            <div v-if="type === 'subscribe'" class="coupon" style="padding-top:25px;">
                Have a coupon?
                <input type="text"
                       name="couponCode"
                       v-model="couponCode"
                       class="coupon-input"
                >
                <button type="button"
                        id="coupon-button"
                        @click="validateCoupon"
                >
                    Apply
                </button>

                <div :class="['coupon-message', couponStatus]">
                    {{ couponMessage }}
                </div>
            </div>
            <!-- / COUPON -->

            <!-- BUTTON -->
            <div class="button-wrapper">
                <!--Proceed to Checkout-->
                <button type="submit"
                        class="btn btn-success btn-lg btn-block btn-embossed"
                        :disabled="processing"
                >
                    <span style="margin-right: 8px;">
                        <span v-if="!processing">{{ buttonText }}</span>
                        <span v-else>Processing, please wait</span>
                    </span>
                    <span class="icon">
                        <i v-if="!processing && type === 'subscribe'" class="fa fa-shopping-cart"></i>
                        <span v-else class="icon"><img :src="processingSvg"></span>
                    </span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        props: {
            submitRoute: { type: String, required: true },
            clientToken: { type: String, required: true },
            planName: { type: String, required: true },
            buttonText: { type: String, required: true },
            type: { type: String, required: true, default: 'subscribe' },
        },

        data() {
            return {
                processing: false,
                couponCode: '',
                couponStatus: '',
                couponMessage: '',
            }
        },

        computed: {
            processingSvg() {
                return inlz.processing_svg
            },

            csrfToken() {
                return window.csrfToken
            }
        },

        mounted() {
            this.setupBraintree();
        },

        methods: {
            setupBraintree() {
                if (braintree) {
                    braintree.dropin.create({
                        container: '#braintree-container',
                        authorization: this.clientToken,
                        paypal: {flow: 'vault'},
                    })
                    .then(client => _prepareForm(client))
                    .catch(error => {
                        this.processing = false;
                        console.error(
                            error.name + ": " + error.message + " " + error.code
                        );
                    });
                }

                let _prepareForm = (client) => {
                    let form = document.getElementById('braintree-form');

                    form.addEventListener('submit', (event) => {
                        event.preventDefault();
                        this.processing = true;

                        client.requestPaymentMethod((error, payload) => {
                            if (error) {
                                this.processing = false;
                                console.error(error.name + ": " + error.message);
                            } else if (payload) {
                                let nonceInput = document.createElement('input');
                                nonceInput.type = 'hidden';
                                nonceInput.name = 'payment_method_nonce';
                                nonceInput.value = payload.nonce;

                                //setup the coupon
                                if (this.couponCode.length) {
                                    let couponInput   = document.createElement('input');
                                    couponInput.type  = 'hidden';
                                    couponInput.name  = 'coupon';
                                    couponInput.value = this.couponStatus;
                                    event.target.append(couponInput);
                                }

                                event.target.append(nonceInput);
                                event.target.submit();
                            }
                        });
                    });
                };
            },


            validateCoupon() {
                if (this.couponCode.length === 0) {
                    return false;
                }

                this.$http.post('/validate-coupon', {
                    'code': this.couponCode,
                    'plan': this.planName,
                })
                .then(response => {
                    this.couponStatus  = response.data.status;
                    this.couponMessage = response.data.message;
                });
            }
        }
    }
</script>


<style lang="less" scoped>
    .coupon {
        color: #bbb;
        .coupon-input {
            padding: 7px;
            border: 2px solid #bdc3c7;
            font-family: 'Helvetica Neue', Arial, sans-serif;
            font-size: 15px;
            line-height: 1.467;
            font-weight: bold;
            text-transform: uppercase;
            height: 42px;
            border-radius: 6px;
            -webkit-appearance: textfield;
            background-color: white;
            -webkit-rtl-ordering: logical;
            cursor: text;
            color: #757575;
        }


        #coupon-button {
            padding: 8px 24px;
            margin-left: 10px;
            border-radius: 4px;
            color: #898989;
            border: 1px solid #dbdbdb;

            &:hover {
                color: #747474;
                border: 1px solid #acacac;
            }
        }

        .coupon-message {
            margin-top: 10px;
            border-radius: 4px;

            &.fail {
                color: #b31212;
                border: 1px solid #b31212;
            }

            &.success {
                color: #34d958;
                border: 1px solid #34d958;
            }
        }
    }

    .wrapper {
        text-align: center;
        padding-bottom: 55px;

        > .header {
            background: #f5f5f5;
            border-radius: 5px;
            padding: 20px;

            > .title {
                font-size: 23px;
                color: #bbb;
                margin-bottom: 10px;
                margin-top: -4px;
            }

            > .price {
                font-size: 14px;
                color: #aaa;
                line-height: 1.3;

                > strong {
                    font-size: 22px;
                    color: #888;
                    line-height: 1.3;

                    > span {
                        font-size: 12px;
                        padding-right: 1px;
                    }
                }
            }

            > .includes {
                text-align: center;
                padding: 7px;
                background: #e9e9e9;
                margin-top: 10px;
                border-radius: 5px;
                color: #777;
                font-weight: bolder;
                font-size: 13px;
            }

            > .business {
                margin-top: 13px;
                font-weight: bolder;
                margin-bottom: -6px;
                cursor: pointer;
                font-size: 14px;
                display: block;
            }
        }

        form {

            > .braintree-container {
                min-height: 80px;
                padding: 0 0px;
            }

            > .button-wrapper {
                padding: 18px;
            }
        }

        > .payment-info {
            margin: 25px auto 80px auto;

            > .credit-cards {
                > img {
                    width: 250px;
                    margin: 7px 0;
                }
            }

            > .braintree {
            }
        }
    }
</style>
