var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "parent" }, [
    _c("div", { staticClass: "container scrollme tableContainer" }, [
      _c("table", { staticClass: "table-responsive table-hover" }, [
        _c("tbody", [
          _c(
            "tr",
            _vm._l(_vm.list, function(l) {
              return _c(
                "td",
                [
                  _c("product", {
                    attrs: { data: l, inlist: true, tsize: "t" }
                  })
                ],
                1
              )
            }),
            0
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.listId > 0
      ? _c("div", { staticClass: "menu" }, [
          _c(
            "a",
            {
              staticClass: "btn-primary bt btn btn-xs",
              attrs: { target: "_blank", href: "widget/" + _vm.listUuid }
            },
            [_c("i", { staticClass: "fa fa-eye" })]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn-default bt btn btn-xs",
              attrs: { href: "lists/" + _vm.listId },
              on: {
                click: function($event) {
                  return _vm.edit()
                }
              }
            },
            [_c("i", { staticClass: "fa fa-pencil" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "bt btn btn-xs btn-danger",
              on: {
                click: function($event) {
                  return _vm.remove()
                }
              }
            },
            [_c("i", { staticClass: "fa fa-remove" })]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }