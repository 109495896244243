var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "logo",
        _vm.extraClasses,
        { loaded: _vm.imgLoaded, errored: _vm.imgErrored, round: _vm.isRound }
      ],
      style: { width: _vm.side }
    },
    [
      _vm.inViewport
        ? [
            _vm.imgLoaded
              ? _c("span", [_c("img", { attrs: { src: _vm.imageSrc } })])
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }