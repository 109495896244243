import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  user: {},
  userDefaults: {},
  userList: [],
  userGroupList: [],
  savingList: false,
  groups: [],
  productGroups: {},
  editingTitle: false,
  userTags: [],
  migrationLinks: [],
  migrationLists: []
}

// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
  SET_USER_LIST(state, list) {
    state.userList = list;
  },
  SET_USER_GROUP_LIST(state, list) {
    state.userGroupList = list;
  },
  APPEND_PRODUCT(state, product) {
    state.userList.push(product)
  },
  PREPEND_PRODUCT(state, product) {
    state.userList.unshift(product)
  },
  REMOVE_PRODUCT(state, productId) {
    state.userList = _.reject(state.userList, (product) =>
    {
      return product.id === productId;
    });
  },
  SET_SINGLE_PRODUCT(state, product) {
    state.userList.pop();

    if (product)
      state.userList.push(product);
  },
  INCREMENT_GROUP(state) {
    let i = 1;

    if (state.groups.length) {
      do {
        if (_.includes(state.groups, i)) {
          i++;
        } else {
          state.groups.push(i);
          break;
        }
      } while (true)
    } else {
      // first
      state.groups.push(1);
    }
  },
  REMOVE_GROUP(state, group) {
    _.remove(state.groups, (_group) =>
    {
      return group === _group;
    })
  },
  SET_PRODUCT_GROUPS(state, data) {
    state.productGroups = data;
  },
  MAP_GROUP_TO_PRODUCT(state, data) {
    state.productGroups[data.id] = data.group;
  },
  SET_EDITING_TITLE(state, bool) {
    state.editingTitle = bool;
  },
  SET_USER_TAGS(state, tags) {
    state.userTags = tags;
  },
  SET_MIGRATION_LINKS(state, links) {
    state.migrationLinks = links;
  },
  SET_MIGRATION_LISTS(state, lists) {
    state.migrationLists = lists;
  }
}

// actions are functions that causes side effects and can involve
// asynchronous operations.
const actions = {
  setUserList: ({ commit }, list) => commit('SET_USER_LIST', list),
  setUserGroupList: ({ commit }, list) => commit('SET_USER_GROUP_LIST', list),
  appendProduct: ({ commit }, product) => commit('APPEND_PRODUCT', product),
  prependProduct: ({ commit }, product) => commit('PREPEND_PRODUCT', product),
  removeProduct: ({ commit }, product) => commit('REMOVE_PRODUCT', product),
  setSingleProduct: ({ commit }, product) => commit('SET_SINGLE_PRODUCT', product),
  nextGroup: ({ commit }) => commit('INCREMENT_GROUP'),
  removeGroup: ({ commit }, group) => commit('REMOVE_GROUP', group),
  setProductGroups: ({ commit }, data) => commit('SET_PRODUCT_GROUPS', data),
  setEditingTitle: ({ commit }, bool) => commit('SET_EDITING_TITLE', bool),
  setUserTags: ({ commit }, tags) => commit('SET_USER_TAGS', tags),
  setMigrationLinks: ({ commit }, links) => commit('SET_MIGRATION_LINKS', links),
  setMigrationLists: ({ commit }, lists) => commit('SET_MIGRATION_LISTS', lists)
}

// getters are functions
const getters = {
  getUserList: state => state.userList,
  getUserGroupList: state => state.userGroupList,
  getSingleProduct: state => state.userList[0],
  getLastGroup: (state) => {
    return _.last(state.groups)
  },
  getProductGroups: state => state.productGroups,
  getEditingTitle: state => state.editingTitle,
  getUserTags: state => state.userTags,
  getMigrationLinks: state => state.migrationLinks,
  getMigrationLists: state => state.migrationLists
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations
});