var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "modal",
        {
          attrs: {
            id: "expired-modal",
            title: "Expired account",
            show: _vm.expiredDialog,
            "ok-text": "Upgrade",
            "ok-class": "upgrade-button btn-primary"
          },
          on: { ok: _vm.upgradeAccount, cancel: _vm.closeExpired }
        },
        [
          _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col-md-12",
                  staticStyle: { "font-size": "14px" }
                },
                [
                  _vm._v(
                    "\n                    To create new lists, modify existing ones and view any statistics on your lists, you will need\n                    to upgrade your account to a paid subscription\n                "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col-md-12 pull-right",
                  staticStyle: { "margin-top": "12px", "font-size": "12px" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "pull-right",
                      attrs: { href: "/pricing", target: "_blank" }
                    },
                    [_vm._v("View plans")]
                  )
                ]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }