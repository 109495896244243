var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, full: true, title: _vm.title },
      on: { cancel: _vm.cancel }
    },
    [
      _c("div", { staticClass: "description" }, [
        _c("i", {
          staticClass: "fa fa-info-circle",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v(
          " Enter the product URL (other fields will auto-complete)\n     "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fields" }, [
        _c(
          "div",
          {
            class: [
              "form-group",
              "product-link",
              { "has-error": _vm.errorMsg.length }
            ]
          },
          [
            _c("label", { staticClass: "control-label" }, [
              _vm._v("Product URL")
            ]),
            _vm._v(" "),
            _vm.editingUrl
              ? _c(
                  "span",
                  {
                    staticStyle: {
                      display: "block",
                      "font-size": "11px",
                      "padding-left": "4px",
                      color: "#d22424"
                    }
                  },
                  [
                    _vm._v(
                      "\n                 Editing url with your own responsibility. Please make sure it is the correct link\n             "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "url-input" },
              [
                _c("input", {
                  ref: "urlInput",
                  class: ["form-control", { fetching: _vm.fetching }],
                  attrs: {
                    autocomplete: "on",
                    type: "text",
                    placeholder: "copy / paste the product link here"
                  },
                  domProps: { value: _vm.urlinput },
                  on: {
                    input: _vm.updateUrl,
                    click: function($event) {
                      return _vm.$refs.urlInput.select()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.fetching
                  ? _c("loader", { attrs: { side: "20px" } })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.errorMsg.length
              ? _c("div", { staticClass: "error-msg" }, [
                  _vm._v(
                    "\n                 " +
                      _vm._s(_vm.errorMsg) +
                      "\n             "
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "div",
              { staticClass: "image-preview" },
              [
                _c("image-square", {
                  attrs: {
                    src: _vm.product.thumb_path,
                    alt: _vm.title,
                    opacity: 0.7,
                    "spinner-color": "#bbb",
                    "spinner-side": "30px",
                    "no-image-msg":
                      "<div style='color: #999;'><div><span style='font-size: 40px; margin-bottom: 5px;' class='fa fa-picture-o fa-3'></span></div><div style='font-size: 12px;'>image preview</div></div>"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [
            _c("label", { staticClass: "control-label" }, [_vm._v("Title")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.product.title,
                  expression: "product.title"
                }
              ],
              ref: "title",
              staticClass: "form-control input-sm",
              attrs: {
                autocomplete: "on",
                type: "text",
                placeholder: "product title"
              },
              domProps: { value: _vm.product.title },
              on: {
                click: function($event) {
                  return _vm.$refs.title.select()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.product, "title", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("label", { staticClass: "control-label" }, [
              _vm._v("Image URL")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "thumb-path-input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.product.thumb_path,
                    expression: "product.thumb_path"
                  }
                ],
                ref: "image",
                staticClass: "form-control input-sm",
                attrs: {
                  autocomplete: "on",
                  type: "text",
                  placeholder: "product image"
                },
                domProps: { value: _vm.product.thumb_path },
                on: {
                  click: function($event) {
                    return _vm.$refs.image.select()
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.product, "thumb_path", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: [
                    "btn",
                    "btn-primary",
                    "hint--top",
                    "hint--rounded",
                    {
                      enabled:
                        (_vm.product.url && !_vm.imageUploading) ||
                        !_vm.followLink
                    }
                  ],
                  style: {
                    "pointer-events":
                      (_vm.product.url && !_vm.imageUploading) ||
                      !_vm.followLink
                        ? "all"
                        : "none"
                  },
                  attrs: { "aria-label": "upload image" },
                  on: { click: _vm.uploadImage }
                },
                [
                  _vm.imageUploading
                    ? _c("loader", {
                        attrs: { side: "15px", color: "#fff", opacity: 1 }
                      })
                    : _c("span", { staticClass: "fa fa-upload" })
                ],
                1
              ),
              _vm._v(" "),
              _c("input", {
                ref: "imageInput",
                staticClass: "hidden",
                attrs: { type: "file", accept: "image/*" },
                on: { change: _vm.getImageFile }
              })
            ])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "hidden" }, [
            _c("label", { staticClass: "control-label" }, [_vm._v("UPC")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.product.upc,
                  expression: "product.upc"
                }
              ],
              ref: "upc",
              staticClass: "form-control input-sm",
              attrs: {
                autocomplete: "on",
                type: "text",
                placeholder: "product upc"
              },
              domProps: { value: _vm.product.upc },
              on: {
                click: function($event) {
                  return _vm.$refs.upc.select()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.product, "upc", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("label", { staticClass: "control-label" }, [_vm._v("Price")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.product.price,
                  expression: "product.price"
                }
              ],
              ref: "price",
              staticClass: "form-control input-sm",
              attrs: {
                autocomplete: "on",
                type: "text",
                placeholder: "product price"
              },
              domProps: { value: _vm.product.price },
              on: {
                click: function($event) {
                  return _vm.$refs.price.select()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.product, "price", $event.target.value)
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("span", { staticClass: "pull-left" }, [
          _c("label", { class: { enabled: _vm.followLink } }, [
            _vm._v("\n             Follow redirects\n\n         ")
          ]),
          _vm._v(" "),
          _vm.followLink
            ? _c("input", {
                attrs: {
                  id: "followLink",
                  type: "checkbox",
                  checked: "",
                  "data-toggle": "switch",
                  "data-off-color": "default",
                  "data-on-color": "success"
                }
              })
            : _c("input", {
                attrs: {
                  id: "followLink",
                  type: "checkbox",
                  "data-toggle": "switch",
                  "data-off-color": "default",
                  "data-on-color": "success"
                }
              }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-info",
              attrs: {
                href:
                  "/page/what-follow-redirects-button-does-linkdeli-add-product-dialog",
                target: "_blank"
              }
            },
            [_c("span", { staticClass: "fa fa-question" })]
          )
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            class: [
              "btn",
              "btn-embossed",
              "btn-primary",
              { unabled: !_vm.canSave || _vm.saving }
            ],
            staticStyle: { width: "69px" },
            on: { click: _vm.save }
          },
          [
            !_vm.saving
              ? [_vm._v("Save")]
              : _c("span", {
                  staticClass: "fa fa-circle-o-notch fa-spin fa-fw"
                })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-embossed btn-darkgray",
            on: { click: _vm.cancel }
          },
          [_vm._v("\n             Close\n         ")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }