<!--Template-->

<template>
</template>

<!--Style-->
<style lang='less'>
    .more-info-lettering {
        color: white;
        background-color: dimgrey;
        padding:10px;
        border-radius: 5px;
    }

    .notification p a,
    .notification li a {
        color: white;
        font-weight: bold;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
</style>

<!--Script-->
<script>

    export default{
        props: ['data'],
        mounted() {
            let urlPath = _.last(_.split(window.location.href, '/'));

            if (this.data.length && urlPath !== 'announcements') {
                let html = '';
                let item =  _.first(this.data);

                _.each(this.data, (item, index) => {
                    html += `<h4>${item.title}</h4>`
                            + `<span>${item.message}</span>`
                            + '<br>';
                });


                html += '<a href="/announcements" target="_blank" class="more-info-lettering"><b>More...</b></a>';

                this.$events.emit('notify', {
                    type: 'info',
                    message: html,
                    onClose: () => {
                        this.$http.post('/api/user/setting', {
                            'last-announcement': item.id
                        });
                    }
                });
            }
        },
        methods: {
            close() {
            }
        },

        render(h) {
            return h() // avoid warning message
        }
    }
</script>
