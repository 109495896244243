<template>
    <div id="lists"
         :class="[ 'container', { 'show': show } ]"
    >
        <div class="menu-wrapper row row-contained">
            <div class="create-new btn-group"
                 data-intro='Hello step one!'
            >
                <button id="create-new"
                        type="button"
                        aria-expanded="false"
                        class="btn btn-primary"
                        @click="openCreate"
                >
                    <span>
                        <span class="fui-plus"></span>
                    </span>
                </button>
                <a @click="openCreate" class="description">new product list</a>
            </div>

            <span class="buttons-right pull-right">
                 <a v-if="plan.social_link && !hasExpired && !isTeamMember"
                    class="btn social-link-button"
                    @click="maybeGoToSocialLink"
                 >
                     <span class="fa fa-instagram"></span>
                     <span class="hidden-xs">Instagram list</span>
                </a>

                <!--<a v-if="plan.html_links && !hasExpired && !isTeamMember"-->
                   <!--class="btn btn-darkgray single-link-button"-->
                   <!--@click="maybeGoToSingleLinks"-->
                <!--&gt;-->
                    <!--<span class="fa fa-link"></span>-->
                    <!--<span class="hidden-xs">Single links</span>-->
                <!--</a>-->

                <a class="btn btn-darkgray"
                   :href="statsRoute"
                >
                    <span class="fa fa-line-chart"></span>
                    <span class="hidden-xs">Statistics</span>
                </a>
            </span>
        </div>

        <template v-if="totalLists">
            <!--SEARCH-->
            <div class="row row-contained">
                <div class="search col-md-12">
                    <div class="input-group input-group-hg input-group-rounded flat">
                        <span class="input-group-btn">
                            <button type="submit" class="btn">
                                <span class="fa fa-search"></span>
                            </button>
                        </span>
                        <input type="text"
                               :value="searchString"
                               class="form-control flat"
                               placeholder="Search..."
                               @input="updateSearch"
                        >
                    </div>
                </div>
            </div><!-- /.row -->

            <!--FILTERS-->
            <div class="row row-contained filters">
                <div class="xs-wrapper visible-xs visible-sm">
                    <a href="#" @click.prevent="mobileFilters = !mobileFilters">
                        {{ mobileFilters ? "Hide Filters" : "Filters" }}
                    </a>
                </div><!-- /.xs-wrapper -->

                <transition name="slide">
                    <div v-show="mobileFilters"
                         :class="['md-wrapper visible-md visible-lg', {'visible-xs': mobileFilters, 'visible-sm': mobileFilters}]">
                        <!-- FILTER BY CATEGORY-->
                        <div class="col-sm-4 col-xs-6">
                        <span class="filter-select">
                            <span class="filter-label">Show</span>
                            <select class="form-control select select-dark select-block"
                                    ref="filterSelector"
                                    data-toggle="select"
                                    v-model="selectedFilter"
                            >
                                <option value="all">All</option>
                                <option value="lists">Lists</option>
                                <option value="templates">Templates</option>
                                <option value="favorites">Favorites</option>
                            </select>
                        </span>
                        </div><!-- /col-sm-4 col-xs-6 -->

                        <!--HOW MANY PER PAGE-->
                        <div class="col-sm-5 col-sm-offset-3 col-xs-6">
                        <span class="filter-per-page">
                            <span class="filter-label">Lists/Page</span>
                            <select class="form-control select select-dark select-block"
                                    ref="selectOptions"
                                    data-toggle="select"
                            >
                                 <option value="5" selected>5</option>
                                 <option value="10">10</option>
                                 <option value="25">25</option>
                                 <option value="50">50</option>
                            </select>
                        </span>
                        </div><!-- /col-sm-5 col-sm-offset-3 col-xs-6 -->
                    </div><!-- /.md-wrapper -->
                </transition>
            </div><!-- /.row -->

            <!--PAGINATION - TOP-->
            <div class="row row-contained">
                <div  v-if="showPaging" class="info">
                    <template>
                        <ul v-if="(lists.last_page > 1)"
                            class="pagination-plain"
                        >
                            <li v-if="lists.prev_page_url"
                                class="previous"
                            >
                                <a class="btn btn-primary circle hint--top hint--rounded"
                                   aria-label="first page"
                                   @click="fetchPage(1)"
                                >
                                    <span class="fa fa-arrow-left" aria-hidden="true"></span>
                                </a>
                            </li>

                            <li v-for="page in pageRange"
                                :class="{ 'active': page === lists.current_page - 1 }"
                            >
                                <a @click="fetchPage(page + 1)">{{ page + 1 }}</a>
                            </li>

                            <li v-if="lists.next_page_url"
                                class="next"
                            >
                                <a class="btn btn-primary circle hint--top hint--rounded"
                                   aria-label="last page"
                                   @click="fetchPage(lists.last_page)"
                                >
                                    <span class="fa fa-arrow-right" aria-hidden="true"></span>
                                </a>
                            </li>
                        </ul>
                    </template>

                    <div class="page"
                          :style="{ 'font-size': '15px',
                                    'font-weight': 'lighter',
                                    'opacity': showPaging ? 1 : 0
                                  }"
                    >
                        <span>
                            page {{ lists.current_page }}/{{ lists.last_page }}
                        </span>
                    </div>
                </div><!-- /.info -->
            </div><!-- /.row -->

            <!--LIST OF COLLECTIONS-->
            <div class="row row-contained">
                <collection v-for="(list, index) in lists.data"
                            :key="list.id"
                            :collection="list"
                            :expired="expired"
                            :loader="loader.url"
                            :style="index === lists.data.length - 1 ? { 'margin-bottom': 0 } : {}"
                ></collection>
            </div><!-- /.row -->

            <!--PAGINATION - BOTTOM-->
            <div class="row row-contained">
                <div v-if="hasResults">
                    <div v-if="showPaging" class="info">
                        <ul v-if="lists.last_page > 1"
                            class="pagination-plain"
                        >
                            <li v-if="lists.prev_page_url"
                                class="previous"
                            >
                                <a class="btn btn-primary circle hint--top hint--rounded"
                                   aria-label="first page"
                                   @click="fetchPage(1)"
                                >
                                    <span class="fa fa-arrow-left" aria-hidden="true"></span>
                                </a>
                            </li>

                            <li v-for="page in pageRange"
                                :class="{ 'active': page === lists.current_page - 1 }"
                            >
                                <a @click="fetchPage(page + 1)">{{ page + 1 }}</a>
                            </li>

                            <li v-if="lists.next_page_url"
                                class="next"
                            >
                                <a class="btn btn-primary circle hint--top hint--rounded"
                                   aria-label="last page"
                                   @click="fetchPage(lists.last_page)"
                                >
                                    <span class="fa fa-arrow-right" aria-hidden="true"></span>
                                </a>
                            </li>
                        </ul>

                        <div class="page"
                              :style="{ 'font-size': '15px',
                                    'font-weight': 'lighter',
                                    'opacity': showPaging ? 1 : 0
                                  }"
                        >
                            <span>
                                page {{ lists.current_page }}/{{ lists.last_page }}
                            </span>
                    </div>
                    </div>
                </div><!-- /.row -->

                <div v-if="!hasResults" class="no-lists" style="margin-bottom: 200px;">
                    No results
                </div>
            </div>
        </template>

        <div v-else
             class="no-lists"
        >
            You have no product lists yet
        </div>

        <!-- declare modals -->
        <modal id="create-new-modal"
               title="Create list"
               :small="true"
               :show="creating"
               ok-text="Create"
               ok-class="create-list-button btn-primary"
               @ok="createList"
               @cancel="closeCreate"
        >
            <div class="form-group"
                 @keyup.enter="createList"
            >
                <label>Product list title</label>
                <input id="new-list-title"
                       v-model="list.title"
                       type="text"
                       class="form-control"
                       ref="newTitle"
                       placeholder="please enter a title"
                >
                <div v-if="!titleOk"
                     style="color: #a00; font-size: 12px; margin-top: 5px"
                >
                    the title must have at least 5 characters
                </div>
            </div>

            <div>
                <label>From template (optional)</label>
                <select class="form-control select select-primary mbl"
                        data-toggle="select"
                        :disabled="!templates.length"
                        ref="templateSelect"
                        style="width: 100%;"
                >
                    <option class="hidden" selected>no template</option>
                    <template v-for="(template, index) in templates">
                        <option :value="template.id"
                        >
                            {{ template.title }}
                        </option>
                    </template>
                </select>

                <div class="row">
                    <div class="col-md-12" style="margin-top: -12px; font-size: 12px;">
                        <a class="pull-right" href="/page/how-to-use-a-template-list" target="_blank">How do I create a
                            template?</a>
                    </div>
                </div>
            </div>
        </modal>

        <!-- declare expiration modal -->
        <modal id="expired-modal"
               title="Expired account"
               :show="expiredDialog"
               ok-text="Upgrade"
               ok-class="upgrade-button btn-primary"
               @ok="upgradeAccount"
               @cancel="closeExpired"
        >
            <div>
                <div class="row">
                    <div class="col-md-12" style="font-size: 14px;">
                        To create new lists, modify existing ones and view any statistics on your lists, you will need
                        to upgrade your account to a paid subscription
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 pull-right" style="margin-top: 12px; font-size: 12px;">
                        <a class="pull-right" href="/pricing" target="_blank">View plans</a>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<!--Style-->
<style lang='less'>
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    #lists {
        .slide-enter-active {
            -moz-transition-duration: 0.4s;
            -webkit-transition-duration: 0.4s;
            -o-transition-duration: 0.4s;
            transition-duration: 0.4s;
            -moz-transition-timing-function: ease-in;
            -webkit-transition-timing-function: ease-in;
            -o-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
        }

        .slide-leave-active {
            -moz-transition-duration: 0.4s;
            -webkit-transition-duration: 0.4s;
            -o-transition-duration: 0.4s;
            transition-duration: 0.4s;
            -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
            -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
            -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        }

        .slide-enter-to, .slide-leave {
            max-height: 100px;
            overflow: hidden;
        }

        .slide-enter, .slide-leave-to {
            overflow: hidden;
            max-height: 0;
        }

        @media screen and (max-width: 767px) {
            min-height: 85vh;
        }

        transition: 0.3s opacity ease;
        opacity: 0;

        &.show {
            opacity: 1;
        }

        > .menu-wrapper {
            margin-bottom: 45px;
            margin-top: 20px;

            > .create-new {

                > button {
                    .transition(0.3s);
                    .slick(0.8);
                    .block-round;

                    width: 50px;
                    height: 50px;
                    position: relative;

                    > span {
                        .centered-block;
                    }
                }

                > .description {
                    color: #555;
                    cursor: pointer;
                    float: left;
                    font-size: 13px;
                    font-weight: bold;
                    padding: 13px 8px;
                    text-transform: uppercase;
                    white-space: nowrap;
                }
            }

            > .buttons-right {
                > .btn {
                    position: relative;
                    margin: 6px 0;

                    @media screen and (max-width: 767px) {
                        padding: 10px 15px;
                    }

                    &.social-link-button {
                        margin-right: 5px;
                        background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
                        color: #fff;
                        opacity: 1;
                        transition: 0.25s all ease;

                        &:hover {
                            opacity: 0.75;
                        }
                    }

                    > span:first-child {
                        padding-right: 10px;
                        position: relative;
                        top: 1px;

                        @media screen and (max-width: 767px) {
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        .no-lists {
            text-align: center;
            font-weight: lighter;
            font-size: 28px;
            color: #aaa;
            margin-top: 55px;
        }

        .search {
            > .input-group {

                > input,
                > span > button {
                    background: #eee !important;
                    border: none;
                }

                > input {
                    padding-left: 5px !important;
                    font-size: 15px;
                }
            }

            padding: 0;
            margin-bottom: 15px;
        }

        > .info {
            padding-bottom: 0;
            padding-left: 10px;
            padding-right: 10px;

            > .filter {
                background: #eee;
                height: 38px;
                border-radius: 50px;
                transition: background ease 0.25s;
                cursor: pointer;
                position: relative;
                display: block;
                padding: 6px;
                padding-left: 16px;
                color: #555;
                margin-top: -3px;

                &:not(.selecting) {
                    &:hover {
                        background: #ddd;
                    }
                }

                > .text {
                    text-transform: capitalize;
                }

                > .symbol {
                    padding: 10px;
                }

                > .options {
                    background: #e7e9eb;
                    border-radius: 4px;
                    overflow: hidden;
                    text-transform: capitalize;
                    position: absolute;
                    right: 0;
                    top: 47px;
                    z-index: 99;

                    > div {
                        padding: 8px 16px;
                        cursor: pointer;
                        transition: all ease 0.3s;

                        &.selected {
                            background: @brand-secondary;
                            color: #fff;
                        }

                        &:hover {
                            background-color: #dfe2e5;
                            color: inherit;
                        }
                    }
                }
            }
        }

        > .filters {
            margin-bottom: 45px;

            .xs-wrapper {
                font-size: 18px;
                padding-left: 10px;
                margin-bottom: 14px;
            }

            .md-wrapper {
                .filter-label {
                    font-size: 13px;
                    font-weight: 500;
                    color: rgb(85, 85, 85);
                    margin-right: 8px;
                }

                .filter-select {
                    display: flex;
                    align-items: center;

                    .select {
                        min-width: 120px;
                    }
                }

                .filter-per-page {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .select {
                        min-width: 70px !important;
                    }
                }
            }
        }

        > .pages {
            padding-bottom: 50px;
            padding-top: 20px;
        }

        > .row .info,
        > .row .pages,
        > .info,
        > .pages {
            margin: 8px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            > .pagination-plain {
                font-size: 16px;
                display: inline-block;
                margin-bottom: 0; /*resets another style*/

                > li {

                    &.active {
                        pointer-events: none;
                    }

                    > a {
                        cursor: pointer;

                        &.btn {
                            width: 30px;
                            height: 30px;
                            position: relative;
                            border-radius: 50%;
                            top: -2px;

                            > span {
                                .centered-block;

                                padding: 0 !important;
                                font-size: 10px;
                            }
                        }
                    }

                    &.previous,
                    &.next {
                        @media (max-width: 462px) {
                            display: none;
                        }
                    }

                    &.previous {
                        padding-right: 7px;

                        > a {

                            > span {
                                padding-right: 5px;
                            }
                        }
                    }

                    &.next {
                        padding-left: 7px;

                        > a {

                            > span {
                                padding-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<!--Script-->
<script>
    import Collection from './Collection.vue';
    import Modal from '../lib/components/Modal.vue'

    import date from 'date-and-time';
    import EnjoyHint from '../enjoyhint'

    import { mixin as clickaway } from 'vue-clickaway';

    const TOUR_PLAYED = 'list-tour-played';

    export default
    {
        mixins: [
            clickaway
        ],

        data() {
            return {
                show: false,
                listData: null,
                user: null,
                templates: [],
                totalLists: null,
                pagination: 5,

                creating: false,
                expiredDialog: false,
                list: {
                    title: '',
                    template: null
                },
                searchString: null,
                selectedFilter: 'all',
                mobileFilters: false,
            }
        },

        props: [
            'data',
            'paginator',
            'loader',
        ],

        computed: {
            lists() {
                return this.listData.paginator;
            },
            paginatorPath() {
                return this.listData.path;
            },
            pageRange: function () {
                return _.filter(_.times(this.lists.last_page), this.rangeFilter);
            },

            dateNow() {
                return moment(new Date()).format('dddd Do of MMMM YYYY (HH:mm)');
            },

            titleOk() {
                let length = this.list.title.length;

                return length === 0 || length >= 5;
            },

            hasResults() {
                try {
                    let bool = !!this.lists.data.length;

                    if (bool) {
                        this.$nextTick(() => {
                            this.initPaging();
                        });

                        return bool;
                    }
                } catch (e) {
                    return false;
                }
            },
            showPaging() {
                try {
                    let bool = this.lists.last_page > 1 || this.pagination !== 5;

                    if (bool) {
                        this.$nextTick(() => {
                            this.initPaging();
                        });

                        return bool;
                    }
                } catch (e) {
                    return true;
                }
            },

            expired() {
                return this.user.status!=="ok"
            },

            statsRoute() {
                return route('stats.index');
            }
        },

        created() {
            _.merge(this, this.data);

            this.totalLists = this.lists.total;

            this.$store.state.user = this.user;
            this.$store.state.userDefaults = JSON.parse(this.user.defaults);
        },

        mounted(){
            this.initPaging();

            this.$events.on('list-removed', () => {
                this.fetchPage();
            });

            $(this.$refs.templateSelect).on('change', (e) => {
                this.list.template = parseInt(e.val);
            });

            $(this.$refs.filterSelector).on('select2-selecting', (e) => {
                this.selectFilter(e.val)
            });

            this.show = true;

            this.$nextTick(() => {
                if (!this.$localStorage.get(TOUR_PLAYED) && !window.isMobile(930, 700)) {
                    let userShops = [];

                    this.$http.get('/api/user/shops').then(response => {
                        userShops = response.data;

                        if (userShops.length) {
                            this.$http.get(`/api/user/setting/${ TOUR_PLAYED }`).then(response => {
                                let tourHasPlayed = !!response.data.value;

                                if (!tourHasPlayed) {
                                    let setPlayed = () => {
                                                let setting = {};
                                                setting[TOUR_PLAYED] = true;

                                                this.$http.post('/api/user/setting', setting);
                                                this.$localStorage.set(TOUR_PLAYED, true);
                                            },
                                            enjoyhint = new EnjoyHint({
                                                onEnd: () => {
                                                    setPlayed();
                                                },
                                                onSkip: () => {
                                                    setPlayed();
                                                }
                                            });

                                    var enjoyhint_script_steps = [
                                        {
                                            'click #create-new': 'Click the "Add" button to create your list',
                                            shape: 'circle',
                                            radius: 30
                                        },
                                        {
                                            'next #new-list-title': 'Enter a title (must be at least 5 characters)'
                                        },
                                        {
                                            'click .create-list-button': 'Click the "Create" button'
                                        }
                                    ];

                                    enjoyhint.set(enjoyhint_script_steps);
                                    enjoyhint.run();
                                }
                                else
                                    this.$localStorage.set(TOUR_PLAYED, true);
                            }).catch(error => {
                                console.log(error);
                            });
                        }
                        else {
                            let enjoyhint = new EnjoyHint({});

                            var enjoyhint_script_steps = [
                                {
                                    'click #nav-shops': 'You have not configured any shops yet.<br>Click "Shops" and add some now!'
                                }
                            ];

                            enjoyhint.set(enjoyhint_script_steps);
                            enjoyhint.run();
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }
            });
        },

        methods: {
            initPaging() {
                if ($('[data-toggle="select"]').length)
                    $('[data-toggle="select"]').select2();

                $(this.$refs.selectOptions).on('select2-selecting', (e) => {
                    let optionVal = parseInt(e.val);

                    document.body.style.cursor = 'wait';

                    this.$http.get(`/api/collections/pagination/${ optionVal }`).then(response => {
                        this.fetchPage(1, () => {
                            this.$nextTick(() => {
                                this.pagination = optionVal;
                            });
                        });
                    }).catch(error => {
                        console.log(error);

                        document.body.style.cursor = 'inherit';
                    });
                });
            },
            openCreate() {

                if (this.user.status == "ok") {
                    this.creating = true;
                } else {
                    // user is not okay for a reason.. maybe add more options here.
                    this.expiredDialog = true;
                }

                this.$nextTick(() => {
                    this.$refs.newTitle.select();
                });
            },

            closeCreate() {
                this.creating = false;
            },

            closeExpired() {
                this.expiredDialog = false;
            },

            upgradeAccount() {
                window.location.href = this.env.APP_URL + '/subscription?return_after_sub=lists';
            },

            createList() {
                if (!this.titleOk)
                    return;

                this.list.title = this.list.title || this.dateNow;

                this.$http.post('/lists', this.list).then(response => {
                    let data = response.body;

                    if (data.error)
                        console.log(data.error)
                    else {
                        // redirect to newly created list
                        window.location.href = `/lists/${ data.collection.id }`;
                    }
                }, error => {
                    console.log(error);
                });

                this.closeCreate();
            },

            updateSearch: _.debounce(function (e) {
                this.searchString = e.target.value;
            }, 500),

            rangeFilter: function (index) {
                return ((index > (this.lists.current_page - 4)) && (index < (this.lists.current_page + 2)))
                        || (this.lists.current_page < 3 && index < 5)
                        || (this.lists.current_page > this.lists.last_page - 3 && index > (this.lists.last_page - 6));
            },

            pageUrl(number) {
                return `${ this.paginatorPath }?page=${ number }`;
            },

            fetchPage(page, onComplete = function () {
            }) {
                page = page || this.lists.current_page;

                let pageUrl;

                switch (page) {
                    case 'previous':
                        pageUrl = this.lists.prev_page_url;
                        break;
                    case 'next':
                        pageUrl = this.lists.next_page_url;
                        break;
                    default:
                        pageUrl = this.pageUrl(page);
                }

                this.$http.get(pageUrl, {
                    params: {
                        search: this.searchString,
                        filter: this.selectedFilter
                    },
                    before: request => {
                        if (this.saveRequest)
                            this.saveRequest.abort();

                        this.saveRequest = request;
                    }
                }).then(response => {
                    this.listData = response.data;

                    if (page !== this.lists.current_page)
                        scroll(0, 0);

                    document.body.style.cursor = 'inherit';
                    onComplete();
                }).catch(error => {
                    console.log(error);

                    document.body.style.cursor = 'inherit';
                    onComplete();
                });
            },

            maybeGoToSingleLinks() {
                if (!this.featureAllowed('html_links')) {
                    this.$events.emit('feature-not-supported', 'html_links');
                    return;
                }

                window.location.href = '/product/single';
            },

            maybeGoToSocialLink() {
                if (!this.featureAllowed('social_link')) {
                    this.$events.emit('feature-not-supported', 'social_link');
                    return;
                }

                window.location.href = '/account?tab=social-link';
            },

            toggleSelectingFilter() {
                this.selectingFilter = !this.selectingFilter;
            },

            selectFilter(filter) {
                this.selectedFilter = filter;
                this.fetchPage(1);
            },

            maybeCloseFilterSelect() {
                if (this.selectingFilter)
                    this.selectingFilter = false;
            }
        },

        components: {
            collection: Collection,
            modal: Modal,
        },

        watch: {
            searchString() {
                this.fetchPage(1);
            }
        }
    }
</script>
