module.exports = {
    product: {
        enabled: {
            background: true,
            title: true,
            shopLinks: true
        },
        background: {
            rgba: {
                r: 244,
                g: 244,
                b: 244,
                a: 1
            },
            hex: '#f4f4f4',
            a: 1
        },
        imageRoundness: 50,
        shopLinks: {
            text: 'buy from'
        },
        fontSize: 11,
        textLines: 2
    },
    widget: {
        thumbSize: 2,
        dummyValue: null,
        description: {
            status: false,
            text: "This list contains affiliate links. If you use these links to buy something we may earn a commission. Thank you"
        }
    }
}
