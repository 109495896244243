// Template

<template>
    <div id="list-stats">
        <div class="container">
            <div class="collection">
                <div class="well">
                    <div class="name">
                    <span>
                        <span class="title">
                            <span>{{ list.title }}</span>
                        </span>
                    </span>
                    </div>
                    <div class="date">
                        <span>{{ creationDate }}</span>
                    </div>
                    <br>
                </div>

                <div class="buttons">
                    <div class="wrapper">
                        <button class="btn btn-sm btn-primary hint--top hint--rounded"
                                aria-label="back"
                                @click="goBack"
                        >
                            <span class="fa fa-arrow-left"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <statistics :data="data"
                    :api-path="apiPath"
        ></statistics>
    </div>
</template>

// Style

<style lang="less">
    @import (inline) "~vue-multiselect/dist/vue-multiselect.min.css";

    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    #list-stats {

        .collection {
            display: block;
            background: #ececec;
            margin-bottom: -25px;
            border-radius: 5px;

            > .well {
                width: 100%;

                &.well {
                    border: none;
                    margin-bottom: 0;
                    padding-bottom: 1px;
                    box-shadow: none;
                    border-radius: 0;
                    background: fadeout(#fff, 50%);
                }

                > .name {
                    font-weight: bold;
                    color: fadeout(#000, 40%);
                    font-size: 25px;
                    /*line-height: 1;*/
                    margin-bottom: 3px;

                    > span {

                        > .title {

                            > .fa {
                                opacity: 0;
                                color: fadeout(#000, 70%);
                                padding-left: 10px;
                            }

                            &:hover {

                                > .fa {
                                    opacity: 1;
                                }
                            }
                        }

                        > .title,
                        input {
                            border: none;
                            outline: none;
                            border-radius: 4px;
                            padding: 0;
                        }

                        > input {
                            padding: 0;
                        }

                        > .btn {
                            .block-round;

                            position: relative;
                            width: 25px;
                            height: 25px;
                            padding: 0;
                            font-size: 10px;
                            margin-left: 3px;
                            margin-top: -3px;

                            > span {
                                .centered-block;

                                line-height: 1;
                            }
                        }

                        > .loading {
                            position: relative;
                            top: 4px;
                            left: 5px;
                        }
                    }
                }

                > .date {
                    font-size: 13px;
                    color: fadeout(#000, 60%);
                }

                > .leads {

                }
            }

            > .buttons {
                display: inline-block;
                width: 100%;

                > .wrapper {
                    display: inline-block;
                    float: right;
                    margin-bottom: -6px;
                    padding: 10px;

                    .btn {
                        .block-round;

                        width: 40px;
                        height: 40px;
                        position: relative;
                        padding: 0;

                        > .icon {
                            .centered-block;

                            font-size: 15px;
                            margin-top: 2px;
                        }
                    }
                }
            }
        }
    }
</style>

// Javascript

<script  >
    import Statistics from './Statistics.vue';

    export default
    {
        data() {
            return {

            }
        },

        props: {
            data: {
                type: Object,
                default: function() {
                    return {};
                }
            },
            leadCount: {
                type: Number,
                default: 0
            },
            apiPath: {
                type: String,
                default: '/stats'
            },
            list: {
                type: Object,
                default: function() {
                    return {};
                }
            }
        },

        created() {
            _.merge(this, this.data);

//            console.log(this.list)
        },

        computed: {
            creationDate() {
                return moment(this.list.created_at).format('dddd Do of MMMM YYYY (HH:mm)');
            }
        },

        methods: {
            goBack() {
                window.history.back();
            }
        },

        components: {
            statistics: Statistics
        }
    }
</script>
