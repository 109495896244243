var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { id: "user-library" } },
    [
      _c("div", { staticClass: "row menu" }, [
        _c("div", { staticClass: "col-md-12 menu-bar" }, [
          _c("ul", { staticClass: "nav nav-pills pull-left" }, [
            _c(
              "li",
              {
                class: { active: _vm.rendering === "customLinks" },
                on: {
                  click: function($event) {
                    return _vm.toggleLinks("customLinks")
                  }
                }
              },
              [
                _vm.isTeamMember
                  ? _c("a", [_vm._v("Team library")])
                  : _c("a", [_vm._v("Your library")])
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                class: { active: _vm.rendering === "usedLinks" },
                on: {
                  click: function($event) {
                    return _vm.toggleLinks("usedLinks")
                  }
                }
              },
              [_c("a", [_vm._v("Most used")])]
            ),
            _vm._v(" "),
            !_vm.isTeamMember
              ? _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.addLink($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                        Add new product\n                    "
                      )
                    ]
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.plan.html_links && !_vm.hasExpired && !_vm.isTeamMember
            ? _c(
                "a",
                {
                  staticClass:
                    "btn btn-darkgray single-link-button pull-right hidden-xs",
                  on: { click: _vm.maybeGoToSingleLinks }
                },
                [
                  _c("span", { staticClass: "fa fa-link" }),
                  _vm._v("\n                Single product link\n            ")
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.plan.html_links && !_vm.hasExpired && !_vm.isTeamMember
            ? _c(
                "a",
                {
                  staticClass:
                    "btn btn-darkgray single-link-button visible-xs-inline-block",
                  staticStyle: { "margin-top": "20px", width: "80%" },
                  on: { click: _vm.maybeGoToSingleLinks }
                },
                [
                  _c("span", { staticClass: "fa fa-link" }),
                  _vm._v("\n                Single product link\n            ")
                ]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row search" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            {
              staticClass: "input-group input-group-hg input-group-rounded flat"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control flat",
                attrs: { type: "text", placeholder: "Search..." },
                domProps: { value: _vm.searchString },
                on: { input: _vm.updateSearch }
              })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row in-bulk hidden-lg" },
        [
          _vm._m(1),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide" } }, [
            _vm.selectedLinks.length
              ? _c("div", { staticClass: "col-md-12 actions" }, [
                  _c("span", [_vm._v("Bulk Actions")]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        class: [
                          "btn",
                          "btn-primary",
                          "btn-sm",
                          "hint--top hint--rounded",
                          { disabled: !_vm.selectedLinks.length }
                        ],
                        attrs: { "aria-label": "add to product list" },
                        on: {
                          click: function($event) {
                            return _vm.toggleAddToList(true)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "icon icon-logo" }),
                        _vm._v(" Add to product-list\n                    ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        class: [
                          "btn",
                          "btn-danger",
                          "btn-sm",
                          "hint--top hint--rounded",
                          {
                            disabled:
                              !_vm.selectedLinks.length || !_vm.selectedCustom
                          }
                        ],
                        attrs: { "aria-label": "remove selected" },
                        on: { click: _vm.deleteSelectedCustom }
                      },
                      [
                        _c("span", { staticClass: "fa fa-trash" }),
                        _vm._v(" Delete from library\n                    ")
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "products" },
        [
          _vm.links.data.length
            ? [
                _c(
                  "div",
                  { staticClass: "info" },
                  [
                    _vm.links.last_page > 1
                      ? [
                          _c(
                            "ul",
                            { staticClass: "pagination-plain" },
                            [
                              _vm.links.prev_page_url
                                ? _c("li", { staticClass: "previous" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-primary circle hint--top hint--rounded",
                                        attrs: { "aria-label": "first page" },
                                        on: {
                                          click: function($event) {
                                            return _vm.fetchPage(1)
                                          }
                                        }
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "fa fa-arrow-left",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.pageRange, function(page) {
                                return _c(
                                  "li",
                                  {
                                    class: {
                                      active:
                                        page === _vm.links.current_page - 1
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.fetchPage(page + 1)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(page + 1))]
                                    )
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _vm.links.next_page_url
                                ? _c("li", { staticClass: "next" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-primary circle hint--top hint--rounded",
                                        attrs: { "aria-label": "last page" },
                                        on: {
                                          click: function($event) {
                                            return _vm.fetchPage(
                                              _vm.links.last_page
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "fa fa-arrow-right",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "page pull-right",
                              staticStyle: {
                                "padding-right": "10px",
                                "font-size": "18px",
                                "font-weight": "lighter"
                              }
                            },
                            [
                              _vm._v(
                                "\n                        page " +
                                  _vm._s(_vm.links.current_page) +
                                  "/" +
                                  _vm._s(_vm.links.last_page) +
                                  "\n                    "
                              )
                            ]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _vm._l(_vm.links.data, function(link, index) {
                  return _c("product", {
                    key: link.id,
                    attrs: {
                      inuserproducts: true,
                      userid: _vm.user.id,
                      data: link,
                      user: _vm.user,
                      tsize: "custom"
                    },
                    on: {
                      deletelink: _vm.deleteLink,
                      addtocollection: _vm.selectLink,
                      editlink: _vm.onProductEdited
                    }
                  })
                })
              ]
            : _c("div", { staticClass: "no-results" }, [
                _vm._v("\n            No results found\n        ")
              ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pages" },
        [
          _vm.links.last_page > 1
            ? [
                _c(
                  "ul",
                  { staticClass: "pagination-plain" },
                  [
                    _vm.links.prev_page_url
                      ? _c("li", { staticClass: "previous" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-primary circle hint--top hint--rounded",
                              attrs: { "aria-label": "first page" },
                              on: {
                                click: function($event) {
                                  return _vm.fetchPage(1)
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "fa fa-arrow-left",
                                attrs: { "aria-hidden": "true" }
                              })
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.pageRange, function(page) {
                      return _c(
                        "li",
                        {
                          class: { active: page === _vm.links.current_page - 1 }
                        },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.fetchPage(page + 1)
                                }
                              }
                            },
                            [_vm._v(_vm._s(page + 1))]
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.links.next_page_url
                      ? _c("li", { staticClass: "next" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-primary circle hint--top hint--rounded",
                              attrs: { "aria-label": "last page" },
                              on: {
                                click: function($event) {
                                  return _vm.fetchPage(_vm.links.last_page)
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "fa fa-arrow-right",
                                attrs: { "aria-hidden": "true" }
                              })
                            ]
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "page pull-right",
                    staticStyle: {
                      "padding-right": "10px",
                      "font-size": "18px",
                      "font-weight": "lighter"
                    }
                  },
                  [
                    _vm._v(
                      "\n                page " +
                        _vm._s(_vm.links.current_page) +
                        "/" +
                        _vm._s(_vm.links.last_page) +
                        "\n            "
                    )
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bulk-actions visible-lg" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "menu" }, [
            _c(
              "button",
              {
                class: [
                  "btn",
                  "btn-primary",
                  "hint--top hint--rounded",
                  { disabled: !_vm.selectedLinks.length }
                ],
                attrs: { "aria-label": "add to list" },
                on: {
                  click: function($event) {
                    return _vm.toggleAddToList(true)
                  }
                }
              },
              [_c("span", { staticClass: "icon icon-logo" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                class: [
                  "btn",
                  "btn-danger",
                  "hint--top hint--rounded",
                  { disabled: !_vm.selectedLinks.length || !_vm.selectedCustom }
                ],
                attrs: { "aria-label": "remove selected" },
                on: { click: _vm.deleteSelectedCustom }
              },
              [_c("span", { staticClass: "fa fa-trash" })]
            )
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedLinks.length,
                  expression: "selectedLinks.length"
                }
              ],
              staticClass: "scrollbar"
            },
            [
              _c(
                "scrollbar",
                { attrs: { type: "macosx", "scroll-bottom": true } },
                [
                  _c(
                    "transition-group",
                    {
                      staticClass: "selected-products",
                      attrs: { name: "selected-products", tag: "div" }
                    },
                    _vm._l(_vm.selectedLinks, function(link) {
                      return _c(
                        "div",
                        { key: link.id, staticClass: "selected-product" },
                        [
                          _c(
                            "div",
                            { staticClass: "thumb-wrapper" },
                            [
                              _c(
                                "div",
                                { staticClass: "thumb" },
                                [
                                  _c("image-square", {
                                    attrs: {
                                      src: link.thumb_path,
                                      alt: link.title,
                                      opacity: 0.7,
                                      "spinner-color": "#bbb",
                                      "spinner-side": "15px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isUserLink(link)
                                ? _c("div", { staticClass: "user" }, [
                                    _c("span", { staticClass: "fa fa-user" })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("logo-image", {
                                attrs: {
                                  src: link.shop_icon,
                                  side: "22px",
                                  "extra-classes": "shop"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(link.title))
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "button",
                              on: {
                                click: function($event) {
                                  return _vm.deselectLink(link)
                                }
                              }
                            },
                            [_c("span", { staticClass: "fa fa-times" })]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.selectedLinks.length,
                  expression: "!selectedLinks.length"
                }
              ],
              staticClass: "no-selected"
            },
            [
              _c("h5", [_vm._v("No item selected")]),
              _vm._v(" "),
              _c("div", [_vm._v("(selected items will appear here)")])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("tags-modal", {
        attrs: {
          show: _vm.tagsModal.show,
          "product-id": _vm.tagsModal.productId,
          "aria-label": "tags-modal"
        },
        on: {
          cancel: function($event) {
            _vm.tagsModal.show = false
          }
        }
      }),
      _vm._v(" "),
      _c("product-modal", {
        attrs: {
          show: _vm.addProductModal.show,
          data: _vm.addProductModal.data,
          "aria-label": "product-modal"
        },
        on: { cancel: _vm.onProductModalClose }
      }),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            title: "Add to list",
            small: true,
            show: _vm.adding,
            "ok-text": "Add",
            "ok-class": "create-list-button btn-primary",
            "aria-label": "add-to-list"
          },
          on: {
            ok: _vm.addSelectedToList,
            cancel: function($event) {
              return _vm.toggleAddToList(false)
            }
          }
        },
        [
          _c(
            "div",
            {
              class: ["form-group", "choice", { selected: _vm.addMode === 0 }],
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.addSelectedToList($event)
                },
                mousedown: function($event) {
                  return _vm.setAddMode("new")
                }
              }
            },
            [
              _c("label", [_vm._v("Create new")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.list.title,
                    expression: "list.title"
                  }
                ],
                ref: "newTitle",
                staticClass: "form-control",
                attrs: {
                  id: "new-list-title",
                  type: "text",
                  placeholder: "please enter a title"
                },
                domProps: { value: _vm.list.title },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.list, "title", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              !_vm.titleOk
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#a00",
                        "font-size": "12px",
                        "margin-top": "5px"
                      }
                    },
                    [
                      _vm._v(
                        "\n                the title must have at least 5 characters\n            "
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.userLists.length
            ? [
                _c("div", { staticClass: "or" }, [
                  _c("div", { staticClass: "text" }, [_vm._v("or")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "line" })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: ["choice", { selected: _vm.addMode === 1 }],
                    staticStyle: { "margin-bottom": "20px" },
                    on: {
                      mousedown: function($event) {
                        return _vm.setAddMode("append")
                      }
                    }
                  },
                  [
                    _c("label", [_vm._v("Append to existing")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        ref: "listSelect",
                        staticClass: "form-control select select-primary",
                        staticStyle: { width: "100%" },
                        attrs: {
                          "data-toggle": "select",
                          disabled: !_vm.userLists.length
                        }
                      },
                      [
                        _vm._l(_vm.userLists, function(userList) {
                          return [
                            userList.id === _vm.list.id
                              ? _c(
                                  "option",
                                  {
                                    attrs: { selected: "" },
                                    domProps: { value: userList.id }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(userList.title) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              : _c(
                                  "option",
                                  { domProps: { value: userList.id } },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(userList.title) +
                                        "\n                        "
                                    )
                                  ]
                                )
                          ]
                        })
                      ],
                      2
                    )
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            title: "Edit list",
            small: true,
            show: _vm.redirect,
            "ok-text": "Edit",
            "cancel-text": "Continue"
          },
          on: {
            ok: _vm.redirectToList,
            cancel: function($event) {
              return _vm.toggleRedirectToList(false)
            }
          }
        },
        [
          _vm._v("\n        Do you want to edit list "),
          _c("b", [_vm._v(_vm._s(_vm.currentListTitle))]),
          _vm._v("?\n    ")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-btn" }, [
      _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
        _c("span", { staticClass: "fa fa-search" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12 help-text" }, [
      _c("i", [
        _vm._v("* Select one or more of your products for bulk actions")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }