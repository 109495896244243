<!--Template-->

<template>
    <div :class="[ 'link', { 'checked': checked } ]"
         @click="toggleSelection"
    >
        <div class="details col-md-1">
            <div class="row">
                <image-square :src="data.thumbPath"
                              :alt="data.name"
                              spinner-color="#aaa"
                              @image-errored='destroySelf'
                >
                    <a :href="url"
                       target="_blank"
                       title="visit link"
                       @click.stop
                    ></a>
                </image-square>
            </div>
        </div>
        <div class="name col-md-4">{{ name }}</div>
        <div class="feed col-md-7">{{ url }}</div>
    </div>
</template>

<!--Style-->
<style lang='less' scoped>
    @import "../../../less/mixins";
    @import "../../../less/libs/flat-ui/variables";

    .link {
        background: #eee;
        border-radius: 5px;
        padding: 10px;
        cursor: pointer;
        font-size: 14px;
        display: inline-block;
        width: 100%;

        &.checked {
            background: mix(#eee, @brand-secondary);
        }

        > .details {

            .image {
                width: 80px;
                background: #fff;
                border-radius: 5px;

                > .thumb {

                    > a {
                        .content-block;

                        top: 50%;
                        left: 50%;
                        opacity: 0;
                    }
                }
            }
        }

        > .name {
            font-weight: bolder;
        }

        > .feed {
            word-wrap: break-word;
            overflow: hidden;
        }
    }
</style>

<!--Script-->
<script  >
    export default{
        props: {
            store: {
                type: Object,
                default: {}
            },
            data: {
                type: Object,
                default: {}
            },
            checked: {
                type: Boolean,
                default: true
            }
        },

        created() {
            _.merge(this, this.data);
        },

        computed: {
            migrationProducts: {
                get() {
                    return this.store.getters.getMigrationLinks;
                },
                set(val) {
                    this.store.dispatch('setMigrationLinks', val);
                }
            }
        },

        methods: {
            select(bool = true) {
                _.each(this.migrationProducts, (link, index) => {
                    if (link.id === this.id && link.visible) {
                        this.$set(this.migrationProducts[index], 'checked', bool);
                    }
                });
            },
            toggleSelection() {
                this.select(!this.checked);
            },
            destroySelf() {
                this.$events.emit('destroyMigrationLink', this.id);
            }
        }
    }
</script>
