var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "product",
        _vm.size_class,
        {
          draggable: _vm.draggable,
          removing: _vm.removing,
          "in-products": _vm.inuserproducts
        }
      ],
      attrs: { "data-grp": _vm.grpVal, "data-id": _vm.data.id || 0 }
    },
    [
      _vm.type === "bootstrap"
        ? _c(
            "div",
            {
              ref: "thumbnail",
              class: [
                "thumbnail",
                "group" + _vm.grpVal,
                {
                  selected: _vm.isSelected,
                  "in-search": _vm.inSearch,
                  "in-list": _vm.inlist,
                  saving: _vm.saving,
                  "editing-title": _vm.editingTitle
                }
              ],
              style: { background: _vm.background },
              on: {
                click: _vm.addToCollection,
                mouseover: _vm.updateBackground
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "top-caption",
                  on: {
                    mouseover: function() {
                      _vm.overCaption = true
                    },
                    mouseout: function() {
                      _vm.overCaption = false
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper" },
                    [
                      _vm.inlist
                        ? [
                            !_vm.isJoined
                              ? [
                                  _vm.index
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "join top-button hint--top hint--rounded hint--small",
                                          attrs: {
                                            "aria-label": _vm.overCaption
                                              ? "join with previous"
                                              : ""
                                          },
                                          on: { click: _vm.joinWithPrevious }
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "icon icon-join"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "break top-button hint--top hint--rounded",
                                    attrs: {
                                      "aria-label": _vm.overCaption
                                        ? "break join"
                                        : ""
                                    },
                                    on: { click: _vm.breakGroup }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "icon icon-break"
                                    })
                                  ]
                                )
                          ]
                        : [
                            _vm.isSelected
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "tick top-button",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.deselectProduct($event)
                                      }
                                    }
                                  },
                                  [_c("span", { staticClass: "fa fa-check" })]
                                )
                              : [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isUserLink,
                                          expression: "isUserLink"
                                        }
                                      ],
                                      staticClass: "user top-button"
                                    },
                                    [_c("i", { staticClass: "fa fa-user" })]
                                  )
                                ]
                          ],
                      _vm._v(" "),
                      _vm.inlist
                        ? _c(
                            "span",
                            {
                              class: [
                                "shop_thumbs",
                                "pull-right",
                                { "is-group": _vm.data.group }
                              ],
                              style: { height: _vm.groupHeight }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pull-right" },
                                [
                                  _c("logo-image", {
                                    attrs: {
                                      src: _vm.data.shop_icon,
                                      side: "35px"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.data.groupLinks
                                    ? _vm._l(_vm.data.groupLinks, function(
                                        product,
                                        index
                                      ) {
                                        return index <= 2
                                          ? _c("logo-image", {
                                              key: product.id,
                                              staticStyle: {
                                                position: "relative",
                                                right: "-3px"
                                              },
                                              attrs: {
                                                src: product.shop_icon,
                                                side: "29px"
                                              }
                                            })
                                          : _vm._e()
                                      })
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.data.groupLinks
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pull-right",
                                        staticStyle: { "padding-top": "6px" }
                                      },
                                      _vm._l(_vm.data.groupLinks, function(
                                        product,
                                        index
                                      ) {
                                        return index > 2
                                          ? _c("logo-image", {
                                              key: product.id,
                                              attrs: {
                                                src: product.shop_icon,
                                                side: "29px"
                                              }
                                            })
                                          : _vm._e()
                                      }),
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _c(
                            "span",
                            { staticClass: "shop_thumbs pull-right" },
                            [
                              _c(
                                "div",
                                { staticClass: "pull-right" },
                                [
                                  _c("logo-image", {
                                    attrs: {
                                      src: _vm.data.shop_icon,
                                      side: "35px"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                    ],
                    2
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { ref: "thumbContainer", staticClass: "thumb_container" },
                [
                  _c("image-square", {
                    attrs: {
                      src: _vm.data.thumb_path,
                      alt: _vm.title,
                      opacity: 0.7,
                      "spinner-color": "#bbb",
                      "spinner-side": "30px"
                    }
                  }),
                  _vm._v(" "),
                  !_vm.inlist
                    ? _c("div", { staticClass: "click-once" }, [
                        _vm.inuserproducts
                          ? _c("span", [_vm._v("Click to select")])
                          : _c("span", { staticClass: "click-add" }, [
                              _vm._m(0),
                              _vm._v(" "),
                              _vm._m(1)
                            ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "product-title caption" },
                [
                  _vm.inlist ||
                  (_vm.inuserproducts && !_vm.isUserLink && !_vm.isTeamMember)
                    ? [
                        _c(
                          "span",
                          {
                            class: [
                              "editable-text",
                              { editing: _vm.editingTitle }
                            ],
                            style: {
                              "pointer-events":
                                _vm.inuserproducts || _vm.currentBackground
                                  ? "all"
                                  : "none"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.editTitle(
                                  _vm.inlist ? "custom" : "default"
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "product-title-text",
                                attrs: { title: _vm.title }
                              },
                              [_vm._v(_vm._s(_vm.title))]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "edit" }, [
                              _c("span", { staticClass: "fa fa-pencil" }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "gradient",
                                style: { background: _vm.linearBackground }
                              })
                            ]),
                            _vm._v(" "),
                            _vm.inlist
                              ? [
                                  _vm.editingTitle
                                    ? _c(
                                        "textarea",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.customTitle,
                                              expression: "customTitle"
                                            },
                                            {
                                              name: "on-clickaway",
                                              rawName: "v-on-clickaway",
                                              value: _vm.saveTitle,
                                              expression: "saveTitle"
                                            }
                                          ],
                                          ref: "titleText",
                                          staticClass: "product-title-text",
                                          attrs: { rows: "3" },
                                          domProps: { value: _vm.customTitle },
                                          on: {
                                            keydown: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.saveTitle($event)
                                            },
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.customTitle =
                                                $event.target.value
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.title))]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.inuserproducts
                              ? [
                                  _vm.editingTitle
                                    ? _c(
                                        "textarea",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.customTitleDefault,
                                              expression: "customTitleDefault"
                                            },
                                            {
                                              name: "on-clickaway",
                                              rawName: "v-on-clickaway",
                                              value: _vm.saveDefaultTitle,
                                              expression: "saveDefaultTitle"
                                            }
                                          ],
                                          ref: "titleText",
                                          staticClass: "product-title-text",
                                          attrs: { rows: "3" },
                                          domProps: {
                                            value: _vm.customTitleDefault
                                          },
                                          on: {
                                            keydown: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.saveDefaultTitle(
                                                $event
                                              )
                                            },
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.customTitleDefault =
                                                $event.target.value
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.title))]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    : _c(
                        "p",
                        {
                          staticClass: "product-title-text",
                          attrs: { title: _vm.title }
                        },
                        [_vm._v(_vm._s(_vm.title))]
                      )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _vm.inlist
                    ? [
                        !_vm.editingTitle
                          ? _c("span", [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary hint--top hint--rounded",
                                  attrs: { "aria-label": "preview" },
                                  on: { click: _vm.previewLink }
                                },
                                [_c("i", { staticClass: "fa fa-eye" })]
                              ),
                              _vm._v(" "),
                              !_vm.data.groupLinks
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-darkgray hint--top hint--rounded",
                                      attrs: { "aria-label": "tags" },
                                      on: { click: _vm.addTag }
                                    },
                                    [_c("i", { staticClass: "fa fa-tags" })]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-danger hint--top hint--rounded",
                                  attrs: { "aria-label": "remove" },
                                  on: { click: _vm.removeFromCollection }
                                },
                                [
                                  _vm.removing
                                    ? _c("loader", {
                                        attrs: {
                                          side: "15px",
                                          color: "#fff",
                                          opacity: 1
                                        }
                                      })
                                    : _c("i", { staticClass: "fa fa-trash" })
                                ],
                                1
                              )
                            ])
                          : _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                staticStyle: {
                                  width: "auto",
                                  height: "20px",
                                  "margin-top": "11px",
                                  "line-height": "0",
                                  "padding-left": "28px",
                                  "padding-right": "12px",
                                  float: "right"
                                },
                                on: { click: _vm.saveTitle }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-check",
                                  staticStyle: { left: "15px" }
                                }),
                                _vm._v("save\n                    ")
                              ]
                            )
                      ]
                    : [
                        _c(
                          "span",
                          [
                            !_vm.inuserproducts
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary hint--top hint--rounded",
                                    attrs: { "aria-label": "similar" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.searchSimilar($event)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-cubes" })]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isUserLink
                              ? [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-primary hint--top hint--rounded",
                                      attrs: { "aria-label": "edit" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.editLink($event)
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fa fa-edit" })]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-darkgray hint--top hint--rounded",
                                      attrs: { "aria-label": "tags" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.addTag($event)
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fa fa-tags" })]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-danger hint--top hint--rounded",
                                      attrs: { "aria-label": "remove" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteLink($event)
                                        }
                                      }
                                    },
                                    [
                                      _vm.removing
                                        ? _c("loader", {
                                            attrs: {
                                              side: "15px",
                                              color: "#fff",
                                              opacity: 1
                                            }
                                          })
                                        : _c("i", {
                                            staticClass: "fa fa-trash"
                                          })
                                    ],
                                    1
                                  )
                                ]
                              : [
                                  !_vm.editingTitle
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-darkgray hint--top hint--rounded",
                                          attrs: { "aria-label": "tags" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.addTag($event)
                                            }
                                          }
                                        },
                                        [_c("i", { staticClass: "fa fa-tags" })]
                                      )
                                    : _vm._e()
                                ]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.editingTitle
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                staticStyle: {
                                  width: "auto",
                                  height: "20px",
                                  "margin-top": "11px",
                                  "line-height": "0",
                                  "padding-left": "28px",
                                  "padding-right": "12px",
                                  float: "right"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.saveDefaultTitle($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-check",
                                  staticStyle: { left: "15px" }
                                }),
                                _vm._v("save\n                ")
                              ]
                            )
                          : _vm._e()
                      ]
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "table"
        ? _c("div", { staticClass: "img-small" }, [
            _c(
              "div",
              { staticClass: "image-wrapper" },
              [
                _c("image-square", {
                  attrs: {
                    src: _vm.data.thumb_path,
                    alt: _vm.title,
                    opacity: 0.7,
                    "spinner-color": "#bbb",
                    "spinner-side": "20px"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.joined
              ? _c("div", { staticClass: "joined" }, [
                  _c("span", [_vm._v(_vm._s(_vm.joined))])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prepend" }, [
      _c("span", [
        _c("div", [_c("span", { staticClass: "icon icon-arrow-top" })]),
        _vm._v(" "),
        _c("div", [_vm._v("prepend")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "append" }, [
      _c("span", [
        _c("div", [_vm._v("append")]),
        _vm._v(" "),
        _c("div", { staticClass: "flipped" }, [
          _c("span", { staticClass: "icon icon-arrow-top" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }