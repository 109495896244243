// Template

<template>
    <modal id="invoice-modal"
           :class="{ 'compulsory': invoiceDetailsMissing || !invoiceDetailsComplete }"
           title="Invoice details"
           :show="detailsModalShow"
           @cancel="invoiceDetailsMissing || !invoiceDetailsComplete ? function() {} : detailsModalShow = false"
    >
        <div v-if="invoiceDetailsMissing"
             class="error-msg"
        >
            <div>Please provide your address for your invoices.</div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label class="control-label">First Name</label>
                <input autocomplete="on"
                       v-model="invoiceDetails.firstName"
                       ref="firstname"
                       type="text"
                       class="form-control input-sm"
                       placeholder="first name"
                       @click="$refs.firstname.select()"
                >
            </div>

            <div class="col-md-6">
                <label class="control-label">Last Name</label>
                <input autocomplete="on"
                       v-model="invoiceDetails.lastName"
                       ref="lastname"
                       type="text"
                       class="form-control input-sm"
                       placeholder="last name"
                       @click="$refs.lastname.select()"
                >
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <label class="control-label">Address</label>
                <input autocomplete="on"
                       v-model="invoiceDetails.address"
                       ref="address"
                       type="text"
                       class="form-control input-sm"
                       placeholder="address"
                       @click="$refs.address.select()"
                >
            </div>

            <div class="col-md-12">
                <label class="control-label">City/Region</label>
                <input autocomplete="on"
                       v-model="invoiceDetails.region"
                       ref="region"
                       type="text"
                       class="form-control input-sm"
                       placeholder="region"
                       @click="$refs.region.select()"
                >
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <label class="control-label">Postal Code</label>
                <input autocomplete="on"
                       v-model="invoiceDetails.postalCode"
                       ref="postalCode"
                       type="text"
                       class="form-control input-sm"
                       placeholder="postal code"
                       @click="$refs.postalCode.select()"
                >
            </div>

            <div class="col-md-6">
                <label class="control-label">Country</label>
                <input autocomplete="on"
                       v-model="invoiceDetails.countryCode"
                       ref="countryCode"
                       type="text"
                       class="form-control input-sm"
                       placeholder="country code"
                       @click="$refs.countryCode.select()"
                >
            </div>
        </div>

        <div slot="footer">
            <button :class="[ 'btn', 'btn-embossed', 'btn-primary', { 'unabled': invoiceDetailsMissing } ]"
                    style="width: 69px;"
                    @click="saveUserDetails"
            >
            <template v-if="!saving">Save</template>
                <span v-else
                      class="fa fa-circle-o-notch fa-spin fa-fw"
                ></span>
            </button>

            <button :class="[ 'btn', 'btn-embossed', 'btn-darkgray', invoiceDetailsMissing || !invoiceDetailsComplete ? 'hidden' : 'btn-darkgray' ]"
                    @click="invoiceDetailsMissing || !invoiceDetailsComplete ? function() {} : detailsModalShow = false"
            >
                Close
            </button>
        </div>
    </modal>
</template>

<style lang="less">
    #invoice-modal {
        @import "../../less/mixins";
        @import "../../less/libs/flat-ui/variables";

        .close {
            display: none !important;
        }

        .error-msg {
            padding: 12px;
            font-size: 14px;
            background: @brand-danger;
            line-height: 1.4;
            color: #fff;
            text-align: center;
            border-radius: 5px;
            margin-bottom: 15px;
        }

        label {
            margin-bottom: 0;
            padding-left: 5px;
            font-weight: bolder;
        }

        input {
            margin-bottom: 10px;
            font-size: 13px;
        }

        .modal-footer {
            button {
                &.unabled {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }
</style>

<script  >

    import Modal from '../lib/components/Modal.vue';

    export default
    {
        components: {
            'modal': Modal
        },

        data()
        {
            return {
                detailsModalShow: false,
                invoiceDetails: {
                    firstName: null,
                    lastName: null,
                    address: null,
                    region: null,
                    postalCode: null,
                    countryCode: null
                },
                invoiceDetailsComplete: false,
                saving: false
            }
        },

        props: {
            data: {
                type: Object,
                default() {
                    return {};
                }
            }
        },

        computed: {
            invoiceDetailsMissing() {
                return (!this.invoiceDetails.firstName ||
                        !this.invoiceDetails.lastName ||
                        !this.invoiceDetails.address ||
                        !this.invoiceDetails.region ||
                        !this.invoiceDetails.postalCode ||
                        !this.invoiceDetails.countryCode);
            }
        },

        created() {
            _.merge(this.invoiceDetails, this.data);
            this.invoiceDetailsComplete = !this.invoiceDetailsMissing;

            if (this.invoiceDetailsMissing)
                this.detailsModalShow = true;

            this.$events.on('edit-invoice-details', () => {
                this.detailsModalShow = true;
            });
        },

        methods: {
            saveUserDetails() {
                this.saving = true;

                this.$http.post('/invoice/save/details', {
                    details: this.invoiceDetails
                }).then(response => {
                    if (response.data === 'ok') {
                        this.$events.emit('invoice-details-changed', this.invoiceDetails);

                        setTimeout(() => {
                            this.saving = false;
                            this.detailsModalShow = false;
                        }, 300);
                    }
                }).catch(error => {
                    this.saving = false;
                })
            }
        }
    }
</script>
