// Template

<template>
    <div id="user-library"
         class="container"
    >
        <div class="row menu">
            <div class="col-md-12 menu-bar">
                <ul class="nav nav-pills pull-left">
                    <li :class="{ 'active': rendering === 'customLinks' }"
                        @click="toggleLinks('customLinks')"
                    >
                        <a v-if="isTeamMember">Team library</a>
                        <a v-else>Your library</a>
                    </li>
                    <li :class="{ 'active': rendering === 'usedLinks' }"
                        @click="toggleLinks('usedLinks')"
                    >
                        <a>Most used</a>
                    </li>
                    <li v-if="!isTeamMember">
                        <a href="#" @click.prevent="addLink">
                            Add new product
                        </a>
                    </li>
                </ul>

                <!--DESKTOP BUTTON-->
                <a v-if="plan.html_links && !hasExpired && !isTeamMember"
                   class="btn btn-darkgray single-link-button pull-right hidden-xs"
                   @click="maybeGoToSingleLinks"
                >
                    <span class="fa fa-link"></span>
                    Single product link
                </a>
                <!--MOBILE BUTTON-->
                <a v-if="plan.html_links && !hasExpired && !isTeamMember"
                   class="btn btn-darkgray single-link-button visible-xs-inline-block"
                   @click="maybeGoToSingleLinks"
                   style="margin-top: 20px; width: 80%;"
                >
                    <span class="fa fa-link"></span>
                    Single product link
                </a>
            </div><!-- /.col-md-12 -->
        </div><!-- /.row -->
        <!-- /.menu -->

        <div class="row search">
            <div class="col-md-12">
                <div class="input-group input-group-hg input-group-rounded flat">
                        <span class="input-group-btn">
                            <button type="submit" class="btn">
                                <span class="fa fa-search"></span>
                            </button>
                        </span>
                    <input type="text"
                           :value="searchString"
                           class="form-control flat"
                           placeholder="Search..."
                           @input="updateSearch"
                    >
                </div>
            </div><!-- ./col-md-12 -->
        </div><!-- ./row -->
        <!-- ./search -->


        <div class="row in-bulk hidden-lg">
            <div class="col-md-12 help-text">
                <i>* Select one or more of your products for bulk actions</i>
            </div><!-- ./col-md-12 -->
            <transition name="slide">
                <div class="col-md-12 actions" v-if="selectedLinks.length">
                    <span>Bulk Actions</span>

                    <div>
                        <button :class="[ 'btn', 'btn-primary', 'btn-sm', 'hint--top hint--rounded', { 'disabled': !selectedLinks.length } ]"
                                aria-label="add to product list"
                                @click="toggleAddToList(true)"
                        >
                            <span class="icon icon-logo"></span> Add to product-list
                        </button>

                        <button :class="[ 'btn', 'btn-danger', 'btn-sm', 'hint--top hint--rounded', { 'disabled': !selectedLinks.length || !selectedCustom } ]"
                                aria-label="remove selected"
                                @click="deleteSelectedCustom"
                        >
                            <span class="fa fa-trash"></span> Delete from library
                        </button>
                    </div>
                </div><!-- ./col-md-12 -->
            </transition>
        </div><!-- ./row -->
        <!-- ./search -->

        <div class="products">
            <template v-if="links.data.length">
                <div class="info">
                    <template v-if="links.last_page > 1">
                        <ul class="pagination-plain">
                            <li v-if="links.prev_page_url"
                                class="previous"
                            >
                                <a class="btn btn-primary circle hint--top hint--rounded"
                                   aria-label="first page"
                                   @click="fetchPage(1)"
                                >
                                    <span class="fa fa-arrow-left" aria-hidden="true"></span>
                                </a>
                            </li>

                            <li v-for="page in pageRange"
                                :class="{ 'active': page === links.current_page - 1 }"
                            >
                                <a @click="fetchPage(page + 1)">{{ page + 1 }}</a>
                            </li>

                            <li v-if="links.next_page_url"
                                class="next"
                            >
                                <a class="btn btn-primary circle hint--top hint--rounded"
                                   aria-label="last page"
                                   @click="fetchPage(links.last_page)"
                                >
                                    <span class="fa fa-arrow-right" aria-hidden="true"></span>
                                </a>
                            </li>
                        </ul>

                        <span class="page pull-right"
                              style="padding-right: 10px; font-size: 18px; font-weight: lighter;"
                        >
                            page {{ links.current_page }}/{{ links.last_page }}
                        </span>
                    </template>
                </div>

                <product v-for="(link, index) in links.data"
                         :inuserproducts="true"
                         :userid="user.id"
                         :key="link.id"
                         :data="link"
                         :user="user"
                         tsize="custom"
                         @deletelink="deleteLink"
                         @addtocollection="selectLink"
                         @editlink="onProductEdited"
                ></product>
            </template>

            <div v-else
                 class="no-results"
            >
                No results found
            </div>
        </div>

        <div class="pages">
            <template v-if="links.last_page > 1">
                <ul class="pagination-plain">
                    <li v-if="links.prev_page_url"
                        class="previous"
                    >
                        <a class="btn btn-primary circle hint--top hint--rounded"
                           aria-label="first page"
                           @click="fetchPage(1)"
                        >
                            <span class="fa fa-arrow-left" aria-hidden="true"></span>
                        </a>
                    </li>

                    <li v-for="page in pageRange"
                        :class="{ 'active': page === links.current_page - 1 }"
                    >
                        <a @click="fetchPage(page + 1)">{{ page + 1 }}</a>
                    </li>

                    <li v-if="links.next_page_url"
                        class="next"
                    >
                        <a class="btn btn-primary circle hint--top hint--rounded"
                           aria-label="last page"
                           @click="fetchPage(links.last_page)"
                        >
                            <span class="fa fa-arrow-right" aria-hidden="true"></span>
                        </a>
                    </li>
                </ul>

                <span class="page pull-right"
                      style="padding-right: 10px; font-size: 18px; font-weight: lighter;"
                >
                    page {{ links.current_page }}/{{ links.last_page }}
                </span>
            </template>
        </div>

        <div class="bulk-actions visible-lg">
            <div class="content">
                <div class="menu">
                    <button :class="[ 'btn', 'btn-primary', 'hint--top hint--rounded', { 'disabled': !selectedLinks.length } ]"
                            aria-label="add to list"
                            @click="toggleAddToList(true)"
                    >
                        <span class="icon icon-logo"></span>
                    </button>

                    <button :class="[ 'btn', 'btn-danger', 'hint--top hint--rounded', { 'disabled': !selectedLinks.length || !selectedCustom } ]"
                            aria-label="remove selected"
                            @click="deleteSelectedCustom"
                    >
                        <span class="fa fa-trash"></span>
                    </button>
                </div>

                <span v-show="selectedLinks.length"
                      class="scrollbar"
                >
                    <scrollbar type="macosx"
                               :scroll-bottom="true"
                    >
                        <transition-group name="selected-products"
                                          tag="div"
                                          class="selected-products"
                        >
                            <div v-for="link in selectedLinks"
                                 class="selected-product"
                                 :key="link.id"
                            >
                                <div class="thumb-wrapper">
                                    <div class="thumb">
                                        <image-square :src="link.thumb_path"
                                                      :alt="link.title"
                                                      :opacity="0.7"
                                                      spinner-color="#bbb"
                                                      spinner-side="15px"
                                        ></image-square>
                                    </div>

                                    <div v-if="isUserLink(link)"
                                         class="user"
                                    >
                                        <span class="fa fa-user"></span>
                                    </div>

                                    <logo-image :src="link.shop_icon"
                                                side="22px"
                                                extra-classes="shop"
                                    ></logo-image>
                                </div>

                                <div class="title">{{ link.title }}</div>

                                <div class="button"
                                     @click="deselectLink(link)"
                                >
                                    <span class="fa fa-times"></span>
                                </div>
                            </div>
                        </transition-group>
                    </scrollbar>
                </span>

                <div v-show="!selectedLinks.length"
                     class="no-selected"
                >
                    <h5>No item selected</h5>
                    <div>(selected items will appear here)</div>
                </div>
            </div>
        </div>


        <tags-modal :show="tagsModal.show"
                    :product-id="tagsModal.productId"
                    @cancel="tagsModal.show=false"
                    aria-label="tags-modal"
        ></tags-modal>

        <product-modal :show="addProductModal.show"
                       :data="addProductModal.data"
                       @cancel="onProductModalClose"
                       aria-label="product-modal"
        ></product-modal>

        <modal title="Add to list"
               :small="true"
               :show="adding"
               ok-text="Add"
               ok-class="create-list-button btn-primary"
               @ok="addSelectedToList"
               @cancel="toggleAddToList(false)"
               aria-label="add-to-list"
        >
            <div :class="[ 'form-group', 'choice', { 'selected': addMode === 0 } ]"
                 @keyup.enter="addSelectedToList"
                 @mousedown="setAddMode('new')"
            >
                <label>Create new</label>
                <input id="new-list-title"
                       v-model="list.title"
                       type="text"
                       class="form-control"
                       ref="newTitle"
                       placeholder="please enter a title"
                >
                <div v-if="!titleOk"
                     style="color: #a00; font-size: 12px; margin-top: 5px"
                >
                    the title must have at least 5 characters
                </div>
            </div>

            <template v-if="userLists.length">
                <div class="or">
                    <div class="text">or</div>
                    <div class="line"></div>
                </div>

                <div :class="[ 'choice', { 'selected': addMode === 1 } ]"
                     style="margin-bottom: 20px;"
                     @mousedown="setAddMode('append')"
                >
                    <label>Append to existing</label>
                    <select class="form-control select select-primary"
                            data-toggle="select"
                            :disabled="!userLists.length"
                            ref="listSelect"
                            style="width: 100%;"
                    >
                        <template v-for="userList in userLists">
                            <option v-if="userList.id === list.id"
                                    :value="userList.id"
                                    selected
                            >
                                {{ userList.title }}
                            </option>

                            <option v-else
                                    :value="userList.id"
                            >
                                {{ userList.title }}
                            </option>
                        </template>
                    </select>
                </div>
            </template>
        </modal>

        <modal title="Edit list"
               :small="true"
               :show="redirect"
               ok-text="Edit"
               cancel-text="Continue"
               @ok="redirectToList"
               @cancel="toggleRedirectToList(false)"
        >
            Do you want to edit list <b>{{ currentListTitle }}</b>?
        </modal>
    </div>
</template>

// Style

<style lang="less" scoped>
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    #user-library {
        width: 100%;
        padding-top: 10px;
        padding-left: 250px;
        padding-right: 700px;
        position: relative;

        @media (max-width: 1660px) and (min-width: 1481px) {
            padding-left: 150px;
            padding-right: 600px;
        }

        @media (max-width: 1480px) {
            padding-left: 100px;
            padding-right: 550px;
        }

        @media (max-width: 1228px) {
            padding-left: 100px;
            padding-right: 100px;
        }

        @media (max-width: 734px) {
            padding-left: 50px;
            padding-right: 50px;
        }

        > .menu {
            margin: 30px 0;

            .nav {

                > li {
                    cursor: pointer;
                }
            }

            .menu-bar > {
                @media screen and (max-width: 767px) {
                    text-align: center;
                }

                > button {
                    float: left;

                    > span {
                        opacity: 0.7;
                        padding-right: 5px;
                    }
                }
            }
        }

        > .search {
            .input-group {

                > input,
                > span > button {
                    background: #eee !important;
                    border: none;
                }

                > input {
                    padding-left: 5px !important;
                    font-size: 15px;
                }
            }

            padding: 0;
            margin-bottom: 40px;
        }

        > .products {
            display: inline-block;
            width: 100%;

            > .info {
                padding-bottom: 20px;

                > .pagination-plain {
                    padding-top: 0 !important;
                    margin-bottom: 0 !important;
                }
            }

            > .no-results {
                text-align: center;
                font-weight: lighter;
                font-size: 22px;
                color: #aaa;
            }

            > .product {
                float: left;
                position: relative;
                width: 20%;

                @media (max-width: 1660px) and (min-width: 1481px) {
                    width: 25%;
                }

                @media (max-width: 1480px) {
                    width: 25%;
                }

                @media (max-width: 1228px) {
                    width: 20%;
                }

                @media (max-width: 920px) {
                    width: 25%;
                }

                @media (max-width: 734px) {
                    width: 33.33%;
                }

                @media (max-width: 534px) {
                    width: 50%;
                }
            }
        }

        > .pages {
            padding-bottom: 50px;
            padding-top: 20px;
        }

        > .products > .info,
        > .pages {

            > .pagination-plain {
                font-size: 16px;
                display: inline-block;

                > li {

                    &.active {
                        pointer-events: none;
                    }

                    > a {
                        cursor: pointer;

                        &.btn {
                            width: 30px;
                            height: 30px;
                            position: relative;
                            border-radius: 50%;
                            top: -2px;

                            > span {
                                .centered-block;

                                padding: 0 !important;
                                font-size: 10px;
                            }
                        }
                    }

                    &.previous,
                    &.next {
                        @media (max-width: 462px) {
                            display: none;
                        }
                    }

                    &.previous {
                        padding-right: 7px;

                        > a {

                            > span {
                                padding-right: 5px;
                            }
                        }
                    }

                    &.next {
                        padding-left: 7px;

                        > a {

                            > span {
                                padding-left: 5px;
                            }
                        }
                    }
                }
            }
        }

        > .in-bulk {
            margin-top: 50px;

            .actions {
                display: flex;
                align-items: center;
                margin-top: 15px;

                @media screen and (max-width:767px) {
                    flex-direction: column;
                }

                > span {
                    margin-right: 15px;
                    font-size: 16px;
                    font-weight: 600;
                    color: #424242;

                    @media screen and (max-width:767px) {
                        margin-bottom: 10px;
                    }
                }

                > div {
                    @media screen and (max-width:767px) {
                        text-align: center;
                    }

                    > button {
                        margin-right: 12px;

                        @media screen and (max-width:767px) {
                            margin-bottom: 5px;
                        }
                    }
                }
            }

            .help-text {
                font-size: 15px;
                font-weight: 600;
                color: #16a085;
                opacity: .7;

                @media screen and (max-width:767px) {
                    text-align: center;
                }
            }
        }

        > .bulk-actions {
            width: 450px;
            position: fixed;
            padding-top: 100px;
            right: 0;
            top: 0;
            height: ~'calc(100% - 135px)';
            padding-right: 50px;

            > .content {
                background: #f5f5f5;
                height: 100%;
                border-radius: 5px;
                padding: 10px;
                display: flex;
                flex-direction: column;

                > .no-selected {
                    margin-top: 20px;

                    > h5 {
                        margin-bottom: 5px;
                    }

                    font-size: 14px;
                    text-align: center;
                    color: #555;
                }

                > .menu {
                    @side: 45px;

                    border-bottom: 1px solid fadeout(#000, 95%);
                    box-shadow: 0 1px 0 fadeout(#fff, 80%);

                    padding: 10px;
                    padding-bottom: 20px;
                    margin-bottom: 10px;

                    > button {
                        .block-round;

                        width: @side;
                        height: @side;
                        position: relative;
                        margin-right: 2px;

                        &.disabled {
                            pointer-events: none;
                        }

                        > span {
                            .centered-block;
                        }
                    }
                }

                > .scrollbar {
                    overflow: hidden;
                    flex: 1 1 0;

                    .selected-products {
                        width: 100%;
                        overflow: hidden;

                        > .selected-product {
                            display: inline-flex;
                            flex-direction: row;
                            margin-top: 10px;
                            width: 100%;
                            position: relative;

                            > .thumb-wrapper {
                                position: relative;
                                margin-right: 10px;

                                > .thumb {
                                    .block-round;

                                    overflow: hidden;
                                    padding: 8px;
                                    background: #fff;
                                    width: 75px;
                                    height: 75px;

                                    -webkit-transform: translateZ(0);
                                    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
                                }

                                > .user {
                                    .block-round;

                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    width: 22px;
                                    height: 22px;
                                    overflow: hidden;
                                    background: #fff;
                                    color: #888;
                                    z-index: 2;

                                    > span {
                                        .centered-block;

                                        font-size: 10px;
                                    }
                                }

                                > .shop {
                                    .block-round;

                                    position: absolute;
                                    bottom: 0;
                                    right: 0;
                                    padding: 2px;
                                    z-index: 2;
                                }
                            }

                            > .title {
                                padding: 5px;
                                font-size: 13px;
                                color: #555;
                                flex: 1 1 0;
                            }

                            > .button {
                                width: 50px;
                                margin-right: 5px;
                                cursor: pointer;
                                display: block;
                                position: relative;

                                > span {
                                    .centered-block;

                                    font-size: 20px;
                                    color: fadeout(#000, 50%);
                                    transition: all ease 0.3s;
                                }

                                &:hover {

                                    > span {
                                        color: @brand-danger;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            > .open-button,
              .close-button {
                display: none;
            }

            @media (max-width: 1228px) {
                margin-top: 88px;
                padding: 30px;
                height: ~'calc(100% - 235px)';
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                right: -450px;
                transition: right ease 0.35s;

                > .open-button {
                    .block-round;
                    .slick(0.8);

                    position: absolute;
                    top: 0;
                    left: -60px;
                    display: block;
                    width: 50px;
                    height: 50px;
                    font-size: 16px;
                    transition: transform 0.25s ease;

                    > span {
                        .centered-block;
                    }
                }

                > .close-button {
                    .block-round;

                    position: absolute;
                    top: -17px;
                    left: -17px;
                }

                &.open {
                    right: 0;
                }

                &.closing {
                    right: -450px !important;
                }

                &.open,
                &.closing {
                    box-shadow: 0 0 40px 5px fadeout(#000, 80%);

                    > .close-button {
                        display: block;
                    }
                }
            }
        }

        > .modal-wrapper {

            .modal-body {

                > .choice {
                    padding-left: 20px;
                    position: relative;

                    &:before {
                        .content-block;

                        width: 10px;
                        height: ~'calc(100% + 7px)';
                        border-radius: 5px;
                        background: #e8e8e8;
                        transition: background ease 0.25s;
                    }

                    &.selected {

                        &:before {
                            background: @brand-secondary;
                        }
                    }
                }

                > .or {
                    .or-line(#fff);

                    margin-top: -10px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .selected-product {
        transition: all ease 0.3s 0.05s;
        z-index: 1;
    }
    .selected-products-enter, .selected-products-leave-to {
        opacity: 0;
        transform: translateX(50px);
        z-index: 0;
    }
    .selected-products-leave-active {
        position: absolute;
    }

    .slide-enter-active {
        -moz-transition-duration: 0.2s;
        -webkit-transition-duration: 0.2s;
        -o-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }

    .slide-leave-active {
        -moz-transition-duration: 0.2s;
        -webkit-transition-duration: 0.2s;
        -o-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    .slide-enter-to, .slide-leave {
        max-height: 100px;
        overflow: hidden;
    }

    .slide-enter, .slide-leave-to {
        overflow: hidden;
        max-height: 0;
    }
</style>

// Javascript

<script>
    import Product from './Product.vue'
    import TagsModal from './TagsModal.vue';
    import ProductModal from './ProductModal.vue';

    import Modal from '../lib/components/Modal';
    import Scrollbar from '../lib/components/Scrollbar';
    import ImageSquare from '../lib/components/ImageSquare';
    import LogoImage from '../lib/components/LogoImage';

    import messages from '../lib/config/messages';

    // Vuex
    import { mapActions } from 'vuex'
    import { mapGetters } from 'vuex'

    export default
    {
        components: {
            'product': Product,
            'product-modal': ProductModal,
            'modal': Modal,
            'scrollbar': Scrollbar,
            'tags-modal': TagsModal,
            'image-square': ImageSquare,
            'logo-image': LogoImage,
        },

        data()
        {
            return {
                usedLinks: {},
                customLinks: {},
                usedLinksSearch: '',
                customLinksSearch: '',
                rendering: 'customLinks',

                tagsModal: {
                    show: false,
                    productId: null
                },
                addProductModal: {
                    show: false
                },
                saveRequest: null,

                adding: false,
                redirect: false,
                addMode: 1,
                list: {
                    id: null,
                    title: '',
                    productIds: null
                },
                lastListId: null,
                existingListTitle: null
            }
        },

        props: {
            data: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            user: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },

        computed: {
            ...mapGetters([
                'getUserList'
            ]),

            links() {
                return this[this.rendering].paginator;
            },
            searchString: {
                get() {
                    return this[`${ this.rendering }Search`];
                },
                set(val) {
                    this[`${ this.rendering }Search`] = val;
                }
            },
            paginatorPath() {
                return this[this.rendering].path;
            },
            pageRange: function()
            {
                return _.filter(_.times(this.links.last_page), this.rangeFilter);
            },

            selectedLinks() {
                return this.getUserList;
            },

            selectedIds() {
                return _.map(this.selectedLinks, link => {
                    return link.id;
                });
            },

            selectedCustom() {
                return _.every(this.selectedLinks, link => {
                   return this.isUserLink(link);
                });
            },

            dateNow() {
                return moment(new Date()).format('dddd Do of MMMM YYYY (HH:mm)');
            },

            titleOk() {
                let length = this.list.title.length;

                return length === 0 || length >= 5;
            },

            currentListTitle() {
                return this.addMode
                        ? this.existingListTitle
                        : this.list.title || this.dateNow;
            }
        },

        methods: {
            maybeGoToSingleLinks() {
                if (!this.featureAllowed('html_links')) {
                    this.$events.emit('feature-not-supported', 'html_links');
                    return;
                }

                window.location.href = '/product/single';
            },

            updateSearch: _.debounce(function(e) {
                this.searchString = e.target.value;
            }, 500),

            toggleLinks(type) {
                this.rendering = type;
            },

            isUserLink(link) {
                return link.user_id === this.user.id
            },

            selectLink(link) {
                this.$store.dispatch('appendProduct', link);
            },

            deselectLink(link) {
                this.$store.dispatch('removeProduct', link.id);
            },

            deleteLink(linkId) {
                swal({
                    title: "Are you sure?",
                    text: "Remove the link from your library?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Remove",
                    closeOnConfirm: false,
                    showLoaderOnConfirm: true
                },
                () => {
                    this.$events.emit('removing-link', linkId);

                    this.$http.delete(`/api/links/${ linkId }`).then(
                        response => {
                            if (response.body == 'ok') {
                                // refresh page etc
                                this.fetchPage();

                                swal({
                                    title: "Removed!",
                                    text: "The link has been removed.",
                                    type: "success",
                                    timer: 1000,
                                    showConfirmButton: false
                                });
                            } else {
                                this.$events.emit('link-remove-failed', linkId);

                                swal({
                                    title: "Error!",
                                    text: "Some error occurred. Please refresh page.",
                                    type: "error",
                                    showConfirmButton: false
                                });
                            }
                        }, error => {
                            this.$events.emit('link-remove-failed', linkId);

                            swal({
                                title: "Error!",
                                text: "Some error occurred. Please refresh page.",
                                type: "error",
                                showConfirmButton: false
                            });
                        }
                    )
                });
            },

            rangeFilter: function(index)
            {
                return ((index > (this.links.current_page - 4)) && (index < (this.links.current_page + 2)))
                        || (this.links.current_page < 3 && index < 5)
                        || (this.links.current_page > this.links.last_page - 3 && index > (this.links.last_page - 6));
            },

            pageUrl(number) {
                return `${ this.paginatorPath }?page=${ number }`;
            },

            fetchPage(page) {
                page = page || this.links.current_page;

                let pageUrl;

                switch (page) {
                    case 'previous':
                        pageUrl = this.links.prev_page_url;
                        break;
                    case 'next':
                        pageUrl = this.links.next_page_url;
                        break;
                    default:
                        pageUrl = this.pageUrl(page);
                }

                this.$http.get(pageUrl, {
                    params: {
                        search: this.searchString
                    },
                    before: request =>
                    {
                        if (this.saveRequest)
                            this.saveRequest.abort();

                        this.saveRequest = request;
                    }
                }).then(response => {
                    this[this.rendering] = response.data;

                    if (page !== this.links.current_page)
                        scroll(0, 0);
                }).catch(error => {
                    console.log(error);
                });
            },

            addSelectedToList() {
                if (!this.titleOk)
                    return;

                this.toggleAddToList(false);

                let list = _.cloneDeep(this.list);

                _.merge(list, {
                    title: this.currentListTitle,
                    productIds: this.selectedIds
                });

                this.$http.post('/lists', list).then(response =>
                {
                    let list = response.body.collection;

                    if (response.body.error)
                        console.log(response.body.error)
                    else {
                        this.list.id = list.id;
                        this.redirect = true;
                    }
                }, (error) => {
                    console.log(error);
                });
            },

            deleteSelectedCustom() {
                swal({
                    title: "Are you sure?",
                    text: `<div>Remove selected links from your library?</div><div><b>${ this.selectedIds.length } links will be removed</b></div>`,
                    html: true,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Remove",
                    closeOnConfirm: false,
                    showLoaderOnConfirm: true
                },
                () => {
                    this.$http.post('/api/links/remove', {
                        ids: this.selectedIds
                    }).then(
                        response => {
                            if (response.body == 'ok') {
                                // refresh page etc
                                this.fetchPage();
                                _.each(this.selectedLinks, link => {
                                   this.deselectLink(link);
                                });

                                swal({
                                    title: "Removed!",
                                    text: "Selected links have been removed.",
                                    type: "success",
                                    timer: 1000,
                                    showConfirmButton: false
                                });
                            } else {
                                swal({
                                    title: "Error!",
                                    text: "Some error occurred. Please refresh page.",
                                    type: "error",
                                    showConfirmButton: false
                                });
                            }
                        }, error => {
                            swal({
                                title: "Error!",
                                text: "Some error occurred. Please refresh page.",
                                type: "error",
                                showConfirmButton: false
                            });
                        }
                    )
                });
            },

            toggleAddToList(bool) {
                this.adding = bool;
            },

            toggleRedirectToList(bool) {
                this.redirect = bool;
            },

            redirectToList() {
                window.location = `/lists/${ this.list.id }`;
            },

            setAddMode(type) {
                switch (type) {
                    case 'new':
                        this.addMode = 0;
                        this.list.id = null;
                        break;
                    case 'append':
                        this.addMode = 1;
                        this.list.id = this.lastListId || this.list.id;
                        break;
                }
            },

            addLink() {
                this.$events.emit('add-link');

                if (!this.$localStorage.get('addLinkMessageShown')) {
                    this.$localStorage.set('addLinkMessageShown', true);

                    this.$events.emit('notify', messages.add_product_warning);
                }
            },

            onProductModalClose(event) {
                this.addProductModal.show = false;

                switch (event.status) {
                    case 'product-added':
                        this.fetchPage();
                        break;
                    case 'product-edited':
                        let index = _.findIndex(this.links.data, [ 'id', event.id ]),
                            product = _.merge(_.cloneDeep(this.links.data[index]), event.data);

                        this.$set(this.links.data, index, product);
                        break;
                }
            },

            onProductEdited(event) {
                let index   = _.findIndex(this.links.data, [ 'id', event.id ]);
                let product = _.merge(_.cloneDeep(this.links.data[index]), event.data);

                this.$set(this.links.data, index, product);
            }
        },

        created() {
            _.merge(this, this.data);
            // console.log("Has expired: " + this.hasExpired);
            // console.log("Is team member: " + this.isTeamMember);
            // console.log("Plan: " + this.plan.html_links);

            try {
                this.list.id = this.lastListId = this.userLists[0].id;
                this.existingListTitle = this.userLists[0].title;
            } catch (e) {}

            this.$events.on('manage-user-tags', () => {
                this.tagsModal = {
                    show: true,
                    productId: null
                };
            });

            this.$events.on('manage-product-tags', (productId) => {
                this.tagsModal = {
                    show: true,
                    productId: productId
                };
            });

            this.$events.on('edit-link', data => {
                this.addProductModal = {
                    show: true,
                    data: _.merge({
                        mode: 'edit'
                    }, data)
                };
            });

            this.$events.on('add-link', () => {
                this.addProductModal = {
                    show: true,
                    data: {
                        mode: 'add'
                    }
                };
            });
        },

        mounted() {
            $(this.$refs.listSelect).on('change', e => {
                this.list.id = this.lastListId = parseInt(e.val);

                _.each(this.userLists, list => {
                    if (list.id === this.lastListId)
                        this.existingListTitle = list.title;
                });
            });

            $(this.$refs.listSelect).on('select2-open', e => {
                this.setAddMode('append');
            });
        },

        watch: {
            searchString() {
                this.fetchPage(1);
            }
        }
    }
</script>
