// Template

<template>
    <div class="tabbable">
        <ul class="nav nav-tabs nav-append-content">
            <li class="active">
                <a href="#tab4">Products</a>
            </li>
            <li class="">
                <a href="#tab5">Lists</a>
            </li>
        </ul>
        <div class="tab-content">

            <!-- migrate products -->
            <div class="tab-pane active" id="tab4">
                <div id="links" class="row" v-if="!products.showResults && migrationProducts.length">
                    <br>
                    <div class="search col-md-6">
                        <div class="input-group input-group-hg input-group-rounded flat">
                            <span class="input-group-btn">
                                <button type="submit" class="btn">
                                    <span class="fa fa-search"></span>
                                </button>
                            </span>
                            <input type="text"
                                   :value="searchString"
                                   class="form-control flat"
                                   placeholder="Enter link/url substring to filter by"
                                   @input="updateSearch"
                            >
                        </div>
                    </div>
                    <div class="buttons col-md-6">
                        <div style="margin-top: 10px;">
                            <button type="button" @click="selectVisible(true)" class="btn btn-primary">Select visible</button>
                            <button type="button" @click="selectVisible(false)" class="btn btn-darkgray" style="margin-left: 3px;">Deselect visible</button>
                            <!--<button type="button" @click="invertSelection" class="btn btn-primary">Inverse selection</button>-->
                        </div>
                    </div>

                    <div class="links">
                        <div class="title"><b>Your Products</b> (selected {{ selectedUserLinks.length }}/{{ migrationProducts.length }} )
                        </div>
                        <br>
                        <div class="content">
                            <virtual-list v-if="filteredUserLinks.length"
                                          :size="100"
                                          :remain="8"
                                          style="padding-right: 10px"
                            >
                                <migration-link v-for='link in filteredUserLinks'
                                                :data="link"
                                                :checked="link.checked"
                                                :key="link.id"
                                                :store="$store"
                                ></migration-link>
                            </virtual-list>

                            <h4 v-else>No results found</h4>
                        </div>
                    </div>

                    <button type="submit"
                            class="btn btn-primary btn-hg"
                            @click='submitLinks'
                    >
                        Submit
                    </button>
                </div>

                <div v-if="!migrationProducts.length"
                     id="links"
                     style="margin-top: 0;"
                >
                    <br>
                    <br>
                    <br>

                    <div class="message">
                        The migration button shows only the products that are not available for search in the new service.<br>
                        It is much easier to search them in LinkDeli!
                    </div>

                    <br>
                    <br>
                    <br>
                </div>

                <div id="result" class="row" v-if="products.showResults">
                    <div class="col-md-12">

                        <h1>Done!</h1>
                        <h3>Total: {{ products.stats.total }}</h3>
                        <h3>Existing: {{ products.stats.existing }}</h3>
                        <h3>Imported: {{ products.stats.imported }}</h3>
                        <h3>Failed: {{ products.stats.failed }}</h3>

                        <p>Existing links are not overwritten or added again to your LinkDeli links.</p>
                    </div>
                </div>
            </div>

            <!-- migrate lists -->
            <div class="tab-pane" id="tab5">
                <div class="lists" v-if="!mylists.showResults && migrationLists.length">
                    <br>
                    <div class="title"><b>Your Lists</b> (selected {{ selectedUserLists.length }}/{{ migrationLists.length }} )
                    </div>
                    <br>
                    <div class="content row">
                        <migration-list v-for='list in lists'
                                        :data="list"
                                        :checked="list.checked"
                                        :key="list.id"
                                        :store="$store"
                        ></migration-list>
                    </div>

                    <button type="submit"
                            class="btn btn-primary btn-hg"
                            :style="{ 'opacity' : !checkedListsList().length ? 0.35 : 1, 'pointer-events': !checkedListsList().length ? 'none' : 'all' }"
                            @click='submitLists'
                    >
                        Submit
                    </button>
                </div>

                <div v-if="!migrationLists.length"
                     class="lists"
                >
                    <br>
                    <br>
                    <br>

                    <div class="message">
                        No lists to migrate!
                    </div>

                    <br>
                    <br>
                    <br>
                </div>

                <div id="result" class="row" v-if="mylists.showResults">
                    <div class="col-md-12">

                        <h1>Done!</h1>
                        <h3>Total: {{ mylists.stats.total }}</h3>
                        <h3>Existing: {{ mylists.stats.existing }}</h3>
                        <h3>Imported: {{ mylists.stats.imported }}</h3>
                        <h3>Failed: {{ mylists.stats.failed }}</h3>

                        <p>Existing lists are not overwritten or added again to your LinkDeli lists.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

// Javascript

<script  >
    import VirtualList from 'vue-virtual-scroll-list'
    import MigrationLink from './MigrationLink.vue'
    import MigrationList from './MigrationList.vue'

    export default
    {
        props: {
            links: {
                type: Array,
                default: []
            },
            lists: {
                type: Array,
                default: []
            },
            user: {
                type: Object
            }
        },

        data()
        {
            return {
                searchString: '',
                products: {
                    showResults: false,
                    stats: null
                },
                mylists: {
                    showResults: false,
                    stats: null
                }
            }
        },

        created() {
            this.migrationProducts = this.links;
            this.migrationLists = this.lists;

            this.$events.on('destroyMigrationLink', (id) => {
                this.migrationProducts = _.reject(this.migrationProducts, {'id': id});
            });
        },

        computed: {
            filteredUserLinks() {
                return this.filterLinkList();
            },
            selectedUserLinks() {
                return this.checkedLinksList();
            },
            selectedUserLists() {
                return this.checkedListsList();
            },
            migrationProducts: {
                get() {
                    return this.$store.getters.getMigrationLinks
                },
                set(val) {
                    this.$store.dispatch('setMigrationLinks', val);
                }
            },
            migrationLists: {
                get() {
                    return this.$store.getters.getMigrationLists;
                },
                set(val) {
                    this.$store.dispatch('setMigrationLists', val);
                }
            }
        },

        methods: {
            updateSearch: _.debounce(function(e) {
                this.searchString = e.target.value;
            }, 500),

            submitLinks() {
//                console.log("user id= " + this.user['id']);

                let idArray = (_.map(_.pickBy(this.migrationProducts, value => {
                    return value.checked === true && value.id;
                }), 'id'));

                this.$http.post(`/api/migrate/products`, {
                    migrationLinkIDs: idArray
                }).then(
                    (response) => {
                        this.products.stats = response.body;
                        this.products.showResults = true;
                    },
                    (response) => {
                        console.log("bad");
                    }
                )


            },
            submitLists() {
//                console.log("user id= " + this.user['id']);

                let idArray = (_.map(_.pickBy(this.migrationLists, function (value, key) {
                    return value.checked == true;
                }), 'id'));

                this.$http.post(`/api/migrate/lists`, {
                    migrationListIDs: idArray
                }).then(
                        (response) => {
                            this.mylists.stats = response.body;
                            this.mylists.showResults = true;
                        },
                        (response) => {
                        }
                )
            },

            filterLinkList() {
                let result = _.filter(this.migrationProducts, (link, index) => {
                    let searchString = _.lowerCase(this.searchString),
                        isVisible = _.includes(_.lowerCase(link.name), searchString) || _.includes(_.lowerCase(link.url), searchString);

                    this.migrationProducts[index].visible = isVisible;
                    return isVisible;
                });

                return result;
            },
            checkedLinksList() {
                return _.filter(this.migrationProducts, link => {
                    return link.checked == true;
                });
            },
            checkedListsList() {
                return _.filter(this.migrationLists, list => {
                    return list.checked == true;
                });
            },
            invertSelection() {
//                _(this.filteredUserLinks).forEach(function (n) {
//                    console.log(n.id);
//                    let jq = $("#chk_" + n.id);
//                    let val = jq.attr('checked');
//                    jq.attr('checked', !val);
//                });
            },
            selectVisible(bool = true) {
                _.each(this.migrationProducts, (link, index) => {
                    if (link.visible) {
                        this.$set(this.migrationProducts[index], 'checked', bool);
                    }
                });
            }
        },

        watch: {
//            'searchString': function() {
//
//                return this.filterLinkList(this.links);
//            }
        },
        components: {
            'migration-link': MigrationLink,
            'migration-list': MigrationList,
            'virtual-list': VirtualList
        }
    }
</script>


// Style

<style lang="less" scoped>
    @import "../../../less/mixins";
    @import "../../../less/libs/flat-ui/variables";

    #links,
    .lists {

        > .message {
            font-size: 20px;
            text-align: center;
            font-weight: lighter;
        }

        > .search {

            > .input-group {

                > input,
                > span > button {
                    background: #eee !important;
                    border: none;
                }

                > input {
                    padding-left: 5px !important;
                    font-size: 15px;
                }
            }

            padding: 0 40px;
            margin-bottom: 40px;
        }

        > .links {
            display: inline-block;
            margin-bottom: 30px;
            width: 100%;
            padding: 0 15px;
        }

        > button {
            margin-left: 15px;
            margin-bottom: 15px;
        }
    }

    .lists {
        padding: 0 11px;

        > button {
            margin-top: 20px;
            margin-left: -11px;
        }
    }
</style>
