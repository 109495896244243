var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("h4", { staticClass: "preview-h" }, [
          _vm._v("Share on Facebook preview")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "preview-box" }, [
          _c(
            "div",
            { staticClass: "preview-image" },
            [
              _c("image-square", {
                attrs: {
                  src: _vm.imageSrc,
                  opacity: 0.7,
                  "spinner-color": "#bbb",
                  "spinner-side": "30px",
                  "no-image-msg":
                    "<div style='color: #999;'><div><span style='font-size: 40px; margin-bottom: 5px;' class='fa fa-picture-o fa-3'></span></div><div style='font-size: 12px;'>image preview</div></div>"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "preview-info" }, [
            _c("div", [_vm._v("LINKDELI.COM")]),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.title.text.length > 0
                      ? _vm.title.text
                      : "Collection's name"
                  ) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.description.length > 0
                      ? _vm.description
                      : "(empty description)"
                  ) +
                  "\n          "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.settings !== null
          ? _c("div", { staticClass: "revert" }, [
              _c(
                "a",
                {
                  staticStyle: { color: "#c71111 !important" },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.revert($event)
                    }
                  }
                },
                [_vm._v("\n          Remove custom settings\n        ")]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { attrs: { for: "titleType" } }, [
              _vm._v("Preview Title")
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.title.type,
                    expression: "title.type"
                  }
                ],
                ref: "titleType",
                staticClass: "form-control select select-dark select-block",
                attrs: { id: "titleType", "data-toggle": "select" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.title,
                      "type",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { attrs: { value: "default" } }, [
                  _vm._v("Use Collection's Name")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "custom" } }, [
                  _vm._v("Use Custom Text")
                ])
              ]
            ),
            _vm._v(" "),
            _vm.title.type === "custom"
              ? [
                  _c("div", { staticClass: "custom-title-input" }, [
                    _c("label", { attrs: { for: "customTitle" } }, [
                      _vm._v("Custom Title")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title.text,
                          expression: "title.text"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        placeholder: "Enter preview title",
                        type: "text",
                        id: "customTitle"
                      },
                      domProps: { value: _vm.title.text },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.title, "text", $event.target.value)
                        }
                      }
                    })
                  ])
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "description" } }, [
            _vm._v("Preview Description")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.description,
                expression: "description"
              }
            ],
            staticClass: "form-control",
            attrs: {
              placeholder: "Enter preview description",
              type: "text",
              id: "description"
            },
            domProps: { value: _vm.description },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.description = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "image_url" } }, [
            _vm._v("Preview Image")
          ]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "text",
              placeholder:
                "Enter image url (eg: https://example.com/image.png)",
              name: "image_url",
              id: "image_url"
            },
            domProps: { value: _vm.imageSrc },
            on: { input: _vm.updateImageSrc }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "manual-upload" }, [
            _c("div", [
              _c("div", { staticClass: "separator" }, [
                _vm._v("\n              - or -\n            ")
              ]),
              _vm._v(" "),
              !_vm.uploading
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-info btn-embossed",
                      on: { click: _vm.openUploadWindow }
                    },
                    [
                      _c("i", { staticClass: "fa fa-upload" }),
                      _vm._v(" Upload image manually\n            ")
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-info btn-embossed",
                      attrs: { disabled: "" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-upload" }),
                      _vm._v(" Uploading\n            ")
                    ]
                  ),
              _vm._v(" "),
              _c("input", {
                ref: "selectImage",
                staticClass: "hidden",
                attrs: {
                  type: "file",
                  accept: "image/*",
                  name: "image_upload",
                  id: "image_upload"
                },
                on: { change: _vm.selectImage }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-lg btn-primary btn-embossed pull-right",
              staticStyle: { "margin-right": "10px" },
              attrs: { disabled: !_vm.readyToSave },
              on: { click: _vm.save }
            },
            [_vm._v("\n          Save\n        ")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }