var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop" },
    [
      _c("logo-image", { attrs: { src: _vm.icon.path, side: "30px" } }),
      _vm._v(" "),
      _c("div", { staticClass: "details" }, [
        _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
        _vm._v(" "),
        _c("div", { staticClass: "feed" }, [
          _vm.feedtype === "nofeed"
            ? _c("span", [
                _c("div", [_vm._v("Not searchable")]),
                _vm._v(" "),
                _vm._m(0)
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.feedtype === "realtime"
            ? _c("span", [_c("div", [_vm._v("Real time search")])])
            : _vm._e(),
          _vm._v(" "),
          _vm.feedtype === "feed"
            ? _c("span", [
                _c("div", [
                  _vm._v("Searchable "),
                  _c(
                    "span",
                    {
                      staticClass: "hint--top hint--rounded hint--info info",
                      attrs: { "aria-label": _vm.feedUpdate }
                    },
                    [_c("span", { staticClass: "fa fa-info" })]
                  )
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("b", [_vm._v(_vm._s(_vm.data.feedcount))]),
                  _vm._v(" products")
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _vm.type === "user"
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-danger hint--top hint--rounded",
                      attrs: {
                        "aria-label": "remove",
                        href: "/shops/delete?sid=" + _vm.id
                      }
                    },
                    [_c("span", { staticClass: "fa fa-trash" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-warning hint--top hint--rounded",
                      attrs: {
                        "aria-label": "edit",
                        href: "/shops/edit?sid=" + _vm.id
                      }
                    },
                    [_c("span", { staticClass: "fa fa-pencil" })]
                  )
                ]
              : [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary hint--top hint--rounded",
                      attrs: {
                        "aria-label": "configure",
                        href: "/shops/subscribe?sid=" + _vm.id
                      }
                    },
                    [_c("span", { staticClass: "fa fa-plus" })]
                  )
                ]
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("use with "),
      _c(
        "a",
        {
          attrs: {
            href: "http://linkdeli.com/page/what-has-feed-no-feed-means",
            target: "_blank"
          }
        },
        [_vm._v("bookmarklet")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }