<!--Template-->
<template>
    <div id="product-list"
         :class="{ 'ready': ready }"
    >
        <draggable v-if="groupedListLinks.length"
                   v-model="groupedListLinks"
                   class="products"
                   :options="draggableOptions"
                   @end="onDragEnd"
        >
            <product v-for="(link, index) in groupedListLinks"
                     :index="index"
                     :key="link.id"
                     :data="link"
                     :inlist="true"
                     :draggable="true"
                     :user="user"
                     ref="product"
                     @savelist="saveList"
                     @removefromlist="removeFromList"
            ></product>
        </draggable>

        <div v-else
             class="empty-msg"
        >
            <h5>Your list is empty</h5>
            Add your products now!
        </div>

        <template v-if="typeof affiliateLinks === 'string'">
            <modal title="Preview link"
                   cancel-text="Close"
                   :show="previewModalShow"
                   ok-class="hidden"
                   @cancel="previewModalShow = false"
            >
                <div class="affiliate-title">
                    <b>Affiliate URL</b> <span>(click to visit)</span>
                </div>

                <a :href="affiliateLinks"
                   target="_blank"
                   class="affiliate-link"
                >
                    <pre>{{ affiliateLinks }}</pre>
                </a>

                <div style="color: #c0392b; font-size: 12px; text-align: center; margin-top: 35px; line-height: 1.3;">
                    <div>The link above is for preview purposes only.</div>
                    <div>Please do not copy/paste it in your blog.</div>
                </div>
            </modal>
        </template>

        <template v-else>
            <modal title="Preview links"
                   cancel-text="Close"
                   :show="previewModalShow"
                   ok-class="hidden"
                   @cancel="previewModalShow = false"
            >
                <div class="affiliate-title">
                    <b>Affiliate URLs</b> <span>(click to visit)</span>
                </div>

                <template v-for="affiliateLink in affiliateLinks">
                    <a :href="affiliateLink"
                       target="_blank"
                       class="affiliate-link"
                    >
                        <pre>{{ affiliateLink }}</pre>
                    </a>
                </template>

                <div style="color: #c0392b; font-size: 12px; text-align: center; margin-top: 35px; line-height: 1.3;">
                    <div>The links above are for preview purposes only.</div>
                    <div>Please do not copy/paste them in your blog.</div>
                </div>
            </modal>
        </template>
    </div>
</template>
<!--Script-->

<script  >
    import draggable from 'vuedraggable'

    import Product from './Product.vue'
    import Modal from '../lib/components/Modal.vue'
    import distinctColors from 'distinct-colors'

    export default{
        props: {
            links: Array,
            collection: Object,
            editable: {
                type: Boolean,
                default: false
            },
            user: Object
        },

        data() {
            return {
                ready: false,
                results: [],
                saveRequest: null,
                focused: true,

                // preview product link modal
                previewModalShow: false,
                affiliateLinks: [],
                multiPreviewLinks: false,
                productId: null
            }
        },

        created() {
            _.each(this.links, (link) => {
                let groupId = link.pivot.group_id;

                if (groupId) {
                    if (!_.includes(this.groups, groupId))
                        this.groups.push(groupId);
                }
            });

            this.listLinks = this.links;
            this.refreshProductGroups();

            // refresh list on window focus (for bookmarklet)
            $(window).blur(() => {
                this.focused = false;
            });

            $(window).focus(() => {
                if (!this.focused)
                {
                    this.refreshList();

//                    console.log('focused')
                    this.focused = true;
                }
            });

            window.onbeforeunload = () => {
                if (this.saving)
                    return "Saving in progress...\nPlease do not close or leave this page.";
            }

            this.$events.on('group-sync', () => {
                this.$forceUpdate();
            });

            // refresh list on 'add product'
            this.$events.on('product-added', () => {
                this.refreshList();
            });


            this.$events.on('preview-product', data => {
                this.productId = data.id;

                if (!data.groupLinks) {
                    this.$http.get(`/affiliatelink/${ this.productId }`).then(response => {
                        this.affiliateLinks = response.data;
                        this.previewModalShow = true;
                    }).catch(error => {
                        console.log(error)
                    })
                } else {
                    let productIds = [ this.productId ];

                    _.each(data.groupLinks, link => {
                        productIds.push(link.id);
                    });

                    this.$http.post('/affiliatelinks', {
                        productIds: productIds
                    }).then(response => {
                        this.affiliateLinks = response.data;
                        this.previewModalShow = true;
                    }).catch(error => {
                        console.log(error)
                    })
                }
            });


            // refresh list on 'append template'
            this.$events.on('template-appended', links => {
                this.listLinks = links;

                this.$nextTick(() => {
                    this.refreshProductGroups();
                });
            });
        },

        mounted() {
            this.createStyles();
            this.ready = true;
        },

        computed: {
            groupedListLinks: {
                get() {
                    let result = [];

                    this.listLinks = _.map(this.listLinks, product => {
                        if (product) {
                            product.group = this.productGroups[product.id];
                            delete product.groupLinks;

                            return product;
                        }
                    });

                    _.each(this.listLinks, (link, index) => {
//                        console.log(link.group)

                        if ((index > 0) && (link.group !== 0)) {
                            let lastIndex = result.length - 1;

                            if (result[lastIndex].group === link.group) {
                                result[lastIndex].groupLinks = result[lastIndex].groupLinks || [];
                                result[lastIndex].groupLinks.push(link);
                            } else
                                result.push(link);
                        } else
                            result.push(link);
                    });

//                    console.log(result)
                    this.$store.dispatch('setUserGroupList', result);

                    return result;
                },
                set(val) {
                    this.listLinks = [];

                    _.each(val, link => {

                        if (link.groupLinks) {
                            let groupLinks = _.clone(link.groupLinks);

                            delete link.groupLinks;
                            this.listLinks.push(link);

                            _.each(groupLinks, groupLink => {
                                this.listLinks.push(groupLink);
                            });
                        } else
                            this.listLinks.push(link);
                    });
                }
            },

            draggableOptions() {
               return {
                   disabled: this.$store.getters.getEditingTitle,
                   scroll: false
               }
            },

            groups: {
                get: function() {
                    return this.$store.state.groups;
                },
                set: function(val) {
                    this.$store.state.groups = val;
                }
            },
            listLinks: {
                get: function() {
                    return this.$store.getters.getUserList;
                },
                set: function(val) {
                    this.$store.dispatch('setUserList', val);
                }
            },
            productGroups: {
                get() {
                    return this.$store.getters.getProductGroups;
                },
                set(val) {
                    this.$store.dispatch('setProductGroups', val);
                }
            },
            saving: {
                get() {
                    return this.$store.state.saveingList;
                },
                set(val) {
                    this.$store.state.saveingList = val;
                }
            }
        },

        methods: {
            refreshProductGroups() {
                let tempProductGroups = {};

                _.each(this.listLinks, product => {
                    if (product.pivot)
                        tempProductGroups[product.id] = product.pivot.group_id;
                    else
                        tempProductGroups[product.id] = 0;
                });

                this.productGroups = tempProductGroups;
                this.groups = _.map(_.uniqBy(_.filter(this.listLinks, link => {
                    return link.pivot.group_id > 0;
                }), 'pivot.group_id'), 'pivot.group_id');
//                console.log(this.groups)

                this.$nextTick(() => {
                    this.$events.emit('group-sync');
                });
            },
            createStyles() {
                this.joinedColors = distinctColors({
                    count: 50,
                    lightMin: 70
                });

//                console.log(this.joinedColors);
                let styleString = "<style type='text/css'>";

                _.each(this.joinedColors, (color, index) => {
                    styleString += `.group${index + 1} { border-color: ${ color.desaturate(2).hex() } !important; }`;
                });

                styleString += "</style>";
                $(styleString).appendTo("head");
            },

            onDragEnd(e) {
                this.saveList();
            },

            removeFromList(link) {
                let linkId = link.id;

                let removeFailed = () => {
                    this.$events.emit('link-remove-failed', linkId);

                    swal({
                        title: "Error!",
                        text: "Some error occurred. Please refresh page.",
                        type: "error",
                        showConfirmButton: false
                    });
                };

                let removeProduct = linkId => {
                    if (this.productGroups[linkId] > 0)
                    {
                        let linkGroup = this.productGroups[linkId],
                            groupItems = _.filter(this.productGroups, group => {
                                return group === linkGroup;
                            });

                        if (groupItems.length <= 2) {
                            this.productGroups = _.mapValues(this.productGroups, group => {
                                return group === linkGroup ? 0 : group;
                            });
                        }
                    }

                    this.$store.dispatch('removeProduct', linkId);
                };

                if (!link.groupLinks) {
                    this.$http.delete(`/api/collection/${ this.collection.id }/remove/${ linkId }`).then(response => {
                        if (response.body == 'ok')
                        {
                            removeProduct(linkId);
                            this.saveList();
                        } else
                            removeFailed();
                    }, () => {
                        removeFailed();
                    });
                } else {
                    let productIds = [ linkId ];

                    _.each(link.groupLinks, link => {
                        productIds.push(link.id);
                    });

                    this.$http.post(`/api/collection/${ this.collection.id }/remove/links`, {
                        productIds: productIds
                    }).then(response => {
                        if (response.body == 'ok')
                        {
                            _.each(productIds, linkId => {
                                removeProduct(linkId);
                            });

                            this.$store.dispatch('removeGroup', link.group);
                            this.$nextTick(() => {
                                this.$events.emit('group-sync');
                            });

                            this.saveList();
                        } else
                            removeFailed();
                    }, () => {
                        removeFailed();
                    });
                }
            },

            saveList() {
                this.saving = true;

                this.$nextTick(() => {
                    let orderIndex = [];
                    let orderIds = [];
                    let groupIds = [];

                    _.each(this.listLinks, (link, index) => {
                        orderIndex.push(index);
                        orderIds.push(link.id);
                        groupIds.push(this.$store.getters.getProductGroups[link.id]);
                    });

                    this.$http.post(`/api/collection/${ this.collection.id }/links`, {
                        orderIds: orderIds,
                        orderIndex: orderIndex,
                        groupIds: groupIds,
                        collectionId: this.collection.id
                    }, {
                        before: function(request)
                        {
                            if (this.saveRequest)
                                this.saveRequest.abort();

                            this.saveRequest = request;
                        }
                    }).then(response => {
                        this.saving = false;
                    }).catch(error => {
//                        this.saving = false;
                    });
                });
            },

            refreshList() {
                this.$http.get(`/api/collection/${ this.collection.id }`).then(response => {
                    this.listLinks = response.data.links;

                    this.$nextTick(() => {
                        this.refreshProductGroups();
                    });
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        components: {
            product: Product,
            draggable: draggable,
            modal: Modal,
        },
        events: {}
    }
</script>
<!--Style-->
<style lang='less'>
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    #product-list {
        min-height: 210px;
        width: 100%;
        position: relative;
        display: inline-block;
        margin-bottom: 13px;
        opacity: 0;
        transition: 0.3s opacity ease;

        &.ready {
            opacity: 1;
        }

        > .empty-msg {
            .centered-block;

            color: #555;
            text-align: center;

            > h5 {
                margin-bottom: 5px;
            }
        }

        .affiliate-title {
            padding-left: 5px;
            margin-bottom: 10px;

            > b {
                font-size: 15px;
            }

            > span {
                font-size: 13px;
                color: #aaa;
                margin-left: 3px;
                position: relative;
                top: -1px;
            }
        }

        .affiliate-link {
            color: #555;

            &:hover {
                color: @brand-secondary;
                text-decoration: underline;
            }

            pre {
                white-space: pre-wrap; /* css-3 */
                white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
                white-space: -pre-wrap; /* Opera 4-6 */
                white-space: -o-pre-wrap; /* Opera 7 */
                word-wrap: break-word;
                font-size: 12px;
                background: #fff;
                border: none;
            }
        }
    }

    .portlet-placeholder {
        border: 1px dotted black;
        margin: 0 1em 1em 0;
        height: 50px;
    }
</style>


