// Template

<template>
    <span class="loader"
          :style="{ 'animation-duration' : duration }"
    >
        <div :style="{
            'width': side,
            'height': side,
            'border-color': color,
            'border-width': borderWidth,
            'opacity': opacity
         }"
             class="spinner"
        ></div>
    </span>
</template>

// Style

<style lang="less">
    .loader {
        display: inline-block;

        > .spinner {
            animation: rotate 0.8s infinite linear;
            border-style: solid;
            border-right-color: transparent !important;
            border-radius: 50%;
        }

        @keyframes rotate {
            0%    { transform: rotate(0deg); }
            100%  { transform: rotate(360deg); }
        }
    }
</style>

// Javascript

<script  >

    export default
    {
        props:
        {
            side:
            {
                type: String,
                default: '35px'
            },
            color:
            {
                type: String,
                default: '#000'
            },
            opacity:
            {
                type: Number,
                default: 0.3
            },
            duration:
            {
                type: String,
                default: '0.1s'
            },
            width:
            {
                type: String,
                default: null
            }
        },
        computed:
        {
            borderWidth: function()
            {
                if (this.width)
                    return this.width;
                else
                {
                    let width = parseInt(this.side) / 8;

                    return `${Math.ceil(width)}px`;
                }
            }
        }
    }
</script>
