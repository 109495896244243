export default {
    data() {
        return {
            plan: null
        }
    },

    created() {
        try {
            this.plan = window.plan.planFeatures;
        } catch(e) {}
    },

    computed: {
        isTrial() {
            return window.plan.key === 'trial';
        },
        isTeamMember() {
            return window.plan.key === 'team_member';
        },
        isTeamOwner() {
            return _.includes([ 'team_small', 'team_medium', 'team_big' ], window.plan.key);
        },
        hasExpired() {
            try {
                return this.user.status !== 'ok';
            } catch (e) {
                throw new Error('this.user is not defined');
            }
        }
    },

    methods: {
        featureAllowed(name) {
            return this.plan[name] && !this.hasExpired;
        },
        isPlan(key) {
            return window.plan.key === key;
        }
    }
}