var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tabbable", attrs: { id: "settings" } }, [
    _c("ul", { staticClass: "nav nav-tabs nav-append-content" }, [
      _c("li", { class: { active: _vm.currentTab === "account" } }, [
        _c("a", { attrs: { href: "#account" } }, [_vm._v("Account")])
      ]),
      _vm._v(" "),
      _c("li", { class: { active: _vm.currentTab === "fb-preview" } }, [
        _c("a", { attrs: { href: "#fb-preview-settings" } }, [
          _vm._v("\n        Facebook Preview\n      ")
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: { href: "#list-styling" },
            on: {
              mousedown: function($event) {
                return _vm.maybePreventFeature("list_styling", $event)
              }
            }
          },
          [_vm._v("\n        List styling\n      ")]
        )
      ]),
      _vm._v(" "),
      _c("li", { class: { active: _vm.currentTab === "social-link" } }, [
        _c(
          "a",
          {
            attrs: { href: "#social-link" },
            on: {
              mousedown: function($event) {
                return _vm.maybePreventFeature("social_link", $event)
              }
            }
          },
          [_vm._v("\n        Social link\n      ")]
        )
      ]),
      _vm._v(" "),
      _vm.isTeamOwner
        ? _c("li", { class: { active: _vm.currentTab === "team" } }, [
            _c("a", { attrs: { href: "#team" } }, [
              _vm._v("\n        Team\n      ")
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content" }, [
      _c(
        "div",
        {
          class: ["tab-pane", { active: _vm.currentTab === "account" }],
          attrs: { id: "account" }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-md-offset-3 col-md-6",
                attrs: { id: "user-settings" }
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.name,
                        expression: "name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "enter your name", type: "text" },
                    domProps: { value: _vm.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.name = $event.target.value
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("E-Mail Address")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "enter your email", type: "text" },
                    domProps: { value: _vm.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                !_vm.isTeamMember
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Blog URL")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.blog,
                            expression: "blog"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "enter your blog URL",
                          type: "text"
                        },
                        domProps: { value: _vm.blog },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.blog = $event.target.value
                          }
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.changePassword
                  ? _c(
                      "div",
                      {
                        staticClass: "btn btn-darkgray btn-sm",
                        on: { click: _vm.applyChangePassword }
                      },
                      [_vm._v("\n            Change Password\n          ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.changePassword
                  ? [
                      _c("div", { staticClass: "wrapper" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("New Password")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newPassword,
                                expression: "newPassword"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              placeholder: "enter your new password",
                              type: "password"
                            },
                            domProps: { value: _vm.newPassword },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.newPassword = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.repeatPassword,
                                expression: "repeatPassword"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              placeholder: "repeat your password",
                              type: "password"
                            },
                            domProps: { value: _vm.repeatPassword },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.repeatPassword = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.isPlan("platinum") || _vm.isTeamOwner
                  ? _c("div", { staticClass: "wrapper" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "option" }, [
                            _c(
                              "label",
                              { class: { enabled: _vm.monetization } },
                              [
                                _vm._v(
                                  "\n                    Monetization\n                    "
                                ),
                                _vm._m(0)
                              ]
                            ),
                            _vm._v(" "),
                            _vm.monetization
                              ? _c("input", {
                                  attrs: {
                                    id: "monetization",
                                    type: "checkbox",
                                    checked: "",
                                    "data-toggle": "switch",
                                    "data-off-color": "default",
                                    "data-on-color": "success"
                                  }
                                })
                              : _c("input", {
                                  attrs: {
                                    id: "monetization",
                                    type: "checkbox",
                                    "data-toggle": "switch",
                                    "data-off-color": "default",
                                    "data-on-color": "success"
                                  }
                                })
                          ]),
                          _vm._v(" "),
                          _vm.monetization
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "10px",
                                    background: "#eee",
                                    "font-size": "14px",
                                    "border-radius": "5px",
                                    margin: "-15px auto 30px auto"
                                  }
                                },
                                [
                                  _vm.linkdeli_shop_set === "needs-config"
                                    ? _c(
                                        "ul",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "0",
                                            "padding-left": "26px"
                                          }
                                        },
                                        [
                                          _c("li", [
                                            _vm._v("Configure "),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/shops/edit?sid=73",
                                                  target: "_blank"
                                                },
                                                on: {
                                                  click: _vm.linkdeliShopSet
                                                }
                                              },
                                              [_vm._v("LinkDeli shop")]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _vm._m(1)
                                        ]
                                      )
                                    : [
                                        _vm._v(
                                          "\n                    Make sure you have joined the "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "https://account.shareasale.com/a-viewmerchant.cfm?merchantid=62729&storeid=1672",
                                              target: "_blank"
                                            }
                                          },
                                          [_vm._v("LinkDeli affiliate program")]
                                        )
                                      ]
                                ],
                                2
                              )
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-lg btn-primary btn-embossed pull-right",
                        on: { click: _vm.saveUser }
                      },
                      [_vm._v("\n                Save\n              ")]
                    )
                  ])
                ])
              ],
              2
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tab-pane", attrs: { id: "list-styling" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12", attrs: { id: "app-settings" } },
            [
              _vm._m(2),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("list-styler", {
                attrs: { data: _vm.listStyle },
                on: { save: _vm.saveStyle }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "tab-pane",
            { active: _vm.currentTab === "fb-preview-settings" }
          ],
          attrs: { id: "fb-preview-settings" }
        },
        [
          _c("fb-preview-settings", {
            attrs: { settings: _vm.data.fb_preview }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: ["tab-pane", { active: _vm.currentTab === "social-link" }],
          attrs: { id: "social-link" }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { attrs: { id: "social-link-settings" } }, [
              _vm.lists.length
                ? _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("transition", { attrs: { name: "fade" } }, [
                        !_vm.vanityUrl
                          ? _c("div", [
                              _c("div", { staticClass: "text" }, [
                                _c("div", [
                                  _vm._v(
                                    "Please define a single-word alias for your link."
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "(You will not be able to change this value once set.)"
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "link-preview" }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("link preview")
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "preview" }, [
                                  _vm._v(_vm._s(_vm.vanityUrlPreview))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-sm-6 col-md-offset-3" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "vanity-url-wrapper" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.vanityUrlTemp,
                                            expression: "vanityUrlTemp"
                                          }
                                        ],
                                        staticClass: "vanity-url",
                                        attrs: {
                                          placeholder: "Your alias",
                                          type: "text"
                                        },
                                        domProps: { value: _vm.vanityUrlTemp },
                                        on: {
                                          click: function($event) {
                                            return $event.target.select()
                                          },
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.saveVanityUrl($event)
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.vanityUrlTemp =
                                              $event.target.value
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.vanityUrlError
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "error-msg" },
                                              [
                                                _vm.vanityUrlError === "empty"
                                                  ? _c("div", [
                                                      _vm._v(
                                                        "\n                          the value cannot be empty\n                        "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.vanityUrlError ===
                                                "not-alphanumeric"
                                                  ? _c("div", [
                                                      _vm._v(
                                                        "\n                          only letters and numbers are allowed\n                        "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.vanityUrlError === "exists"
                                                  ? _c("div", [
                                                      _vm._v(
                                                        "\n                          the alias already exists, please try another one\n                        "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "save" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            on: { click: _vm.saveVanityUrl }
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "fa fa-floppy-o"
                                            }),
                                            _vm._v(
                                              "Save\n                      "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          : _c("div", [
                              _c(
                                "div",
                                { staticClass: "col-md-offset-3 col-md-6" },
                                [
                                  _c("div", { staticClass: "text" }, [
                                    _c("div", [
                                      _vm._v("Connect to one of your lists:")
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "select-list" }, [
                                    _c(
                                      "select",
                                      {
                                        ref: "socialLinkList",
                                        staticClass:
                                          "form-control select select-dark select-block",
                                        attrs: { "data-toggle": "select" }
                                      },
                                      [
                                        _vm._l(_vm.lists, function(list) {
                                          return [
                                            _vm.socialLink === list.uuid
                                              ? _c(
                                                  "option",
                                                  {
                                                    attrs: { selected: "" },
                                                    domProps: {
                                                      value: list.uuid
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(list.title) +
                                                        "\n                        "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "option",
                                                  {
                                                    domProps: {
                                                      value: list.uuid
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(list.title) +
                                                        "\n                        "
                                                    )
                                                  ]
                                                )
                                          ]
                                        })
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-primary btn-embossed hint--top hint--rounded",
                                        attrs: {
                                          "aria-label": "preview",
                                          href: _vm.vanityUrlPreview,
                                          target: "_blank"
                                        }
                                      },
                                      [_c("span", { staticClass: "fa fa-eye" })]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text" }, [
                                    _c("div", [
                                      _vm._v(
                                        "Embed the link in your social media profiles."
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "link-preview copy-url" },
                                    [
                                      _c("div", { staticClass: "title" }, [
                                        _vm._v("link preview")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          ref: "vanityUrlPreview",
                                          staticClass: "preview"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(_vm.vanityUrlPreview) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value: _vm.vanityUrlPreview,
                                              expression: "vanityUrlPreview",
                                              arg: "copy"
                                            }
                                          ],
                                          staticClass:
                                            "btn btn-primary btn-embossed hint--top hint--rounded",
                                          attrs: { "aria-label": "copy" },
                                          on: {
                                            click: function($event) {
                                              return _vm.textSelect(
                                                _vm.$refs.vanityUrlPreview
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "fa fa-clipboard"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                      ])
                    ],
                    1
                  )
                : _c("div", [_vm._m(3)])
            ])
          ]),
          _vm._v(" "),
          _c("br")
        ]
      ),
      _vm._v(" "),
      _vm.isTeamOwner
        ? _c(
            "div",
            {
              class: ["tab-pane", { active: _vm.currentTab === "team" }],
              attrs: { id: "team" }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12", attrs: { id: "team-settings" } },
                  [
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.teamName
                        ? _c("div", [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("div", { staticClass: "option" }, [
                                  _c("div", { staticClass: "checkbox" }, [
                                    _c("label", [
                                      _vm._v(
                                        "\n                        Allow team members to use their own affiliate IDs for your shop\n\n                        "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.teamAllowAffiliateIDs,
                                            expression: "teamAllowAffiliateIDs"
                                          }
                                        ],
                                        attrs: {
                                          "data-toggle": "checkbox",
                                          type: "checkbox",
                                          name: "remember"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.teamAllowAffiliateIDs
                                          )
                                            ? _vm._i(
                                                _vm.teamAllowAffiliateIDs,
                                                null
                                              ) > -1
                                            : _vm.teamAllowAffiliateIDs
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.teamAllowAffiliateIDs,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.teamAllowAffiliateIDs = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.teamAllowAffiliateIDs = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.teamAllowAffiliateIDs = $$c
                                            }
                                          }
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c("div", { staticClass: "text" }, [
                              _c("div", [
                                _vm._v("You can manage your team members here.")
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "Invite members to your team (or remove existing ones)."
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-6 team-members" },
                              [
                                _c("div", { staticClass: "header" }, [
                                  _c("h4", [_vm._v("Members")]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "assigned" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.teamMembers.length) +
                                          " / " +
                                          _vm._s(_vm.team.max)
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.teamMembers.length
                                  ? _vm._l(_vm.teamMembers, function(member) {
                                      return _c(
                                        "div",
                                        {
                                          key: member.id,
                                          class: [
                                            "member",
                                            "row",
                                            { deleting: member.deleting }
                                          ]
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "user-name" },
                                            [
                                              _c("label", [_vm._v("Name")]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "name" },
                                                [_vm._v(_vm._s(member.name))]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "user-email" },
                                            [
                                              _c("label", [_vm._v("Email")]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "email" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "mailto:" +
                                                          member.email
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(member.email)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "user-status" },
                                            [
                                              _c("label", [
                                                _vm._v("Activated")
                                              ]),
                                              _vm._v(" "),
                                              _c("div", {
                                                class: [
                                                  "active",
                                                  { yes: member.activated }
                                                ]
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "user-delete" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "btn btn-danger btn-embossed",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteMemberPrompt(
                                                        member
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "fa fa-trash"
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  : _c("div", { staticClass: "no-members" }, [
                                      _c("div", [
                                        _vm._v("Your team has no members yet.")
                                      ])
                                    ])
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "col-md-6",
                                style: {
                                  "pointer-events": _vm.canInviteMembers
                                    ? "auto"
                                    : "none"
                                }
                              },
                              [
                                _c("div", { staticClass: "invitation-form" }, [
                                  _c("label", [_vm._v("Invite new member")]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "field" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "col-md-3 control-label"
                                        },
                                        [_vm._v("email")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-md-9" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.teamMemberEmail,
                                              expression: "teamMemberEmail"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            placeholder:
                                              "recipient's email address",
                                            type: "text"
                                          },
                                          domProps: {
                                            value: _vm.teamMemberEmail
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.teamMemberEmail =
                                                $event.target.value
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.teamMemberEmailError
                                          ? _c(
                                              "div",
                                              { staticClass: "error-msg" },
                                              [
                                                _vm._v(
                                                  "\n                          Please enter a valid email\n                        "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "field",
                                      staticStyle: { "margin-top": "13px" }
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "col-md-3 control-label"
                                          },
                                          [_vm._v("name")]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-md-9" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.teamMemberUsername,
                                                expression: "teamMemberUsername"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              placeholder:
                                                "recipient's distinctive name",
                                              type: "text"
                                            },
                                            domProps: {
                                              value: _vm.teamMemberUsername
                                            },
                                            on: {
                                              keypress: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.inviteMemberPrompt(
                                                  $event
                                                )
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.teamMemberUsername =
                                                  $event.target.value
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm.teamMemberUsernameError
                                            ? _c(
                                                "div",
                                                { staticClass: "error-msg" },
                                                [
                                                  _vm._v(
                                                    "\n                          Please enter a distinctive name\n                        "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.canInviteMembers
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-top": "25px",
                                            "font-size": "15px",
                                            color: "#a33025",
                                            "line-height": "1.3"
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Your current plan does not allow you to add any more members to your\n                      team. In case you still need to do so, you have one the following\n                      options:\n                    "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "1) Remove an existing member"
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v("2) Upgrade your plan")
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "3) Contact us\n                      "
                                            ),
                                            _c("small", [
                                              _vm._v(
                                                '(in case you have subscribed for the "Big Team" plan)'
                                              )
                                            ])
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    staticStyle: { "margin-top": "20px" }
                                  },
                                  [
                                    _c("div", { staticClass: "col-md-12" }, [
                                      _c(
                                        "button",
                                        {
                                          ref: "inviteButton",
                                          class: [
                                            "btn btn-lg btn-primary btn-embossed pull-right",
                                            { disabled: !_vm.canInviteMembers }
                                          ],
                                          style: {
                                            "pointer-events":
                                              _vm.inviting ||
                                              !_vm.canInviteMembers
                                                ? "none"
                                                : "auto",
                                            opacity: _vm.inviting ? 0.85 : 1
                                          },
                                          on: { click: _vm.inviteMemberPrompt }
                                        },
                                        [
                                          _vm.inviting
                                            ? _c("span", {
                                                staticClass:
                                                  "fa fa-circle-o-notch fa-spin fa-fw"
                                              })
                                            : [_vm._v("Invite")]
                                        ],
                                        2
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          ])
                        : _c("div", [
                            _c("div", { staticClass: "text" }, [
                              _c("div", [
                                _vm._v(
                                  "Please define a single-word name for your team."
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "(You will not be able to change this value once set.)"
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-6 col-md-offset-3" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "team-name-wrapper" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.teamNameTemp,
                                          expression: "teamNameTemp"
                                        }
                                      ],
                                      staticClass: "team-name",
                                      attrs: {
                                        placeholder: "Team name",
                                        type: "text"
                                      },
                                      domProps: { value: _vm.teamNameTemp },
                                      on: {
                                        click: function($event) {
                                          return $event.target.select()
                                        },
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.saveTeamName($event)
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.teamNameTemp = $event.target.value
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.teamNameError
                                      ? [
                                          _c(
                                            "div",
                                            { staticClass: "error-msg" },
                                            [
                                              _vm.teamNameError === "empty"
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                        the value cannot be empty\n                      "
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.teamNameError ===
                                              "not-alphanumeric"
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                        only letters and numbers are allowed\n                      "
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.teamNameError === "exists"
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                        the name already exists\n                      "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "save" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          on: { click: _vm.saveTeamName }
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "fa fa-floppy-o"
                                          }),
                                          _vm._v("Save\n                    ")
                                        ]
                                      )
                                    ])
                                  ],
                                  2
                                )
                              ]
                            )
                          ])
                    ])
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-info",
        attrs: { href: "/page/monetize-your-product-lists", target: "_blank" }
      },
      [_c("span", { staticClass: "fa fa-question" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v(" you have joined the "),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://account.shareasale.com/a-viewmerchant.cfm?merchantid=62729&storeid=1672",
            target: "_blank"
          }
        },
        [_vm._v("LinkDeli affiliate program")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("div", [
        _vm._v("This will be the predefined styling for all your lists.")
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("Styling any list explicitly, will override these defaults.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("div", [_vm._v("The social link needs to be connected to a list.")]),
      _vm._v(" "),
      _c("div", [_vm._v("Please create a list and try again.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }