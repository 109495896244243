// Template

<template>
  <div id="settings" class="tabbable">
    <ul class="nav nav-tabs nav-append-content">
      <li :class="{ 'active': currentTab === 'account' }">
        <a href="#account">Account</a>
      </li>
      <li :class="{ 'active': currentTab === 'fb-preview' }">
        <a href="#fb-preview-settings">
          Facebook Preview
        </a>
      </li>
      <li>
        <a href="#list-styling"
           @mousedown="maybePreventFeature('list_styling', $event)"
        >
          List styling
        </a>
      </li>
      <li :class="{ 'active': currentTab === 'social-link' }">
        <a href="#social-link"
           @mousedown="maybePreventFeature('social_link', $event)"
        >
          Social link
        </a>
      </li>
      <li v-if="isTeamOwner"
          :class="{ 'active': currentTab === 'team' }"
      >
        <a href="#team">
          Team
        </a>
      </li>
    </ul>

    <!-- TAB CONTENT FOR EACH SETTING-->
    <div class="tab-content">
      <div :class="[ 'tab-pane', { 'active': currentTab === 'account' } ]"
           id="account"
      >
        <div class="row">
          <div id="user-settings"
               class="col-md-offset-3 col-md-6"
          >
            <div class="form-group">
              <label>Name</label>
              <input class="form-control"
                     placeholder="enter your name"
                     type="text"
                     v-model="name"
              >
            </div>

            <div class="form-group">
              <label>E-Mail Address</label>
              <input class="form-control"
                     placeholder="enter your email"
                     type="text"
                     v-model="email"
              >
            </div>

            <div v-if="!isTeamMember"
                 class="form-group"
            >
              <label>Blog URL</label>
              <input class="form-control"
                     placeholder="enter your blog URL"
                     type="text"
                     v-model="blog"
              >
            </div>

            <div v-if="!changePassword"
                 class="btn btn-darkgray btn-sm"
                 @click="applyChangePassword"
            >
              Change Password
            </div>

            <template v-if="changePassword">
              <div class="wrapper">
                <div class="form-group">
                  <label>New Password</label>
                  <input class="form-control"
                         placeholder="enter your new password"
                         type="password"
                         v-model="newPassword"
                  >
                </div>

                <div class="form-group">
                  <input class="form-control"
                         placeholder="repeat your password"
                         type="password"
                         v-model="repeatPassword"
                  >
                </div>
              </div>
            </template>

            <div v-if="isPlan('platinum') || isTeamOwner"
                 class="wrapper"
            >
              <div class="row">
                <div class="col-md-12">
                  <div class="option">
                    <label :class="{ 'enabled': monetization }">
                      Monetization
                      <a href="/page/monetize-your-product-lists" target="_blank"
                         class="btn btn-info">
                        <span class="fa fa-question"></span>
                      </a>
                    </label>
                    <input v-if="monetization"
                           id="monetization"
                           type="checkbox"
                           checked
                           data-toggle="switch"
                           data-off-color="default"
                           data-on-color="success"
                    >
                    <input v-else
                           id="monetization"
                           type="checkbox"
                           data-toggle="switch"
                           data-off-color="default"
                           data-on-color="success"
                    >
                  </div>

                  <div v-if="monetization"
                       style="padding: 10px; background: #eee; font-size: 14px; border-radius: 5px; margin: -15px auto 30px auto;"
                  >
                    <ul v-if="linkdeli_shop_set === 'needs-config'"
                        style="margin-bottom: 0; padding-left: 26px;"
                    >
                      <li>Configure <a href="/shops/edit?sid=73" target="_blank"
                                       @click="linkdeliShopSet">LinkDeli shop</a></li>
                      <li> you have joined the <a
                          href="https://account.shareasale.com/a-viewmerchant.cfm?merchantid=62729&storeid=1672"
                          target="_blank">LinkDeli affiliate program</a></li>
                    </ul>

                    <template v-else
                    >
                      Make sure you have joined the <a
                        href="https://account.shareasale.com/a-viewmerchant.cfm?merchantid=62729&storeid=1672"
                        target="_blank">LinkDeli affiliate program</a>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <button class="btn btn-lg btn-primary btn-embossed pull-right"
                        @click="saveUser"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane" id="list-styling">
        <div class="row">
          <div id="app-settings"
               class="col-md-12"
          >
            <div class="text">
              <div>This will be the predefined styling for all your lists.</div>
              <div>Styling any list explicitly, will override these defaults.</div>
            </div>

            <br>
            <br>

            <list-styler :data="listStyle"
                         @save="saveStyle"
            ></list-styler>
          </div>
        </div>
      </div>

      <div :class="[ 'tab-pane', { 'active': currentTab === 'fb-preview-settings' } ]"
           id="fb-preview-settings"
      >
        <fb-preview-settings :settings="data.fb_preview"></fb-preview-settings>
      </div>

      <div :class="[ 'tab-pane', { 'active': currentTab === 'social-link' } ]"
           id="social-link"
      >
        <div class="row">
          <div id="social-link-settings">
            <div class="row"
                 v-if="lists.length"
            >
              <transition name="fade">
                <div v-if="!vanityUrl">
                  <div class="text">
                    <div>Please define a single-word alias for your link.</div>
                    <div>(You will not be able to change this value once set.)</div>
                  </div>

                  <div class="link-preview">
                    <div class="title">link preview</div>
                    <div class="preview">{{ vanityUrlPreview }}</div>
                  </div>

                  <br>

                  <div class="col-sm-6 col-md-offset-3">
                    <div class="vanity-url-wrapper">
                      <input v-model="vanityUrlTemp"
                             class="vanity-url"
                             placeholder="Your alias"
                             type="text"
                             @click="$event.target.select()"
                             @keyup.enter="saveVanityUrl"
                      >

                      <template v-if="vanityUrlError">
                        <div class="error-msg">
                          <div v-if="vanityUrlError === 'empty'">
                            the value cannot be empty
                          </div>

                          <div v-if="vanityUrlError === 'not-alphanumeric'">
                            only letters and numbers are allowed
                          </div>

                          <div v-if="vanityUrlError === 'exists'">
                            the alias already exists, please try another one
                          </div>
                        </div>
                      </template>

                      <div class="save">
                        <button class="btn btn-primary"
                                @click="saveVanityUrl"
                        >
                          <span class="fa fa-floppy-o"></span>Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div class="col-md-offset-3 col-md-6">
                    <div class="text">
                      <div>Connect to one of your lists:</div>
                    </div>

                    <div class="select-list">
                      <select class="form-control select select-dark select-block"
                              ref="socialLinkList"
                              data-toggle="select"
                      >
                        <template v-for="list in lists">
                          <option v-if="socialLink === list.uuid"
                                  :value="list.uuid"
                                  selected
                          >
                            {{ list.title }}
                          </option>

                          <option v-else
                                  :value="list.uuid"
                          >
                            {{ list.title }}
                          </option>
                        </template>
                      </select>

                      <a class="btn btn-primary btn-embossed hint--top hint--rounded"
                         aria-label="preview"
                         :href="vanityUrlPreview"
                         target="_blank"
                      >
                        <span class="fa fa-eye"></span>
                      </a>
                    </div>
                    <br>
                    <br>

                    <div class="text">
                      <div>Embed the link in your social media profiles.</div>
                    </div>

                    <div class="link-preview copy-url">
                      <div class="title">link preview</div>
                      <div class="preview"
                           ref="vanityUrlPreview"
                      >
                        {{ vanityUrlPreview }}
                      </div>
                      <button class="btn btn-primary btn-embossed hint--top hint--rounded"
                              aria-label="copy"
                              v-clipboard:copy="vanityUrlPreview"
                              @click="textSelect($refs.vanityUrlPreview)"
                      >
                        <span class="fa fa-clipboard"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <div v-else>
              <div class="text">
                <div>The social link needs to be connected to a list.</div>
                <div>Please create a list and try again.</div>
              </div>
            </div>
          </div>
        </div>

        <br>
      </div>

      <div v-if="isTeamOwner"
           :class="[ 'tab-pane', { 'active': currentTab === 'team' } ]"
           id="team"
      >
        <div class="row">
          <div id="team-settings"
               class="col-md-12"
          >
            <transition name="fade">
              <div v-if="teamName">


                <div class="row">
                  <div class="col-md-12">
                    <div class="option">


                      <!--<label class="container_checkbox">Allow team members to use their own affiliate IDs-->
                      <!--for your shop-->
                      <!--<input type="checkbox" v-model="teamAllowAffiliateIDs">-->
                      <!--<span class="checkmark"></span>-->
                      <!--</label>-->


                      <div class="checkbox">
                        <label>
                          Allow team members to use their own affiliate IDs for your shop

                          <input
                              data-toggle="checkbox"
                              type="checkbox"
                              name="remember"
                              v-model="teamAllowAffiliateIDs"
                          >
                        </label>
                      </div>

                      <!--<label :class="{ 'enabled': monetization }">-->
                      <!--Allow team members to use their own affiliate IDs for your shop-->

                      <!--</label>-->
                      <!--<input-->
                      <!--v-model="teamAllowAffiliateIDs"-->
                      <!--class="custom-checkbox"-->
                      <!--id="allow_affiliate_ids_checkbox"-->
                      <!--type="checkbox"-->
                      <!--&gt;-->
                      <!--<span class="cr"><i class="cr-icon glyphicon glyphicon-ok"></i></span>-->
                      <!--<input v-else-->
                      <!--id="teamAllowAffiliateIDs"-->
                      <!--type="checkbox"-->
                      <!--data-toggle="switch"-->
                      <!--data-off-color="default"-->
                      <!--data-on-color="success"-->
                      <!--&gt;-->
                    </div>

                    <!--<div v-if="monetization"-->
                    <!--style="padding: 10px; background: #eee; font-size: 14px; border-radius: 5px; margin: -15px auto 30px auto;"-->
                    <!--&gt;-->
                    <!--<ul v-if="linkdeli_shop_set === 'needs-config'"-->
                    <!--style="margin-bottom: 0; padding-left: 26px;"-->
                    <!--&gt;-->
                    <!--<li>Configure <a href="/shops/edit?sid=73" target="_blank" @click="linkdeliShopSet">LinkDeli shop</a></li>-->
                    <!--<li> you have joined the <a href="https://account.shareasale.com/a-viewmerchant.cfm?merchantid=62729&storeid=1672" target="_blank">LinkDeli affiliate program</a></li>-->
                    <!--</ul>-->

                    <!--<template v-else-->
                    <!--&gt;-->
                    <!--Make sure you have joined the <a href="https://account.shareasale.com/a-viewmerchant.cfm?merchantid=62729&storeid=1672" target="_blank">LinkDeli affiliate program</a>-->
                    <!--</template>-->
                    <!--</div>-->
                  </div>
                </div>

                <hr/>


                <div class="text">
                  <div>You can manage your team members here.</div>
                  <div>Invite members to your team (or remove existing ones).</div>
                </div>

                <br>
                <br>

                <div class="col-md-6 team-members">
                  <div class="header">
                    <h4>Members</h4>
                    <div class="assigned">
                      <span>{{ teamMembers.length }} / {{ team.max }}</span>
                    </div>
                  </div>

                  <template v-if="teamMembers.length">
                    <div v-for="member in teamMembers"
                         :class="[ 'member', 'row', { 'deleting': member.deleting } ]"
                         :key="member.id"
                    >
                      <div class="user-name">
                        <label>Name</label>
                        <div class="name">{{ member.name }}</div>
                      </div>

                      <div class="user-email">
                        <label>Email</label>
                        <div class="email">
                          <a :href="'mailto:' + member.email">{{ member.email }}</a>
                        </div>
                      </div>

                      <div class="user-status">
                        <label>Activated</label>
                        <div :class="[ 'active', { 'yes' : member.activated } ]"></div>
                      </div>

                      <div class="user-delete">
                        <div class="btn btn-danger btn-embossed"
                             @click="deleteMemberPrompt(member)"
                        >
                          <span class="fa fa-trash"></span>
                        </div>
                      </div>
                    </div>
                  </template>

                  <div v-else
                       class="no-members"
                  >
                    <div>Your team has no members yet.</div>
                  </div>
                </div>

                <div class="col-md-6"
                     :style="{ 'pointer-events': canInviteMembers ? 'auto' : 'none' }"
                >
                  <div class="invitation-form">
                    <label>Invite new member</label>

                    <div class="field">
                      <div class="row">
                        <label class="col-md-3 control-label">email</label>
                        <div class="col-md-9">
                          <input v-model="teamMemberEmail"
                                 placeholder="recipient's email address"
                                 type="text"
                                 class="form-control"
                          >

                          <div v-if="teamMemberEmailError"
                               class="error-msg"
                          >
                            Please enter a valid email
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field"
                         style="margin-top: 13px;"
                    >
                      <div class="row">
                        <label class="col-md-3 control-label">name</label>
                        <div class="col-md-9">
                          <input v-model="teamMemberUsername"
                                 placeholder="recipient's distinctive name"
                                 type="text"
                                 class="form-control"
                                 @keypress.enter="inviteMemberPrompt"
                          >

                          <div v-if="teamMemberUsernameError"
                               class="error-msg"
                          >
                            Please enter a distinctive name
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="!canInviteMembers"
                         style="margin-top: 25px; font-size: 15px; color: #a33025; line-height: 1.3;"
                    >
                      <div>Your current plan does not allow you to add any more members to your
                        team. In case you still need to do so, you have one the following
                        options:
                      </div>
                      <br>
                      <div>1) Remove an existing member</div>
                      <div>2) Upgrade your plan</div>
                      <div>3) Contact us
                        <small>(in case you have subscribed for the "Big Team" plan)</small>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 20px;">
                    <div class="col-md-12">
                      <button
                          :class="[ 'btn btn-lg btn-primary btn-embossed pull-right', { 'disabled': !canInviteMembers } ]"
                          :style="{ 'pointer-events': inviting || !canInviteMembers ? 'none' : 'auto', opacity: inviting ? 0.85 : 1 }"
                          @click="inviteMemberPrompt"
                          ref="inviteButton"
                      >
                                                <span v-if="inviting"
                                                      class="fa fa-circle-o-notch fa-spin fa-fw"
                                                ></span>

                        <template v-else>Invite</template>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <div class="text">
                  <div>Please define a single-word name for your team.</div>
                  <div>(You will not be able to change this value once set.)</div>
                </div>

                <br>

                <div class="col-sm-6 col-md-offset-3">
                  <div class="team-name-wrapper">
                    <input v-model="teamNameTemp"
                           class="team-name"
                           placeholder="Team name"
                           type="text"
                           @click="$event.target.select()"
                           @keyup.enter="saveTeamName"
                    >

                    <template v-if="teamNameError">
                      <div class="error-msg">
                        <div v-if="teamNameError === 'empty'">
                          the value cannot be empty
                        </div>

                        <div v-if="teamNameError === 'not-alphanumeric'">
                          only letters and numbers are allowed
                        </div>

                        <div v-if="teamNameError === 'exists'">
                          the name already exists
                        </div>
                      </div>
                    </template>

                    <div class="save">
                      <button class="btn btn-primary"
                              @click="saveTeamName"
                      >
                        <span class="fa fa-floppy-o"></span>Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

// Style

<style lang="less" scoped>
@import "../../less/mixins";
@import "../../less/libs/flat-ui/variables";

#settings {

  #fb-preview-settings,
  #user-settings,
  #social-link-settings {
    padding: 35px 20px 60px 20px;

    h4 {
      color: #555;
    }

    input {
      border-color: transparent;
      background: #f5f5f5;
    }

    label {
      padding-left: 5px;
      color: #777;
    }

    > .wrapper {
      margin-top: 50px;
    }

    .option {
      position: relative;
      padding-bottom: 20px;

      > label {
        font-size: 17px;
        color: #555;
        font-weight: bolder;
        width: 100%;
        padding-left: 4px;

        &:not(.enabled) {
          opacity: 0.6;
        }

        > .btn {
          .block-round;

          position: relative;
          width: 25px;
          height: 25px;
          padding: 0;
          font-size: 10px;
          margin-left: 4px;
          margin-top: -4px;

          > span {
            .centered-block;

            line-height: 1;
          }
        }
      }

      > .bootstrap-switch {
        right: 0;
        position: absolute;
        top: 5px;
      }
    }
  }

  #app-settings,
  #team-settings,
  #social-link-settings {
    padding: 35px 20px 45px 20px;

    .text {
      color: #777;
      font-size: 18px;
      text-align: center;
      font-weight: lighter;
    }
  }

  #social-link-settings {

    .select-list {
      margin-top: 17px;
      text-align: center;

      display: flex;
      height: 55px;

      > a {
        flex: 0 0 55px;
        margin-left: 10px;
        padding: 0;
        position: relative;

        > span {
          .centered-block;

          /*font-size: 11px;*/
        }
      }
    }

    .link-preview {
      margin-top: 25px;
      text-align: center;

      > .title {
        padding: 5px;
        border-radius: 4px;
        color: #fff;
        background: #555;
        max-width: 300px;
        display: none;
        margin: 0 auto;
      }

      > .preview {
        color: #c5c5c5;
        font-size: 24px;
        display: block;
        margin-top: 10px;
      }

      &.copy-url {
        display: flex;
        height: 55px;
        margin-top: 17px;

        > .preview {
          margin-top: 0;
          padding: 11px;
          border-radius: 200px;
          background: #f4f4f4;
          flex: 1;
          font-size: 20px;
          color: #aaa;
        }

        > button {
          flex: 0 0 55px;
          margin-left: 10px;
        }
      }
    }

    .vanity-url-wrapper {
      text-align: center;

      > .vanity-url {
        border: none;
        background: #f4f4f4;
        border-radius: 50px;
        padding: 10px;
        text-align: center;
        font-size: 20px;
        font-weight: bolder;
        color: #555;
        outline: none;
      }

      > .error-msg {
        margin-top: 20px;
        color: @brand-danger;
        font-size: 16px;
      }

      > .save {
        margin-top: 20px;

        > button {

          > span {
            margin-right: 8px;
          }
        }
      }
    }
  }

  #team-settings {

    .team-name-wrapper {
      text-align: center;

      > .team-name {
        border: none;
        background: #f4f4f4;
        border-radius: 50px;
        padding: 10px;
        text-align: center;
        font-size: 20px;
        font-weight: bolder;
        color: #555;
        outline: none;
      }

      > .error-msg {
        margin-top: 20px;
        color: @brand-danger;
        font-size: 16px;
      }

      > .save {
        margin-top: 20px;

        > button {

          > span {
            margin-right: 8px;
          }
        }
      }
    }

    .team-members {
      padding: 0;

      > .header {
        border-radius: 4px;
        overflow: hidden;

        > h4 {
          color: #fff;
          text-align: center;
          padding: 20px;
          background: @brand-secondary;
          margin-top: 0;
          margin-bottom: 0;
        }

        > .assigned {
          margin-top: 0;
          padding: 10px;
          background: darken(@brand-secondary, 10%);
          text-align: center;

          > span {
            color: #fff;
          }
        }
      }

      > .member {
        background: #fafafa;
        border-radius: 4px;
        padding: 10px;
        margin: 10px 0;
        display: flex;

        &.deleting {
          opacity: 0.85;
          pointer-events: none;
        }

        > div {
          flex: 1;
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }

          > div {
            font-size: 12px;
            text-align: center;
          }

          > label {
            font-size: 13px;
            font-weight: bolder;
            padding: 5px;
            border-radius: 5px;
            background: #eee;
            line-height: 1;
            text-align: center;
            width: 100%;
            margin-bottom: 10px;
          }

          > .name {
            color: #777;
            line-height: 1;
            height: 15px;
            /*font-weight: bolder;*/
            white-space: nowrap;
          }

          > .active {
            .block-round;

            height: 16px;
            width: 16px;
            border-radius: 50px;
            background: @brand-warning;
            display: block;
            margin: 0 auto;

            &.yes {
              background: @brand-success;
            }
          }

          &.user-delete {
            flex: 0 0 55px;

            > .btn {
              .block-round;

              width: 100%;
              height: 100%;
              position: relative;

              > span {
                .centered-block;

                font-size: 17px;
              }
            }
          }
        }
      }

      > .no-members {
        color: #777;
        font-size: 17px;
        text-align: center;
        font-weight: lighter;
        padding-top: 50px;
      }
    }

    .invitation-form {
      border-radius: 4px;
      background: #fafafa;
      padding: 20px;

      > label {
        font-size: 17px;
        color: #555;
        font-weight: bolder;
        width: 100%;
        padding-left: 4px;
      }

      > .field {
        margin-top: 8px;

        label {
          font-weight: bolder;
          color: #888;
          line-height: 3.1;
          margin-bottom: 0;
          text-align: center;
          font-size: 14px;
        }

        input {
          border-color: transparent;

          &:hover {
            border-color: #d9dcde;
          }

          &:focus {
            border-color: @brand-secondary;
          }
        }

        .error-msg {
          color: @brand-danger;
          margin-top: 5px;
          padding-left: 5px;
          font-weight: bolder;
          font-size: 12px;
        }
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>

<style lang="less">
#settings {

  #social-link-settings {

    .select-list {

      > div {
        flex: 1;
        color: #aaa;
        border-radius: 200px;

        > a {
          font-size: 17px;
          padding: 15px 39px 21px 15px;
        }
      }
    }
  }
}
</style>

<style lang='less'>
#user-settings {

  .option {

    > .bootstrap-switch {
      right: 0;
      position: absolute;
      top: 5px;
    }
  }
}
</style>

// Javascript

<script>
import axios from 'axios';
import validator from 'validator';
import queryString from 'query-string';

import ListStyler from './ListStyler.vue'
import FbPreviewSettings from './FbPreviewSettings.vue';

export default {
  data() {
    return {
      email: null,
      name: null,
      blog: null,
      listStyle: null,
      socialLink: null,

      changePassword: false,

      newPassword: null,
      repeatPassword: null,
      monetization: false,
      linkdeli_shop_set: null,

      vanityUrl: null,
      vanityUrlTemp: null,
      vanityUrlError: null,

      team: {},
      teamName: null,
      teamMembers: [],
      teamNameTemp: null,
      teamNameError: null,
      teamAllowAffiliateIDs: false,

      inviting: false,

      teamMemberEmail: '',
      teamMemberEmailError: false,
      teamMemberUsername: '',
      teamMemberUsernameError: false,
      currentMember: {},

      currentTab: null
    }
  },

  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      }
    },
    user: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },

  created() {
    _.merge(this, this.data);

    this.vanityUrl = this.user.vanity_url;
    this.teamName = this.team.name;
    this.teamMembers = this.team.members;
    this.teamAllowAffiliateIDs = this.team.allow_affiliate_ids;

    this.currentTab = queryString.parse(window.location.search).tab || 'account';
  },

  mounted() {
    this.initListSelect();

    // make sure all switches have been initialized
    let switchListen = (switchClass, listener) => {
      let $switch = null
          , loop = setInterval(() => {
        $switch = $(switchClass);

        if ($switch.length) {
          $switch.on('switchChange.bootstrapSwitch', listener);
          clearInterval(loop);
        }
      }, 500);
    };

    switchListen('.bootstrap-switch-id-monetization', (e, state) => {
      this.monetization = state;

      if (state) {
        this.$http.get('/api/user/monetization').then(response => {
          this.linkdeli_shop_set = response.body;
        }).catch(error => {
          // do nothing
        });
      }
    });
  },

  components: {
    'list-styler': ListStyler,
    'fb-preview-settings': FbPreviewSettings,
  },

  computed: {
    password() {
      if (this.newPassword)
        return {
          new: this.newPassword,
          repeat: this.repeatPassword
        };
      else
        return null;
    },

    // teams
    canInviteMembers() {
      return this.team.max > this.teamMembers.length;
    },

    // social link
    vanityUrlPreview() {
      let alias = null;

      if (!this.vanityUrl) {
        alias = this.vanityUrlTemp ? _.kebabCase(_.toLower(this.vanityUrlTemp)) : 'your-alias';
      } else {
        alias = this.vanityUrl;
      }

      return `${this.env.SHORTENER_URL}social/${alias}`;
    }
  },

  methods: {
    applyChangePassword() {
      this.changePassword = true;
    },

    linkdeliShopSet() {
      this.$http.post('/api/user/setting', {
        linkdeli_shop_set: 'ok'
      }).then(response => {
        this.linkdeli_shop_set = 'ok';
      }).catch(error => {
        // do nothing
      });
    },

    saveUser() {
      let user = {
        name: this.name,
        email: this.email,
        blog: this.blog,
        password: this.password
      };

      if (this.isPlan('platinum') || this.isTeamOwner) {
        _.merge(user, {
          monetization: this.monetization
        });
      }

      this.$http.post('/account/edit', user).then(response => {
        if (response.body === 'ok') {
          this.$events.emit('notify', {
            type: 'success',
            title: 'Saved',
            message: 'Account details saved successfully',
            timeout: 2000,
            onClose() {
              window.location.reload();
            }
          });
        } else {
          this.$events.emit('notify', {
            type: 'danger',
            title: 'Password not saved',
            message: response.body
          });
        }
      }).catch(error => {
        this.$events.emit('notify', {
          type: 'danger',
          title: 'Error',
          message: 'Error saving account details',
          timeout: 2000
        });
      });
    },

    maybePreventFeature(featureName, e) {
      if (!this.featureAllowed(featureName)) {
        e.preventDefault();
        this.$events.emit('feature-not-supported', featureName);
      }
    },

    // list styling
    saveStyle(style) {
      this.$http.post('/api/user/setting', {
        listStyle: style
      }).then(response => {
        this.$events.emit('notify', {
          type: 'success',
          title: 'Saved',
          message: 'List styling saved successfully',
          timeout: 2000
        });
      }).catch(error => {
        this.$events.emit('notify', {
          type: 'danger',
          title: 'Error',
          message: 'Error saving list styling',
          timeout: 2000
        });
      });
    },

    // social link
    saveVanityUrl() {
      if (!this.vanityUrlTemp) {
        this.vanityUrlError = 'empty';
        return;
      } else if (!validator.isAlphanumeric(this.vanityUrlTemp)) {
        this.vanityUrlError = 'not-alphanumeric';
        return;
      }

      this.$http.post('/api/user/vanity_url', {
        url: _.kebabCase(_.toLower(this.vanityUrlTemp))
      }).then(response => {
        let body = response.body,
            status = body.status;
        modal-title
        switch (status) {
          case 'ok':
            this.vanityUrl = body.url;
            this.socialLink = body.socialLink;

            this.$events.emit('notify', {
              type: 'success',
              title: 'Saved',
              message: 'Social link alias saved successfully',
              timeout: 2000
            });
            break;
          case 'exists':
            this.vanityUrlError = 'exists';
            return;
        }

      }).catch(error => {
        this.$events.emit('notify', {
          type: 'danger',
          title: 'Error',
          message: 'Error saving social link alias',
          timeout: 2000
        });
      });
    },

    initListSelect() {
      if ($('[data-toggle="select"]').length) {
        $('[data-toggle="select"]').select2();
      }

      $(this.$refs.socialLinkList).on('select2-selecting', e => {
        let optionVal = e.val;

        this.$http.post('/api/user/social_list', {
          listId: optionVal
        }).then(response => {
          if (response.body === 'ok') {
            this.$events.emit('notify', {
              type: 'success',
              title: 'Saved',
              message: 'Selected list saved successfully',
              timeout: 2000
            });
          } else {
            this.$events.emit('notify', {
              type: 'danger',
              title: 'Selected list not saved',
              message: response.body
            });
          }
        }).catch(error => {
          this.$events.emit('notify', {
            type: 'danger',
            title: 'Error',
            message: 'Error saving selected list',
            timeout: 2000
          });
        });
      });
    },

    // teams
    saveTeamName() {
      if (!this.teamNameTemp) {
        this.teamNameError = 'empty';
        return;
      } else if (!validator.isAlphanumeric(this.teamNameTemp)) {
        this.teamNameError = 'not-alphanumeric';
        return;
      }

      this.teamNameTemp = _.kebabCase(_.toLower(this.teamNameTemp));

      this.$http.get(`/api/user/team/name/${this.teamNameTemp}`).then(response => {
        let data = response.body;

        if (data === 'ok') {
          this.teamName = this.teamNameTemp;
        } else if (data === 'exists') {
          this.teamNameError = 'exists';
        } else if (data === 'empty') {
          this.teamNameError = 'empty';
        }
      }).catch(error => {
        this.$events.emit('notify', {
          type: 'danger',
          title: 'Error',
          message: 'Error saving team name',
          timeout: 2000
        });
      });
    },

    inviteMemberPrompt() {
      if (validator.isEmail(this.teamMemberEmail) && this.teamMemberUsername.length) {

        swal({
              title: "Are you sure?",
              text: `Send an invitation to <b>${this.teamMemberEmail}</b>?`,
              html: true,
              showCancelButton: true,
              confirmButtonColor: "#16a085",
              confirmButtonText: "Send",
              closeOnConfirm: true
            },
            () => {
              this.inviting = true;

              this.teamMemberEmailError = false;
              this.teamMemberUsernameError = false;

              this.$http.post('/api/user/team/member/invite', {
                email: this.teamMemberEmail,
                name: this.teamMemberUsername
              }).then(response => {
                console.log(response.body)
                if (response.body === 'email-already-registered') {
                  this.$events.emit('notify', {
                    type: 'danger',
                    title: 'Caution',
                    message: `The email '${this.teamMemberEmail}' is already in use by a user. Please contact our support in order to proceed.`
                  });
                } else if (response.body === 'name-exists') {
                  this.$events.emit('notify', {
                    type: 'danger',
                    title: 'Error',
                    message: `You have already used the name '${this.teamMemberUsername}'. Your member names have to be unique. Please use a different name and try again.`
                  });
                } else {
                  this.teamMembers = response.body;

                  this.$events.emit('notify', {
                    type: 'success',
                    title: 'Member invited',
                    message: `Sent invitation to ${this.teamMemberUsername}`,
                    timeout: 2000
                  });

                  this.teamMemberEmail = '';
                  this.teamMemberUsername = '';
                }

                this.inviting = false;
              }).catch(error => {
                console.log(error)
                this.$events.emit('notify', {
                  type: 'danger',
                  title: 'Error',
                  message: 'Error sending the invitation',
                  timeout: 2000
                });

                this.inviting = false;
              });
            });
      } else {
        if (!validator.isEmail(this.teamMemberEmail))
          this.teamMemberEmailError = true;
        if (!this.teamMemberUsername.length)
          this.teamMemberUsernameError = true;
      }
    },

    deleteMemberPrompt(member) {
      this.currentMember = member;

      swal({
            title: "Are you sure?",
            text: `Remove <b>${this.currentMember.name}</b> from your team?`,
            html: true,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Remove",
            closeOnConfirm: true
          },
          () => {
            this.currentMember.deleting = true;

            this.$http.delete(`/api/user/team/member/delete/${this.currentMember.id}`).then(response => {
              this.teamMembers = response.body;

              this.$events.emit('notify', {
                type: 'success',
                title: 'Member removed',
                message: `<b>${this.currentMember.name}</b> deleted successfully`,
                timeout: 2000
              });
            }).catch(error => {
              this.$events.emit('notify', {
                type: 'danger',
                title: 'Error',
                message: 'Error deleting team member',
                timeout: 2000
              });

              this.currentMember.deleting = true;
            });
          });
    },


  },

  watch: {
    teamAllowAffiliateIDs(val) {
      this.$http.post('/api/user/team/allowaffiliateids', {
        allowAffiliateIDs: val
      }).then(response => {
      }).catch(error => {
        // do nothing
      });

    },
    inviting(val) {
      if (val)
        this.$refs.inviteButton.style.width = window.getComputedStyle(this.$refs.inviteButton).width;
    },
    vanityUrl(val) {
      if (val) {
        this.$nextTick(() => {
          this.initListSelect();
        });
      }
    }
  }
}
</script>
