var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "invoice" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "content" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "invoice" }, [
            _vm._v("Invoice " + _vm._s(_vm.details.invoice.local_id))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "customer" }, [
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "name" }, [
                _vm._v(
                  "\n                            " +
                    _vm._s(_vm.details.invoiceDetails.firstName) +
                    " " +
                    _vm._s(_vm.details.invoiceDetails.lastName) +
                    "\n                        "
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(_vm._s(_vm.details.invoiceDetails.address || "address"))
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  _vm._s(_vm.details.invoiceDetails.region || "region") +
                    ", " +
                    _vm._s(
                      _vm.details.invoiceDetails.postalCode || "postal code"
                    ) +
                    "\n                        "
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm.details.invoiceDetails.countryCode || "country code"
                  )
                )
              ]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.details.vatNo))]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "edit", on: { click: _vm.editInvoiceDetails } },
                [_c("span", { staticClass: "fa fa-pencil" })]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "date" }, [
              _c("div", {}, [_vm._v(_vm._s(_vm.details.invoice.timestamp))]),
              _vm._v(" "),
              _c("div", {}, [
                _vm._v(
                  "Due " +
                    _vm._s(_vm.details.invoice.timestamp) +
                    " (Due Upon Receipt)"
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "payments" }, [
            _c("table", { staticClass: "table" }, [
              _vm._m(1),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  [
                    _c("tr", [
                      _c("td", [_vm._v("1")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("Monthly subscription to LinkDeli (plan "),
                        _c(
                          "b",
                          { staticStyle: { "text-transform": "capitalize" } },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.details.invoice.planName)
                            )
                          ]
                        ),
                        _vm._v(")\n                                ")
                      ]),
                      _vm._v(" "),
                      _c("td"),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.details.invoice.planTotal))])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", { staticStyle: { border: "none" } }),
                      _vm._v(" "),
                      _c("td", { staticStyle: { border: "none" } }),
                      _vm._v(" "),
                      _vm._m(3),
                      _vm._v(" "),
                      _c("td", { staticStyle: { border: "none" } }, [
                        _c("b", [_vm._v(_vm._s(_vm.details.invoice.paid))])
                      ])
                    ])
                  ],
                  _vm._v(" "),
                  _vm._m(4)
                ],
                2
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "logo" }, [
        _c("img", { attrs: { src: "/img/logo.svg" } })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info" }, [
        _c("div", { staticClass: "company" }, [_vm._v("LinkDeli - Kormpetis")]),
        _vm._v(" "),
        _c("div", [_vm._v("Proussis i710, Kardia")]),
        _vm._v(" "),
        _c("div", [_vm._v("57500, GREECE")]),
        _vm._v(" "),
        _c("div", [_vm._v("Email: info@linkdeli.com")]),
        _vm._v(" "),
        _c("div", [_vm._v("VAT: EL026620252")]),
        _vm._v(" "),
        _c("a", { attrs: { href: "https://linkdeli.com", target: "_blank" } }, [
          _vm._v(
            "\n                        https://linkdeli.com\n                    "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" }, attrs: { span: "1" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "60%" }, attrs: { span: "1" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "10%" }, attrs: { span: "1" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "10%" }, attrs: { span: "1" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Qty")]),
        _vm._v(" "),
        _c("th", [_vm._v("Description")]),
        _vm._v(" "),
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Price")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticStyle: { border: "none" } }, [
      _c("b", [_vm._v("Paid")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td"),
      _vm._v(" "),
      _c("td"),
      _vm._v(" "),
      _c("td", [
        _vm._v(
          "\n                               Total Due\n                            "
        )
      ]),
      _vm._v(" "),
      _c("td", [
        _vm._v(
          "\n                               $ 0.00\n                            "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }