var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "list-stats" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "collection" }, [
          _c("div", { staticClass: "well" }, [
            _c("div", { staticClass: "name" }, [
              _c("span", [
                _c("span", { staticClass: "title" }, [
                  _c("span", [_vm._v(_vm._s(_vm.list.title))])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "date" }, [
              _c("span", [_vm._v(_vm._s(_vm.creationDate))])
            ]),
            _vm._v(" "),
            _c("br")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "buttons" }, [
            _c("div", { staticClass: "wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary hint--top hint--rounded",
                  attrs: { "aria-label": "back" },
                  on: { click: _vm.goBack }
                },
                [_c("span", { staticClass: "fa fa-arrow-left" })]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("statistics", { attrs: { data: _vm.data, "api-path": _vm.apiPath } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }