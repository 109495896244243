// Template

<template>
    <div :class="[ 'image', { 'loaded': imgLoaded } ]">
        <div class="aspectKeep"></div>

        <template v-if="inViewport">
            <span v-if="!hasImage"
                  class="no-image"
            >
                <div class="default">
                    <span v-if="noImageMsg.length" v-html="noImageMsg"></span>
                    <b v-else>No image found</b>
                </div>
            </span>

            <transition v-else
                        name="fade"
            >
                <loader v-if="!imgLoaded"
                        :side="spinnerSide"
                        :color="spinnerColor"
                        :opacity="opacity"
                        width="spinnerWidth"
                ></loader>

                <div v-else
                     class="thumb"
                     :style="{ 'border-radius': roundness }"
                >
                    <template v-if="isPreview || isCover">
                        <span :class="{ 'preview': isPreview, 'cover': isCover }"
                              :data-src="imageSrc"
                              :style="{ 'background-image': imageUrl }"
                        ></span>
                    </template>

                    <template v-else>
                        <img :src="imageSrc"
                             :alt="alt"
                        >
                    </template>

                    <slot></slot>
                </div>
            </transition>
        </template>
    </div>
</template>

// Style

<style lang="less">
    @import "../../../less/mixins";

    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s
    }
    .fade-enter, .fade-leave-to {
        opacity: 0
    }

    .image {
        position: relative;

        > .no-image {
            .centered-block;

            text-align: center;
            width: ~'calc(100% - 20px)';

            > .default {
                margin-bottom: 10px;
                font-size: 14px;
            }
        }

        > .loader {
            .centered-block;
        }

        > span.symbol {
            .centered-block;
            font-size: 37px;
            color: fadeout(#000, 60%);
            z-index: 0;
        }

        > .aspectKeep {
            width: 100%;
            padding-bottom: 100%;
        }

        > .thumb {
            .content-block;
            animation-duration: 0.3s;
            background: transparent;

            > * {
                .centered-block;
                border-radius: 3px;
                overflow: hidden;
            }

            > img {
                max-width: 100%;
                max-height: 100%;
            }

            > .preview,
            > .cover {
                width: 100%;
                height: 100%;

                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat no-repeat;
            }
        }
    }
</style>

// Javascript

<script  >
    import inViewport from 'in-viewport';
    import _ from 'lodash';

    import Loader from './Loader.vue';

    export default
    {
        components: {
            loader: Loader
        },

        data()
        {
            return {
                imgLoaded: false,
                imgAspect: null,
                imgErrored: false,
                inViewport: false,
                tryingNoProtocol: true,
                forceDefaultMessage: false,
            };
        },
        props:
        {
            isPreview:
            {
                type: Boolean,
                default: false
            },
            isCover:
            {
                type: Boolean,
                default: false
            },
            src:
            {
                type: String,
                default: null
            },
            alt:
            {
                type: String,
                default: null
            },
            roundness: {
                type: String,
                default: '0%'
            },
            spinnerSide:
            {
                type: String
            },
            spinnerColor:
            {
                type: String
            },
            opacity:
            {
                type: Number
            },
            spinnerWidth:
            {
                type: Number,
                default: null
            },
            load:
            {
                type: Boolean,
                default: true
            },
            errorImage: {
                type: String,
                default: '/img/no-image.jpg'
            },
            noImageMsg: {
                type: String,
                default: ''
            }
        },
        computed:
        {
            hasImage()
            {
                return !!this.src;
            },

            imageUrl()
            {
                return `url("${ this.imageSrc }")`;
            },

            imageSrc()
            {
                return this.imgErrored
                        ? this.errorImage
                        : this.maybeRemoveProtocol(this.src);
            }
        },
        methods:
        {
            loadImage()
            {
                if (!this.hasImage || !this.load || !this.inViewport)
                    return;

                let image = new Image(),
                    onComplete = () =>
                    {
                        this.imgAspect = image.width / image.height;
                        this.imgLoaded = true;

                        this.$emit('image-loaded', {
                            width: image.width,
                            height: image.height,
                            aspect: this.imgAspect
                        });
                    },
                    onError = (e) =>
                    {
                        if (this.tryingNoProtocol) {
                            this.tryingNoProtocol = false;
                            this.loadImage();
                        } else {
                            this.imgErrored = true;
                            this.$emit('image-errored');
                            this.loadImage();
                        }
                    };

                image.onload = function()
                {
                    onComplete();
                };

                image.onerror = function(e)
                {
                    onError(e);
                };

                image.src = this.imageSrc;

                if (image.complete)
                    onComplete();
            },
            maybeRemoveProtocol(src) {
                let resultSrc = _.replace(_.replace(src, 'http:', ''), 'https:', '');

                return this.tryingNoProtocol
                        ? resultSrc
                        : src;
            }
        },
        mounted()
        {
            inViewport(this.$el, () => {
                this.inViewport = true;
                this.loadImage();
            });
        },
        watch:
        {
            src()
            {
                this.imgErrored = false;
                this.imgLoaded = false;
                this.tryingNoProtocol = true;
                this.loadImage();
            },
            load(bool)
            {
                if (bool)
                    this.loadImage();
            }
        }
    }
</script>
