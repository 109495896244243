var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "modal",
        {
          attrs: {
            show: _vm.showVatExemptionModal,
            id: "vat-modal",
            title: "Business VAT exemption",
            "ok-text": "I am in the EU but don't have a business VAT ID",
            "cancel-text": "I am not in the EU",
            force: true
          },
          on: {
            ok: function($event) {
              return _vm.noBusinessVAT()
            },
            cancel: function($event) {
              return _vm.notInTheEU()
            }
          }
        },
        [
          _c("div", { staticClass: "description" }, [
            _vm._v(
              "\n            EU Businesses are exempted of EU VAT. If you are an EU-based business please fill in below.\n        "
            )
          ]),
          _vm._v(" "),
          _c("ul", {}, [
            _c("li", {}, [
              _vm._v("EU Business: Please fill country and VAT id")
            ]),
            _vm._v(" "),
            _c("li", {}, [
              _vm._v("EU Citizen: Please click on the green button")
            ]),
            _vm._v(" "),
            _c("li", {}, [
              _vm._v("Non-EU Citizen: Please click on the gray button")
            ])
          ]),
          _vm._v(" "),
          _c("hr", { staticStyle: { color: "darkgray" } }),
          _vm._v(" "),
          _c("div", { staticClass: "fields" }, [
            _c("div", [
              _c("label", [_vm._v("Select country")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  ref: "languageSelect",
                  staticClass: "form-control select select-primary mbl",
                  staticStyle: { width: "100%" },
                  attrs: { "data-toggle": "select" }
                },
                [
                  _vm._l(_vm.countryCodes, function(countryCode, index) {
                    return [
                      _vm.userCountry === _vm.countryNames[index]
                        ? _c(
                            "option",
                            {
                              attrs: { selected: "" },
                              domProps: { value: countryCode }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.countryNames[index]) +
                                  "\n                        "
                              )
                            ]
                          )
                        : _c("option", { domProps: { value: countryCode } }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.countryNames[index]) +
                                "\n                        "
                            )
                          ])
                    ]
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "form-group",
                  "vat",
                  { "has-error": _vm.errorMsg.length }
                ]
              },
              [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v("VAT number")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "vat-input" },
                  [
                    _c("input", {
                      ref: "vatInput",
                      class: ["form-control", { fetching: _vm.fetching }],
                      attrs: {
                        autocomplete: "on",
                        type: "text",
                        placeholder: "enter your VAT number here"
                      },
                      domProps: { value: _vm.vatInput },
                      on: {
                        input: _vm.updateVatInput,
                        click: function($event) {
                          return _vm.$refs.vatInput.select()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.fetching
                      ? _c("loader", { attrs: { side: "20px" } })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.errorMsg.length
                  ? _c("div", { staticClass: "error-msg" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.errorMsg) +
                          "\n                "
                      )
                    ])
                  : _c("div", [
                      _c("small", { staticClass: "pull-right" }, [
                        _vm._v(
                          "Your VAT ID will automatically be checked for validity"
                        )
                      ])
                    ])
              ]
            ),
            _vm._v(" "),
            _c("hr", { staticStyle: { color: "darkgray" } })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }