<!-- Template  -->
<template>
    <modal :show="show"
           :full="true"
           :title="title"
           @cancel="cancel"
    >
        <div class="description">
            <i class="fa fa-info-circle" aria-hidden="true"></i> Enter the product URL (other fields will auto-complete)
        </div>
        <div class="fields">
            <div :class="[ 'form-group', 'product-link', { 'has-error': errorMsg.length } ]">
                <label class="control-label">Product URL</label>
                <span v-if="editingUrl" style="display:block; font-size:11px; padding-left: 4px; color: #d22424;">
                    Editing url with your own responsibility. Please make sure it is the correct link
                </span>
                <div class="url-input">
                    <input autocomplete="on"
                           :value="urlinput"
                           ref="urlInput"
                           type="text"
                           :class="[ 'form-control', { 'fetching': fetching } ]"
                           placeholder="copy / paste the product link here"
                           @input="updateUrl"
                           @click="$refs.urlInput.select()"
                    >
                       <!--:readonly="mode == 'edit'"-->
                    <loader v-if="fetching"
                            side="20px"
                    ></loader>
                </div>
                <div v-if="errorMsg.length"
                     class="error-msg"
                >
                    {{ errorMsg }}
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="image-preview">
                        <image-square :src="product.thumb_path"
                                      :alt="title"
                                      :opacity="0.7"
                                      spinner-color="#bbb"
                                      spinner-side="30px"
                                      no-image-msg="<div style='color: #999;'><div><span style='font-size: 40px; margin-bottom: 5px;' class='fa fa-picture-o fa-3'></span></div><div style='font-size: 12px;'>image preview</div></div>"
                        ></image-square>
                    </div>
                </div>

                <div class="col-md-6">
                    <label class="control-label">Title</label>
                    <input autocomplete="on"
                           v-model="product.title"
                           ref="title"
                           type="text"
                           class="form-control input-sm"
                           placeholder="product title"
                           @click="$refs.title.select()"
                    >

                    <label class="control-label">Image URL</label>
                    <div class="thumb-path-input">
                        <input autocomplete="on"
                               v-model="product.thumb_path"
                               ref="image"
                               type="text"
                               class="form-control input-sm"
                               placeholder="product image"
                               @click="$refs.image.select()"
                        >
                        <button :class="[ 'btn', 'btn-primary', 'hint--top', 'hint--rounded', { 'enabled': (product.url && !imageUploading) || !followLink } ]"
                                :style="{ 'pointer-events': (product.url && !imageUploading) || !followLink ? 'all' : 'none' }"
                                aria-label="upload image"
                                @click="uploadImage"
                        >
                            <loader v-if="imageUploading"
                                    side="15px"
                                    color="#fff"
                                    :opacity="1"
                            ></loader>
                            <span v-else
                                  class="fa fa-upload"
                            ></span>
                        </button>

                        <input class="hidden"
                               type="file"
                               accept="image/*"
                               ref="imageInput"
                               @change="getImageFile"
                        >
                    </div>
                </div>

                <!--these fields will not be visible-->
                <span class="hidden">
                    <label class="control-label">UPC</label>
                    <input autocomplete="on"
                           v-model="product.upc"
                           ref="upc"
                           type="text"
                           class="form-control input-sm"
                           placeholder="product upc"
                           @click="$refs.upc.select()"
                    >

                    <label class="control-label">Price</label>
                    <input autocomplete="on"
                           v-model="product.price"
                           ref="price"
                           type="text"
                           class="form-control input-sm"
                           placeholder="product price"
                           @click="$refs.price.select()"
                    >
                </span>
            </div>
        </div>

        <div slot="footer">
            <span class=pull-left>

            <label :class="{ 'enabled': followLink }">
                Follow redirects

            </label>
            <input v-if="followLink"
                   id="followLink"
                   type="checkbox"
                   checked
                   data-toggle="switch"
                   data-off-color="default"
                   data-on-color="success"
            >
            <input v-else
                   id="followLink"
                   type="checkbox"
                   data-toggle="switch"
                   data-off-color="default"
                   data-on-color="success"
            >
   <a href="/page/what-follow-redirects-button-does-linkdeli-add-product-dialog" target="_blank"
      class="btn btn-info">
                    <span class="fa fa-question"></span>
                </a>
            </span>

            <button :class="[ 'btn', 'btn-embossed', 'btn-primary', { 'unabled': !canSave || saving } ]"
                    style="width: 69px;"
                    @click="save"
            >
                <template v-if="!saving">Save</template>
                <span v-else
                      class="fa fa-circle-o-notch fa-spin fa-fw"
                ></span>
            </button>

            <button class="btn btn-embossed btn-darkgray"
                    @click="cancel"
            >
                Close
            </button>
        </div>
    </modal>
</template>

<!--Style-->
<style lang="less" scoped>
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    .description {
        padding: 7px;
        color: @brand-info;
        border: 1px solid @brand-info;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 20px;

        i {
            margin-right: 10px;
        }
    }

    .fields {
        display: inline-block;
        width: 100%;

        > .product-link {
            margin-bottom: 15px;
            display: inline-block;
            width: 100%;

            > .url-input {
                position: relative;

                > input.fetching {
                    padding-right: 40px;
                }

                > .loader {
                    position: absolute;
                    top: 11px;
                    right: 11px;
                }
            }

            > .error-msg {
                color: @brand-danger;
                font-size: 13px;
                text-align: center;
            }

            > button {
                float: right;
            }
        }

        label {
            margin-bottom: 0;
            padding-left: 5px;
            font-weight: bolder;
        }

        input {
            margin-bottom: 10px;
        }

        .image-preview {
            width: 157px;
            margin: 0 auto;
        }

        .thumb-path-input {
            position: relative;

            > input {
                width: ~'calc(100% - 45px)';
                float: left;
            }

            > button {
                .block-round;

                width: 35px;
                height: 35px;
                margin-left: 10px;
                position: relative;
                padding: 0;

                > span {
                    .centered-block;

                    padding-bottom: 2px;
                    font-size: 12px;
                }
            }
        }
    }

    .bnt-questionmark {
        .btn-info {
            padding: 5px;
            margin: 0px;
        }

    }

    .modal-footer {
        button {
            &.unabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
</style>

<!--Script-->
<script>
    import validator from 'validator';
    import messages from '../lib/config/messages';

    import Modal from '../lib/components/Modal';
    import Loader from '../lib/components/Loader';
    import ImageSquare from '../lib/components/ImageSquare';

    export default {
        components: {
            'modal': Modal,
            'loader': Loader,
            'image-square': ImageSquare,
        },

        data(){
            return {
                urlinput: null,
                originalUrlInput: null,
                fetching: false,
                product: {
                    title: null,
                    upc: null,
                    thumb_path: null,
                    url: null,
                    price: null
                },
                errorMsg: '',
                error: [],
                saving: false,
                open: false,
                imageUploading: false,
                autocorrectUrl: false,
                followLink: true
            }
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            data: {
                type: Object,
                default() {
                    return {};
                }
            },
            onClose: {
                type: Function,
                default: () => {
                    return;
                }
            },
            singleLink: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            canSave() {
                try {
                    return (this.product.thumb_path &&
                            this.product.title &&
                            this.urlinput && !this.errorMsg.length
                    );
                } catch (e) {
                    return false;
                }
            },

            mode() {
                return this.data.mode || 'add';
            },

            editingUrl() {
                return this.mode === 'edit' && (this.originalUrlInput !== this.urlinput);
            },

            title() {
                return `${ _.capitalize(this.mode) } product`;
            },

            errorText() {
                let text = '';

                _.each(this.error, (error, key) => {
                    text += `${ _.upperCase(key) }: ${ error }\n`;
                });

                return text;
            }
        },

        methods: {
            updateUrl: _.debounce(function (e) {
                this.urlinput = e.target.value;
            }, 500),

            fetchLinkData() {
                // when editing do not refetch details.
                if (this.mode === 'edit') {
                    return true;
                }

                if (! this.urlinput) {
                    return this.errorMsg = 'Please enter the URL of your product';
                } else if (! validator.isURL(this.urlinput)) {
                    return this.errorMsg = 'Please enter a valid URL';
                } else {
                    this.errorMsg = '';
                }

                if (this.followLink) {
                    this.fetching = true;

                    this.$http.get('/api/link/custom/data/fetch', {
                        params: { url: this.urlinput }
                    })
                    .then(
                        (response) => {
                            let productData = response.body;

                            this.autocorrectUrl = true;
                            this.urlinput = decodeURIComponent(productData.url);
                            this.$nextTick(() => {
                                this.autocorrectUrl = false;
                            });

                            try {
                                // workaround for problematic images
                                let malformedJpgCheck = productData.thumb_path.indexOf('.jpg/');
                                let malformedPngCheck = productData.thumb_path.indexOf('.png/');

                                if (malformedJpgCheck > -1)
                                    productData.thumb_path = productData.thumb_path.substring(0, malformedJpgCheck + 4);
                                else if (malformedPngCheck > -1)
                                    productData.thumb_path = productData.thumb_path.substring(0, malformedPngCheck + 4);
                            } catch (e) {
                                // do nothing
                            }

                            this.product = {
                                url: this.urlinput,
                                title: productData.title || this.product.title,
                                thumb_path: productData.thumb_path || this.product.thumb_path,
                                upc: productData.upc,
                                price: productData.price
                            };

                            this.fetching = false;

                            if (!this.product.title || !this.product.thumb_path)
                                this.$events.emit('notify', {
                                    title: 'Incomplete product information',
                                    message: 'Please enter the missing values manually.',
                                    timeout: 5000
                                });
                        }
                    ).
                    catch((error) => {
                        this.fetching = false;
                        this.errorMsg = 'Please enter a valid URL';
                        error.json().then((response) => {
                            this.errorMsg = response.message
                                                ? response.message
                                                : 'Please enter a valid URL'
                        });
                    });
                }
            },

            resetProductData() {
                this.product = {
                    title: null,
                    upc: null,
                    thumb_path: null,
                    url: null,
                    price: null
                };
            },

            resetAll() {
                this.urlinput = null;
                this.errorMsg = '';

                this.resetProductData();
            },

            save() {
                this.saving = true;

                switch (this.mode) {
                    case 'add':
                        this.addLink()
                        break;
                    case 'edit':
                        this.updateLink()
                        break;
                }
            },

            addLink() {
                this.product.url = this.urlinput;

                this.$http.post('/api/link/custom/add', {
                    data: this.product,
                    listId: this.data.listId || null
                }).then(
                        response => {
                            if (response) {
                                if (response.body.success) {
                                    if (this.singleLink) {
                                        this.saving = false;

                                        this.cancel({
                                            status: 'product-added',
                                            data: response.body.link
                                        });
                                    } else {
                                        swal({
                                                    title: "Done!",
                                                    text: "Link added to your library!",
                                                    type: "success",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#DD6B55",
                                                    confirmButtonText: "Add another!",
                                                    cancelButtonText: "Close",
                                                    closeOnConfirm: true,
                                                    showLoaderOnConfirm: true
                                                },
                                                (isConfirm) => {
                                                    this.saving = false;

                                                    if (isConfirm) {
                                                        this.resetAll();
                                                        return true;
                                                    } else
                                                        this.cancel({
                                                            status: 'product-added',
                                                            data: response.body.link
                                                        });
                                                });

                                        this.saving = false;
                                    }
                                }
                            }
                        },
                        error => {
                            this.handleError(error);
                        }
                )
            },

            updateLink() {
                this.product.url = this.urlinput;

                this.$http.patch(`/api/link/custom/update/${ this.data.id }`, this.product).then(
                        response => {
                            this.$events.emit('product-edited', {
                                id: this.data.id,
                                data: this.product
                            });

                            if (response) {
                                if (response.body.success) {
                                    swal({
                                        title: "Link changes",
                                        text: 'Link changes have been saved!',
                                        type: "success",
                                        timer: 1000,
                                        showConfirmButton: false
                                    });

                                    this.saving = false;
                                    this.cancel({
                                        status: 'product-edited',
                                        id: this.data.id,
                                        data: this.product
                                    });
                                }
                            }
                        },
                        error => {
                            this.handleError(error);
                        }
                )
            },

            handleError(error) {
                this.saving = false;
                this.error = error.data;

                swal({
                    title: "Some error occurred",
                    text: this.errorText,
                    type: "error"
                });

                if (this.error.error === 'Invalid URL')
                    this.$events.emit('notify', messages.link_format_info);
            },

            cancel(event)
            {
                event = event || {status: 'none'};

                this.$emit('cancel', event);

                setTimeout(() => {
                    this.open = false;
                    this.resetAll();
                }, 500);
            },

            uploadImage() {
                this.$refs.imageInput.click();
            },

            getImageFile() {
                let imageFile = this.$refs.imageInput.files[0],
                        fileSize = (imageFile.size / (1024 * 1024)).toFixed(2);

                if (!_.startsWith(imageFile.type, 'image')) {
                    this.$events.emit('notify', {
                        type: 'danger',
                        title: 'File type not supported',
                        message: 'The file you are trying to upload is not an image.',
                        timeout: 5000
                    });
                }
                else if (fileSize > 5) {
                    this.$events.emit('notify', {
                        type: 'danger',
                        title: 'Image size exceeded',
                        message: 'Your image is bigger than 5MB. Please upload a smaller image',
                        timeout: 5000
                    });
                }
                else {
                    let formData = new FormData();
                    formData.append('image', imageFile);

                    this.imageUploading = true;

                    this.$http.post('/api/link/custom/upload/image', formData).then(response => {
                        let data = response.body;

                        if (data.success)
                            this.product.thumb_path = this.env.S3_IMAGES + data.img;
                        else if (data.error)
                            this.$events.emit('notify', {
                                type: 'danger',
                                message: data.msg,
                                timeout: 5000
                            });

                        this.imageUploading = false;
                    }).catch(error => {
                        this.$events.emit('notify', {
                            type: 'danger',
                            message: 'Something went wrong. Please try again.',
                            timeout: 5000
                        });

                        this.imageUploading = false;
                    });
                }
            }
        },
      mounted() {
        let switchListen = (switchClass, listener) => {
          let $switch = null
              , loop = setInterval(() => {
            $switch = $(switchClass);

            if ($switch.length) {
              $switch.on('switchChange.bootstrapSwitch', listener);
              clearInterval(loop);
            }
          }, 500);
        };

        switchListen('.bootstrap-switch-id-followLink', (e, state) => {
          this.followLink = state;
        });
      },
        watch: {
            show(bool) {
                if (bool && !!this.data) {
                    this.urlinput = this.data.url;
                    this.originalUrlInput = this.urlinput;
                    _.merge(this.product, this.data);

                    setTimeout(() => {
                        this.open = true;
                    }, 50)
                }
            },
            urlinput(val) {
                if (this.autocorrectUrl)
                    return;

                if (val && this.open)
                    this.fetchLinkData();

                if (!val)
                    this.resetAll();
            }
        }
    }
</script>
