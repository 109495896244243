<template>
    <div id="plan-countdown">
        <div v-if="isTrial"
             class="trial-time"
        >
            <a href='/subscription'>
                <template v-if="isActiveTrial">
                    <div class="title">
                        Trial ends in <span class="time">{{ trialTime }}</span>
                    </div>
                </template>

                <template v-else>
                    <div class="title">
                        Account has <span class="expired">expired</span>
                    </div>
                </template>
            </a>
        </div>

        <template v-else>
            <div v-if="planClicks - user.clicks > 0"
                 class="clicks-percent hint--left hint--rounded hint--medium"
                 aria-label="This is the number of leads available until the end of this month"
            >
                <svg :width="svgSize"
                     :height="svgSize"
                     viewBox="0 0 120 120"
                >
                    <circle cx="60" cy="60" r="54" fill="none" stroke="#cccccc" stroke-width="12"/>
                    <circle class="progress__value"
                            cx="60"
                            cy="60"
                            r="54"
                            fill="none"
                            :stroke="counterColor"
                            :stroke-dashoffset="counterOffset"
                            stroke-width="12"
                            stroke-linecap="round"
                    />
                </svg>
            <span class="clicks-remaining"
                  :style="{ 'color': counterColor }"
            >
                {{ clicksRemaining }}
            </span>
                <span class="back"></span>
            </div>

            <div v-else
                 class="no-clicks-left"
            >
                <div class="title">
                    Monthly leads <span class="expired">outreached</span>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang='less'>
    @import "../../../less/mixins";
    @import "../../../less/libs/flat-ui/variables";

    #plan-countdown {
        position: absolute;
        top: -55px;
        right: 10px;
        z-index: 99999;
        display: inline-flex;
        /*padding: 15px;*/
        cursor: default;

        > .trial-time,
        > .no-clicks-left {
            border: 1px solid #e2e2e2;
            text-align: center;
            font-weight: bolder;
            background: #ececec;
            padding: 10px;
            border-radius: 5px;
            line-height: 1.4;

            > .title {
                font-size: 17px;
            }

            > .time {
                color: @brand-secondary;
            }

            > .expired {
                color: @brand-danger;
            }
        }

        > .clicks-percent {
            display: inline-flex;
            position: relative;

            > svg {
                transform: rotate(-90deg);
                position: relative;
                z-index: 1;

                > .progress__value {
                    stroke-dasharray: 339.292;
                }
            }

            > .clicks-remaining {
                .centered-block;

                font-weight: bold;
                font-size: 17px;
                z-index: 2;
            }

            > .back {
                .content-block;
                .block-round;

                transform: scale(0.98);
                background: #fff;
                z-index: 0;
            }
        }
    }
</style>

<!--Script-->
<script>
    export default {
        data() {
          return {
              strokeDash: 339.292,
              svgSize: 75,
              planClicks: null,
              percentClicksUsed: 0,
              plan: false
          }
        },

        props: {
            user: {
                type: Object,
                default: {}
            }
        },

        created() {
            this.plan = window.plan;

            this.$events.on('feature-not-supported', (feature, option = null) => {
                swal({
                    title: "Upgrade?",
                    text: `Your current plan does not support <strong>${ !option ? this.plan.features[feature].name : _.capitalize(this.plan.features[feature].plan[option]) }</strong>`,
                    html: true,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "More info!",
                    cancelButtonText: "Not now",
                    closeOnConfirm: false
                }, () => {
                    location.href = '/pricing';
                });
            });

            this.planClicks = this.plan.features.clicks.plan[this.plan.planFeatures.clicks];
            this.percentClicksUsed = Number.isInteger(this.planClicks) ? this.user.clicks / this.planClicks : 0;
        },

        computed: {
            trialTime() {
                return moment(this.user.trial_ends_at).fromNow(true);
            },

            isActiveTrial() {
                let trialEndsAt = (new Date(moment(this.user.trial_ends_at))).getTime();
                let hasExpired  = trialEndsAt<Date.now();
                let userOk      = this.user.status === 'ok';

                return this.isTrial && !hasExpired && userOk;
            },

            clicksRemaining() {
                let remainingClicks = this.planClicks - this.user.clicks;

                return remainingClicks >= 1000 ? `${ Math.round(remainingClicks * 10 / 1000) / 10 }K` : remainingClicks;
            },

            counterColor() {
                if (this.percentClicksUsed <= 0.5) {
                    return '#16a085';
                }

                if (this.percentClicksUsed <= 0.75) {
                    return '#ec9e2a';
                }

                return '#c0392b';
            },

            counterOffset() {
                return this.strokeDash - this.strokeDash * this.percentClicksUsed;
            }
        }
    }
</script>
