<!-- Template  -->
<template>
    <div id="product-search"
         ref="parent"
    >
        <div class="search-terms">
            <div id="search-terms-input" class="input-group input-group-hg input-group-rounded flat">
                <span class="input-group-btn">
                    <button type="submit" class="btn">
                        <span class="fa fa-search"></span>
                    </button>
                </span>
                <input id="productname"
                       type="text"
                       v-model="searchstring"
                       class="form-control flat"
                       placeholder="Enter search terms here..."
                       @keyup.enter="searchClick()"
                >
            </div>

            <button id="search-button"
                    @click="searchClick()"
                    class="btn btn-primary"
                    :disabled="searchDisabled"
            >
                <span class="fa fa-search"></span>
            </button>

            <div v-show="searchInvalid" class="search-invalid">
              Please type at least 4 characters
            </div>
        </div>

        <!--///////////////////////// ADVANCED /////////////////////////////-->

        <div class="advanced-settings">
            <div class="wrapper row">
                <!--where to search-->
                <div class="checkboxes col-md-3">
                    <div class="checkbox">
                        <label>
                            <input @change="togShopsClicked"
                                   v-model="togShops"
                                   data-toggle="checkbox"
                                   type="checkbox"
                                   value="togshops"
                            >
                            Shops
                        </label>
                    </div>

                    <div class="checkbox">
                        <label>
                            <input @change="togMineClicked"
                                   v-model="togMine"
                                   data-toggle="checkbox"
                                   type="checkbox"
                                   value="togmine"
                            >
                            <template v-if="isTeamMember">Team</template>
                            <template v-else>My Products</template>
                        </label>
                    </div>
                </div>

                <div class="col-md-9 options">

                    <!--////////////////////////////// SHOPS ////////////////////////////-->

                    <div v-show="togShops"
                         class="form-group shops"
                    >
                        <label>Shops
                            <a v-if="!isTeamMember"
                               href="/shops" target="_blank"
                            >
                                <span class="fa fa-gear"></span>
                            </a>

                            <a class="toggle-select"
                               @click="toggleSelectShops"
                            >{{ allShopsSelected ? 'deselect' : 'select' }} all</a>
                        </label>

                        <select id="shopsSelect"
                                multiple="multiple"
                                class="form-control multiselect multiselect-primary"
                                data-toggle="select"
                        >
                            <option v-for="s in shops"
                                    :value="s.id"
                                    selected
                            >
                                {{ s.name }}
                            </option>
                        </select>
                    </div>

                    <!--//////////////////////////////// TAGS ////////////////////////////-->

                    <tag-select-box owner="user"
                                    :show="true"
                                    @selectionchanged="tagsChanged"
                    ></tag-select-box>
                </div>
            </div>
        </div>


        <!--/////////////////////////////// Results panel /////////////////////////////-->

        <div class="results">
            <div class="results-hint"></div>

            <template v-if="results.length">
                <product v-for="link in results"
                         :userid="userid"
                         :key="link.id"
                         :data="link"
                         :inlist="false"
                         :multiselect="true"
                         @searchsimilar="searchSimilar"
                         @appendtocollection="addToCollection"
                         @prependtocollection="addToCollection($event, true)"
                         @deletelink="deleteLink"
                         background="#ececec"
                ></product>
            </template>

            <div v-else
                 class="msg"
            >
                <template v-if="searchState === 'no-search'">
                    <h4>Search for a product by name</h4>
                    <p>See how search works <a target='_blank' href='/page/search-for-a-product-by-name'>here <span
                            class='fa fa-external-link'></span></a></p>
                </template>

                <template v-if="searchState === 'searching'">
                    <loader side="85px"
                            color="#45b39d"
                            :opacity="1"
                            width="5px"
                    ></loader>
                </template>

                <template v-if="searchState === 'no-results'">
                    <h4>No Results</h4>
                    <h5>Try different search criteria</h5>
                    <p>See how search works <a target='_blank' href='/page/search-for-a-product-by-name'>here <span
                            class='fa fa-external-link'></span></a></p>
                </template>
            </div>
        </div>

        <div v-if="!isTeamMember"
             class="add-custom"
        >
            <hr>
            <button class="btn btn-darkgray btn-embossed"
                    @click="addLink"
            >
                <span class="fa fa-plus"></span>
                Add product
            </button>

            <div>
                <div class="description">You can manually add a product here</div>
                <div class="warning">Make sure you have <a href="/page/search-for-a-product-by-name" target="_blank">searched</a>
                    and tried the <a
                            href="/page/adding-a-product-to-your-list-manually-the-bookmarklet-and-the-add-product-button"
                            target="_blank">bookmarklet</a> first
                </div>
            </div>
        </div>
    </div>
</template>

<!--Scripts-->
<script  >
    import Product from './Product.vue';
    import NoLoadListPreview from './NoLoadListPreview.vue';
    import TagSelectBox from './TagSelectBox.vue';
    import Loader from '../lib/components/Loader.vue';

    import messages from '../lib/config/messages';

    export default{
        components: {
            'product': Product,
            'list-preview': NoLoadListPreview,
            'tag-select-box': TagSelectBox,
            'loader': Loader,
        },

        props: {
            q: {
                type: String
            },
            collectionId: {
                type: Number
            },
            userid: {
                type: Number
            },
            shops: {
                type: Array,
                default: []
            },
            singleLink: {
                type: Boolean,
                default: false
            }
        },

        localStorage: {
            togMine: {
                type: Boolean
            },

            togShops: {
                type: Boolean
            },

            searchstring: {
                type: String
            },

            selectedTags: {
                type: Array
            },

            selectedShops: {
                type: Array
            },

            addLinkMessageShown: {
                type: Boolean
            }
        },

        data () {
            return {
                searchstring: null,
                results: [],
                searchState: 'no-search',
                searchPayload: {},
                togs: [],
                searchDisabled: false,
                searchInvalid: false,

                togMine: false,
                togShops: false,

                selectedTags: [],
                selectedShops: [],
                lastSelectedLinkData: {},
                currentCollectionId: 0,
                currentCollectionLinkIds: [],
                currentCollectionLinks: [],
                currentCollectionUUID: "",
                currentUserId: null,
                showDeleteLinksModal: false,
                showDeleteLinksID: null,
                saveRequest: null
            }
        },

        created() {
            this.selectedShops = this.$localStorage.get('selectedShops');
        },

        mounted()
        {
            if (this.q) {
                this.searchstring = this.q;
            }

            this.$nextTick(() => {
                this.loadSearchState();

                $('#shopsSelect').on('change', event => {
                    this.selectedShops = event.val;
                });
            })
        },

        computed: {
            allShopsSelected() {
                if (this.shops && this.selectedShops) {
                    return this.shops.length === this.selectedShops.length;
                } else {
                    if (!this.selectedShops) {
                        this.selectedShops = [];
                    }
                    return false;
                }
            }
        },

        methods: {
            listSelected() {
                if (this.$refs.modalSelectedCollection.selectedCollectionId === -1) {
                } else {
                    this.modalShow = false;
                    this.$refs.selectedCollection.selectedCollectionId = parseInt(this.$refs.modalSelectedCollection.selectedCollectionId);
                    this.addToCollection(this.lastSelectedLinkData);
                    this.lastSelectedLinkId = 0;
                }
            },

            togShopsClicked() {
                if (!this.togShops && !this.togMine)
                    this.togMine = true;
            },

            togMineClicked() {
                if (!this.togMine && !this.togShops)
                    this.togShops = true;
            },

            toggleSelectShops() {
                if (!this.allShopsSelected) {
                    let allShopIds = _.map(this.shops, 'id');

                    $('#shopsSelect').select2('val', allShopIds);
                    this.selectedShops = allShopIds;
                }
                else {
                    $('#shopsSelect').select2('val', []);
                    this.selectedShops = [];
                }
            },

            searchClick(str = null) {
                if (!this.shouldStartSearch(str)) {
                  return false
                }

                this.results = [];
                this.searchState = 'searching';

                let searchPayload = this.preparePayload(str)
                this.searchFor(searchPayload);
            },

            shouldStartSearch(str) {
              // Search is disabled for 4 secs, after a search.
              if (this.searchDisabled) {
                return false
              }

              // ***
              // Minor patch, in case we reach this point with 'null' as searchString
              // force-set it to empty string
              // ***
              if (this.searchstring === null) {
                this.searchstring = ""
              }

              // Search input must have at least 4 chars to initiate search
              // Special case for 0 chars, search only in user's products.
              this.searchInvalid = false
              if (str === null && this.searchstring.length > 0 && this.searchstring.length < 4) {
                this.searchInvalid = true
                return false
              }

              // Re-enable the search here, after 4 secs.
              this.searchDisabled = true
              setTimeout(() => {
                this.searchDisabled = false
              }, 4000)

              return true
            },

            preparePayload(str) {
              let searchPayload  = {};
              searchPayload.mine = this.togMine;

              if (this.togShops && this.selectedShops) {
                searchPayload.shops = this.selectedShops;
              } else {
                searchPayload.shops = false;
              }

              searchPayload.tags = this.selectedTags;
              searchPayload.searchString = str ? str : this.searchstring;

              return searchPayload
            },

            searchFor(payload) {
                // calls SearchApiController.php
                this.$http.get('/api/search', {
                    params: {
                        search: payload
                    },
                    before: request => {
                        if (this.saveRequest)
                            this.saveRequest.abort();

                        this.saveRequest = request;
                    }
                }).then(
                  (response) => {
                      if (typeof response.body === 'string') {
                        return false;
                      }

                      this.results = response.body

                      if (!this.results.length) {
                          this.searchState = 'no-results';
                      }

                      if (enjoyhint) {
                        enjoyhint.trigger('products-loaded');
                      }
                  },
                  (response) => {}
                )
            },

            searchSimilar(msg) {
                window.scrollTo(0, this.$refs.parent.offsetTop - 10);

                this.searchstring = msg.msg;
                this.searchClick(msg.msg);
            },

            getSelectedCollection() {
                let selectedCollectionId = parseInt(this.$refs.selectedCollection.selectedCollectionId);
                return selectedCollectionId;
            },

            setSelectedCollection(id) {
                this.$refs.selectedCollection = id;
            },

            addToCollection(product, prepend = false) {
                // calls CollectionsController@addLinkToCollection

                let action = prepend
                        ? 'prepend'
                        : 'append';

                if (typeof product.isAmazon !== 'undefined' && product.isAmazon) {
                    this.$http.post(`/api/collection/${ this.collectionId }/addAmazon`, {
                        product: product,
                        action: action
                    }).then(response => {
                        let pivot = response.body;

                        product.id = pivot.product_id;
                        product.pivot = pivot;

                        if (this.singleLink)
                            this.$store.dispatch('setSingleProduct', product);
                        else {
                            this.$store.dispatch(`${ action }Product`, product);
                            this.$events.emit('product-added');
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }
                else {
                    this.$http.post(`/api/collection/${ this.collectionId }/add`, {
                        product: product,
                        action: action
                    }).then(response => {
                        let pivot = response.body;

                        product.pivot = pivot;

                        if (this.singleLink)
                            this.$store.dispatch('setSingleProduct', product);
                        else {
                            this.$store.dispatch(`${ action }Product`, product);
                            this.$events.emit('product-added');
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }
            },
            tagsChanged: function (tags) {
                this.selectedTags = tags;
            },

            deleteLink: function (linkId) {
                swal({
                            title: "Are you sure?",
                            text: "Remove the link from your library?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Remove",
                            closeOnConfirm: false,
                            showLoaderOnConfirm: true
                        },
                        () => {
                            this.$events.emit('removing-link', linkId);

                            this.$http.delete(`/api/links/${ linkId }`).then(
                                    response => {
                                        if (response.body == 'ok') {
                                            this.results = _.reject(this.results, link => link.id === linkId);

                                            swal({
                                                title: "Removed!",
                                                text: "The link has been removed.",
                                                type: "success",
                                                timer: 1000,
                                                showConfirmButton: false
                                            });
                                        } else {
                                            this.$events.emit('link-remove-failed', linkId);

                                            swal({
                                                title: "Error!",
                                                text: "Some error occurred. Please refresh page.",
                                                type: "error",
                                                showConfirmButton: false
                                            });
                                        }
                                    }, error => {
                                        this.$events.emit('link-remove-failed', linkId);

                                        swal({
                                            title: "Error!",
                                            text: "Some error occurred. Please refresh page.",
                                            type: "error",
                                            showConfirmButton: false
                                        });
                                    }
                            )
                        });
            },

            loadSearchState: function () {
                this.togShops = this.$localStorage.get('togShops');
                this.togMine = this.$localStorage.get('togMine');
                this.searchstring = this.$localStorage.get('searchstring');
                this.selectedTags = this.$localStorage.get('selectedTags');

                this.selectedShops = this.$localStorage.get('selectedShops');

                $('#shopsSelect').val(this.selectedShops);
                $('#shopsSelect').trigger('change');
            },

            addLink() {
                this.$events.emit('add-link');

                if (!this.$localStorage.get('addLinkMessageShown')) {
                    this.$localStorage.set('addLinkMessageShown', true);

                    this.$events.emit('notify', messages.add_product_warning);
                }
            }
        },

        watch: {
            togShops: function (val) {
                this.$localStorage.set('togShops', val);
            },
            togMine: function (val) {
                this.$localStorage.set('togMine', val);
            },
            searchstring: function (val) {
                this.$localStorage.set('searchstring', val);
            },
            selectedTags: function (val) {
                this.$localStorage.set('selectedTags', val);
            },
            selectedShops: function (val) {
                this.$localStorage.set('selectedShops', val);
            }
        }
    }
</script>

<!--Styles-->
<style scoped lang="less">
    @import "../../less/mixins";
    @import "../../less/libs/flat-ui/variables";

    .input-group {

        &.flat {

            input {
                padding: 6px 10px 6px 14px;
                font-weight: lighter;
                color: #888;
                font-size: 18px;
            }

            input,
            button {
                border: none;
            }
        }
    }

    #product-search {
        background: #f5f5f5;
        border-radius: 5px;
        padding: 19px;
        margin-bottom: 95px;

        > .search-terms {
            width: 100%;
            display: inline-block;
            margin-bottom: -5px;

            > .input-group {
                width: ~'calc(100% - 80px)';
                float: left;
            }

            > button,
            > span {
                .block-round;

                width: 53px;
                height: 53px;
                margin-left: 19px;
                position: relative;
                float: left;
                font-size: 20px;

                > span {
                    .centered-block;
                }
            }

            > span {
                .trans-default;

                margin-left: 10px;
                font-size: 27px;
                color: #888;
                cursor: pointer;

                &:hover,
                &.open {
                    color: @brand-secondary;
                }
            }

          .search-invalid {
            padding-left: 12px;
            font-size: 14px;
            color: #c81212;
          }
        }

        > .advanced-settings {
            margin-top: 19px;
            padding: 10px;
            border-radius: 5px;
            background: #ececec;
            color: #888;
            user-select: none;

            > .title {
                margin-bottom: 10px;
                font-size: 16px;
                font-weight: bolder;
            }

            > .wrapper {
                padding: 10px;

                > .checkboxes {
                    margin-bottom: -10px;
                }

                > .options {

                    > .shops {

                        > label {
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 1;
                            margin-bottom: 11px;
                            padding-left: 5px;
                            width: 100%;

                            > a {
                                position: relative;
                                top: 2px;
                                left: 2px;
                                font-size: 20px;
                                cursor: pointer;
                            }

                            > .toggle-select {
                                float: right;
                                padding-right: 10px;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }

        > .results {
            width: 100%;
            min-height: 200px;
            margin-top: 19px;
            position: relative;
            display: inline-block;

            > .results-hint {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                pointer-events: none;
                z-index: 99;
                max-height: 256px;
            }

            > .msg {
                .centered-block;

                text-align: center;
                color: #555;
            }
        }

        > .add-custom {
            display: inline-block;
            width: 100%;
            margin-bottom: -5px;

            hr {
                margin-top: 20px;
                margin-bottom: 20px;
                border-top: 2px solid fadeout(#000, 95%);
            }

            > button {
                float: left;

                > span {
                    opacity: 0.7;
                    padding-right: 5px;
                }
            }

            > div {
                float: left;
                padding-left: 15px;
                color: #777;
                font-size: 14px;

                > .warning {
                    line-height: 1;
                    font-size: 12px;
                    color: #999;
                }

                @media (max-width: 528px) {
                    padding-left: 0;
                    padding-right: 15px;
                    padding-top: 10px;
                }

                @media (max-width: 500px) {
                    padding-right: 0;
                }
            }
        }
    }

    .dropdown.dropdown-lg .dropdown-menu {
        margin-top: -1px;
        padding: 6px 20px;
    }

    .input-group-btn .btn-group {
        display: flex !important;
    }

    .btn-group .btn {
        border-radius: 0;
        margin-left: -1px;
    }

    .btn-group .btn:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .btn-group .form-horizontal .btn[type="submit"] {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .form-horizontal .form-group {
        margin-left: 0;
        margin-right: 0;
    }

    .form-group .form-control:last-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    @media screen and (min-width: 768px) {
        #adv-search {
            width: 500px;
            margin: 0 auto;
        }

        .dropdown.dropdown-lg {
            position: static !important;
        }

        .dropdown.dropdown-lg .dropdown-menu {
            min-width: 500px;
        }
    }
</style>

<style lang="less">
    #product-search {

        .multiselect {
            width: ~'calc(100% - 10px)';
            min-height: 0;
        }
    }
</style>
