var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "plan-countdown" } },
    [
      _vm.isTrial
        ? _c("div", { staticClass: "trial-time" }, [
            _c(
              "a",
              { attrs: { href: "/subscription" } },
              [
                _vm.isActiveTrial
                  ? [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("\n                    Trial ends in "),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.trialTime))
                        ])
                      ])
                    ]
                  : [_vm._m(0)]
              ],
              2
            )
          ])
        : [
            _vm.planClicks - _vm.user.clicks > 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "clicks-percent hint--left hint--rounded hint--medium",
                    attrs: {
                      "aria-label":
                        "This is the number of leads available until the end of this month"
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: _vm.svgSize,
                          height: _vm.svgSize,
                          viewBox: "0 0 120 120"
                        }
                      },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "60",
                            cy: "60",
                            r: "54",
                            fill: "none",
                            stroke: "#cccccc",
                            "stroke-width": "12"
                          }
                        }),
                        _vm._v(" "),
                        _c("circle", {
                          staticClass: "progress__value",
                          attrs: {
                            cx: "60",
                            cy: "60",
                            r: "54",
                            fill: "none",
                            stroke: _vm.counterColor,
                            "stroke-dashoffset": _vm.counterOffset,
                            "stroke-width": "12",
                            "stroke-linecap": "round"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "clicks-remaining",
                        style: { color: _vm.counterColor }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.clicksRemaining) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "back" })
                  ]
                )
              : _c("div", { staticClass: "no-clicks-left" }, [_vm._m(1)])
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _vm._v("\n                    Account has "),
      _c("span", { staticClass: "expired" }, [_vm._v("expired")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _vm._v("\n                Monthly leads "),
      _c("span", { staticClass: "expired" }, [_vm._v("outreached")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }