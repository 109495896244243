var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: { "pointer-events": _vm.waiting ? "none" : "auto" },
      attrs: { id: "team-stats" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "choose-mode" }, [
          _c("div", { staticClass: "modes" }, [
            _c(
              "div",
              {
                class: ["mode", { active: _vm.showTeam }],
                on: {
                  click: function($event) {
                    return _vm.setMode("team")
                  }
                }
              },
              [_c("span", [_vm._v("Team")])]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: ["mode", { active: !_vm.showTeam }],
                on: {
                  click: function($event) {
                    return _vm.setMode("user")
                  }
                }
              },
              [_c("span", [_vm._v("Members")])]
            )
          ]),
          _vm._v(" "),
          _c("div", { class: ["choose-user", { show: !_vm.showTeam }] }, [
            _c(
              "select",
              {
                ref: "selectOptions",
                staticClass: "form-control select select-dark select-block",
                attrs: { "data-toggle": "select" }
              },
              _vm._l(_vm.users, function(user, index) {
                return _c(
                  "option",
                  { domProps: { value: user.id } },
                  [
                    index === 0
                      ? [
                          _vm._v(
                            "\n                            " +
                              _vm._s(user.name) +
                              "   |   owner\n                        "
                          )
                        ]
                      : [
                          _vm._v(
                            "\n                            " +
                              _vm._s(user.name) +
                              "\n                        "
                          )
                        ]
                  ],
                  2
                )
              }),
              0
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.showTeam
        ? _c(
            "div",
            [
              _c("statistics", {
                attrs: { data: _vm.teamData, "is-team": true }
              })
            ],
            1
          )
        : [
            !_vm.refreshingUser
              ? _c("statistics", { attrs: { data: _vm.userData } })
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }