var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "list-description product-options" }, [
      _c("div", { staticClass: "option" }, [
        _c("label", { staticClass: "enabled" }, [_vm._v("List Description")]),
        _vm._v(" "),
        this.widgetOptions.description.status
          ? _c("input", {
              attrs: {
                id: "description-box",
                type: "checkbox",
                checked: "",
                "data-toggle": "switch",
                "data-off-color": "default",
                "data-on-color": "success"
              }
            })
          : _c("input", {
              attrs: {
                id: "description-box",
                type: "checkbox",
                "data-toggle": "switch",
                "data-off-color": "default",
                "data-on-color": "success"
              }
            }),
        _vm._v(" "),
        _c("div", { staticClass: "list-description-text" }, [
          _c("textarea", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.widgetOptions.description.status,
                expression: "this.widgetOptions.description.status"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.widgetOptions.description.text,
                expression: "widgetOptions.description.text"
              }
            ],
            staticClass: "form-control",
            attrs: { name: "description", id: "description" },
            domProps: { value: _vm.widgetOptions.description.text },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.widgetOptions.description,
                  "text",
                  $event.target.value
                )
              }
            }
          })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "list-styler row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "product-preview" }, [
          _c("h4", [_vm._v("Item Preview")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "wrapper" },
            [
              _vm.inViewport
                ? _c("widget-product", {
                    attrs: {
                      productdata: _vm.dummyProductData,
                      "product-options": _vm.productOptions
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "product-options" }, [
          _c("div", { staticClass: "option" }, [
            _c("label", { staticClass: "enabled" }, [
              _vm._v("\n            Product list widget style\n          ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "select",
                {
                  ref: "listStyle",
                  staticClass: "form-control select select-dark select-block",
                  attrs: { id: "list-style", "data-toggle": "select" },
                  domProps: {
                    value:
                      _vm.widgetOptions.listStyle === undefined
                        ? "0"
                        : _vm.widgetOptions.listStyle
                  }
                },
                [
                  _c(
                    "option",
                    { domProps: { value: 0, selected: !_vm.isGridStyle } },
                    [_vm._v("\n                Grid\n              ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "option",
                    { domProps: { value: 1, selected: _vm.isGridStyle } },
                    [_vm._v("\n                Slider\n              ")]
                  )
                ]
              ),
              _vm._v(" "),
              _vm._m(0)
            ])
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm.fullFeatureSupport
            ? _c("div", { staticClass: "option" }, [
                _c(
                  "label",
                  { class: { enabled: _vm.productOptions.enabled.background } },
                  [_vm._v("\n            Background color\n          ")]
                ),
                _vm._v(" "),
                _vm.productOptions.enabled.background
                  ? _c("input", {
                      attrs: {
                        id: "background",
                        type: "checkbox",
                        checked: "",
                        "data-toggle": "switch",
                        "data-off-color": "default",
                        "data-on-color": "success"
                      }
                    })
                  : _c("input", {
                      attrs: {
                        id: "background",
                        type: "checkbox",
                        "data-toggle": "switch",
                        "data-off-color": "default",
                        "data-on-color": "success"
                      }
                    }),
                _vm._v(" "),
                _vm.productOptions.enabled.background
                  ? _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "color-field",
                            on: { click: _vm.setChooseBackground }
                          },
                          [
                            _c("div", {
                              staticClass: "inner",
                              style: { background: _vm.backgroundColor }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _vm.chooseBackground
                          ? _c("chrome-picker", {
                              directives: [
                                {
                                  name: "on-clickaway",
                                  rawName: "v-on-clickaway",
                                  value: _vm.unsetChooseBackground,
                                  expression: "unsetChooseBackground"
                                }
                              ],
                              model: {
                                value: _vm.productOptions.background,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.productOptions,
                                    "background",
                                    $$v
                                  )
                                },
                                expression: "productOptions.background"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.fullFeatureSupport
            ? _c("div", { staticClass: "option" }, [
                _c("label", { staticClass: "enabled" }, [
                  _vm._v("\n            Image roundness\n          ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "image-roundness-slider" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "option" }, [
            _c(
              "label",
              { class: { enabled: _vm.productOptions.enabled.title } },
              [_vm._v("\n            Product title\n          ")]
            ),
            _vm._v(" "),
            _vm.productOptions.enabled.title
              ? _c("input", {
                  attrs: {
                    id: "title",
                    type: "checkbox",
                    checked: "",
                    "data-toggle": "switch",
                    "data-off-color": "default",
                    "data-on-color": "success"
                  }
                })
              : _c("input", {
                  attrs: {
                    id: "title",
                    type: "checkbox",
                    "data-toggle": "switch",
                    "data-off-color": "default",
                    "data-on-color": "success"
                  }
                }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.productOptions.enabled.title,
                    expression: "productOptions.enabled.title"
                  }
                ],
                staticClass: "field"
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("label", { staticClass: "col-md-3 control-label" }, [
                    _vm._v("font size")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c("input", {
                      staticClass: "form-control spinner",
                      staticStyle: { background: "#fff" },
                      attrs: {
                        id: "font-size",
                        type: "text",
                        "data-toggle": "spinner",
                        readonly: ""
                      },
                      domProps: { value: _vm.productOptions.fontSize }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("label", { staticClass: "col-md-3 control-label" }, [
                    _vm._v("text lines")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-9" }, [
                    _c(
                      "select",
                      {
                        ref: "textLines",
                        staticClass:
                          "form-control select select-dark select-block",
                        attrs: { id: "text-lines", "data-toggle": "select" },
                        domProps: { value: _vm.productOptions.textLines }
                      },
                      [
                        _vm._l(4, function(tl) {
                          return [
                            tl === _vm.productOptions.textLines
                              ? _c(
                                  "option",
                                  {
                                    attrs: { selected: "" },
                                    domProps: { value: tl }
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(tl) +
                                        " line (s)\n                    "
                                    )
                                  ]
                                )
                              : _c("option", { domProps: { value: tl } }, [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(tl) +
                                      " line (s)\n                    "
                                  )
                                ])
                          ]
                        }),
                        _vm._v("\n                  >\n                ")
                      ],
                      2
                    )
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _vm.fullFeatureSupport
            ? _c("div", { staticClass: "option" }, [
                _c(
                  "label",
                  { class: { enabled: _vm.productOptions.enabled.shopLinks } },
                  [_vm._v("\n            Shop links\n          ")]
                ),
                _vm._v(" "),
                _vm.productOptions.enabled.shopLinks
                  ? _c("input", {
                      attrs: {
                        id: "shop-links",
                        type: "checkbox",
                        checked: "",
                        "data-toggle": "switch",
                        "data-off-color": "default",
                        "data-on-color": "success"
                      }
                    })
                  : _c("input", {
                      attrs: {
                        id: "shop-links",
                        type: "checkbox",
                        "data-toggle": "switch",
                        "data-off-color": "default",
                        "data-on-color": "success"
                      }
                    }),
                _vm._v(" "),
                _vm.productOptions.enabled.shopLinks
                  ? _c("div", { staticClass: "field" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("label", { staticClass: "col-md-3 control-label" }, [
                          _vm._v("display text")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-9" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.productOptions.shopLinks.text,
                                expression: "productOptions.shopLinks.text"
                              }
                            ],
                            staticClass: "form-control",
                            staticStyle: { background: "#fff" },
                            attrs: { placeholder: "(no text)", type: "text" },
                            domProps: {
                              value: _vm.productOptions.shopLinks.text
                            },
                            on: {
                              click: function($event) {
                                return $event.target.select()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.productOptions.shopLinks,
                                  "text",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            : _c("div", { staticClass: "option" }, [
                _c(
                  "label",
                  {
                    class: { enabled: _vm.productOptions.shopLinks.text.length }
                  },
                  [_vm._v('\n            "Buy from"\n          ')]
                ),
                _vm._v(" "),
                _vm.productOptions.shopLinks.text.length
                  ? _c("input", {
                      attrs: {
                        id: "buy-from",
                        type: "checkbox",
                        checked: "",
                        "data-toggle": "switch",
                        "data-off-color": "default",
                        "data-on-color": "success"
                      }
                    })
                  : _c("input", {
                      attrs: {
                        id: "buy-from",
                        type: "checkbox",
                        "data-toggle": "switch",
                        "data-off-color": "default",
                        "data-on-color": "success"
                      }
                    })
              ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row", staticStyle: { "margin-top": "20px" } },
          [
            _c("div", { staticClass: "col-md-12" }, [
              _vm.showReset
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-lg btn-darkgray btn-embossed pull-right",
                      on: { click: _vm.resetStyle }
                    },
                    [_vm._v("\n            Reset\n          ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-lg btn-primary btn-embossed pull-right",
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.saveStyle }
                },
                [_vm._v("\n            Save\n          ")]
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-info",
        staticStyle: { float: "right" },
        attrs: {
          title: "How do Grid and Slider widgets look like?",
          href:
            "/page/how-to-add-your-affiliate-product-list-in-your-newsletter-email",
          target: "_blank"
        }
      },
      [_c("span", { staticClass: "fa fa-question" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "option" }, [
      _c("label", { staticClass: "enabled" }, [
        _vm._v("\n            Thumbnail size\n          ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "thumb-size-slider-wrapper" }, [
        _c("div", { staticClass: "thumb-size-slider" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "legends" }, [
        _c("span", { staticClass: "left small" }, [_vm._v("small")]),
        _vm._v(" "),
        _c("span", { staticClass: "centered medium" }, [_vm._v("medium")]),
        _vm._v(" "),
        _c("span", { staticClass: "right large" }, [_vm._v("large")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }