<template>
    <div ref="container"
         :class="typeClass"
    >
        <slot></slot>
    </div>
</template>

// Style

<style lang="less">
    @import (inline) "~jquery.scrollbar/jquery.scrollbar.css";

    .scroll-wrapper {
        position: relative;
        height: 100%;

        > .scroll-content {
            position: relative;
            height: 100%;
        }
    }
</style>

// Javascript

<script  >
    require('./../vendor/jquery.scrollbar.js');

    export default
    {
        props:
        {
            type: {
                type: String,
                default: 'dynamic'
            },
            scrollTop: {
                type: String,
                default: ''
            },
            scrollBottom: {
                type: Boolean,
                default: false
            },
            scrollOffset: {
                type: Number,
                default: 0
            }
        },

        data() {
          return {
              $scrollbar: null
          }
        },

        computed: {
            typeClass: function()
            {
                return `scrollbar-${this.type}`;
            }
        },

        mounted: function()
        {
            this.$scrollbar = $(this.$refs.container);

            this.$scrollbar.scrollbar({
                onUpdate: () => {
                    try {
                        if (this.scrollBottom)
                        {
                            this.$nextTick(() => {
                                this.$scrollbar.scrollTop(9999);
                            });
                        }
                    } catch (e) {}
                }
            });

            try {
                if (this.scrollTop.length)
                {
                    let offsetTop = $(this.scrollTop).position().top;
//                console.log(offsetTop);

                    if (offsetTop)
                        this.$scrollbar.scrollTop(offsetTop + this.scrollOffset);
                }
            } catch (e) {}
        }
    }
</script>
