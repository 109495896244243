var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "parent", attrs: { id: "product-search" } }, [
    _c("div", { staticClass: "search-terms" }, [
      _c(
        "div",
        {
          staticClass: "input-group input-group-hg input-group-rounded flat",
          attrs: { id: "search-terms-input" }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchstring,
                expression: "searchstring"
              }
            ],
            staticClass: "form-control flat",
            attrs: {
              id: "productname",
              type: "text",
              placeholder: "Enter search terms here..."
            },
            domProps: { value: _vm.searchstring },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchClick()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchstring = $event.target.value
              }
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { id: "search-button", disabled: _vm.searchDisabled },
          on: {
            click: function($event) {
              return _vm.searchClick()
            }
          }
        },
        [_c("span", { staticClass: "fa fa-search" })]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.searchInvalid,
              expression: "searchInvalid"
            }
          ],
          staticClass: "search-invalid"
        },
        [_vm._v("\n          Please type at least 4 characters\n        ")]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "advanced-settings" }, [
      _c("div", { staticClass: "wrapper row" }, [
        _c("div", { staticClass: "checkboxes col-md-3" }, [
          _c("div", { staticClass: "checkbox" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.togShops,
                    expression: "togShops"
                  }
                ],
                attrs: {
                  "data-toggle": "checkbox",
                  type: "checkbox",
                  value: "togshops"
                },
                domProps: {
                  checked: Array.isArray(_vm.togShops)
                    ? _vm._i(_vm.togShops, "togshops") > -1
                    : _vm.togShops
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.togShops,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "togshops",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.togShops = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.togShops = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.togShops = $$c
                      }
                    },
                    _vm.togShopsClicked
                  ]
                }
              }),
              _vm._v("\n                        Shops\n                    ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "checkbox" }, [
            _c(
              "label",
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.togMine,
                      expression: "togMine"
                    }
                  ],
                  attrs: {
                    "data-toggle": "checkbox",
                    type: "checkbox",
                    value: "togmine"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.togMine)
                      ? _vm._i(_vm.togMine, "togmine") > -1
                      : _vm.togMine
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.togMine,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "togmine",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.togMine = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.togMine = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.togMine = $$c
                        }
                      },
                      _vm.togMineClicked
                    ]
                  }
                }),
                _vm._v(" "),
                _vm.isTeamMember ? [_vm._v("Team")] : [_vm._v("My Products")]
              ],
              2
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-9 options" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.togShops,
                    expression: "togShops"
                  }
                ],
                staticClass: "form-group shops"
              },
              [
                _c("label", [
                  _vm._v("Shops\n                        "),
                  !_vm.isTeamMember
                    ? _c("a", { attrs: { href: "/shops", target: "_blank" } }, [
                        _c("span", { staticClass: "fa fa-gear" })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "toggle-select",
                      on: { click: _vm.toggleSelectShops }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.allShopsSelected ? "deselect" : "select") +
                          " all"
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    staticClass: "form-control multiselect multiselect-primary",
                    attrs: {
                      id: "shopsSelect",
                      multiple: "multiple",
                      "data-toggle": "select"
                    }
                  },
                  _vm._l(_vm.shops, function(s) {
                    return _c(
                      "option",
                      { attrs: { selected: "" }, domProps: { value: s.id } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(s.name) +
                            "\n                        "
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c("tag-select-box", {
              attrs: { owner: "user", show: true },
              on: { selectionchanged: _vm.tagsChanged }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "results" },
      [
        _c("div", { staticClass: "results-hint" }),
        _vm._v(" "),
        _vm.results.length
          ? _vm._l(_vm.results, function(link) {
              return _c("product", {
                key: link.id,
                attrs: {
                  userid: _vm.userid,
                  data: link,
                  inlist: false,
                  multiselect: true,
                  background: "#ececec"
                },
                on: {
                  searchsimilar: _vm.searchSimilar,
                  appendtocollection: _vm.addToCollection,
                  prependtocollection: function($event) {
                    return _vm.addToCollection($event, true)
                  },
                  deletelink: _vm.deleteLink
                }
              })
            })
          : _c(
              "div",
              { staticClass: "msg" },
              [
                _vm.searchState === "no-search"
                  ? [
                      _c("h4", [_vm._v("Search for a product by name")]),
                      _vm._v(" "),
                      _vm._m(1)
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchState === "searching"
                  ? [
                      _c("loader", {
                        attrs: {
                          side: "85px",
                          color: "#45b39d",
                          opacity: 1,
                          width: "5px"
                        }
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.searchState === "no-results"
                  ? [
                      _c("h4", [_vm._v("No Results")]),
                      _vm._v(" "),
                      _c("h5", [_vm._v("Try different search criteria")]),
                      _vm._v(" "),
                      _vm._m(2)
                    ]
                  : _vm._e()
              ],
              2
            )
      ],
      2
    ),
    _vm._v(" "),
    !_vm.isTeamMember
      ? _c("div", { staticClass: "add-custom" }, [
          _c("hr"),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-darkgray btn-embossed",
              on: { click: _vm.addLink }
            },
            [
              _c("span", { staticClass: "fa fa-plus" }),
              _vm._v("\n            Add product\n        ")
            ]
          ),
          _vm._v(" "),
          _vm._m(3)
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-btn" }, [
      _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
        _c("span", { staticClass: "fa fa-search" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("See how search works "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "/page/search-for-a-product-by-name"
          }
        },
        [_vm._v("here "), _c("span", { staticClass: "fa fa-external-link" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("See how search works "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "/page/search-for-a-product-by-name"
          }
        },
        [_vm._v("here "), _c("span", { staticClass: "fa fa-external-link" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "description" }, [
        _vm._v("You can manually add a product here")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "warning" }, [
        _vm._v("Make sure you have "),
        _c(
          "a",
          {
            attrs: {
              href: "/page/search-for-a-product-by-name",
              target: "_blank"
            }
          },
          [_vm._v("searched")]
        ),
        _vm._v("\n                and tried the "),
        _c(
          "a",
          {
            attrs: {
              href:
                "/page/adding-a-product-to-your-list-manually-the-bookmarklet-and-the-add-product-button",
              target: "_blank"
            }
          },
          [_vm._v("bookmarklet")]
        ),
        _vm._v(" first\n            ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }