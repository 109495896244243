<!--Template-->

<template>
    <div v-show="show"
         class="form-group tags"
         id="tags-select"
    >
        <label>Tags
            <a v-if="owner === 'user'"
               @click="manageTags"
            >
                <span class="fa fa-gear"></span>
            </a>
        </label>

        <select ref="tagsSelect"
                multiple="multiple"
                class="form-control multiselect multiselect-primary"
                data-toggle="select"
        >
            <template v-for="tag in tags">
                <option v-if="tag.selected"
                        :value="tag.id"
                        selected
                >
                    {{ tag.name }}
                </option>

                <option v-else
                        :value="tag.id"
                >
                    {{ tag.name }}
                </option>
            </template>
        </select>
    </div>
</template>

<!--Script-->
<script  >
    export default{
        localStorage: {
            selectedTags: {
                type: Array
            }
        },

        props: {
            show: false,
            readonly: {
                type: Boolean
            },
            owner: {
                // this can be "user", "link"
                type: String,
                required: true
            },
            linkid: {
                // this used only with 'link' owner
                type: Number
            },
            linkTags: {
                type: Array
            },
        },

        data(){
            return {
                selected: [],
                $tagsSelect: null,
                innerLinkTags: []
            }
        },

        computed: {
            tags: {
                get: function() {
                    switch (this.owner) {
                        case "user":
                            return this.userTags;
                        case "link":
                            return this.innerLinkTags;
                    }
                },
                set: function(val) {
                    switch (this.owner) {
                        case "user":
                            this.userTags = val;
                            break;
                        case "link":
                            this.innerLinkTags = val;
                            break;
                    }
                }
            },

            userTags: {
                get: function() {
                    return this.$store.getters.getUserTags;
                },
                set: function(val) {
                    this.$store.dispatch('setUserTags', val);
                }
            }
        },

        created() {
            if (this.owner === 'user') {
                _.each(this.$localStorage.get('selectedTags'), (value) => {
                    this.selected.push(value);
                });
            }

            this.$events.on('tags-loaded', () => {
                this.mapTags(this.selected);

                this.$nextTick(() => {
                    this.$tagsSelect.select2('destroy').select2();
                })

                this.$tagsSelect.off().on('change', (e) => {
                    this.$emit("selectionchanged", e.val);
                });

                if (this.owner === 'link')
                {
                    this.$tagsSelect.on('select2-opening', (e) => {
                        e.preventDefault();
                    });
                }
            })

            this.$events.on('tag-edited', () => {
                this.$nextTick(() => {
                    this.refreshLinkTags();
                })
            })
        },

        mounted() {
            this.$tagsSelect = $(this.$refs.tagsSelect);
        },

        methods: {
            mapTags(selection) {
                this.tags = _.map(this.tags, (tag) => {
                    if (_.indexOf(selection, '' + tag.id) !== -1)
                        tag.selected = true;
                    else
                        tag.selected = false;

                    return tag;
                });
            },

            manageTags() {
                this.$events.emit('manage-user-tags');
            },

            refreshLinkTags(val = null) {
                let userTags = _.cloneDeep(this.userTags);

                this.innerLinkTags = _.filter(userTags, (tag) => {
                    if (_.indexOf(val || this.linkTags, tag.id) !== -1)
                    {
                        tag.selected = true;
                        return tag;
                    }
                });
            }
        },

        watch: {
            linkTags: function(val) {
                this.refreshLinkTags(val);
            },

            userTags: function() {
                if (this.owner === 'link')
                    this.refreshLinkTags();
            },

            tags: function() {
                this.$nextTick(() => {
                    this.$tagsSelect.select2('destroy').select2();
                });
            }
        }
    }
</script>

<!--Style-->
<style lang="less" scoped>
    .tags {
        margin-bottom: 0;

        > label {
            font-weight: bold;
            font-size: 16px;
            line-height: 1;
            margin-bottom: 11px;
            padding-left: 5px;

            > a {
                position: relative;
                top: 2px;
                left: 2px;
                font-size: 20px;
                cursor: pointer;
            }
        }
    }
</style>

<style lang="less">
    #tags-select {

        .multiselect {
            width: ~'calc(100% - 10px)';
            min-height: 0;
        }
    }
</style>
