var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { side: _vm.side, open: _vm.isOpen, closing: _vm.closing },
      attrs: { id: "teasers" }
    },
    [
      _vm.title.length
        ? _c("div", [
            _c("h1", [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c("br")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "search" }, [
        _c("div", { staticClass: "form-group" }, [
          _c(
            "div",
            { staticClass: "input-group input-group input-group-rounded" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "Search" },
                on: { input: _vm.debounceInput }
              })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { ref: "teasers", staticClass: "teasers" },
        [
          _c(
            "scrollbar",
            {
              attrs: {
                type: "macosx",
                "scroll-top": ".current",
                "scroll-offset": 50
              }
            },
            _vm._l(_vm.filteredDocs, function(doc, index) {
              return _c(
                "section",
                {
                  key: doc.id,
                  class: { current: _vm.isCurrent(doc) },
                  on: {
                    click: function($event) {
                      _vm.side ? _vm.openDoc(doc.post_name) : function() {}
                    }
                  }
                },
                [
                  _vm.editing
                    ? [
                        doc.post_status === "draft"
                          ? _c("div", { staticClass: "editing" }, [
                              _c("span", [_vm._v("draft")])
                            ])
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("image-square", {
                    attrs: {
                      src: doc.thumb,
                      "spinner-color": "#bbb",
                      "spinner-side": "30px"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "wrapper" }, [
                    _c("h5", { staticClass: "title" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openDoc(doc.post_name)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(doc.post_title) +
                              "\n                        "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.teaserText(doc.post_content)))]),
                    _vm._v(" "),
                    !_vm.side
                      ? _c(
                          "aside",
                          [
                            _vm.parentPath === "blog"
                              ? _c("span", { staticClass: "date" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.dateFormat(doc.post_date)) +
                                      "\n                    "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            doc.post_status !== "draft"
                              ? [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: {
                                        click: function($event) {
                                          return _vm.openDoc(doc.post_name)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Read more\n                            "
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.editing
                              ? [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function($event) {
                                          return _vm.openEdit(doc.ID)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Edit\n                            "
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                ],
                2
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "gradient" })
        ],
        1
      ),
      _vm._v(" "),
      _vm.side
        ? [
            !_vm.isOpen
              ? _c(
                  "div",
                  {
                    staticClass: "open-button btn btn-primary",
                    on: { click: _vm.open }
                  },
                  [_c("span", { staticClass: "fa fa-search" })]
                )
              : _c(
                  "div",
                  {
                    staticClass: "close-button btn btn-primary",
                    on: { click: _vm.close }
                  },
                  [_c("span", { staticClass: "fa fa-close" })]
                )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-btn" }, [
      _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
        _c("span", { staticClass: "fui-search" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }