var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["container", { show: _vm.show }], attrs: { id: "add-products" } },
    [
      _c("collection", {
        attrs: {
          collection: _vm.collection,
          templates: _vm.templates,
          "adding-products": true,
          loader: _vm.loader.url
        }
      }),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            show: _vm.styleModal.show,
            large: true,
            title: "List styling",
            "ok-class": "hidden",
            "cancel-text": "Close"
          },
          on: {
            cancel: function($event) {
              _vm.styleModal.show = false
            }
          }
        },
        [
          _c("list-styler", {
            attrs: {
              "is-global": false,
              data: _vm.listStyle.data,
              defaults: _vm.listStyle.defaults
            },
            on: { save: _vm.saveStyle }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("product-list", {
        attrs: { user: _vm.user, collection: _vm.collection, links: _vm.links }
      }),
      _vm._v(" "),
      _c("search-products", {
        attrs: {
          "collection-id": _vm.collection.id,
          userid: _vm.user.id,
          shops: _vm.shops
        }
      }),
      _vm._v(" "),
      _c("tags-modal", {
        attrs: {
          show: _vm.tagsModal.show,
          "product-id": _vm.tagsModal.productId
        },
        on: {
          cancel: function($event) {
            _vm.tagsModal.show = false
          }
        }
      }),
      _vm._v(" "),
      _c("product-modal", {
        attrs: {
          show: _vm.addProductModal.show,
          data: _vm.addProductModal.data
        },
        on: { cancel: _vm.onProductModalClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }