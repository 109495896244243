<template>
  <div class="grid-item">
    <div class="thumbnail ld-thumb"
         :style="{ 'background-color': backgroundColor, 'height': height }"
    >
      <!--<div class="row-num">{{ row }}</div>-->
      <div class="image-wrapper">
        <image-square :src="linkdata.thumb_path"
                      :alt="linkdata.title"
                      :opacity="0.7"
                      :roundness="imageRoundness"
                      spinner-color="#aaa"
                      @image-errored="requestImageRePull"
        >
          <a class="link-layer"
             :href="linkUrl(firstShop)"
             target="_blank"
             rel="nofollow"
          ></a>
        </image-square>
      </div>

      <div v-if="options.enabled.title"
           class="title-wrapper"
           :style="{ 'min-height': titleMinHeight }"
      >
        <div class="product-title"
             ref="title"
             @click="resetTrunk"
             :style="{ 'font-size': fontSize }"
        >
          {{ title }}
        </div>
      </div>

      <template v-if="options.enabled.shopLinks">
        <div v-if="options.shopLinks.text !== null && options.shopLinks.text.length"
             class="caption ld-caption"
        >
          <div class="shopfrom-title">{{ options.shopLinks.text }}</div>
        </div>

        <div class="shop_thumb_container">
          <div class="shops-wrapper">
                <span v-for="shop in shops"
                      class="shop_thumb_wrapper hint--top hint--rounded"
                      :aria-label="shop.name"
                >
                <span>
                    <logo-image :src="shop.thumb"
                                side="26px"
                                extra-classes="shop_thumb"
                                :is-round="false"
                    >
                        <a :href="linkUrl(shop)"
                           target="_blank"
                           rel="nofollow"
                        ></a>
                    </logo-image>
                </span>
            </span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
import {product as options} from '../lib/config/widget';
import ImageSquare from '../lib/components/ImageSquare';
import LogoImage from '../lib/components/LogoImage';
import '../lib/vendor/jquery.trunc8.js';

export default {
  props: {
    productdata: null,
    productOptions: null,
    columns: null,
    index: null
  },
  data() {
    return {
      linktarget: "",
      linkdata: {
        thumb_path: "",
        title: "loading"
      },
      shops: [],
      computedHeight: null,
      height: 'auto'
    }
  },
  computed: {
    firstShop() {
      return this.shops[0];
    },
    title() {
      let customTitle = this.linkdata.pivot.title || this.linkdata.custom_title;

      return customTitle ? customTitle : this.linkdata.title;
    },

    options() {
      return _.merge(options, this.productOptions || {});
    },
    backgroundColor() {
      if (this.options.enabled.background) {
        let color = this.options.background.rgba;

        return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
      } else
        return 'transparent';
    },
    imageRoundness() {
      return `${this.options.imageRoundness || 50}%`;
    },
    fontSize() {
      return `${this.options.fontSize || 11}px`;
    },
    textLines() {
      return `${this.options.textLines || 2}px`;
    },
    titleMinHeight() {
      return `${(this.options.fontSize || 11) * this.options.textLines + 6}px`;
    },
    row() {
      if (this.columns)
        return parseInt(this.index / this.columns) + 1;
      else
        return null;
    }
  },
  created() {
    this.prepareData();

    this.linkdata = this.productdata.links[0];
    this.linktarget = '/go/' + this.linkdata.pivot.id;
  },

  mounted() {
    this.trunk();

    this.$events.on('reset-trunk', () => {
      $(this.$refs.title).trunk8('revert');
    });

    this.$events.on('row-height-changed', () => {
      let height = this.$store.state.rowHeights[this.row];

      if (height)
        this.height = `${height}px`;
    });

    this.$events.on('row-heights-reset', () => {
      this.height = 'auto';

      this.$nextTick(() => {
        setTimeout(() => {
          this.computedHeight = this.$el.offsetHeight;
        }, 10);
      });
    });
  },
  components: {
    'image-square': ImageSquare,
    'logo-image': LogoImage,
  },
  methods: {
    linkUrl(shop) {
      return `${shop.link}`;
    },



//            sendReferrer: function () {
//                return true;
//            },

    prepareData: function () {
      let _self = this;

      _.each(_self.productdata.links, link => {
        let iconpath = '/favicon.ico';
        if (link.icon) {
          iconpath = link.icon.path;
        }

        _self.shops.push({
          thumb: iconpath,
          name: link.icon.name,
          link: link.finalUrl
        });
      });
    },
    resetTrunk() {
//                this.$events.emit('reset-trunk');
    },
    trunk() {
      $(this.$refs.title).trunk8({
        lines: this.options.textLines
      });

      setTimeout(() => {
        this.computedHeight = this.$el.offsetHeight;
      }, 50);
    },
    requestImageRePull() {
      // image broken. So, if product update is over e.g. one day in the past, try to repull image
      let currentDate    = new Date();

      let imageUpdatedAt = new Date(this.linkdata.updated_at);
      let dateDiffInDays = Math.floor((currentDate - imageUpdatedAt) / (1000 * 60 * 60 * 24));

      if (dateDiffInDays > 5) {   // haven't tried refreshing for five days now
        this.$http.get(`/api/links/${this.linkdata.id}/repullimage`, {}).then(response => {
          let r = response.body;


          if (r == null || r == "") {
            // no new image
            console.log("No new image");

          } else {
            // new image, reload
            this.linkdata.thumb_path = r;

            console.log("Loading new image");
            console.log(r);

          }

        }).catch(error => {
          console.log(error);
        });

      }
    }
  },
  watch: {
    fontSize() {
      this.trunk();
    },
    textLines() {
      this.trunk();
    },
    row() {
      setTimeout(() => {
        let height = this.$store.state.rowHeights[this.row];

        if ((height || 0) < this.computedHeight) {
          this.$store.dispatch('setRowHeight', {row: this.row, height: this.computedHeight});
          this.$events.emit('row-height-changed');
        }
      }, 50);
    }
  }
}
</script>


<style lang="less">
@import "../../less/mixins";

.grid-item {
  margin: 4px;
  cursor: default;
  /*background-color: #0a6ebd;*/

  .ld-thumb {
    margin-bottom: 8px;
    line-height: 1;
    /*transition: border .2s ease-in-out;*/
    border: none;
    border-radius: 6px;
    padding: 0;
    /*overflow: hidden;*/

    > div:last-child {
      padding-bottom: 10px;
    }

    > .image-wrapper {
      padding: 10px;

      > .image {
        overflow: hidden;
        /* padding: 60px; */
        padding: 8px;
        position: relative;

        > .thumb {
          background: #fff;

          > img {
            max-width: 72%;
            max-height: 72%;
          }

          > .link-layer {
            .content-block;
            .centered-block;

            background: transparent;
          }
        }
      }
    }

    > .title-wrapper {
      padding: 0 12px;
      padding-top: 6px;
      margin-bottom: 5px;

      > .product-title {
        text-align: center;
        color: fadeout(#000, 25%);
        transition: font-size 0.2s ease;
      }
    }

    > .ld-caption {
      margin-top: 10px;
      padding: 2px;
      /*background: #e7e7e7;*/

      .shopfrom-title {
        font-size: 12px;
        text-align: center;
        margin: 0;
        font-weight: bold;
      }
    }

    > .shop_thumb_container {
      text-align: center;
      padding: 10px;
      /*background: #444;*/
      margin: 0 -3px;

      > .shops-wrapper {
        position: relative;

        > .shop_thumb_wrapper {
          display: inline-block;
          max-width: 25%;

          > span {
            padding-left: 2px;
            padding-right: 2px;
            display: inline-block;
            position: relative;
            max-width: 100%;

            > .shop_thumb {
              display: inline-block;
              max-width: 100%;

              background: #fff;
              overflow: hidden;
              border-radius: 3px;
              padding: 0;

              &.errored {
                padding: 3px !important;
              }

              > span {
                max-width: 100%;

                > img {
                  border: none;
                  max-width: 100%;
                }
              }

              > a {
                display: block;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                z-index: 5;
              }
            }
          }
        }
      }
    }
  }
}
</style>
