<!--Receives a product list id and displays small thumbs preview with remove icons-->
<!--Template-->
<template>
        <!--<ul id="sortable" v-bind:class="{'sortable': editable}">-->
        <!--<ul class="list">-->
        <!--<li v-for="l in links">-->
        <!--<product :data=l :inlist=true :size=xs></product>-->
        <!--</li>-->
        <!--</ul>-->

    <div class="list-preview">
        <template v-if="uniqueLinks.length">
            <scrollbar type="macosx">
                <div class="viewport">
                    <product v-for="link in uniqueLinks"
                             :data="link"
                             :inlist="true"
                             tsize="t"
                             :key="link.id"
                             :joined="limkNum(link)"
                    ></product>
                </div>
            </scrollbar>
        </template>

        <span v-else
              class="no-preview"
        >
            empty list
        </span>
    </div>
</template>

<!--Style-->
<style lang='less'>
    @import "../../less/mixins";


    .list-preview {
        min-height: 75px;
        width: 100%;
        margin-right: -10px;
        margin-bottom: 2px;
        padding-top: 15px;
        /*background: fadeout(#000, 90%);*/
        position: relative;
        user-select: none;

        > .vue-scrollbar__wrapper {
            background: transparent;
        }

        > .no-preview {
            .centered-block;

            text-transform: uppercase;
            opacity: 0.5;
            font-weight: bolder;
        }

        .scroll-wrapper {
            max-height: 92px !important;
        }

        .viewport {
            display: table;
            padding-bottom: 17px;

            > .product {
                display: table-cell;
            }
        }
    }
</style>

<!--Script-->
<script  >
    import Product from './Product.vue'
    import Scrollbar from '../lib/components/Scrollbar.vue'

    export default{
        components: {
            product: Product,
            scrollbar: Scrollbar
        },

        props: {
            listid: {
                type: String
            },
            links: {
                type: Array,
                default: []
            }
        },
        created() {
            this.groupedLinks = _.groupBy(this.links, link => {
                return link.pivot.group_id;
            });
            this.uniqueLinks = _.sortedUniqBy(this.links, link => {
                if (link.pivot.group_id > 0)
                    return link.pivot.group_id;
                else
                    return link.pivot.id;
            });
        },
        mounted() {
//            console.log(this.groupedLinks);
//            console.log(this.uniqueLinks);
//            this.fetchLinks(this.listid);
        },
        data(){
            return {
                uniqueLinks: [],
                groupedLinks: []
            }
        },
        methods: {
            limkNum(link) {
                let groupId = link.pivot.group_id;

                if (groupId)
                    return this.groupedLinks[groupId].length;
                else
                    return null;
            }
//            fetchLinks() {
//                let self = this;
//
//                this.$http.get('/api/collection/' + this.listid).then(
//                        (response) => {
//                            self.links = response.body;
//                        },
//                        (response) => {
//                            console.log("in death");
//                        }
//                )
//            }
        },
        events: {}
    }
</script>
