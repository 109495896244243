var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-wrapper col-md-6" }, [
    _c(
      "div",
      {
        class: ["list", { checked: _vm.checked }],
        on: { click: _vm.toggleSelection }
      },
      [
        _c("div", { staticClass: "details" }, [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("div", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.creationDate))
          ])
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "preview",
            attrs: {
              href:
                "http://www.inlinkz.com/new/previewProject.php?id=" + _vm.id,
              target: "_blank"
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [_vm._m(0)]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("div", { staticClass: "text" }, [_vm._v("preview")]),
      _vm._v(" "),
      _c("div", { staticClass: "fa fa-eye" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }