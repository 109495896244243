var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "single-link-info" }, [
        _vm._v("\n        Search for a product and then select it\n    ")
      ]),
      _vm._v(" "),
      _c("search-products", {
        attrs: {
          "collection-id": _vm.collectionId,
          userid: _vm.userId,
          shops: _vm.shops,
          "single-link": true
        }
      }),
      _vm._v(" "),
      _c("tags-modal", {
        attrs: {
          show: _vm.tagsModal.show,
          "product-id": _vm.tagsModal.productId
        },
        on: {
          cancel: function($event) {
            _vm.tagsModal.show = false
          }
        }
      }),
      _vm._v(" "),
      _c("product-modal", {
        attrs: {
          show: _vm.addProductModal.show,
          data: _vm.addProductModal.data,
          "single-link": true
        },
        on: { cancel: _vm.onProductModalClose }
      }),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            id: "web-code",
            title: "Grab the code",
            "cancel-text": "Close",
            show: _vm.codeShow,
            "cancel-class": "btn-darkgray",
            "ok-class": "hidden",
            full: true
          },
          on: { cancel: _vm.closeCodeModal }
        },
        [
          _c("div", { staticClass: "code-category" }, [_vm._v("HTML link")]),
          _vm._v(" "),
          _c("pre", { ref: "htmlItem" }, [_vm._v(_vm._s(_vm.htmlLink))]),
          _vm._v(" "),
          _c("div", { staticClass: "code-preview" }, [
            _c("div", { staticClass: "description" }, [_vm._v("preview")]),
            _vm._v(" "),
            _c("div", {
              staticClass: "preview",
              domProps: { innerHTML: _vm._s(_vm.htmlLink) }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { display: "inline-block", width: "100%" } },
            [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.htmlLink,
                      expression: "htmlLink",
                      arg: "copy"
                    }
                  ],
                  staticClass: "btn btn-primary btn-primary pull-right",
                  on: {
                    click: function($event) {
                      return _vm.textSelect(_vm.$refs.htmlItem)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-clipboard",
                    staticStyle: { "margin-right": "10px" },
                    attrs: { "aria-hidden": "true" }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Copy")])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "code-category" }, [_vm._v("link URL")]),
          _vm._v(" "),
          _c("pre", { ref: "linkPlain" }, [_vm._v(_vm._s(_vm.linkPlain))]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { display: "inline-block", width: "100%" } },
            [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.linkPlain,
                      expression: "linkPlain",
                      arg: "copy"
                    }
                  ],
                  staticClass: "btn btn-primary btn-primary pull-right",
                  on: {
                    click: function($event) {
                      return _vm.textSelect(_vm.$refs.linkPlain)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-clipboard",
                    staticStyle: { "margin-right": "10px" },
                    attrs: { "aria-hidden": "true" }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Copy")])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("br")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }