var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        show: _vm.show,
        full: _vm.full,
        title: _vm.title,
        okText: _vm.okText,
        cancelText: _vm.cancelText,
        removeClose: _vm.removeClose,
        opacity: "half"
      },
      on: { cancel: _vm.cancel, ok: _vm.getStarted }
    },
    [
      _c(
        "div",
        {
          staticClass: "content-wrapper",
          staticStyle: { "text-align": "center" }
        },
        [
          _c("div", { staticClass: "media" }, [
            _c("img", {
              attrs: { src: _vm.badgeImg, alt: "15 day trial image" }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("span", [_vm._v("Start a Risk-Free 15-Day Trial")]),
            _vm._v(" "),
            _c("br"),
            _vm._v("\n            (No Credit Card Required)\n\n            "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "small-letters" }, [
              _vm._v("\n                Trial plan offers all "),
              _c("strong", [_vm._v("PLATINUM")]),
              _vm._v(" features\n            ")
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }